import { SxProps, Theme } from "@mui/material";
import { ReactNode, CSSProperties } from "react";
import { CommonItem } from "../CommonInfo";

export const reportGridStyle: React.CSSProperties = {
  display: 'inline-grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(min(10rem, 100%), 1fr))',
  gap: '1rem 0.5rem',
  alignContent: 'start',
  justifyContent: 'space-around',
  marginTop: 24,
  marginLeft: 24,
  // height:0 // hack so browsers don't add unneccesary whitespace and scrollbar
}

export const visuallyHidden: SxProps<Theme> = {
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px'
};

export const Report = ({ style, children }: { style: CSSProperties, children: ReactNode }) => <div style={{ ...reportGridStyle, ...style }}>{children}</div>

export const ReportGridHeading = ({ title, icon, children }: {
  title: string;
  icon?: ReactNode;
  children: ReactNode;
}) => (
  <div style={{ gridColumn: '1/-1' }}><CommonItem title={title} icon={icon ?? <></>}>{children}</CommonItem></div>
);