import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import { uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavigationBar } from '../Components/NavigationBar';
import { useAllInvites } from '../auth/useAllInvites';
import { useAllUsers } from '../auth/useAllUsers';
import { useCreateProject } from '../auth/useCreateProject';
import { AccessItem, ClientProjectAccessItem, useInviteUser } from '../auth/useInviteUser';
import { useLogout } from '../auth/useLogout';
import { CID_PID_PAIRS, SUPER_ADMIN, UserAccess } from '../store/user';

export const SuperAdmin = () => {
  const { execute: refreshUsers, data: users } = useAllUsers();
  const { execute: refreshInvites, data: userInvites } = useAllInvites();
  const navigate = useNavigate()
  const superAdmin = useAtomValue(SUPER_ADMIN);
  useEffect(() => {
    if (!superAdmin) {
      navigate('/')
    }
  }, [superAdmin, navigate])


  const [newlyCreated, setNewlyCreated] = useState<UserAccess[]>([]);
  const clientProjects = useAtomValue(CID_PID_PAIRS);
  const clientInputColour = useCallback(
    (client: string) =>
      !client
        ? undefined
        : [...clientProjects, ...newlyCreated].find(({ clientId }) => clientId === client)
          ? 'success'
          : 'warning',
    [clientProjects, newlyCreated],
  );
  const projectInputColour = useCallback(
    (client: string, project: string) =>
      !client || !project
        ? undefined
        : [...clientProjects, ...newlyCreated].find(
          ({ clientId, projectId }) => clientId === client && projectId === project,
        )
          ? 'success'
          : 'warning',
    [clientProjects, newlyCreated],
  );

  const [email, setEmail] = useState('');
  const [emailClientId, setEmailClientId] = useState('');
  const [emailProjectId, setEmailProjectId] = useState('');

  const [newClientId, setNewClientId] = useState('');
  const [newProjectId, setNewProjectId] = useState('');

  const emailAccess = useMemo<AccessItem[]>(
    () => [
      {
        type: 'client-project',
        clientId: emailClientId,
        projectId: emailProjectId,
      },
    ],
    [emailClientId, emailProjectId],
  );

  const newClientProjectAccess = useMemo<ClientProjectAccessItem>(
    () => ({
      type: 'client-project',
      clientId: newClientId,
      projectId: newProjectId,
    }),
    [newClientId, newProjectId],
  );

  const {
    execute: createUser,
    data: emailInviteData,
    error: emailInviteError,
  } = useInviteUser(emailAccess, email);

  const {
    execute: createProject,
    data: createProjectData,
    error: createProjectError,
  } = useCreateProject(newClientProjectAccess);

  useEffect(() => {
    refreshUsers();
    refreshInvites();
  }, [refreshUsers, refreshInvites]);

  useEffect(() => {
    refreshUsers();
  }, [refreshUsers, emailInviteData]);

  useEffect(() => {
    if (createProjectData && createProjectData.length > 1) {
      setNewlyCreated(prev =>
        uniqBy(
          [...prev, ...createProjectData.flatMap(x => (x.type === 'superadmin' ? [] : x))],
          ({ clientId, projectId }) => `${clientId}/${projectId}`,
        ),
      );
    }
  }, [createProjectData, setNewlyCreated]);

  return !superAdmin ? <></> : (
    <>
      <NavigationBar currentPath='super-admin' />
      <Stack p={2} spacing={4} marginTop={2}>
        <Paper>
          <Stack p={2} direction="column" spacing={2}>
            <>
              <Typography variant="h6">Create new project</Typography>
              <Stack direction="row" spacing={2}>
                <TextField label="Client ID" onChange={e => setNewClientId(e.target.value)} />
                <TextField label="Project ID" onChange={e => setNewProjectId(e.target.value)} />
              </Stack>
              <Button onClick={createProject} disabled={!newClientId || !newProjectId}>
                Create project
              </Button>
              {createProjectData && createProjectData.length === 1 && (
                <Typography variant="caption">Project already exists</Typography>
              )}
              {createProjectData && createProjectData.length > 1 && (
                <Typography variant="caption" color="greenyellow">
                  Project created!
                </Typography>
              )}
              {createProjectError && (
                <Typography variant="caption" color="red">
                  Error creating project
                </Typography>
              )}
            </>
          </Stack>
        </Paper>
        <Paper>
          <Stack p={2} direction="column" spacing={2}>
            <>
              <Typography variant="h6">Send email invite</Typography>
              <Stack direction="row" spacing={2}>
                <TextField label="Email" onChange={e => setEmail(e.target.value)} />
                <TextField
                  label="Client ID"
                  onChange={e => setEmailClientId(e.target.value)}
                  color={clientInputColour(emailClientId)}
                />
                <TextField
                  label="Project ID"
                  onChange={e => setEmailProjectId(e.target.value)}
                  color={projectInputColour(emailClientId, emailProjectId)}
                />
              </Stack>
              <Button onClick={createUser} disabled={!email || !emailClientId || !emailProjectId}>
                Send email invite
              </Button>
              {emailInviteData && (
                <Typography variant="caption" color="greenyellow">
                  Sent!
                </Typography>
              )}
              {emailInviteError && (
                <Typography variant="caption" color="red">
                  Ruh roh!
                </Typography>
              )}
            </>
          </Stack>
        </Paper>
        <Paper>
          <Stack p={2} direction="column" spacing={2}>
            <Typography variant="h6">Active email invites</Typography>
            {userInvites?.map(invite => (
              <Paper elevation={6} key={invite.id}>
                <Box p={2} component="div">
                  <Typography component="pre">ID: {invite.id}</Typography>
                  <Typography component="pre">
                    Access:{' '}
                    {invite.access
                      .map(access =>
                        access.type === 'superadmin'
                          ? 'STAFF'
                          : `${access.clientId}/${access.projectId}`,
                      )
                      .join(',')}
                  </Typography>
                  <Typography component="pre">
                    Created at: {new Date(invite.createdAt).toString()}
                  </Typography>
                </Box>
              </Paper>
            ))}
            <Button onClick={refreshInvites}>Refresh invites</Button>
          </Stack>
        </Paper>
        <Paper>
          <Stack p={2} spacing={2}>
            <Typography variant="h6">Users</Typography>
            {users?.map(user => (
              <Paper elevation={6} key={user.id}>
                <Box p={2} component="div">
                  <Typography component="pre">ID: {user.id}</Typography>
                  <Typography component="pre">Label: {user.label}</Typography>
                  <Typography component="pre">
                    Access:{' '}
                    {user.access
                      .map(access =>
                        access.type === 'superadmin'
                          ? 'STAFF'
                          : `${access.clientId}/${access.projectId}`,
                      )
                      .join(',')}
                  </Typography>
                  <Typography component="pre">
                    Created at: {new Date(user.createdAt).toString()}
                  </Typography>
                </Box>
              </Paper>
            ))}
            <Button onClick={refreshUsers}>Refresh users</Button>
          </Stack>
        </Paper>
      </Stack>
    </>
  );
};
