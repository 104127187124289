import { useMemo } from 'react';
import { SearchSidebar } from '../../../util/SearchSidebar/SearchSidebar';
import { PortableAsset } from '../../api/PortableAsset.validator';
import { ServiceConfig } from '../../api/ServiceConfig.validator';
import { useEditingData } from '../EditTools/useEditingData';
import { useMapData } from '../MapData/useMapData';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

export const EditServiceConfigSidebar = () => {
  const map = useMapData();
  const { currentEdit, setCurrentEdit, servieConfigEdits } = useEditingData();
  const { showSecondary } = useSidebarInteraction();

  const highlightedUuid = currentEdit?.type === 'portable' ? currentEdit.asset.uuid : undefined;

  const serviceConfigs = useMemo(() => {
    const existing = map.data?.serviceConfigs.filter(sc => !servieConfigEdits[sc.id]) ?? [];
    const creating = Object.values(servieConfigEdits).filter((sc): sc is ServiceConfig => !!sc && !('delete' in sc))
    return [...existing, ...creating].map(sc => servieConfigEdits[sc.id] ? { ...sc, edited: true } : { ...sc, edited: false })
  }, [map.data?.serviceConfigs, servieConfigEdits])

  const addNewServiceConfig: ServiceConfig & { edited: boolean } = {
    id: 'fresh-*',
    name: '+ Add new configuration...',
    clientId: map.cid,
    projectId: map.pid,
    state_json: { underground_config: {} },
    edited: false,
  };

  return (
    <SearchSidebar
      title="Configurations"
      onClick={(id: string, label: string) => {
        const asset = id === addNewServiceConfig.id
          ? { ...addNewServiceConfig, id: `fresh-${new Date().getTime()}`, name: '' }
          : serviceConfigs.find(sc => sc.id === id);
        setCurrentEdit(asset ? { type: 'service config', asset: { ...asset, uuid: undefined } } : undefined);
        if (asset) {
          showSecondary(Secondary.NONE);
        }
      }}
      hide={() => showSecondary(Secondary.NONE)}
      options={
        [addNewServiceConfig, ...serviceConfigs]
          .map(({ id, name, edited }) => ({
            label: `${edited ? '* ' : ''}${name}${edited ? ` [${id.includes('fresh') ? 'creating' : 'edited'}]` : ''}`,
            id,
            selected: highlightedUuid === id,
          }))
          .sort(({ label: a }, { label: b }) => a.replace(/^\+ /, ' ').localeCompare(b.replace(/^\+ /, ' ')))
      }
    />
  );
};
