import axios from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { TIMED_OUT } from '../store/auth';
import { AUTHN_URL } from '../store/url';
import { useAsyncTask } from '../util/AsyncTask';

export const useAcceptInviteCallback = ({
  password,
  secret,
  email,
}: {
  password: string;
  secret: string;
  email: string;
}) => {
  const setTimedOut = useSetAtom(TIMED_OUT);
  const httpBaseUrl = useAtomValue(AUTHN_URL);
  const Authorization = `Basic ${btoa(`${email}:${password}`)}`;

  const accept = useCallback(async () => {
    await axios.post(`${httpBaseUrl}/open/user/accept-email-invite`, {
      secret,
      email,
      password,
    });

    const {
      data: { accessJwt, refreshJwt },
    }: { data: { accessJwt: string; refreshJwt: string } } = await axios.post(
      `${httpBaseUrl}/open/jwt/basic`,
      {},
      {
        headers: {
          Authorization,
        },
      },
    );
    if (!accessJwt || !refreshJwt) throw new Error('Missing expected access/refresh JWTs');

    setTimedOut(false);
    return { accessJwt, refreshJwt };
  }, [httpBaseUrl, Authorization, setTimedOut, password, secret, email]);

  return useAsyncTask<{ accessJwt: string; refreshJwt: string }, typeof accept>(accept);
};
