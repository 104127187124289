import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Divider, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

export const WelfareSidebar = () => {
  const { showSecondary } = useSidebarInteraction();
  const { highlight } = useMapInteraction();

  return (
    <List component="div" disablePadding>
      <ListItem button onClick={() => showSecondary(Secondary.NONE)}>
        <ListItemIcon>
          <ArrowBack />
        </ListItemIcon>
      </ListItem>
      <Divider />
      <Box p={2} component='div'>
        <Typography variant='h6'>Welfare</Typography>
      </Box>
      <ListItem button onClick={() => {
        highlight({ category: 'welfare', id: 'check', label: 'Welfare check' });
        showSecondary(Secondary.NONE)
      }}>
        <Button variant="outlined" fullWidth={true}>Welfare Check</Button>
      </ListItem>
    </List>
  );
};