import { Typography } from '@mui/material';
import { uniqBy } from 'lodash';
import { AssetState, LiveMapState } from '../../../util/Events/Messages';
import { stringifyIdRecord } from '../../../util/stringUtils';
import { useSecondsAgo } from '../../../util/useSecondsAgo';
import { isActiveForNearby } from '../../util/portableAssetUtils';
import { useMapData } from '../MapData/useMapData';
import { HeartbeatIconVerbose } from '../WorldOverlay/HeartbeatIcon/HeartbeatIcon';
import { BatteryInfo, CommonItem, UpdateInfo } from './CommonInfo';
import { NearbyBeaconsReport } from './Report/NearbyBeacons';

export const beaconsNearAsset = ({ asset, liveState, now }: { asset?: AssetState, liveState?: LiveMapState, now: Date }) => {
  if (!(asset?.type === 'mqttgateway' || asset?.type === 'rfiReader' || asset?.isMicrofence)) return;

  const sensedNearby = uniqBy(
    (asset?.recentSensed?.filter(({ iso8601 }) => isActiveForNearby(iso8601, now))) || [],
    s => stringifyIdRecord(s.ids.id)
  );

  return sensedNearby.map((sensed) => {
    const id = stringifyIdRecord(sensed.ids.id);
    const asset = liveState?.assets.get(id);
    return { id, label: asset?.label ?? sensed.ids.label ?? JSON.stringify(sensed.ids.id), asset }
  });
}

export const LocatorInfo = ({ id }: { id: string }) => {
  const { data: mapData, getNow } = useMapData();

  const asset = mapData?.liveData?.state.assets.get(id);
  const relatedAsset = asset?.type === 'gateway' ? mapData?.liveData?.state.assets.get(id.replace('gatewayId', 'locatorId')) : undefined;

  const lastHeartbeatIso8601 = asset?.lastHeartbeat?.iso8601 ??
    asset?.lastBattery?.iso8601 ??
    relatedAsset?.lastHeartbeat?.iso8601 ??
    relatedAsset?.lastBattery?.iso8601;
  const secondsSinceLastHeartbeat = useSecondsAgo({
    from: lastHeartbeatIso8601 ? new Date(lastHeartbeatIso8601) : undefined,
    getNow
  });

  const beaconsNearby = beaconsNearAsset({ asset, liveState: mapData?.liveData?.state, now: getNow() });

  if (!asset) {
    return <></>;
  } else if (asset.type === 'locator') {
    return (
      <>
        <BatteryInfo batteryPercentage={asset.lastBattery?.percent} />
        <HeartbeatInfo secondsSinceLastHeartbeat={secondsSinceLastHeartbeat} />
        {beaconsNearby && <NearbyBeaconsReport nearby={beaconsNearby} />}
      </>
    );
  } else if (asset.type === 'mqttgateway') {
    return (
      <>
        <UpdateInfo lastUpdate={asset.lastUpdate} />
        {beaconsNearby && <NearbyBeaconsReport nearby={beaconsNearby} />}
      </>
    );
  } else {
    return (
      <>
        <HeartbeatInfo secondsSinceLastHeartbeat={secondsSinceLastHeartbeat} />
      </>
    );
  }
};

export const HeartbeatInfo = ({ secondsSinceLastHeartbeat }: { secondsSinceLastHeartbeat?: number }) => {
  return (
    <>
      <CommonItem
        icon={<HeartbeatIconVerbose secondsSinceLast={secondsSinceLastHeartbeat} />}
        title="Last heartbeat:"
      >
        <Typography>
          {secondsSinceLastHeartbeat !== undefined
            ? `${secondsSinceLastHeartbeat} seconds ago`
            : `unknown`}
        </Typography>
      </CommonItem>
    </>
  );
};
