import { minutesToMilliseconds } from 'date-fns';
import { ReactNode, useEffect } from 'react';
import { useTimedOut } from './useTimedOut';

const ACTIVE_EVENTS = ['mousemove', 'mousedown', 'touchstart', 'touchmove', 'keydown'];

export const ExpireSessionOnInactivity = ({ children }: { children: ReactNode }) => {
  const timedOut = useTimedOut();

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    const inactivityTimer = () => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        timedOut();
      }, minutesToMilliseconds(60*4));
    };

    ACTIVE_EVENTS.forEach(event => window.addEventListener(event, inactivityTimer, true));
    inactivityTimer();

    return () => {
      clearTimeout(timerId);
      ACTIVE_EVENTS.forEach(event => window.removeEventListener(event, inactivityTimer, true));
    };
  }, [timedOut]);

  return <>{children}</>;
};
