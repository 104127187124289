import { Button, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DropdownNumberValues } from '../../../util/FormControl/Dropdown';
import { NavmeshLevel, NavmeshLink } from '../../api/Navmesh.validator';
import { useMapData } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { useEditingData } from './useEditingData';

export const EditInfoLevel = ({ level }: { level: NavmeshLevel, }) => {
  const { data: mapData, cid: clientId, pid: projectId } = useMapData();
  const { level: selectedLevel, setLevel } = useMapInteraction();
  const { setCurrentEdit, dispatchNavmeshLevelEdit, dispatchNavmeshLinkEdit, multiselectedNavmeshLinkIds, dispatchMultiselectNavmeshLinkId, applyNavmeshEdits } = useEditingData();
  const [newId, setNewId] = useState(isNaN(level.id) ? '' : level.id.toString());
  const [newName, setNewName] = useState(level.name);
  const [deleting, setDeleting] = useState(false);
  const [reassignedLevel, setReassignToLevel] = useState<number>();

  const reassignLevelOptions = useMemo(() => (applyNavmeshEdits(mapData?.navMesh ?? { clientId, projectId })?.levels ?? [])
    .filter(({ id }) => id !== Number(newId))
    .map(({ id, name }) => ({ value: id, label: name })),
    [applyNavmeshEdits, mapData?.navMesh, clientId, projectId, newId]
  );

  const isValid = !!Number(newId) && !!(newName.trim())

  const isNew = isNaN(level.id);

  const selectedLinks = useMemo(
    () => (applyNavmeshEdits(mapData?.navMesh ?? { clientId, projectId })?.links ?? [])
      .filter(link => multiselectedNavmeshLinkIds[link.id]),
    [applyNavmeshEdits, mapData?.navMesh, clientId, projectId, multiselectedNavmeshLinkIds]
  );

  const levelLinks = useMemo(
    () => (applyNavmeshEdits(mapData?.navMesh ?? { clientId, projectId })?.links ?? [])
      .filter(link => link.level === Number(newId)),
    [applyNavmeshEdits, mapData?.navMesh, clientId, projectId, newId]
  );

  useEffect(() => { // handle level changing while open
    setNewId(isNaN(level.id) ? '' : level.id.toString());
    setNewName(level.name);
  }, [level.id, level.name])


  const dispatchEdit = useCallback(() => {
    if (!isValid) throw new Error('UI should not try to dispatch invalid data');

    const update: NavmeshLevel & { uuid: undefined } = {
      id: Number(newId),
      name: newName.trim(),
      uuid: undefined,
    };
    dispatchNavmeshLevelEdit(update);
  }, [isValid, newId, newName, dispatchNavmeshLevelEdit])

  const dispatchDeletion = useCallback(() => {
    if (!isNaN(Number(newId))) {
      dispatchNavmeshLevelEdit({ id: Number(newId), delete: true });
    }
    if (levelLinks.length) {
      const edits = levelLinks.map(link => ({ ...link, level: reassignedLevel, uuid: undefined }));
      dispatchNavmeshLinkEdit(edits);
    }
  }, [newId, dispatchNavmeshLevelEdit, levelLinks, reassignedLevel, dispatchNavmeshLinkEdit])

  const dispatchRevert = useCallback(() => {
    dispatchNavmeshLevelEdit({ id: Number(newId), revert: true });
  }, [newId, dispatchNavmeshLevelEdit]);

  const canSetLinkLevels = !isNew && selectedLinks.length;
  const dispatchSelectedLinkEdits = useCallback((links: NavmeshLink[]) => {
    if (!canSetLinkLevels || !links.length) throw new Error('UI should not try to dispatch empty data');

    const linkEdits = links.map(link => ({ ...link, level: level.id, uuid: undefined }));

    dispatchNavmeshLinkEdit(linkEdits);

  }, [canSetLinkLevels, level.id, dispatchNavmeshLinkEdit])

  return (<>
    <Grid item maxWidth="100%" container direction="row" spacing={2}>
      <Grid item xs={4}>
        <TextField required disabled={!isNew} label="ID number" variant="outlined" fullWidth value={newId} onChange={(e) => setNewId(e.target.value.replace(/\D/g, ''))} />
      </Grid>
      <Grid item xs={8}>
        <TextField required label="Name" variant="outlined" fullWidth value={newName} onChange={(e) => setNewName(e.target.value)} />
      </Grid>
    </Grid>
    <Grid item container>
      <Button disabled={!isValid} variant="contained" onClick={dispatchEdit}>Set</Button>
      <Button variant="outlined" onClick={dispatchRevert}>Undo changes</Button>
      <Button disabled={deleting} color='warning' variant="outlined" onClick={() => setDeleting(true)}>Delete</Button>
    </Grid>
    {deleting && (<>
      <Grid item>
        <Typography>Select new level for {levelLinks.length} links</Typography>
      </Grid>
      <Grid item maxWidth="100% !important">
        <DropdownNumberValues label='Level' selected={reassignedLevel} setSelected={val => setReassignToLevel(val !== undefined ? Number(val) : undefined)} items={reassignLevelOptions} />
      </Grid>

      <Grid item container>
        <Button color='warning' variant="outlined" onClick={dispatchDeletion}>Delete level and reassign links</Button>
        <Button variant="outlined" onClick={() => setDeleting(false)}>Cancel</Button>
      </Grid>
    </>)}
    <Grid item>
      <FormControlLabel control={<Switch checked={selectedLevel === Number(newId)} onChange={e => setLevel(e.target.checked ? (Number(newId) ?? undefined) : undefined)} />} label="View only this level" />
    </Grid>
    <Grid item container>
      <Button disabled={!canSetLinkLevels} variant="contained" onClick={() => dispatchSelectedLinkEdits(selectedLinks)}>Set level for {selectedLinks.length} selected {selectedLinks.length === 1 ? 'link' : 'links'}</Button>
    </Grid>
  </>);
};
