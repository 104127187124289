import axios, { AxiosError, AxiosResponse } from "axios";
import { useAtomValue } from "jotai";
import { ReactNode, useEffect } from 'react';
import { REFRESH_JWT_TOKEN } from "../store/auth";
import { useLogout } from "./useLogout";
import { useRefresh } from "./useRefresh";

const isUnauthedError = (errorResponse: AxiosResponse | Error) => 'message' in errorResponse && errorResponse.message.includes('401');
const isProbablyCorsError = (errorResponse: AxiosResponse | Error) => errorResponse instanceof AxiosError && errorResponse.message === 'Network Error' && errorResponse.status === undefined;
const getConfigOrUndefined = (errorResponse: AxiosResponse | Error) => 'config' in errorResponse ? errorResponse.config : undefined;

const jsonParseOrUndefined = (stringified: string) => {
  try {
    return JSON.parse(stringified);
  } catch {
    return undefined;
  }
}

export const ExpireSessionOn401 = ({ children }: { children: ReactNode }) => {
  const logout = useLogout();
  const refresh = useRefresh();
  const refreshToken = useAtomValue(REFRESH_JWT_TOKEN);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (successResponse: AxiosResponse<unknown>) => successResponse,
      async (errorResponse: AxiosResponse | Error) => {
        if (isProbablyCorsError(errorResponse)) {
          logout();
        } else if (isUnauthedError(errorResponse)) {
          const refreshResponse = await refresh(refreshToken);
          const config = getConfigOrUndefined(errorResponse);
          if (!config?.headers?.["X-is-retry"] && config) {
            const headers = {
              Authorization: `Bearer ${refreshResponse.accessJwt}`,
              "X-is-retry": "1",
            };
            const data = jsonParseOrUndefined(config.data) ?? config.data;
            return axios({ ...config, data, headers });
          } else {
            logout();
            return Promise.reject(errorResponse);
          }
        }
        return Promise.reject(errorResponse);
      }
    );

    return () => axios.interceptors.response.eject(interceptor);
  }, [logout, refresh, refreshToken]);

  return <>{children}</>;
};
