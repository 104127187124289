import { Slider, SliderValueLabelProps, Tooltip, SxProps, Theme } from "@mui/material"
import { useState } from "react";
import { labelFromSliderValue, timeFromSliderValue, timeToSliderValue } from './timeSliderUtils';

const ValueLabelComponent = (props: SliderValueLabelProps) => {
  const { children, value } = props;
  return (
    <Tooltip
      enterTouchDelay={0}
      placement="bottom"
      title={labelFromSliderValue(value)}
    >
      {children}
    </Tooltip>
  );
}

export const TimeSlider = ({ current, onSelected, disabled, sx }: { current: Date, onSelected: (date: Date) => void, disabled?: boolean, sx?: SxProps<Theme> }) => {
  const [userMovedTo, setUserMovedTo] = useState<number | undefined>();

  const minVal = timeToSliderValue(-1, 59); // 11:59pm previous day
  const maxVal = timeToSliderValue(24, 1); // 12:01am next day

  const today = new Date();
  const isToday = today.getFullYear() === current.getFullYear() && today.getMonth() === current.getMonth() && today.getDate() && today.getDate() === current.getDate();
  const maxSelectable = isToday ? timeToSliderValue(today.getHours(), today.getMinutes()) : maxVal


  const styles = {
    ...(sx || {}),
    cursor: userMovedTo ? 'none' : undefined,
    "& .MuiSlider-thumb:active": {
      cursor: "none"
    },
  };


  return (<Slider
    sx={styles}
    disabled={disabled}
    size="small"
    min={minVal}
    max={maxVal}
    value={userMovedTo ?? timeToSliderValue(current.getHours(), current.getMinutes())}
    aria-label="Replay time"
    valueLabelDisplay="auto"
    slots={{
      valueLabel: ValueLabelComponent,
    }}
    color="secondary"
    onChange={(_event, value) => {
      setUserMovedTo(Array.isArray(value) ? value[0] : value)
    }}
    onChangeCommitted={(_event, rawValue) => {
      const value = Math.min(Number(rawValue), maxSelectable);
      const { hours, minutes } = timeFromSliderValue(value);
      const selected = new Date(current.getFullYear(), current.getMonth(), current.getDate(), hours, minutes);
      onSelected(selected);
      setUserMovedTo(undefined);
    }}
  />)
}
