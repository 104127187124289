import { Work } from '@mui/icons-material';
import { Box, Link, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { compareDesc } from 'date-fns';
import { Vector3 } from 'three';
import { stringifyIdRecord } from '../../../../util/stringUtils';
import { LocatedAsset } from '../../../api/LocatedAsset.validator';
import { locatedAssetOnLevelFilter } from '../../../util/assetUtils';
import { levelIdOfPoint } from '../../../util/findNavmeshPath';
import { portbaleAssetsFromLiveData, useMapData, locatorsFromLiveData } from '../../MapData/useMapData';
import { Highlighted, useMapInteraction } from '../../MapInteraction/useMapInteraction';
import { BatteryIconVerbose } from '../../WorldOverlay/BatteryIcon/BatteryIcon';
import { UpdateInfo } from '../CommonInfo';
import { Report, ReportGridHeading, visuallyHidden } from './Report';


export const LocatorGridItem = ({ locator, plain }: { locator: LocatedAsset & { position: Vector3 }, plain?: boolean }) => {
  const { highlight } = useMapInteraction();
  const { data: mapData } = useMapData();
  const ids = { locatorId: locator.id };
  const label = locator.label ?? locator.id;
  const asset = mapData?.liveData?.state.assets.get(stringifyIdRecord(ids));

  return (
    <Stack direction='row' key={stringifyIdRecord(ids)} display='flex' alignItems='stretch' paddingLeft={1} sx={{ gridColumn: "auto / span 2", verticalAlign: 'center' }}>
      <Typography sx={{ flexGrow: 1 }}>
        {plain
          ? <span>{label}</span>
          : <Link onClick={() => highlight({ id: stringifyIdRecord(ids), category: 'locator', label })}>{label}</Link>
        }
      </Typography>
      <Typography variant='caption' sx={{ width: '8em', alignItems: 'center', display: 'flex', marginRight: '8em' }}>
        <span title={asset?.lastBattery?.percent === undefined ? 'Unknown battery level' : 'Battery level'}><BatteryIconVerbose percentage={asset?.lastBattery?.percent} /></span>
        {asset?.lastBattery?.percent !== undefined && <strong>{Math.round(asset.lastBattery.percent)}%</strong>}
        {asset?.lastBattery?.percent === undefined && <Box component="span" sx={visuallyHidden}>Unknown battery level</Box>}
      </Typography>
    </Stack>
  )
}

export const LocatorsReport = ({ height, plain, level }: { height?: number, plain?: boolean, level?: number }) => {
  const { data: mapData } = useMapData();
  const onSelectedLevel = locatedAssetOnLevelFilter(level, mapData?.navMesh);

  const assets = (mapData?.liveData ? locatorsFromLiveData(mapData?.liveData, { includeSurface: true }) : []).filter(onSelectedLevel);
  const locators = (mapData?.locatedAssets || [])
    .filter(l => l.type === 'locator')
    .filter(asset => onSelectedLevel({ positionVector: asset.position }))
  //   if (level === undefined) return true;
  //   if (!mapData?.navMesh || !asset.position) return false;

  //   return levelIdOfPoint(asset.position, mapData.navMesh) === level;
  // });

  const lastUpdated = assets.flatMap(state => state.lastUpdate ?? []).sort(compareDesc).at(0) ?? mapData?.liveData?.state?.lastUpdate;

  return (
    <>
      <UpdateInfo lastUpdate={lastUpdated} />
      <Report style={{ height }}>
        <ReportGridHeading title='Locators' icon={<Work />}>
          <Typography>Battery levels</Typography>
        </ReportGridHeading>
        {locators.length > 0 && locators.map(locator => LocatorGridItem({ locator, plain }))}
        {locators.length === 0 && <Typography sx={{ gridColumn: '1 / -1' }}>No locators in project</Typography>}
      </Report>
    </>
  );
};
