import { useMemo } from 'react';
import { SearchSidebar } from '../../../util/SearchSidebar/SearchSidebar';
import { Microfence } from '../../api/Microfence.validator';
import { MicrofencePair } from '../../api/MicrofencePair.validator';
import { useEditingData } from '../EditTools/useEditingData';
import { useMapData } from '../MapData/useMapData';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

export const EditMicrofenceSidebar = () => {
  const map = useMapData();
  const { currentEdit, setCurrentEdit, microfenceEdits, microfencePairEdits, } = useEditingData();
  const { showSecondary } = useSidebarInteraction();

  const highlightedId = currentEdit?.type === 'microfence' && 'assetId' in currentEdit.asset && currentEdit.asset.id || undefined;
  const highlightedPairId = currentEdit?.type === 'microfence' && 'upstreamId' in currentEdit.asset && currentEdit.asset.id || undefined;

  const microfences: (Microfence & {edited:boolean, pair:boolean, uuid:undefined})[] = useMemo(() => {
    const existing = map.data?.microfences?.filter(m => !microfenceEdits[m.id]) ?? [];
    const creating = Object.values(microfenceEdits).filter((m): m is Microfence => !!m && !('delete' in m));
    return [...existing, ...creating].map(m => microfenceEdits[m.id] ? {...m, edited: true, pair:false, uuid:undefined} : { ...m, edited: false, pair:false, uuid:undefined});
  }, [map.data?.microfences, microfenceEdits])

  
  const microfencePairs: (MicrofencePair & {edited:boolean, pair:boolean, uuid:undefined})[] = useMemo(() => {
    const existing = map.data?.microfencePairs?.filter(mp => !microfencePairEdits[mp.id]) ?? [];
    const creating = Object.values(microfencePairEdits).filter((m): m is MicrofencePair => !!m && !('delete' in m));
    return [...existing, ...creating].map(m => microfencePairEdits[m.id] ? {...m, edited: true, pair:true, uuid:undefined} : { ...m, edited: false, pair:true, uuid:undefined});
  }, [map.data?.microfencePairs, microfencePairEdits])

  const addNewMicrofence: Microfence & {edited: boolean, pair: boolean, uuid:undefined} = {
    id: 'fresh-*',
    name: '+ Add new microfence...',
    clientId: map.cid,
    projectId: map.pid,
    type: 'locator',
    boundaryRssi: -90,
    timeoutSeconds: 60,
    assetId: {locatorId: ''},
    edited: false,
    pair: false,
    uuid: undefined,
  };

  const addNewMicrofencePair: MicrofencePair & {edited: boolean, pair: boolean, uuid:undefined} = {
    id: 'fresh-**',
    name: '+ Add new pair...',
    clientId: map.cid,
    projectId: map.pid,
    type: 'locator',
    boundaryRssi: -90,
    timeoutSeconds: 120,
    upstreamId: {locatorId: ''},
    downstreamId: {locatorId: ''},
    isTagboard: false,
    edited: false,
    pair: false,
    uuid: undefined,
  };

  return (
    <SearchSidebar
      title="Microfences"
      onClick={(pairAndId: string, label: string) => {
        const [pair, id] = pairAndId.split('__');
        const collection: ((Microfence & {edited:boolean, pair:boolean, uuid:undefined})|(MicrofencePair & {edited:boolean, pair:boolean, uuid:undefined}))[] = pair ? microfencePairs : microfences;

        const addNew = id === addNewMicrofence.id ? addNewMicrofence : id === addNewMicrofencePair.id ? addNewMicrofencePair : undefined;

        const asset: ((Microfence | MicrofencePair) & {uuid:undefined}) | undefined = addNew
          ? { ...addNew, id: `fresh-${new Date().getTime()}`, name: '', uuid:undefined }
          : collection.filter(m => m.id === id).map(m => { const {edited, pair, ...rest} = m; return rest; })[0];

        setCurrentEdit(asset ? { type: 'microfence', asset } : undefined); 
        if (asset) {
          showSecondary(Secondary.NONE);
        }
      }}
      hide={() => showSecondary(Secondary.NONE)}
      options={
        [addNewMicrofence, addNewMicrofencePair, ...microfences, ...microfencePairs]
          .map(({ id, name, edited, pair }) => ({
            label: `${edited ? '* ' : ''}${name ?? id}${edited ? ` [${id.includes('fresh') ? 'creating' : 'edited'}]` : ''}`,
            id: `${pair ? 'pair' : ''}__${id}`,
            selected: id === (pair ? highlightedPairId : highlightedId)
          }))
          .sort(({ label: a }, { label: b }) => a.replace(/^\+ /, ' ').localeCompare(b.replace(/^\+ /, ' ')))
      }
    />
  );
};
