import axios from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { TIMED_OUT } from '../store/auth';
import { AUTHN_URL } from '../store/url';
import { useAsyncTask } from '../util/AsyncTask';

export const useRecoverJwts = ({ secret }: { secret: string }) => {
  const setTimedOut = useSetAtom(TIMED_OUT);
  const httpBaseUrl = useAtomValue(AUTHN_URL);

  const recover = useCallback(async () => {
    const {
      data: { accessJwt, refreshJwt },
    } = await axios.post(`${httpBaseUrl}/open/user/accept-recovery-token`, { secret });
    if (!accessJwt || !refreshJwt) throw new Error('Missing expected access/refresh JWTs');
    setTimedOut(false);

    return { accessJwt, refreshJwt };
  }, [httpBaseUrl, setTimedOut, secret]);

  return useAsyncTask<{ accessJwt: string; refreshJwt: string }, typeof recover>(recover);
};
