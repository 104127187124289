import { Alert, Link, Snackbar, Stack } from '@mui/material';
import { differenceInMinutes } from 'date-fns';
import { uniqBy } from 'lodash';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { NOTIFICATION_AGE_LIMIT_MINUTES } from '../../config';
import { Notification } from '../../util/Events/Messages';
import { SMALL_WIDTH } from '../../util/Sidebar/Sidebar';
import { useMobile } from '../../util/useMobile';
import { useSecondsAgo } from '../../util/useSecondsAgo';
import { useWideScreen } from '../../util/useWideScreen';
import { useMapData } from '../map/MapData/useMapData';
import { useMapInteraction } from '../map/MapInteraction/useMapInteraction';


export const NotificationsToast = () => {
  const { data: map, getNow } = useMapData();
  const { highlight } = useMapInteraction();
  const isMobile = useMobile();

  const [seenNotificationIds, setSeenNotificationIds] = useState<Map<string, boolean>>(new Map());
  const [lastUnseenNotification, setLastUnseenNotification] = useState<Notification>();


  useEffect(() => {
    const now = getNow();
    const latestUnseenNotification = map?.liveData?.state.notifications
      .filter(n => !seenNotificationIds.get(n.notificationId) && differenceInMinutes(now, n.date.getTime()) < NOTIFICATION_AGE_LIMIT_MINUTES)
      .sort((a, b) => b.date.getTime() - a.date.getTime())
      .at(0);
    if (latestUnseenNotification && seenNotificationIds.get(latestUnseenNotification.notificationId) === undefined) {
      seenNotificationIds.set(latestUnseenNotification.notificationId, false);
      setLastUnseenNotification(latestUnseenNotification);
    }
  }, [getNow, map?.liveData?.state.notifications, seenNotificationIds])

  const setNotificationSeen = useCallback((notificationId: string) => {
    setSeenNotificationIds(m => {
      const seens = Array.from(m.entries()).filter(([_, s]) => s);
      return new Map([...seens, [notificationId, true]])
    })
  }, [setSeenNotificationIds])

  const notificationSecondsAgo = useSecondsAgo({ from: lastUnseenNotification?.date, getNow })

  return (<>
    {lastUnseenNotification && (
      <Snackbar open={!seenNotificationIds.get(lastUnseenNotification.notificationId)}
        sx={{
          marginLeft: isMobile ? 0 : `${SMALL_WIDTH}px`,
          marginBottom: `${SMALL_WIDTH}px`,
        }}
        autoHideDuration={60_000}
        onClose={(e, reason) => {
          if (reason === 'clickaway') return;
          setNotificationSeen(lastUnseenNotification.notificationId)
        }}>
        <Alert
          onClose={e => { e.stopPropagation(); setNotificationSeen(lastUnseenNotification.notificationId); }}
          onClick={() => { setNotificationSeen(lastUnseenNotification.notificationId); highlight(lastUnseenNotification.asset); }}
          severity="error"
          sx={{
            width: '100%',
            border: '1px solid rgb(244, 67, 54)',
            cursor: 'pointer',
          }}>
          <Stack direction='column'>
            <strong>{lastUnseenNotification.title}:</strong>
            <span>
              <Link onClick={() => {
                setNotificationSeen(lastUnseenNotification.notificationId);
                highlight(lastUnseenNotification.asset);
              }}>{lastUnseenNotification.asset.label}</Link>, {notificationSecondsAgo} seconds ago.
            </span>
          </Stack>
        </Alert>
      </Snackbar>
    )}
  </>
  );
};
