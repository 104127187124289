import {
  Grid,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { atom } from 'jotai';
import { clientProjectHack } from '../auth/clientProjectHack';
import { LIVE_TIME } from '../store/liveEvents';
import { ActiveTagsReport } from '../underground/map/HighlightedInfo/Report/ActiveTagsReport';
import { CrossingsReport } from '../underground/map/HighlightedInfo/Report/CrossingsReport';
import { LocatorsReport } from '../underground/map/HighlightedInfo/Report/LocatorsReport';
import { SafeZonesReport } from '../underground/map/HighlightedInfo/Report/SafeZonesReport';
import { Map } from '../underground/map/Map/Map';
import { MapInterpolationProvider } from '../underground/map/MapData/useInterpolatedData';
import { MapDataProvider, useMapData } from '../underground/map/MapData/useMapData';
import { MapInteractionProvider } from '../underground/map/MapInteraction/useMapInteraction';
import { LatestEventsProvider } from '../util/Events/LatestEventsProvider';
import { ProjectLiveEventsProvider } from '../util/Events/LiveEventsProvider';
import { LiveStateUpdate } from '../util/Events/Messages';
import { Report, useSidebarInteraction } from './sidebars/useSidebarInteraction';
import { useIsUndergroundProject } from './useIsUndergroundProject';

const ReportContainer = ({ clientId, projectId, level, children }: { clientId: string, projectId: string, level?: number, children: React.ReactNode }) => {
  const { data: mapData } = useMapData();
  const { navigateTo } = useSidebarInteraction();

  const [displayClientId, displayProjectId] = clientProjectHack(clientId, projectId);

  const levelLabel = mapData?.navMesh?.levels?.find(l => l.id === level)?.name ?? level;
  return (
    <Paper elevation={2}>
      <Stack p={2} direction='column' spacing={2}>
        <Typography variant='h6'>
          <Link onClick={() => navigateTo(clientId, projectId)}>{displayClientId} / {displayProjectId}</Link>{level !== undefined && (
            <Typography component='span' sx={{ paddingLeft: 1 }}> Level {levelLabel}</Typography>
          )}
        </Typography>
        {children}
      </Stack>
    </Paper>
  )
}

const ProjectTagBoard = ({ clientId, projectId, level }: { clientId: string, projectId: string, level?: number }) => {
  const { data: mapData } = useMapData();

  const hasCrossingTagboards = !!(mapData?.microfencePairs.find(mp => mp.isTagboard));

  return (
    <ReportContainer clientId={clientId} projectId={projectId} level={level}>
      <>
        <ActiveTagsReport plain={true} groupByLevel={true} level={level} />
        <SafeZonesReport plain={true} level={level} />
        {hasCrossingTagboards && (<Paper elevation={3}>
          <Stack p={2} pt={0} direction='column' spacing={2}>
            <CrossingsReport plain={true} asTagboards={true} />
          </Stack>
        </Paper>)}
      </>
    </ReportContainer>
  );
}

const ProjectLocators = ({ clientId, projectId, level }: { clientId: string, projectId: string, level?: number }) => {
  return (
    <ReportContainer clientId={clientId} projectId={projectId} level={level}>
      <LocatorsReport plain={true} level={level} />
    </ReportContainer>
  );

}

export const LevelView = ({ clientId, projectId, level }: { clientId: string, projectId: string, level?: number }) => {
  const { report } = useSidebarInteraction();

  return (
    <Paper elevation={1}>
      <Grid container margin={0} padding={0}>
        <Grid item xs={12} sm={12} md={6} lg={8} xl={9}>
          {report === Report.Tagboards && <ProjectTagBoard clientId={clientId} projectId={projectId} level={level} />}
          {report === Report.Locators && <ProjectLocators clientId={clientId} projectId={projectId} level={level} />}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3} minHeight={200}>
          <MapInteractionProvider level={level}>
            <Map mode='live' />
          </MapInteractionProvider>
        </Grid>
      </Grid>
    </Paper>
  )
};

export const ProjectViewInner = ({ clientId, projectId }: { clientId: string, projectId: string }) => {
  const { report } = useSidebarInteraction();
  const map = useMapData();

  const levels = map.data?.navMesh?.levels;

  return (<>
    {levels && levels.length && levels.map(l => <Grid item key={`${clientId}/${projectId}/${l.id}`}><LevelView clientId={clientId} projectId={projectId} level={l.id} /></Grid>)}
    {!levels?.length && <Grid item><LevelView clientId={clientId} projectId={projectId} /></Grid>}
  </>)
};

export const ProjectView = ({ clientId, projectId }: { clientId: string, projectId: string }) => {
  const PROJECT_LAST_EVENTS_FROM_PERSISTOR = atom<LiveStateUpdate[]>([]);
  const PROJECT_LIVE_MESSAGES = atom<LiveStateUpdate[]>([]);
  const PROJECT_LIVE_STREAM = atom(get =>
    get(PROJECT_LIVE_MESSAGES).sort(
      event => new Date(event.data.iso8601).getTime() - new Date(event.data.iso8601).getTime(),
    ),
  );
  const PROJECT_LIVE_UPDATES_LATESTS = atom(get => get(PROJECT_LIVE_STREAM).slice(-10));

  const { data: isUndergroundProject, error, loading } = useIsUndergroundProject({ clientId: clientId, projectId: projectId });

  return (<>
    {isUndergroundProject && (<ProjectLiveEventsProvider cid={clientId} pid={projectId} liveMessagesAtom={PROJECT_LIVE_MESSAGES}>
      <LatestEventsProvider hoursAgo={2} clientId={clientId} projectId={projectId} latestEventsAtom={PROJECT_LAST_EVENTS_FROM_PERSISTOR} timeNowAtom={LIVE_TIME}>
        <MapDataProvider cid={clientId} pid={projectId} lastEventsFromPersistorAtom={PROJECT_LAST_EVENTS_FROM_PERSISTOR} liveUpdatesLatestsAtom={PROJECT_LIVE_UPDATES_LATESTS}>
          <MapInterpolationProvider>
            <ProjectViewInner clientId={clientId} projectId={projectId} />
          </MapInterpolationProvider>
        </MapDataProvider>
      </LatestEventsProvider>
    </ProjectLiveEventsProvider>)}
  </>)
};
