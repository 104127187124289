import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AUTHN_URL } from '../store/url';
import { useAsyncTask } from '../util/AsyncTask';

export const useRequestRecoveryEmail = (username: string) => {
  const httpBaseUrl = useAtomValue(AUTHN_URL);

  const requestRecoveryEmail = useCallback(async () => {
    await axios.post(`${httpBaseUrl}/open/user/recover`, {
      email: username,
      origin: window.location.origin,
    });
    return true;
  }, [httpBaseUrl, username]);

  return useAsyncTask<boolean, typeof requestRecoveryEmail>(requestRecoveryEmail);
};
