import { LoadingButton } from '@mui/lab';
import { Container, FormControl, InputLabel, Grid, MenuItem, Paper, Select, Stack, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useAtomValue, useSetAtom } from 'jotai';
import { truncate } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Copyright from '../Components/Global/Copyright';
import {CID, CID_PID_PAIRS, ClientProject, PID, SUPER_ADMIN} from '../store/user';
import { useWideScreen } from '../util/useWideScreen';
import { applyClientProjectHack } from './clientProjectHack';

export const ProjectSelection = () => {
  const navigate = useNavigate();
  const isWidescreen = useWideScreen();

  const setCid = useSetAtom(CID);
  const setPid = useSetAtom(PID);
  const [selectedClient, setSelectedClient] = useState<{ clientId: string, displayClientId: string } | undefined>();
  const [selectedClientProject, setSelectedClientProject] = useState<ClientProject>();

  const isSuperAdmin = useAtomValue(SUPER_ADMIN);

  useEffect(() => {
    if (selectedClientProject) {
      setCid(selectedClientProject.clientId);
      setPid(selectedClientProject.projectId);
      setTimeout(() => {
        navigate('/map');
      }, 250);
    }
  }, [
    selectedClientProject,
    navigate,
    setCid,
    setPid,
  ]);

  let projects: {clientId: string, projectId: string, displayClientId: string, displayProjectId: string, hasMultipleProjects?: boolean}[] = applyClientProjectHack(useAtomValue(CID_PID_PAIRS))
  projects = projects.map(project => {
    return {
      ...project,
      hasMultipleProjects: !!projects.find(p => p.displayProjectId === project.displayProjectId && p.displayClientId !== project.displayClientId)
    }
  });
  const clients: string[] = isSuperAdmin ? Array.from(new Set(projects.map(p => p.displayClientId))) : [];

  const hasClientProjects = projects.length > 0;

  useEffect(() => {
    if (!hasClientProjects) {
      navigate('/auth/error');
    }
  }, [hasClientProjects, navigate]);

  return (
    <Box component="div"
      sx={{
        width: '100vw',
        height: '100vh',
        background: 'linear-gradient(90.26deg, #4CB8C4 0.14%, #3CD3AD 99.83%)',
      }}
    >
      <Grid container direction="column" justifyContent="center" height="100%">
        <Container component="main" maxWidth={isWidescreen ? 'md' : 'xs'}>
          <Stack direction="column" spacing={2} sx={{ maxHeight: '80vh' }}>
            <Container component="div" maxWidth="xs">
              <img src='/Logo_large.png' alt="Logo Geomoby" width="100%" />
            </Container>

            <Paper elevation={5} sx={{ maxHeight: '100%', overflowY: 'auto' }}>
              <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  height="100%"
                  padding={2}
                  columnSpacing={1}
              >
                {isSuperAdmin && (
                    <Grid item xs={12}>
                      <Typography variant="h6" textAlign="center">
                        Select a client
                      </Typography>
                    </Grid>
                )}

                {isSuperAdmin && (
                    <FormControl fullWidth>
                      <InputLabel id="select-client">Client</InputLabel>
                      <Select
                          fullWidth
                          labelId="select-client"
                          id="select-client"
                          value={selectedClient?.displayClientId ?? ''}
                          label="Client"
                          onChange={e => {
                            const project = projects.find(project => project.displayClientId === e.target.value);
                            if (!project) return;
                            setSelectedClient({ clientId: project.clientId, displayClientId: project.displayClientId })
                          }}
                      >
                        {clients.map(client => (
                            <MenuItem key={client} value={client}>
                              <Tooltip title={client}>
                                <Typography
                                    style={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      width: 'calc(100% - 30px)',
                                    }}
                                >
                                  {client}
                                </Typography>
                              </Tooltip>
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                )}

                {selectedClient && (
                    <Grid item xs={12}>
                      <Typography variant="h6" textAlign="center">
                        Select a project
                      </Typography>
                    </Grid>
                )}

                {(isSuperAdmin
                        ? projects.filter(p => p.displayClientId === selectedClient?.displayClientId).sort((a, b) => a.displayProjectId.localeCompare(b.displayProjectId))
                        : projects.sort((a, b) => a.displayProjectId.localeCompare(b.displayProjectId))
                ).map(({ clientId, projectId, displayClientId, displayProjectId, hasMultipleProjects }) => (
                    <Grid item xs={12} lg={6} key={`${clientId}/${projectId}`}>
                      <Tooltip title={!isSuperAdmin && hasMultipleProjects ? `${displayClientId}/${displayProjectId}` : displayProjectId}>
                        <LoadingButton
                            key={`${clientId}/${projectId}`}
                            style={{
                              marginTop: 10,
                            }}
                            variant="contained"
                            loading={
                                selectedClientProject?.clientId === clientId &&
                                selectedClientProject?.projectId === projectId
                            }
                            size="large"
                            fullWidth
                            color="secondary"
                            onClick={() => {
                              setSelectedClientProject({ clientId, projectId });
                            }}
                            disabled={!!selectedClientProject}
                        >
                          {truncate(!isSuperAdmin && hasMultipleProjects ? `${displayClientId}/${displayProjectId}` : displayProjectId, {
                            length: 30,
                            omission: '...',
                          })}
                        </LoadingButton>
                      </Tooltip>
                    </Grid>
                ))}
              </Grid>
            </Paper>
          </Stack>
          <Box component="div"
            mt={6}>
            <Copyright />
          </Box>
        </Container>
      </Grid>
    </Box>
  );
};
