import { AddCircle, Engineering, History, PhoneIphone } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { BEACON_OLD_LOCATION_SECONDS, BEACON_RECENT_SECONDS } from '../../../config';
import { AssetStatus, StatusType } from '../../../util/Events/schema';
import { stringifyIdRecord } from '../../../util/stringUtils';
import { useLastKnownUnderground } from '../../api/useLastKnownUnderground';
import { beaconIdHack } from '../../util/beaconIdHack';
import { levelIdOfPoint } from '../../util/findNavmeshPath';
import { releaseVector3, temporaryVector3 } from '../../util/vectorUtils';
import { Particle, useMapData } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { makeParticleColor } from '../scenes/Default/Particle/Particle';
import { BatteryInfo, CommonItem, UncertainPositionInfo, UpdateInfo } from './CommonInfo';

const stringifyParticle = ({ weight, position }: Particle) => {
  const x = Math.round(position.x);
  const y = Math.round(position.y);
  const z = Math.round(position.z);
  const w = weight.toFixed(4);
  return `<${x}, ${y}, ${z}, ${w}>`;
};

const StatusInfo = ({ status }: { status: Omit<AssetStatus, "ids"> }) => {
  if (status.type === StatusType.DRAEGER) {
    // status.statusId is the Draeger status byte, see https://geomoby.atlassian.net/wiki/spaces/BG/pages/2120384513/Integration+Draeger
    const ready = (status.statusId & 1) === 1;
    const alarm = (status.statusId >> 1 & 1) === 1;
    const gasAlarm = (status.statusId >> 2 & 1) === 1;
    const errorState = (status.statusId >> 3 & 1) === 1;
    const calibrationNeeded = (status.statusId >> 4 & 1) === 1;
    const bumptestNeeded = (status.statusId >> 5 & 1) === 1;

    const message = (status.statusId <= 0 || status.statusId > 255)
      ? 'Unknown'
      : ([
        ready && !alarm && !gasAlarm && !errorState && !calibrationNeeded && !bumptestNeeded ? 'OK' : undefined,
        alarm && !gasAlarm ? 'Device alarm' : undefined,
        gasAlarm ? 'Gas alarm' : undefined,
        errorState && !alarm && !gasAlarm ? 'Device error' : undefined,
        calibrationNeeded && !errorState ? 'Calibration needed' : undefined,
        calibrationNeeded && errorState ? 'Calibration error' : undefined,
        bumptestNeeded && !errorState ? 'Bumptest needed' : undefined,
        bumptestNeeded && errorState ? 'Bumptest error' : undefined,
      ].filter(m => m !== undefined).join('; ') || 'Unknown');
    const color = (status.statusId <= 0 || status.statusId > 255)
      ? undefined
      : (alarm || gasAlarm || errorState)
        ? 'error' as const
        : (calibrationNeeded || bumptestNeeded)
          ? 'orange' as const
          : undefined;

    return (
      <CommonItem icon={<Engineering />} title="Status:">
        <Typography color={color}>{message}</Typography>
      </CommonItem>
    )
  }

  return (
    <CommonItem icon={<Engineering />} title="Status:">
      <Typography>{status.statusId}{status.message && ` | ${status.message}`}</Typography>
    </CommonItem>
  )
}

export const BeaconInfo = ({ id }: { id: string }) => {
  const {
    highlight,
    highlighted,
    setLastKnownForHighlighted,
    settings: { beaconParticles },
    setCamera,
  } = useMapInteraction();
  const { data: mapData, getNow } = useMapData();

  const beacon = mapData?.liveData?.state.assets.get(id);
  const linkedDeviceId = beaconIdHack(id).includes('beaconId') ? beaconIdHack(id).replace('beaconId', 'deviceId') : undefined
  const linkedDevice = linkedDeviceId ? mapData?.liveData?.state.assets.get(linkedDeviceId) : undefined;
  const status = beacon?.lastStatus;

  const lastUpdated = beacon?.lastLocation?.iso8601 ? new Date(beacon?.lastLocation?.iso8601) : undefined;

  const nowRef = useRef(getNow())
  const beaconIdRef = useRef(beacon?.id ?? {});

  const isOldOrNeverUpdated = !lastUpdated || (getNow().getTime() - lastUpdated.getTime()) > BEACON_RECENT_SECONDS * 1000;
  const { execute, loading, data: lastKnown, error } = useLastKnownUnderground(beaconIdRef.current, nowRef.current);

  useEffect(() => {
    if (isOldOrNeverUpdated) {
      execute();
    }
  }, [isOldOrNeverUpdated, execute])

  const { x, y, z } = lastKnown?.position ?? {}
  const lastKnownIso8601 = lastKnown?.iso8601;

  useEffect(() => {
    if (x !== undefined && y !== undefined && z !== undefined) {
      setLastKnownForHighlighted({ x, y, z });
    } else {
      setLastKnownForHighlighted(undefined)
    }
  }, [x, y, z, setLastKnownForHighlighted])

  useEffect(() => {
    if (isOldOrNeverUpdated && x !== undefined && y !== undefined && z !== undefined) {
      if (beacon && mapData?.navMesh) {
        const point = temporaryVector3(x, y, z);
        beacon.lastKnownLevelId = levelIdOfPoint(point, mapData.navMesh);
        releaseVector3(point);
      }
    }
  }, [isOldOrNeverUpdated, x, y, z, beacon, mapData?.navMesh])

  useEffect(() => {
    if (isOldOrNeverUpdated && lastKnownIso8601 && beacon) {
      beacon.lastKnownIso8601 = lastKnownIso8601;
    }
  }, [isOldOrNeverUpdated, lastKnownIso8601, beacon])

  useEffect(() => { // Un-set last known location when beacon/state changes, or if highlighted info box is closed
    if (!isOldOrNeverUpdated) setLastKnownForHighlighted(undefined)
    return () => setLastKnownForHighlighted(undefined);
  }, [isOldOrNeverUpdated, setLastKnownForHighlighted])

  return (
    <>
      <UpdateInfo lastUpdate={lastUpdated} oldSeconds={BEACON_OLD_LOCATION_SECONDS} oldMessage={"Awaiting next update"} />
      {beacon?.group === 'ert' && (
        <CommonItem icon={<AddCircle htmlColor='orange' />} title="ERT:">
          <Typography>Emergency Response Team</Typography>
        </CommonItem>
      )}
      {status && <StatusInfo status={status} />}
      {linkedDevice && (
        <CommonItem icon={<PhoneIphone />} title="Linked device:">
          <Grid container direction="row" alignItems="baseline" spacing={2} xs={6} item>
            <Link onClick={() => highlight({ id: stringifyIdRecord(linkedDevice.id), category: 'device', label: linkedDevice.label })}>{linkedDevice.label}</Link>
          </Grid>
        </CommonItem>
      )}
      <UncertainPositionInfo confidence={beacon?.lastLocation?.confidence} />
      <BatteryInfo batteryPercentage={beacon?.lastBattery?.percent} />
      {isOldOrNeverUpdated && (
        <CommonItem icon={<Box component="div" color="warning.light"><History /></Box>} title="Last location:">
          <>
            {loading && <Typography>loading...</Typography>}
            {lastKnown && <Stack>
              <Link onClick={() => setCamera('3Dchase')}>{new Date(lastKnown.iso8601).toLocaleString()}</Link>
              {(x !== undefined && y !== undefined && z !== undefined) && <Typography variant='caption'>X: {x.toFixed(1)}, Y: {y.toFixed(1)}, Z: {z.toFixed(1)}</Typography>}
            </Stack>}
            {(error || (!lastKnown && !loading)) && <Typography>unknown</Typography>}
          </>
        </CommonItem>
      )}
    </>
  );
};
