import { stringifyIdRecord } from '../../util/stringUtils';

const idsToRewrite: Record<string, string | undefined> = {
  '129N0244': '020010',
};

export const beaconLabelHack = (label: string) => {
  return idsToRewrite[label] ?? label;
};

export const beaconIdHack = (stringifiedId: string) => {
  try {
    const id = Object.fromEntries(JSON.parse(stringifiedId));
    if ('beaconId' in id && id['beaconId']) {
      return stringifyIdRecord({ ...id, beaconId: beaconLabelHack(String(id['beaconId'])) });
    }
    return stringifiedId;
  } catch (e) {
    return stringifiedId;
  }
};
