import { atomFromEnv } from '../util/atomFromEnv';

export const UG_LEGACY_API = atomFromEnv('REACT_APP_API_URL', `${location.origin}/api`);

const serviceUrl = (name: string, port: number) =>
  location.origin
    .replace('underground.geomoby', `${name}.service.geomoby`)
    .replace('underground-beta.geomoby', `${name}.service.geomoby`)
    .replace('underground', name)
    .replace('3000', port.toString());

const navmeshbuildeUrl =
  location.origin.replace('3000', '5000') +
  (location.origin.includes('3000') ? '' : '/navmeshbuilder');

export const AUTHN_URL = atomFromEnv('REACT_APP_AUTHN_URL', serviceUrl('authn', 8009));
export const INGESTOR_URL = atomFromEnv('REACT_APP_INGESTOR_URL', serviceUrl('ingestor', 8010));
export const PERSISTOR_URL = atomFromEnv('REACT_APP_PERSISTOR_URL', serviceUrl('persistor', 8011));
export const TRIGGERS_URL = atomFromEnv('REACT_APP_TRIGGERS_URL', serviceUrl('triggers', 8012));
export const STREAMER_URL = atomFromEnv('REACT_APP_STREAMER_URL', serviceUrl('streamer', 8013));
export const NOTIFIER_URL = atomFromEnv('REACT_APP_NOTIFIER_URL', serviceUrl('notifier', 8014));
export const METADATA_URL = atomFromEnv('REACT_APP_METADATA_URL', serviceUrl('metadata', 8015));
export const NAVMESHBUILDER_URL = atomFromEnv('REACT_APP_NAVMESHBUILDER_URL', navmeshbuildeUrl);
