import { useMemo } from 'react';
import { SearchSidebar } from '../../../util/SearchSidebar/SearchSidebar';
import { LocatedAsset } from '../../api/LocatedAsset.validator';
import { locatedAssetOnLevelFilter } from '../../util/assetUtils';
import { releaseVector3, temporaryVector3 } from '../../util/vectorUtils';
import { useEditingData } from '../EditTools/useEditingData';
import { useMapData } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

export const EditLocatorSidebar = () => {
  const map = useMapData();
  const { currentEdit, setCurrentEdit, locatedAssetEdits } = useEditingData();
  const { level } = useMapInteraction();
  const { showSecondary } = useSidebarInteraction();
  const onSelectedLevel = locatedAssetOnLevelFilter(level, map?.data?.navMesh);

  const highlightedUuid = currentEdit?.type === 'located'
    ? (currentEdit?.asset.type === 'locator' || currentEdit?.asset.type === 'gateway' ? currentEdit.asset.uuid : undefined)
    : undefined;

  const locatorsAndGateways = useMemo(() => {
    const existing = map.data?.locatedAssets.filter(l => (l.type === 'locator' || l.type === 'gateway' || l.type === 'mqttgateway') && !locatedAssetEdits[l.uuid]) ?? []
    const creating = Object.values(locatedAssetEdits).filter((l): l is LocatedAsset => !!l && !('delete' in l) && (l.type === 'locator' || l.type === 'gateway' || l.type === 'mqttgateway'))
    return [...existing, ...creating]
      .filter(l => {
        const positionVector = temporaryVector3(l.position.x, l.position.x, l.position.z);
        const isOnLevel = onSelectedLevel({ positionVector });
        releaseVector3(positionVector);
        return isOnLevel;
      })
      .map(l => locatedAssetEdits[l.uuid] ? { ...l, edited: true } : { ...l, edited: false })
  }, [locatedAssetEdits, map.data?.locatedAssets, onSelectedLevel])

  return (
    <SearchSidebar
      title="Locators"
      onClick={(id: string, label: string) => {
        const asset = locatorsAndGateways.find(a => a.uuid === id);
        setCurrentEdit(asset ? { type: 'located', asset } : undefined);
      }}
      hide={() => showSecondary(Secondary.NONE)}
      options={
        locatorsAndGateways.map(({ uuid, id, label, edited }) => ({
          label: `${edited ? '* ' : ''}${label ?? id}${edited ? ` [${uuid.includes('fresh') ? 'creating' : 'edited'}]` : ''}`,
          id: uuid,
          selected: highlightedUuid === uuid,
        }))
          .sort(({ label: a }, { label: b }) => a.localeCompare(b))
      }
    />
  );
};
