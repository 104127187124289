import { Line } from '@react-three/drei';
import { Vector3 } from 'three';
import { FullOrPartialNavmesh } from '../../../EditTools/useEditingData';
import { SelectedLink } from './SelectedLink';

export const SelectedLinks = ({ linkIds, navmesh }: { linkIds: Record<number, boolean>, navmesh: FullOrPartialNavmesh }) => {

  const selected = Object.entries(linkIds).filter(([_, include]) => !!include).flatMap(([id]) => {
    const link = navmesh.links?.find(l => l.id === parseInt(id));
    if (!link) return [];

    const [a, b] = [link.a, link.b].map(nodeId => navmesh.nodes?.find(n => n.id === nodeId));
    if (!a || !b) return [];

    return <SelectedLink key={'selected_' + link.id} color='gold' from={a.pos} to={b.pos} new={false} navigable={!link.unnavigable} />
  })

  return <>{selected}</>
};
