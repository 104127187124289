/**
 * The master copy of the kafka schema is in the Persistor service:
 * https://github.com/geomoby/persistor/blob/master/src/kafka/schema.ts
 */
// Helper types
export enum SensorType {
  BATTERY_PERCENT = 0,
  TEMPERATURE_CELSIUS = 1,
  SPO2_PERCENT = 2,
  HEARTRATE_BPM = 3,
}
export enum StatusType {
  PLOD = 'PLOD',
  DRAEGER = 'DRAEGER',
}
export enum TagboardState {
  OUT = 0,
  IN = 1,
  EXCLUDED = 2, // for damaged, missing, flat battery, etc.
}

export type AssetIdentifiers = {
  clientId: string;
  projectId: string;
  /*
    In general, assets are uniquely identified by the combination of a couple pieces of meta data.
    Rather than representing the ID as some combined string or hash, we leave it as this plain object.
    Upsides include - no confusion about ID (de)serialization of the value, effortless schema additions.
    Examples:
      { deviceId: 'geomobyTestMobile' }
      { uuid: '0c5a1e22-eb94-4651-8f02-ca465a59e9ad', major: 3, minor: 12 }
      { webhook: 'https://api.bhp.com/positional-data' }
    The following assets would be treated uniquely:
      { device: 'device' }
      { deviceId: 'device' }
      { deviceId: 'device', type: 'device' }
  */
  id: Record<string, string>;
  label?: string;
};
export type ProjectlessAssetIdentifiers = {
  id: Record<string, string>;
  label?: string;
};
export type Coordinates = {
  latitude: number;
  longitude: number;
  errorRadiusMetres?: number;
  trueAltitude?: number;
};
export type CartesianCoordinates = {
  x: number;
  y: number;
  z: number;
};
export type Sensed = {
  ids: ProjectlessAssetIdentifiers;
  distanceMetres?: number;
  rssi?: number;
  txPower?: number;
  sensedAs?: string;
  advChannel?: number;
};
export type EmsMeasurements = {
  dryBulbTemperatureCelcius?: number;
  wetBulbTemperatureCelcius?: number;
  relativeHumidityPercent?: number;
  windVelocityMetresPerSecond?: number;
  barometricPressureHectopascals?: number;
  altitudeCalculatedMetres?: number;
  oxygenPercent?: number;
  carbonDioxidePercent?: number;
  methanePercent?: number;
  carbonMonoxidePpm?: number;
  nitrogenDioxidePpm?: number;
  hydrogenSulphidePpm?: number;
  sulphurDioxidePpm?: number;
  amoniaMicrogramsPerCubicMetre?: number;
  PM1_0MicrogramsPerCubicMetre?: number;
  PM2_5MicrogramsPerCubicMetre?: number;
  PM10MicrogramsPerCubicMetre?: number;
};
export type ConnectionStats = {
  obs_received: number;
  heartbeats_received: number;
  error_states_received: number;
  ping_reports_received: number;
  wifi_credentials_received: number;
  obs_succeeded: number;
  heartbeats_succeeded: number;
  error_states_succeeded: number;
  ping_reports_succeeded: number;
  wifi_credentials_succeeded: number;
  seconds_since_successful_post: number;
  recent_post_statuses: number[];
  wifi_connection_succeeded: number;
  wifi_connection_failed: number;
  wifi_disconnected: number;
  sntp_succeeded: number;
  sntp_failed: number;
};
export type ProjectlessFenceIdentifiers = {
  id: string;
  type: string;
  label?: string;
};
export type ProjectlessBoundaryIdentifiers = {
  id: string;
  type: SensorType;
  label?: string;
};
export type ProjectlessLayerIdentifiers = {
  id: string;
  label?: string;
};
export type ProjectlessPairIdentifiers = {
  id: string;
  label?: string;
};
// Event types
export type AssetCoordinates = {
  ids: AssetIdentifiers;
  iso8601: string;
  coordinates: Coordinates;
  sourceIds?: ProjectlessAssetIdentifiers;
  offsetDistanceMetres?: number;
};
export type AssetCoordinatesBounded = {
  ids: AssetIdentifiers;
  iso8601: string;
  coordinates: Coordinates;
  sourceIds?: ProjectlessAssetIdentifiers;
  inTrackingBounds: boolean;
  offsetDistanceMetres?: number;
};
export type AssetCoordinatesChanged = {
  ids: AssetIdentifiers;
  fromIso8601: string;
  iso8601: string;
  from: Coordinates;
  to: Coordinates;
};
export type AssetCoordinatesTriggered = {
  ids: AssetIdentifiers;
  iso8601: string;
  coordinates: Coordinates;
  entered: boolean;
  order: number;
  fenceIds: ProjectlessFenceIdentifiers;
  layerIds: ProjectlessLayerIdentifiers;
  isBreachEvent: boolean;
  isBufferEvent: boolean;
};
export type AssetCoordinatesRelative = {
  ids: AssetIdentifiers;
  iso8601: string;
  position: CartesianCoordinates;
  velocity: CartesianCoordinates;
  confidence?: number;
  lastSeenIso8601?: string;
};
export type AssetSensed = {
  ids: AssetIdentifiers;
  iso8601: string;
  sensed?: Sensed[];
};
export type AssetSensedChanged = {
  ids: AssetIdentifiers;
  sensedIds: ProjectlessAssetIdentifiers;
  iso8601: string;
  rssi: number;
  fromInside: boolean;
  fromIso8601?: string | undefined;
};
export type AssetSensedTriggered = {
  ids: AssetIdentifiers;
  sensedIds: ProjectlessAssetIdentifiers;
  iso8601: string;
  entered: boolean;
};

/**
 * An "asset pair" acts like an airlock: A pair of assets (upstream and downstream) are each
 * treated as microfences, with extra events for the pair as a whole:
 *  - AssetPairSensed is emitted when a sensed asset exits either pair at either the
 *    upstream or downstream asset
 *  - AssetPairCrossed is emitted if the AssetPairSensed exit location (upstream/downstream)
 *    changed since the previous AssetPairSensed
 */
export type AssetPairSensed = {
  ids: AssetIdentifiers;
  pairIds: ProjectlessPairIdentifiers;
  exitedUpstream: boolean;
  iso8601: string;
};
export type AssetPairCrossed = {
  ids: AssetIdentifiers;
  pairIds: ProjectlessPairIdentifiers;
  exitedUpstream: boolean;
  iso8601: string;
  fromIso8601: string;
};
export type AssetSpo2 = {
  ids: AssetIdentifiers;
  iso8601: string;
  percent: number;
};
export type AssetBattery = {
  ids: AssetIdentifiers;
  iso8601: string;
  percent: number;
  volts?: number;
};
export type AssetTemperature = {
  ids: AssetIdentifiers;
  iso8601: string;
  celsius: number;
};
export type AssetSensorChanged = {
  ids: AssetIdentifiers;
  sensorType: SensorType;
  fromIso8601: string;
  iso8601: string;
  from: number;
  to: number;
};
export type AssetSensorTriggeredBoundary = {
  ids: AssetIdentifiers;
  boundaryIds: ProjectlessBoundaryIdentifiers;
  iso8601: string;
  entered: boolean;
  value: number;
};
export type AssetHeartbeat = {
  ids: AssetIdentifiers;
  iso8601: string;
  hopCount?: number;
};
export type AssetEms = {
  ids: AssetIdentifiers;
  iso8601: string;
  breach: boolean;
  measurements: EmsMeasurements;
};
export type AssetError = {
  ids: AssetIdentifiers;
  iso8601: string;
  errorCode: number;
  reasonCode: number;
};
export type AssetMessage = {
  ids: AssetIdentifiers;
  iso8601: string;
  expiryIso8601?: string;
  devices: string[];
  text: string;
};
export type AssetWelfareCheck = {
  ids: AssetIdentifiers;
  iso8601: string;
  expiryIso8601: string;
};
export type AssetStatus = {
  ids: AssetIdentifiers;
  iso8601: string;
  type: StatusType;
  statusId: number;
  message?: string;
};
export type AssetExtension = {
  ids: AssetIdentifiers;
  iso8601: string;
  smartIdLocation?: string;
  extensionsMm: number[];
};
export type AssetConnection = {
  ids: AssetIdentifiers;
  iso8601: string;
  gatewayVersion: string;
  espVersion: string;
  stats: ConnectionStats;
};
export type AssetHeartrate = {
  ids: AssetIdentifiers;
  iso8601: string;
  bpm: number;
};
export type AssetTagboard = {
  ids: AssetIdentifiers;
  iso8601: string;
  state: keyof typeof TagboardState;
  reason: Record<string, unknown>;
};

// Maps kafka topics to Typescript typees
export type Topics = {
  'asset.coordinates': AssetCoordinates;
  'asset.coordinates.bounded': AssetCoordinatesBounded;
  'asset.coordinates.changed': AssetCoordinatesChanged;
  'asset.coordinates.triggered': AssetCoordinatesTriggered;
  'asset.coordinates.relative': AssetCoordinatesRelative;
  'asset.sensed': AssetSensed;
  'asset.sensed.changed': AssetSensedChanged;
  'asset.sensed.triggered': AssetSensedTriggered;
  'asset.pair.sensed': AssetPairSensed;
  'asset.pair.crossed': AssetPairCrossed;
  'asset.battery': AssetBattery;
  'asset.spo2': AssetSpo2;
  'asset.temperature': AssetTemperature;
  'asset.sensor.changed': AssetSensorChanged;
  'asset.sensor.triggered.boundary': AssetSensorTriggeredBoundary;
  'asset.heartbeat': AssetHeartbeat;
  'asset.ems': AssetEms;
  'asset.error': AssetError;
  'asset.message': AssetMessage;
  'asset.status': AssetStatus;
  'asset.extension': AssetExtension;
  'asset.connection': AssetConnection;
  'asset.heartrate': AssetHeartrate;
  'asset.tagboard': AssetTagboard;
};
