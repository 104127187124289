import differenceInSeconds from 'date-fns/differenceInSeconds';
import { BEACON_RECENT_SECONDS, CROSSING_RECENT_SECONDS } from '../../config';
import { AssetState } from '../../util/Events/Messages';

export const describeCount = (count: number, single: string, plural?: string) =>
  `${count} ${count === 1 ? single : plural ?? single + 's'}`;

export const isPersonnelDevice = ({ type, id }: AssetState) => type && type !== 'beacon';
export const isPersonnelBeacon = ({ model, type, id }: AssetState) =>
  (type === 'beacon' && model === 'Miners_Helmet') || !type;
export const isTruckBeacon = ({ model, type }: AssetState) =>
  type === 'beacon' && model !== 'Light_Vehicle' && model !== 'Miners_Helmet';
export const isLightVehicleBeacon = ({ model, type }: AssetState) =>
  type === 'beacon' && model === 'Light_Vehicle';

export const isActive = (asset: AssetState, now: Date, inactivityThresholdMinutes?: number) => {
  if (!asset.recentLocations || !asset.recentLocations.length) {
    return false;
  }
  const lastSeenIso8601 = asset.recentLocations[0].iso8601;
  const thresholdSeconds = inactivityThresholdMinutes
    ? inactivityThresholdMinutes * 60
    : BEACON_RECENT_SECONDS;
  return (
    !!lastSeenIso8601 && differenceInSeconds(now, new Date(lastSeenIso8601)) < thresholdSeconds
  );
};

export const isActiveForCrossing = (asset: AssetState, now: Date) => {
  const lastSeenDate = asset.lastUpdate;
  return !!lastSeenDate && differenceInSeconds(now, lastSeenDate) < CROSSING_RECENT_SECONDS;
};

export const isActiveForNearby = (iso8601: string, now: Date) => {
  return differenceInSeconds(now, new Date(iso8601)) < BEACON_RECENT_SECONDS;
};

export const activeBeaconsText = (activeAssets: AssetState[]) => {
  const activePersonnelBeacons = activeAssets.filter(isPersonnelBeacon).length;
  const activePersonnelDevices = activeAssets.filter(isPersonnelDevice).length;
  const activeTrucksVehicles = activeAssets.filter(isTruckBeacon).length;
  const activeLightVehicles = activeAssets.filter(isLightVehicleBeacon).length;

  return [
    describeCount(activePersonnelBeacons, 'personnel beacon'),
    describeCount(activePersonnelDevices, 'device'),
    describeCount(activeLightVehicles, 'light vehicle'),
    describeCount(activeTrucksVehicles, 'truck'),
  ].join(', ');
};
