import { PhoneIphone, Work, Layers } from '@mui/icons-material';
import { Box, Link, SxProps, Theme, Typography } from '@mui/material';
import { compareDesc } from 'date-fns';
import { ReactNode, useCallback, useEffect, useRef } from 'react';
import { Vector3 } from 'three';
import { SAFE_DISTANCE_METRES } from '../../../../config';
import { AssetState } from '../../../../util/Events/Messages';
import { stringifyIdRecord } from '../../../../util/stringUtils';
import { unsafeEntries } from '../../../../util/typeUtils';
import { LocatedAsset } from '../../../api/LocatedAsset.validator';
import { Navmesh } from '../../../api/Navmesh.validator';
import { findNavmeshPath, levelIdOfPoint } from '../../../util/findNavmeshPath';
import { AssetGridItem } from '../../../util/portableAsset';
import { describeCount, isActive, isPersonnelBeacon, isPersonnelDevice } from '../../../util/portableAssetUtils';
import { distanceViaPoints } from '../../../util/vectorUtils';
import { useInterpolatedMapData } from '../../MapData/useInterpolatedData';
import { portbaleAssetsFromLiveData, useMapData } from '../../MapData/useMapData';
import { useMapInteraction } from '../../MapInteraction/useMapInteraction';
import { CommonItem, UpdateInfo } from '../CommonInfo';
import { Report, ReportGridHeading } from './Report';

const filterSafeAssets = (assets: AssetState[], safeAssetsForZone: Record<string, boolean>) => assets.filter(({ id }) => {
  return stringifyIdRecord(id) in safeAssetsForZone;
});

export const SafeZone = ({ locator, active, plain }: { locator: LocatedAsset & { position: Vector3 }, active: { personnel: AssetState[], devices: AssetState[] }, plain?: boolean }) => {
  const { highlight } = useMapInteraction();
  const { assetsWelfare } = useInterpolatedMapData();
  const vec3 = useRef(new Vector3());

  const safeAssetsForZone = Object.fromEntries(
    unsafeEntries(assetsWelfare).filter(([_, welfare]) => {
      if (!welfare.nearestZone || !welfare.safe) return false;

      const [x, y, z] = welfare.nearestZone;
      vec3.current.set(x, y, z);
      return vec3.current.distanceTo(locator.position) < 5;
    }).map(([ids]) => [ids, true])
  );

  const nearby = {
    personnel: filterSafeAssets(active.personnel, safeAssetsForZone),
    devices: filterSafeAssets(active.devices, safeAssetsForZone),
  };

  const personnelBeaconCount = nearby.personnel.length;
  const personnelDeviceCount = nearby.devices.length;
  const personnelCount = personnelBeaconCount + personnelDeviceCount;
  const personnelBreakdown = personnelCount === 0
    ? ''
    : `(${describeCount(personnelBeaconCount, 'beacon')}, ${describeCount(personnelDeviceCount, 'device')})`;

  const toAssetGridItem = useCallback(
    (asset: AssetState) => AssetGridItem(asset, highlight, !!plain),
    [highlight, plain]
  );

  return (
    <>
      <ReportGridHeading title={`${locator.label ?? locator.id}:`} icon={<Work />}>
        <Typography>{personnelCount} personnel {personnelBreakdown}</Typography>
      </ReportGridHeading>
      {[...nearby.personnel, ...nearby.devices].map(toAssetGridItem)}
    </>
  )
}

export const SafeZonesReport = ({ height, plain, level: specifiedLevel }: { height?: number, plain?: boolean, level?:number }) => {
  const { level: selectedLevel } = useMapInteraction();
  const { data: mapData, getNow } = useMapData();
  const now = getNow();

  const level = selectedLevel ?? specifiedLevel;
  const levelLabel = level !== undefined ? (mapData?.navMesh?.levels?.find(l => l.id === level)?.name ?? level) : 'All levels';
  
  const safeLocations = (mapData?.locatedAssets || []).filter(l => l.safe)
  .filter(asset => {
    if (level === undefined) return true;
    if (!mapData?.navMesh || !asset.position) return false;

    return levelIdOfPoint(asset.position, mapData.navMesh) === level;
  });

  const assets = (mapData?.liveData ? portbaleAssetsFromLiveData(mapData?.liveData) : []);
  const activeAssets = assets.filter(state => isActive(state, now)).sort((a, b) => a.label.localeCompare(b.label));
  const lastUpdated = activeAssets.flatMap(state => state.lastUpdate ?? []).sort(compareDesc).at(0) ?? mapData?.liveData?.state?.lastUpdate;

  const personnel = activeAssets.filter(isPersonnelBeacon);
  const devices = activeAssets.filter(isPersonnelDevice);

  return (
    <>
      {!plain && <UpdateInfo lastUpdate={lastUpdated} />}
      {selectedLevel !== undefined && (
        <CommonItem title="Level:" icon={<Layers />}>
          <Typography>{levelLabel}</Typography>
        </CommonItem>
      )}
      {!plain && safeLocations.length === 0 && (
        <CommonItem title={''} icon={<></>}>
          <Typography>No safe zones {selectedLevel !== undefined ? 'on this level' : 'configured'}</Typography>
        </CommonItem>
      )}
      {safeLocations.length > 0 && (<Report style={{ height }}>
        {safeLocations.map(locator => <SafeZone key={locator.id} locator={locator} active={{ personnel, devices }} plain={plain} />)}
      </Report>)}
    </>
  );
};
