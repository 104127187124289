import { Line } from '@react-three/drei';
import { useMemo } from 'react';
import { Vector3 } from 'three';
import { findNavmeshPath } from '../../../../util/findNavmeshPath';
import useInterval from '../../../../util/useInterval';
import { distanceViaPoints } from '../../../../util/vectorUtils';
import { useMapData } from '../../../MapData/useMapData';
import { useMapInteraction } from '../../../MapInteraction/useMapInteraction';

export const Directions = ({ from }: { from: Vector3 }) => {
  const { navigation, setNavDistance } = useMapInteraction();
  const map = useMapData();

  const navmesh = map?.data?.navMesh;

  const toX = navigation?.toLocation.x;
  const toY = navigation?.toLocation.y;
  const toZ = navigation?.toLocation.z;
  const toVector = useMemo(() => toX !== undefined && toY !== undefined && toZ !== undefined ? new Vector3(toX, toY, toZ) : undefined, [toX, toY, toZ])

  const fromX = from.x;
  const fromY = from.y;
  const fromZ = from.z;
  const fromVector = useMemo(() => fromX !== undefined && fromY !== undefined && fromZ !== undefined ? new Vector3(fromX, fromY, fromZ) : undefined, [fromX, fromY, fromZ]);

  const path = navmesh && toVector && fromVector && !fromVector.equals(toVector) && findNavmeshPath(navmesh, toVector, fromVector) || undefined;

  useInterval(() => setNavDistance(distanceViaPoints(path)), 300);

  return (<>
    {path && fromVector && <Line points={path} color={'green'} lineWidth={6} />}
  </>)
};
