import { Button, ButtonGroup, Grid, TextField, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { NavmeshNode } from '../../api/Navmesh.validator';
import { useMapData } from '../MapData/useMapData';
import { useEditingData } from './useEditingData';

export type Axis = 'x' | 'y' | 'z';

const axisIndex = (axis: Axis): 0 | 1 | 2 => {
  switch (axis) {
    case 'x': return 0;
    case 'y': return 1;
    case 'z': return 2;
  }
};

export const EditInfoNode = ({ node }: { node: NavmeshNode }) => {
  const { tool, setCurrentEdit, dispatchNavmeshNodeEdit, navmeshLinkEdits, dispatchNavmeshLinkEdit, setCurrentDeletion, applyNavmeshEdits } = useEditingData();
  const { data: mapData, cid: clientId, pid: projectId } = useMapData();

  const navmesh = useMemo(() => applyNavmeshEdits(mapData?.navMesh ?? { clientId, projectId }), [mapData?.navMesh, clientId, projectId, applyNavmeshEdits]);
  const links = useMemo(() => navmesh?.links?.filter(({ a, b }) => node.id === a || node.id === b), [navmesh?.links, node.id]);
  const newLinkLevel = links?.at(0)?.level;

  const updateAssetPosition = (axis: Axis, newValue: number) => {
    const [x, y, z] = node.pos.map((value, i) => axisIndex(axis) === i ? newValue : value);
    setCurrentEdit({
      type: 'node',
      asset: {
        ...node,
        pos: [x, y, z],
        uuid: undefined,
      }
    })
  };

  const PositionAdjuster = ({ axis }: { axis: Axis }) => {
    const currentValue = node.pos[axisIndex(axis)];
    return (
      <Grid item container direction='row'>
        <Grid item xs={3} sx={{ alignSelf: 'center' }}>
          <Typography>{axis.toUpperCase()}: {currentValue.toFixed(1)}</Typography>
        </Grid>
        <Grid item xs={9}>
          <ButtonGroup variant="contained">
            <Button size='small' title={`Decrease ${axis.toUpperCase()}`} onClick={() => updateAssetPosition(axis, currentValue - 1)}>{'<<'}</Button>
            <Button size='small' title={`Decrease ${axis.toUpperCase()} slightly`} onClick={() => updateAssetPosition(axis, currentValue - 0.1)}>{'<'}</Button>
            <Button size='small' title={`Increase ${axis.toUpperCase()} slightly`} onClick={() => updateAssetPosition(axis, currentValue + 0.1)}>{'>'}</Button>
            <Button size='small' title={`Increase ${axis.toUpperCase()}`} onClick={() => updateAssetPosition(axis, currentValue + 1)}>{'>>'}</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    )
  };

  const dispatchEdit = useCallback(() => {
    dispatchNavmeshNodeEdit(node)
    setCurrentEdit(undefined);
  }, [node, dispatchNavmeshNodeEdit, setCurrentEdit])


  const setNewLinkAsCurrentEdit = useCallback(() => {
    setCurrentEdit({
      type: 'new link',
      asset: {
        id: NaN,
        a: node.id,
        b: NaN,
        uuid: undefined,
        level: newLinkLevel
      }
    })
  }, [node.id, newLinkLevel, setCurrentEdit])

  const dispatchDeletion = useCallback(() => {
    dispatchNavmeshNodeEdit({ id: node.id, delete: true });
    if (links?.length === 2) {
      // bridge the links
      const [link0, link1] = links.sort((a, b) => a.id - b.id);
      const { a: a0, b: b0 } = link0;
      const { a: a1, b: b1 } = link1;
      const [a, b] = [a0, b0, a1, b1].filter(x => x !== node.id);
      dispatchNavmeshLinkEdit({ ...link0, a, b });
      dispatchNavmeshLinkEdit({ id: link1.id, delete: true });
    } else {
      // delete all links
      links?.forEach(link =>
        dispatchNavmeshLinkEdit({ id: link.id, delete: true })
      );
    }
    setCurrentEdit(undefined);
  }, [node.id, links, dispatchNavmeshNodeEdit, dispatchNavmeshLinkEdit, setCurrentEdit])

  const dispatchRevert = useCallback(() => {
    dispatchNavmeshNodeEdit({ id: node.id, revert: true });
    setCurrentEdit(undefined);
  }, [node.id, setCurrentEdit, dispatchNavmeshNodeEdit]);

  return (<>
    <Grid item maxWidth='100%'>
      <TextField disabled label="ID" variant="outlined" fullWidth value={node.id} />
    </Grid>
    {tool === 'MOVE' && (<>
      <Grid item>
        <Typography fontSize='90%'><em>Adjust position using controls</em></Typography>
      </Grid>
      <PositionAdjuster axis='x' />
      <PositionAdjuster axis='y' />
      <PositionAdjuster axis='z' />
    </>)}

    <Grid item container rowGap={1}>
      <Button variant="contained" onClick={dispatchEdit}>Set</Button>
      <Button variant="outlined" onClick={dispatchRevert}>Undo changes</Button>
      <Button color={(!links || links.length === 2) ? 'warning' : 'error'} variant="outlined" onClick={dispatchDeletion}>
        Delete {
          (!links || links.length === 0 || links.length === 2)
            ? 'node'
            : links.length === 1
              ? 'node and 1 link'
              : `node and ${links.length} ${links.length === 1 ? 'link' : 'links'}`
        }
      </Button>
    </Grid>
    {tool !== 'MOVE' && (<Grid item>
      <Button onClick={setNewLinkAsCurrentEdit}>New link...</Button>
    </Grid>)}
  </>
  );
};
