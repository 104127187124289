import { AppBar, Box, Button, Stack, Tab, Tabs } from "@mui/material";
import { useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";
import { useLogout } from "../auth/useLogout";
import { SUPER_ADMIN } from "../store/user";

export const NavigationBar = ({ currentPath }: { currentPath: 'super-admin' | 'my-account' }) => {
  const navigate = useNavigate()
  const logout = useLogout();

  const isSuperAdmin = useAtomValue(SUPER_ADMIN)

  return (
    <AppBar position="sticky" color="inherit">
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
        <Box component="div" pl={2} height={22}>
          <img src='/Logo.png' alt="Logo Geomoby" height="100%" />
        </Box>
        <Tabs value={"/" + currentPath} variant="scrollable" scrollButtons="auto">
          {[
            { label: 'Underground Map', path: `/map` },
            { label: 'Underground Replay', path: `/map/replay` },
            { label: 'My Account', path: `/my-account` },
            ...(isSuperAdmin ? [{ label: 'Super admin', path: '/super-admin' }] : []),
          ].map(({ path, label }) => (
            <Tab
              key={path}
              value={path}
              label={label}
              onClick={() => {
                path
                  ? navigate(path)
                  : logout();
              }}
            />
          ))}
        </Tabs>
        <Box component="div" pr={1}>
          <Button
            title="Log out"
            variant="outlined"
            onClick={() => {
              logout();
            }}
          >
            Log out
          </Button>
        </Box>
      </Stack>
    </AppBar>
  )
}