import { Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { differenceInSeconds, format } from 'date-fns';
import { ReactNode } from 'react';
import { clientProjectHack } from '../../../auth/clientProjectHack';
import { HEIGHT, HorizontalOverlay } from '../../../util/HorizontalOverlay/HorizontalOverlay';
import { useMobile } from '../../../util/useMobile';
import { portableAssetOnLevelFilter } from '../../util/assetUtils';
import { ActiveBeaconsSymbols } from '../../util/portableAsset';
import { isActive } from '../../util/portableAssetUtils';
import { useInterpolatedMapData } from '../MapData/useInterpolatedData';
import { useMapData, portbaleAssetsFromLiveData } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { MapPageArgs } from '../MapPage/MapPage';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

const useStyles = makeStyles({
  root: {
    height: '100%',
    padding: '0 8px',
  },
  text: {
    color: '#ddd',
  },
  warningText: {
    color: '#faa',
  },
  logo: {
    height: HEIGHT - 8,
  },
  divider: {
    margin: '0 8px',
  },
});

export const MapHorizontalOverlay = ({ mode }: { mode: MapPageArgs['mode'] }) => {
  const { data, getNow } = useMapData();
  const { level: levelId } = useMapInteraction();
  const { assetsLevels } = useInterpolatedMapData();
  const { showSecondary } = useSidebarInteraction();
  const classes = useStyles();
  const isMobile = useMobile();
  const lastUpdate = data?.liveData?.state.lastUpdate;

  const level = data?.navMesh?.levels?.find(l => l.id === levelId)?.name;
  const onSelectedLevel = portableAssetOnLevelFilter(levelId, assetsLevels);

  const displayClientProject = data ? clientProjectHack(data.clientId, data.projectId) : undefined;

  return (
    <HorizontalOverlay>
      <Grid className={classes.root} container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container alignItems="center">
            <img src='/Logo.png' className={classes.logo} />
            <Divider
              className={classes.divider}
              orientation="vertical"
              flexItem
              sx={{ margin: '0 10px' }}
            />
            {!isMobile && (<><Grid item>
              <Typography className={classes.text}>{displayClientProject ? displayClientProject.join('/') : undefined}
                {level && (<>: <Typography component='span' color='primary' onClick={() => showSecondary(Secondary.LEVELS)} sx={{ cursor: 'pointer' }}>{level}</Typography></>)}
              </Typography>
            </Grid>
              <Divider
                className={classes.divider}
                orientation="vertical"
                flexItem
                sx={{ margin: '0 10px' }}
              /></>)}
            {mode !== 'edit' && data?.liveData && <Grid item>
              <ActiveBeaconsSymbols activeAssets={
                portbaleAssetsFromLiveData(data.liveData)
                  .filter(onSelectedLevel)
                  .filter(asset => isActive(asset, getNow()))} />
            </Grid>}
          </Grid>
        </Grid>
        <Grid item>
          <Typography className={classes.text}>
            {!isMobile && `Last update: ${lastUpdate ? format(lastUpdate, 'HH:mm:ss z') : 'Never'}`}
            {isMobile && lastUpdate && format(lastUpdate, 'HH:mm:ss')}
          </Typography>
        </Grid>
      </Grid>
    </HorizontalOverlay>
  );
};
