import { Context, createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Object3D, Vector3 } from 'three';
import { AssetCoordinatesRelative, CartesianCoordinates } from '../../../util/Events/schema';
import { useMapData } from '../MapData/useMapData';

export type Highlighted = {
  category: 'locator' | 'gateway' | 'beacon' | 'device' | 'report' | 'sensor' | 'welfare';
  id: string;
  label: string;
};

export type Navigation = {
  id: string;
  toLocation: CartesianCoordinates;
}

export type Settings = {
  locatorLabels: boolean;
  locatorModels: boolean;
  locatorIcons: boolean;
  beaconLabels: boolean;
  beaconModels: boolean;
  beaconIcons: boolean;
  beaconLines: boolean;
  beaconParticles: boolean;
  interpolate: boolean;
  navigationMeshLine: boolean;
  generateTunnel: boolean;
  cinematicMode: boolean;
};
export const DEFAULT_SETTINGS = {
  locatorLabels: true,
  locatorModels: true,
  locatorIcons: true,
  beaconLabels: true,
  beaconModels: true,
  beaconIcons: true,
  beaconLines: true,
  beaconParticles: false,
  interpolate: true,
  navigationMeshLine: false,
  generateTunnel: true,
  cinematicMode: true,
};

export type CameraType = '3D' | '3Dchase' | '3DchaseLocked';

const SETTINGS_KEY = 'savedVisualSettings';

const loadSettings = (): Settings => {
  const rawSettings = localStorage.getItem(SETTINGS_KEY);
  const settings = JSON.parse(rawSettings ?? '{}');
  return { ...DEFAULT_SETTINGS, ...settings };
};

const saveSettings = (settings: Settings) => {
  localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));
};

const getCameraSettingsKey = (cid: string, pid: string) => `savedCameraSettings/${cid}/${pid}`;
export type CameraSettings = {
  camera: '3D',
  position: [x: number, y: number, z: number],
  target: [x: number, y: number, z: number],
  zoom: number | undefined,
}
export const DEFAULT_CAMERA_SETTINGS: CameraSettings = {
  camera: '3D',
  position: [0, 0, 999],
  target: [0, 0, 0],
  zoom: 0.8,
}
export const loadCameraSettings = (cid: string, pid: string): CameraSettings & { default: boolean } => {
  const rawCameraSettings = sessionStorage.getItem(getCameraSettingsKey(cid, pid));
  const cameraSettings = JSON.parse(rawCameraSettings ?? '{}');
  return {
    ...DEFAULT_CAMERA_SETTINGS,
    ...cameraSettings,
    default: !rawCameraSettings,
  };
}
export const saveCameraSettings = (cameraSettings: CameraSettings, cid: string, pid: string) => {
  sessionStorage.setItem(getCameraSettingsKey(cid, pid), JSON.stringify(cameraSettings));
}

export type Interactions = {
  camera: CameraType;
  setCamera: (camera: CameraType) => void;
  unsetChaseCamera: () => void;

  lockedNormal: Vector3;
  lockNormal: (normal: Vector3) => void;

  highlighted?: Highlighted;
  highlight: (highlight?: Highlighted) => void;

  settings: Readonly<Settings>;
  setSettings: <T extends keyof Settings>(feature: T, value: Settings[T]) => void;

  targetObject: Object3D;

  navigation?: Navigation;
  setNavigation: (navigation?: Navigation) => void;

  navDistance?: number;
  setNavDistance: (number?: number) => void;

  level?: number;
  setLevel: (level?: number) => void;

  fitScreenRequest?: true;
  setFitScreenRequest: (requested?: true) => void;

  largerInfoOverlay: boolean,
  setLargerInfoOverlay: (larger: boolean) => void;

  lastKnownForHighlighted?: AssetCoordinatesRelative['position'],
  setLastKnownForHighlighted: (lastKnown?: AssetCoordinatesRelative['position']) => void;
};

export const MapInteractionContext: Context<Interactions> = createContext<Interactions>({
  camera: '3D',
  setCamera: () => null,
  unsetChaseCamera: () => null,
  lockedNormal: new Vector3(0, 0, 1),
  lockNormal: () => null,
  highlight: () => null,
  settings: DEFAULT_SETTINGS,
  setSettings: () => null,
  targetObject: new Object3D(),
  setNavigation: () => null,
  setNavDistance: () => null,
  setLevel: () => null,
  setFitScreenRequest: () => null,
  largerInfoOverlay: true,
  setLargerInfoOverlay: (_: boolean) => void 0,
  setLastKnownForHighlighted: (_?: AssetCoordinatesRelative['position']) => void 0,
});



export const MapInteractionProvider = ({ level: initialLevel, children }: { level?: number, children: ReactNode }) => {
  const { cid, pid } = useMapData();
  const [settings, setSettings] = useState<Settings>(loadSettings());
  const [camera, setCamera] = useState<CameraType>((loadCameraSettings(cid, pid)).camera);
  const [lockedNormal, lockNormal] = useState<Vector3>(new Vector3(0, 0, 1));
  const [highlighted, highlight] = useState<Highlighted | undefined>(undefined);
  const [navigation, setNavigation] = useState<Navigation | undefined>(undefined);
  const targetObjectRef = useRef(new Object3D);
  const [navDistance, setNavDistance] = useState<number | undefined>(undefined);
  const [level, setLevel] = useState<number | undefined>(initialLevel);
  const [fitScreenRequest, setFitScreenRequest] = useState<true | undefined>();
  const [largerInfoOverlay, setLargerInfoOverlay] = useState(true);
  const [lastKnownForHighlighted, setLastKnownForHighlighted] = useState<AssetCoordinatesRelative['position']|undefined>(undefined);

  const highlightCategory = highlighted?.category;
  useEffect(() => {
    const isLargerByDefault = highlightCategory === 'report';
    setLargerInfoOverlay(isLargerByDefault);
  }, [highlightCategory])

  const unsetChaseCamera = useCallback(() => {
    setCamera('3D')
  }, [setCamera])

  // Unset chase camera when there is nothing to chase
  const isChaseCamera = camera.includes('chase');
  useEffect(() => {
    if (!navigation && highlighted?.category !== 'beacon' && isChaseCamera) {
      unsetChaseCamera();
    }
  }, [navigation, highlighted?.category, isChaseCamera, unsetChaseCamera])

  useEffect(() => {
    saveSettings(settings);
  }, [settings]);

  return (
    <MapInteractionContext.Provider
      value={{
        camera,
        setCamera,
        unsetChaseCamera,
        lockedNormal,
        lockNormal,
        highlighted,
        highlight,
        settings,
        setSettings: (feature, value) =>
          setSettings(settings => ({ ...settings, [feature]: value })),
        navigation,
        setNavigation,
        navDistance,
        setNavDistance,
        targetObject: targetObjectRef.current,
        level,
        setLevel,
        fitScreenRequest,
        setFitScreenRequest,
        largerInfoOverlay,
        setLargerInfoOverlay,
        lastKnownForHighlighted,
        setLastKnownForHighlighted,
      }}
    >
      {children}
    </MapInteractionContext.Provider>
  );
};

export const useMapInteraction = () => useContext(MapInteractionContext);
