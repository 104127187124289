import { SignalWifi0Bar, Wifi, WifiOff } from '@mui/icons-material';
import { Box } from '@mui/material';

const BAD_SECONDS = 600;

export const HeartbeatIconVerbose = ({
  secondsSinceLast,
}: {
  secondsSinceLast: number | undefined;
}) => {
  return secondsSinceLast === undefined ? (
    <Box component="div" color="warning.light">
      <SignalWifi0Bar />
    </Box>
  ) : secondsSinceLast <= BAD_SECONDS ? (
    <Wifi />
  ) : (
    <HeartbeatIcon secondsSinceLast={secondsSinceLast} />
  );
};

export const HeartbeatIcon = ({ secondsSinceLast }: { secondsSinceLast: number | undefined }) => {
  return secondsSinceLast !== undefined && secondsSinceLast > BAD_SECONDS ? (
    <Box component="div" color="error.light">
      <WifiOff />
    </Box>
  ) : (
    <></>
  );
};
