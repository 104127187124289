import React from 'react';
import { SearchSidebar } from '../../../util/SearchSidebar/SearchSidebar';
import { stringifyIdRecord } from '../../../util/stringUtils';
import { locatedAssetOnLevelFilter } from '../../util/assetUtils';
import { levelIdOfPoint } from '../../util/findNavmeshPath';
import { locatedSensorsFromLiveData, useMapData } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

export const SensorSidebar = () => {
  const map = useMapData();
  const { highlight, highlighted, level } = useMapInteraction();
  const { showSecondary } = useSidebarInteraction();
  const onSelectedLevel = locatedAssetOnLevelFilter(level, map?.data?.navMesh);

  const highlightedSensor = highlighted && highlighted.category === 'sensor'
    ? highlighted.id
    : undefined;

  const sensorAssets = (map.data?.liveData ? locatedSensorsFromLiveData(map.data.liveData) : [])
    .filter(onSelectedLevel);

  return (
    <SearchSidebar
      title="Sensors"
      onClick={(id: string, label: string) => {
        highlight({ category: 'sensor', id, label });
      }}
      hide={() => showSecondary(Secondary.NONE)}
      options={
        sensorAssets.map(({ id, label }) => ({
          label: label,
          id: stringifyIdRecord(id),
          selected: highlightedSensor === stringifyIdRecord(id),
        }))
      }
    />
  );
};
