import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { METADATA_URL } from '../store/url';
import { AsyncTask } from '../util/AsyncTask';
import { useAxiosFetch } from '../util/useAxiosFetch';

export const useIsUndergroundProject = ({
  clientId,
  projectId,
}: {
  clientId: string;
  projectId: string;
}): AsyncTask<boolean> => {
  const metadataUrl = useAtomValue(METADATA_URL);

  const {
    loading,
    data,
    response,
    error: fetchError,
  } = useAxiosFetch(`${metadataUrl}/${clientId}/${projectId}/navmesh`);

  const error = useMemo(() => {
    if (!fetchError) return undefined;

    return {
      message: 'Failed to fetch project',
      context: fetchError,
    };
  }, [fetchError]);

  return {
    loading,
    data: loading || error ? undefined : !!data,
    error,
  };
};
