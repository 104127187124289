import React from 'react';
import { SearchSidebar } from '../../../util/SearchSidebar/SearchSidebar';
import { stringifyIdRecord } from '../../../util/stringUtils';
import { devicesFromLiveData, useMapData } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

export const DeviceSidebar = () => {
  const map = useMapData();
  const { highlight, highlighted } = useMapInteraction();
  const { showSecondary } = useSidebarInteraction();

  const highlightedDevice =
    highlighted && highlighted.category === 'device' ? highlighted.id : undefined;

  const devices = map.data?.liveData ? devicesFromLiveData(map.data.liveData) : [];

  return (
    <SearchSidebar
      title="Devices"
      onClick={(id: string, label:string) => {
        highlight({ category: 'device', id, label });
      }}
      hide={() => showSecondary(Secondary.NONE)}
      options={
        devices.map(({ id, label }) => ({
          label,
          id: stringifyIdRecord(id),
          selected: highlightedDevice === stringifyIdRecord(id),
        })) ?? []
      }
    />
  );
};
