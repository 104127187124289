import { registerType, createDetailedValidator } from 'typecheck.macro';
import { TagboardState } from '../../util/Events/schema';

export type TagboardTag = {
  clientId: string;
  projectId: string;
  id: { [identifier: string]: string };
  label?: string | null;
  reason: { [key: string]: string | object };
  state: string;
  updatedAt: string;
};
export type TagboardTags = TagboardTag[];

registerType('TagboardTags');

export const isTagboardTags = createDetailedValidator<TagboardTags>({
  expectedValueFormat: 'type-ir',
});
