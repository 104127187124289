import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { BannerOverlay } from '../../../util/HorizontalOverlay/BannerOverlay';

export const MapBannerOverlay = ({children}: {children: ReactNode}) => {
  return (
    <BannerOverlay>
      <Box
        component="div"
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={"100%"}
      >
        {children}
      </Box>
    </BannerOverlay>
  );
};
