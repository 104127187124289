import { useFrame } from '@react-three/fiber';
import React, { useEffect, useMemo, useRef } from 'react';
import { BufferGeometry, Material, Mesh, Object3D, Vector3 } from 'three';
import { AssetState } from '../../../../../util/Events/Messages';
import { stringifyIdRecord } from '../../../../../util/stringUtils';
import { useSecondsAgo } from '../../../../../util/useSecondsAgo';
import { Locator as LocatorProps, useMapData } from '../../../MapData/useMapData';
import { useMapInteraction } from '../../../MapInteraction/useMapInteraction';
import { MapText } from '../../../MapText/MapText';
import { Pin } from '../../../Pin/Pin';
import { BatteryIcon } from '../../../WorldOverlay/BatteryIcon/BatteryIcon';
import { HeartbeatIcon } from '../../../WorldOverlay/HeartbeatIcon/HeartbeatIcon';
import { WorldOverlay } from '../../../WorldOverlay/WorldOverlay';

export const Sensor = (state: AssetState) => {
  const {
    highlighted,
    highlight,
    settings: { locatorModels: showModels, locatorLabels: showLabels, locatorIcons: showIcons },
  } = useMapInteraction();
  const zDeltaRef = useRef<Vector3>(new Vector3())
  const { getNow, data: mapData } = useMapData();

  const sensorRef = useRef<Mesh<BufferGeometry, Material | Material[]>>(null);

  const position: Vector3 = useMemo(
    () => state.positionVector ?? new Vector3(),
    [state.positionVector]
  )
  const label = useMemo(
    () => state.label ?? state.id['locatorId'] ?? stringifyIdRecord(state.id),
    [state.label, state.id]
  );

  useFrame(({ clock }) => {
    if (sensorRef.current) {
      const time = clock.getElapsedTime();
      zDeltaRef.current.set(0, 0, Math.sin(time) / 20);
      sensorRef.current.rotation.z = Math.sin(time / 2) / 3;
      sensorRef.current.position.z = position.clone().add(zDeltaRef.current).z;
    }
  });

  const secondsSinceLastUpdate = useSecondsAgo({
    from: state.lastUpdate ?? mapData?.liveData?.startDate,
    getNow
  });

  return (
    <>
      {showModels && (
        <>
          <mesh ref={sensorRef} scale={0.5} position={position} castShadow receiveShadow>
            <boxGeometry args={[0.5, 1, 0.75]} />
            <meshStandardMaterial attach="material" color="#96b8df" />
          </mesh>
        </>
      )}
      {showIcons && (
        <Pin
          position={position}
          icon="located-wireless"
          selected={highlighted?.category === 'sensor' && highlighted?.id === stringifyIdRecord(state.id)}
          onClick={() => highlight({ id: stringifyIdRecord(state.id), category: 'sensor', label })}
        />
      )}
      {showLabels && (
        <WorldOverlay position={position} unselectable={'on'}>
          <MapText>
            {label}
            {state.lastBattery && <BatteryIcon percentage={state.lastBattery.percent} />}
            <HeartbeatIcon secondsSinceLast={secondsSinceLastUpdate} />
          </MapText>
        </WorldOverlay>
      )}
    </>
  );
};
