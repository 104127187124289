import { Sensors } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { AssetState } from '../../../../util/Events/Messages';
import { AssetGridItem } from '../../../util/portableAsset';
import { useMapInteraction } from '../../MapInteraction/useMapInteraction';
import { Report, ReportGridHeading } from './Report';

const localeCompareByLabel = (a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label);

export const PlainNearbyGridItem = ({ id, label }: { id: string, label: string }) => (
  <Typography key={'plain-' + id} display='flex' alignItems='stretch' paddingLeft={1}>
    <span>{label}</span>
  </Typography>
);

export const NearbyBeacons = ({ nearby }: { nearby: { id: string, label: string, asset?: AssetState }[] }) => {
  const { highlight } = useMapInteraction();

  const toAssetOrPlainGridItem = useCallback(
    (beacon: { id: string, label: string, asset?: AssetState }) => beacon.asset ? AssetGridItem(beacon.asset, highlight, false) : PlainNearbyGridItem(beacon),
    [highlight]
  );

  return (
    <>
      <ReportGridHeading title="Nearby beacons:" icon={<Sensors />}>
        <Typography>{nearby.length}</Typography>
      </ReportGridHeading>
      {nearby.sort(localeCompareByLabel).map(toAssetOrPlainGridItem)}
    </>
  )
}

export const NearbyBeaconsReport = ({ nearby }: { nearby: { id: string, label: string, asset?: AssetState }[] }) => {
  return (
    <Report style={{ height: 0 }}>
      <NearbyBeacons nearby={nearby} />
    </Report>
  );
}
