import { Line } from '@react-three/drei';
import { Color, Vector3 } from 'three';

export const SelectedLink = ({ from, to, new: isNew, navigable, color }: { from: [number, number, number], to?: [number, number, number], new: boolean, navigable: boolean, color?: Color | string | number }) => {
  const fromVec = new Vector3(...from)
  const toVec = to ? new Vector3(...to) : undefined;

  return (<>
    {!!to && <Line dashed={isNew} points={[from, to]} color={color ?? (isNew ? 'yellow' : navigable ? 'green' : 'red')} lineWidth={3} />}
    <mesh scale={1.5} position={fromVec} castShadow receiveShadow>
      <sphereGeometry args={[0.5, 15, 15]} />
      <meshStandardMaterial attach="material" color="red" />
    </mesh>
    {!!to && <mesh scale={1.5} position={toVec} castShadow receiveShadow>
      <sphereGeometry args={[0.5, 15, 15]} />
      <meshStandardMaterial attach="material" color="orange" />
    </mesh>}
  </>);
};
