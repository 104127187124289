import { Cancel } from '@mui/icons-material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Mesh, Vector3 } from 'three';
import { LocatedAsset } from '../../../api/LocatedAsset.validator';
import { Microfence } from '../../../api/Microfence.validator';
import { MicrofencePair } from '../../../api/MicrofencePair.validator';
import { closestNavmeshPointToPoint, findLinkAndNodes, levelIdOfPoint } from '../../../util/findNavmeshPath';
import { Camera } from '../../Camera/Camera';
import { CurrentEdit, FullOrPartialNavmesh, useEditingData } from '../../EditTools/useEditingData';
import { useMapData, NavMesh as NavMeshProps } from '../../MapData/useMapData';
import { useMapInteraction } from '../../MapInteraction/useMapInteraction';
import { MapText } from '../../MapText/MapText';
import { NavMesh } from '../../NavMesh/NavMesh';
import { WorldOverlay } from '../../WorldOverlay/WorldOverlay';
import { Locator } from './Locator/Locator';
import { SelectedLink } from './SelectedLink/SelectedLink';
import { SelectedLinks } from './SelectedLink/SelectedLinks';
export const Edit = () => {
  const { data: map, cid: clientId, pid: projectId } = useMapData();
  const { tool, locatedAssetEdits, currentEdit, setCurrentEdit, applyNavmeshEdits, microfenceEdits, microfencePairEdits, multiselectedNavmeshLinkIds, dispatchMultiselectNavmeshLinkId } = useEditingData();
  const { level } = useMapInteraction();

  if (!map) {
    console.error('Rendering map without any data');
  }

  const camera = useMemo(() => <Camera />, [])

  const navMesh: FullOrPartialNavmesh | undefined = useMemo(() => applyNavmeshEdits(map?.navMesh ?? { clientId, projectId }), [applyNavmeshEdits, map?.navMesh, clientId, projectId]);

  const isVisible = useCallback((position: Vector3 | undefined) => {
    if (!navMesh) return false;
    if (!position) return false;
    if (level === undefined) return true;

    return levelIdOfPoint(position, navMesh) === level;
  }, [navMesh, level]);

  const locators = useMemo(() => {
    return map?.locatedAssets.filter(la => la.type === 'locator' && !(locatedAssetEdits[la.uuid]) && currentEdit?.asset?.uuid !== la.uuid && isVisible(la.position)) ?? [];
  }, [map?.locatedAssets, locatedAssetEdits, currentEdit?.asset?.uuid, isVisible])

  const gateways = useMemo(() => {
    return map?.locatedAssets.filter(la => (la.type === 'gateway' || la.type === 'mqttgateway') && !locatedAssetEdits[la.uuid] && currentEdit?.asset?.uuid !== la.uuid && isVisible(la.position)) ?? [];
  }, [map?.locatedAssets, locatedAssetEdits, currentEdit?.asset?.uuid, isVisible]);
  const sensors = useMemo(() => {
    return map?.locatedAssets.filter(la => la.type !== 'locator' && la.type !== 'gateway' && la.type !== 'mqttgateway' && !locatedAssetEdits[la.uuid] && currentEdit?.asset?.uuid !== la.uuid && isVisible(la.position)) ?? [];
  }, [map?.locatedAssets, locatedAssetEdits, currentEdit?.asset?.uuid, isVisible]);

  const [selectedPoint, setSelectedPoint] = useState<[x: number, y: number, z: number]>();
  const [showSelectedPoint, setShowSelectedPoint] = useState<boolean>(false);
  const selectedPointVector3 = useRef<Vector3>(new Vector3())
  const selectedNodeRef = useRef<Mesh>(null)

  const microfencesLocatorIds = useMemo(() => {
    const existing = map?.microfences.filter(m => m.assetId['locatorId'] && !(microfenceEdits[m.id])) ?? [];
    const editing = Object.values(microfenceEdits).filter((m): m is Microfence => !!m && !('delete' in m) && !!m.assetId['locatorId']);
    return [...existing, ...editing].map(m => m.assetId['locatorId']);
  }, [map?.microfences, microfenceEdits])
  const microfencesGatewayIds = useMemo(() => {
    const existing = map?.microfences.filter(m => m.assetId['mqttgatewayId'] && !(microfenceEdits[m.id])) ?? [];
    const editing = Object.values(microfenceEdits).filter((m): m is Microfence => !!m && !('delete' in m) && !!m.assetId['mqttgatewayId']);
    return [...existing, ...editing].map(m => m.assetId['mqttgatewayId']);
  }, [map?.microfences, microfenceEdits])

  const microfencePairsUpstreamLocatorIds = useMemo(() => {
    const existing = map?.microfencePairs.filter(m => m.upstreamId['locatorId'] && !(microfencePairEdits[m.id])) ?? [];
    const editing = Object.values(microfencePairEdits).filter((m): m is MicrofencePair => !!m && !('delete' in m) && !!m.upstreamId['locatorId']);
    return [...existing, ...editing].map(m => m.upstreamId['locatorId']);
  }, [map?.microfencePairs, microfencePairEdits]);
  const microfencePairsUpstreamGatewayIds = useMemo(() => {
    const existing = map?.microfencePairs.filter(m => m.upstreamId['mqttgatewayId'] && !(microfencePairEdits[m.id])) ?? [];
    const editing = Object.values(microfencePairEdits).filter((m): m is MicrofencePair => !!m && !('delete' in m) && !!m.upstreamId['mqttgatewayId']);
    return [...existing, ...editing].map(m => m.upstreamId['mqttgatewayId']);
  }, [map?.microfencePairs, microfencePairEdits]);

  const microfencePairsDownstreamLocatorIds = useMemo(() => {
    const existing = map?.microfencePairs.filter(m => m.downstreamId['locatorId'] && !(microfencePairEdits[m.id])) ?? [];
    const editing = Object.values(microfencePairEdits).filter((m): m is MicrofencePair => !!m && !('delete' in m) && !!m.downstreamId['locatorId']);
    return [...existing, ...editing].map(m => m.downstreamId['locatorId']);
  }, [map?.microfencePairs, microfencePairEdits]);
  const microfencePairsDownstreamGatewayIds = useMemo(() => {
    const existing = map?.microfencePairs.filter(m => m.downstreamId['mqttgatewayId'] && !(microfencePairEdits[m.id])) ?? [];
    const editing = Object.values(microfencePairEdits).filter((m): m is MicrofencePair => !!m && !('delete' in m) && !!m.downstreamId['mqttgatewayId']);
    return [...existing, ...editing].map(m => m.downstreamId['mqttgatewayId']);
  }, [map?.microfencePairs, microfencePairEdits]);


  const isMicrofence = useCallback(
    (asset: LocatedAsset) => {
      switch (asset.type) {
        case 'locator':
          return (
            microfencesLocatorIds.includes(asset.id) ||
            microfencePairsUpstreamLocatorIds.includes(asset.id) ||
            microfencePairsDownstreamLocatorIds.includes(asset.id)
          );
        case 'mqttgateway':
          return (
            microfencesGatewayIds.includes(asset.id) ||
            microfencePairsUpstreamGatewayIds.includes(asset.id) ||
            microfencePairsDownstreamGatewayIds.includes(asset.id)
          );
        default:
          return false;
      }
    },
    [
      microfencesLocatorIds,
      microfencePairsUpstreamLocatorIds,
      microfencePairsDownstreamLocatorIds,
      microfencesGatewayIds,
      microfencePairsUpstreamGatewayIds,
      microfencePairsDownstreamGatewayIds
    ]
  );

  useEffect(() => { // Update selected node vector when current edit changes
    if (currentEdit?.type === 'node') {
      const [x, y, z] = currentEdit.asset.pos;
      selectedNodeRef.current?.position.set(x, y, z)
    }
  }, [currentEdit?.type, currentEdit?.asset])

  useEffect( // Show/hide selected point info
    () => {
      if (selectedPoint) {
        selectedPointVector3.current.set(selectedPoint[0], selectedPoint[1], selectedPoint[2]);
        setShowSelectedPoint(tool === 'INFO');
      }
    },
    [selectedPoint, setShowSelectedPoint, tool]
  );

  useEffect( // Add new located asset to edit
    () => {
      if (!['LOCATOR', 'GATEWAY', 'SENSOR'].includes(tool) || !selectedPoint) {
        return;
      }

      const asset: LocatedAsset & { uuid: string } = {
        uuid: `fresh-${new Date().getTime()}`,
        clientId,
        projectId,
        id: '',
        position: {
          x: selectedPoint[0],
          y: selectedPoint[1],
          z: selectedPoint[2],
        },
        type: tool === 'SENSOR' ? '' : tool.toLocaleLowerCase(),
      }
      const current: CurrentEdit = { type: 'located', asset, };
      setCurrentEdit(current);
      setSelectedPoint(undefined);
    },
    [selectedPoint, setSelectedPoint, setCurrentEdit, tool, clientId, projectId]
  );

  useEffect( // Move selected asset to selected point
    () => {
      if (tool !== 'MOVE' || !selectedPoint || currentEdit?.type !== 'located') return;

      setCurrentEdit({
        ...currentEdit,
        asset: {
          ...currentEdit.asset,
          position: {
            x: selectedPoint[0],
            y: selectedPoint[1],
            z: selectedPoint[2],
          }
        }
      });
      setSelectedPoint(undefined);
    },
    [tool, selectedPoint, setSelectedPoint, currentEdit, setCurrentEdit]
  )

  const onLocatorClick = useCallback((asset: LocatedAsset) => {
    setCurrentEdit({
      type: 'located',
      asset
    })
    if (tool === 'INFO') {
      setSelectedPoint(undefined);
    }
  }, [tool, setCurrentEdit, setSelectedPoint]);

  useEffect( // Set navmesh link to edit
    () => {
      if (!navMesh || tool !== 'LINK' || !selectedPoint) {
        return;
      }

      const { link: nearestLinkId, nodeA, nodeB, nodeAId, nodeBId } = findLinkAndNodes(selectedPointVector3.current, navMesh);

      const currentLink = navMesh.links?.find(l => l.id === nearestLinkId);
      if (!currentLink) return;

      const nearestNodeId = selectedPointVector3.current.distanceTo(nodeA) < selectedPointVector3.current.distanceTo(nodeB) ? nodeAId : nodeBId;
      const nearestNode = navMesh.nodes?.find(n => n.id === nearestNodeId);

      const current: CurrentEdit = { type: 'link', asset: { ...currentLink, uuid: undefined } };
      setCurrentEdit(prev => prev?.type === 'new link'
        ? { ...prev, linkTo: nearestNode }
        : current
      );
      setSelectedPoint(undefined);
    },
    [navMesh, selectedPoint, setSelectedPoint, setCurrentEdit, tool, clientId, projectId]
  );

  useEffect( // Set navmesh node to edit, or end point for new link
    () => {
      if (!navMesh || tool !== 'NODE' || !selectedPoint) {
        return;
      }

      const { nodeA, nodeB, nodeAId, nodeBId } = findLinkAndNodes(selectedPointVector3.current, navMesh);

      const nearestNodeId = selectedPointVector3.current.distanceTo(nodeA) < selectedPointVector3.current.distanceTo(nodeB) ? nodeAId : nodeBId;

      const currentNode = navMesh.nodes?.find(n => n.id === nearestNodeId);
      if (!currentNode) return;

      const current: CurrentEdit = { type: 'node', asset: { ...currentNode, uuid: undefined } };
      setCurrentEdit(prev => prev?.type === 'new link'
        ? { ...prev, linkTo: currentNode }
        : current
      );
      setSelectedPoint(undefined);
    },
    [navMesh, selectedPoint, setSelectedPoint, setCurrentEdit, tool, clientId, projectId]
  );

  useEffect(() => { // Add/remove navmesh links when multi-selecting
    if (!navMesh || (tool !== 'MULTISELECT' && tool !== 'AUTO_PLACE')) {
      dispatchMultiselectNavmeshLinkId({ clearAll: true });
      return;
    } else if (!selectedPoint) {
      return;
    }

    const { link: linkId } = findLinkAndNodes(selectedPointVector3.current, navMesh);

    dispatchMultiselectNavmeshLinkId({ linkId });
    setSelectedPoint(undefined);
  }, [navMesh, tool, selectedPoint, dispatchMultiselectNavmeshLinkId])

  const renderNavmesh: NavMeshProps['navMesh'] | undefined = useMemo(() => {
    if (!navMesh?.nodes || !navMesh?.links) return;

    return {
      links: navMesh.links,
      nodes: navMesh.nodes.map(n => ({
        id: n.id,
        pos: new Vector3(n.pos[0], n.pos[1], n.pos[2])
      }))
    }
  }, [navMesh?.nodes, navMesh?.links]);

  const highlightLinkFrom = useMemo(() => {
    if (!navMesh?.nodes || (currentEdit?.type !== 'new link' && currentEdit?.type !== 'link')) return;

    return navMesh.nodes.find(n => n.id === currentEdit.asset.a)?.pos;
  }, [currentEdit?.asset, currentEdit?.type, navMesh?.nodes]);

  const highlightLinkTo = useMemo(() => {
    if (currentEdit?.type === 'new link') {
      return currentEdit.linkTo?.pos;
    } else if (currentEdit?.type === 'link' && navMesh?.nodes) {
      return navMesh.nodes.find(n => n.id === currentEdit.asset.b)?.pos;
    }
  }, [currentEdit, navMesh?.nodes])

  const highlightLinkNavigable = (currentEdit?.type === 'new link' || currentEdit?.type === 'link') ? !currentEdit.asset.unnavigable : true;

  const navmeshPositionVec = useMemo(() => new Vector3(), []);


  return (
    <>
      <ambientLight intensity={0.1} />
      <directionalLight position={[3, 5, 7]} intensity={0.5} />
      <directionalLight position={[-3, -5, -7]} intensity={0.1} />
      {camera}
      {renderNavmesh && <NavMesh key={JSON.stringify(renderNavmesh)} type="navMesh" position={navmeshPositionVec} navMesh={renderNavmesh} mode='edit' onLineClick={(e) => {
        if (!navMesh) return;
        setSelectedPoint(closestNavmeshPointToPoint(navMesh, e.point).toArray());
      }} />
      }
      {locators.map(locator => (
        <Locator key={`locator${locator.uuid}`} {...locator} uuid={String(locator.uuid)} onClick={onLocatorClick} microfence={isMicrofence(locator)} />
      ))}
      {Object.values(locatedAssetEdits)
        .filter((locator): locator is LocatedAsset => !!locator && !('delete' in locator))
        .filter(locator => currentEdit?.type !== 'located' || currentEdit.asset.uuid !== locator.uuid)
        .map(locator => (
          <Locator edited key={`locator${locator.uuid}`} {...locator} onClick={onLocatorClick} microfence={isMicrofence(locator)} />
        ))}
      {currentEdit && currentEdit.type === 'located' && (
        <Locator edited key={`locator${currentEdit.asset.id}`} {...currentEdit.asset} onClick={onLocatorClick} microfence={isMicrofence(currentEdit.asset)} />
      )}
      {gateways.map(gateway => (
        <Locator key={`gateway${gateway.uuid}`} {...gateway} uuid={String(gateway.uuid)} onClick={onLocatorClick} microfence={isMicrofence(gateway)} />
      ))}
      {sensors.map(sensor => (
        <Locator key={`sensor${sensor.type}${sensor.uuid}`} {...sensor} onClick={onLocatorClick} />
      ))}
      {tool === 'INFO' && selectedPoint && showSelectedPoint && (
        <WorldOverlay position={selectedPoint}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MapText>
              {selectedPoint.map(n => n.toFixed(2)).join(', ')}
            </MapText>
            <Cancel fontSize='small' sx={{ cursor: 'pointer' }} onClick={() => setShowSelectedPoint(false)} />
          </div>
        </WorldOverlay>
      )}
      {currentEdit && currentEdit.type === 'node' && (
        <mesh scale={1.5} ref={selectedNodeRef} castShadow receiveShadow>
          <sphereGeometry args={[0.5, 15, 15]} />
          <meshStandardMaterial attach="material" color="red" />
        </mesh>
      )}
      {currentEdit && (currentEdit.type === 'new link' || currentEdit.type === 'link') && highlightLinkFrom && (
        <SelectedLink from={highlightLinkFrom} to={highlightLinkTo} new={currentEdit?.type === 'new link'} navigable={highlightLinkNavigable} />
      )}
      {navMesh && <SelectedLinks linkIds={multiselectedNavmeshLinkIds} navmesh={navMesh} />}
    </>
  );
};
