import { ArrowBack, LocationSearching, MyLocation } from '@mui/icons-material';
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { BulkEdit, useEditingData } from '../EditTools/useEditingData';
import { useMapData } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

export const EditBulkSidebar = () => {
  const { showSecondary } = useSidebarInteraction();
  const { bulkEdit: currentBulkEdit, setBulkEdit } = useEditingData();
  const hide = () => showSecondary(Secondary.NONE);

  const options: { label: string, id: string, edit: BulkEdit, selected: boolean }[] = useMemo(() => [
    { label: 'Beacons and devices', id: 'edit-bulk-portable-assets', edit: 'portable' as const },
    { label: 'Upload map', id: 'edit-upload-navmesh', edit: 'navmesh' as const },
  ]
    .map(({ label, id, edit }: { label: string, id: string, edit: BulkEdit }) => ({ label, id, edit, selected: currentBulkEdit === edit })),
    [currentBulkEdit]
  );

  return (
    <List component='div' disablePadding>
      <ListItem button onClick={() => hide()}>
        <ListItemIcon>
          <ArrowBack />
        </ListItemIcon>
      </ListItem>
      <Divider />
      <Box p={2} component='div'>
        <Typography variant='h6'>Bulk edit</Typography>
      </Box>
      {options
        .map(({ label, id, edit, selected }) => (
          <ListItem
            button
            key={id}
            onClick={() => {
              setBulkEdit(edit)
              hide();
            }}
          >
            <ListItemIcon>{selected ? <MyLocation /> : <LocationSearching />}</ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
    </List>
  );
};
