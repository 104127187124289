import { differenceInSeconds } from 'date-fns';
import { boolean } from 'fp-ts';
import React, { useEffect, useMemo, useState } from 'react';
import { SearchSidebar } from '../../../util/SearchSidebar/SearchSidebar';
import { beaconLabelHack } from '../../util/beaconIdHack';
import { isActive } from '../../util/portableAssetUtils';
import { portbaleAssetsFromLiveData, useMapData } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

export const LevelsSidebar = () => {
  const map = useMapData();
  const { highlight, highlighted, level: selectedLevel, setLevel } = useMapInteraction();
  const { showSecondary } = useSidebarInteraction();

  const levels = [
    {id: undefined, name: "All levels"},
    ...(map.data?.navMesh?.levels ?? [])
  ];


  return (
    <SearchSidebar
      title="Levels"
      onClick={(id: string, label: string) => {
        const idNumber = Number(id);
        setLevel(isNaN(idNumber) ? undefined : idNumber);
      }}
      hide={() => showSecondary(Secondary.NONE)}
      options={ levels
          .map((level) => ({
            label: level.name,
            id: level.id?.toString() ?? 'all',
            selected: level.id === selectedLevel,
          })) || []
      }
    />
  );
};
