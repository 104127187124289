import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { NAVMESHBUILDER_URL } from '../../../store/url';
import { useAsyncTask } from '../../../util/AsyncTask';
import { FullOrPartialNavmesh } from './useEditingData';

export type Placement = { id: number; pos: [number, number, number] };

export const useAutoCreateLevels = ({
  clientId,
  projectId,
  navmesh,
}: {
  clientId: string;
  projectId: string;
  navmesh?: FullOrPartialNavmesh;
}) => {
  const authedRequestConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const httpBaseUrl = useAtomValue(NAVMESHBUILDER_URL);

  const createLevels = useCallback(async () => {
    if (!navmesh) throw new Error('navmesh is required');

    return (
      await axios.post<FullOrPartialNavmesh>(
        `${httpBaseUrl}/${clientId}/${projectId}/create-levels`,
        navmesh,
        authedRequestConfig,
      )
    ).data;
  }, [httpBaseUrl, authedRequestConfig, clientId, projectId, navmesh]);

  return useAsyncTask<FullOrPartialNavmesh, typeof createLevels>(createLevels);
};
