import { LoadingButton } from '@mui/lab';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Snackbar } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NAVMESHBUILDER_DEFAULT_LOCATOR_SPACING, } from '../../../config';
import { useMapData } from '../MapData/useMapData';
import { useAutoCreateLevels } from './useAutoCreateLevels';
import { FullOrPartialNavmesh, useEditingData } from './useEditingData';

export const AutoCreateLevelsDialog = () => {
  const { tool, setTool, dispatchNavmeshLevelEdit, dispatchNavmeshLinkEdit, dispatchNavmeshNodeEdit, applyNavmeshEdits, setOverwritingNavmesh, multiselectedNavmeshLinkIds } = useEditingData();
  const { data: mapData, cid: clientId, pid: projectId } = useMapData();

  const [open, setOpen] = useState(false);
  const [spacing, setSpacing] = useState(NAVMESHBUILDER_DEFAULT_LOCATOR_SPACING);

  const navmesh = useMemo(
    () => applyNavmeshEdits(mapData?.navMesh ?? { clientId, projectId }),
    [mapData?.navMesh, clientId, projectId, applyNavmeshEdits]
  );

  const { execute, data: newNavmesh, error, loading } = useAutoCreateLevels({ clientId, projectId, navmesh })
  const [settled, setSettled] = useState(false);

  useEffect(() => {
    if (newNavmesh || error) {
      setSettled(true);
    }
  }, [newNavmesh, error, setSettled]);

  useEffect(() => {
    if (tool === 'AUTO_LEVELS') {
      setSettled(false);
      setOpen(true);
    }
  }, [tool, setOpen])

  const handleClose = () => {
    setTool('INFO');
    setSettled(false);
    setOpen(false);
  }

  const dispatchNavmeshEdits = useCallback((newNavmesh: FullOrPartialNavmesh) => {
    setOverwritingNavmesh(true);
    dispatchNavmeshLevelEdit(newNavmesh.levels ?? []);
    dispatchNavmeshNodeEdit(newNavmesh.nodes ?? []);
    dispatchNavmeshLinkEdit(newNavmesh.links ?? []);
  }, [setOverwritingNavmesh, dispatchNavmeshLevelEdit, dispatchNavmeshNodeEdit, dispatchNavmeshLinkEdit])

  useEffect(() => {
    if (!settled || !newNavmesh?.levels) return;

    dispatchNavmeshEdits(newNavmesh);
  }, [settled, newNavmesh, dispatchNavmeshEdits])

  return (<>
    <Dialog
      open={open}
      aria-labelledby="auto-locators-dialog-title"
      aria-describedby="auto-locators-dialog-description"
    >
      <DialogTitle id="auto-locators-dialog-title">
        Create levels
      </DialogTitle>
      <DialogContent><>
        <DialogContentText id="auto-locators-dialog-description">
          Automatically assign levels to tunnel segments
        </DialogContentText>
      </></DialogContent>
      <DialogActions>
        <Button disabled={settled} onClick={handleClose}>Cancel</Button>
        <LoadingButton disabled={!navmesh || settled} loading={loading} variant="contained" onClick={execute} autoFocus>
          Submit
        </LoadingButton>
      </DialogActions>
      <LinearProgress color={error ? 'error' : undefined} sx={{ opacity: loading ? 1 : 0 }} />
    </Dialog>
    <Snackbar
      open={open && settled}
      autoHideDuration={error ? null : 3000}
      onClose={handleClose}
    >
      {error
        ? <Alert
          severity="error"
          sx={{
            width: '100%',
            border: '1px solid rgb(244, 67, 54)',
            cursor: 'pointer',
          }}
          onClose={handleClose}
        >Error: {Object(error)['message']}</Alert>
        : <Alert
          severity="success"
          sx={{
            width: '100%',
            border: '1px solid rgb(102, 187, 106)',
            cursor: 'pointer',
          }}
          onClose={handleClose}
        >Automatically created {(newNavmesh?.levels ?? []).length} levels</Alert>
      }
    </Snackbar>
  </>);
};
