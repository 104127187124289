import { Menu } from '@mui/icons-material';
import { Backdrop, IconButton, useMediaQuery } from '@mui/material';
import MaterialDrawer, { DrawerProps } from '@mui/material/Drawer';
import { Theme, useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useMobile } from '../useMobile';

export const SMALL_WIDTH = 56;

const useDrawer = makeStyles((theme: Theme) => {
return createStyles({
    root: {
      whiteSpace: 'nowrap',
    },
    primary: {},
    secondary: {
      left: SMALL_WIDTH,
    },
    secondaryMobile: {
      left: 0,
    },
    big: {
      width: 320,
      transition: theme.transitions?.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    bigMobile: {
      width: '80vw',
    },
    small: {
      transition: theme.transitions?.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: SMALL_WIDTH,
    },
    hidden: {
      transition: theme.transitions?.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      border: 'none',
      overflowX: 'hidden',
      width: 0,
    },
  });
});

const Drawer = ({
  state,
  children,
  secondary,
  ...other
}: { secondary?: boolean; state: 'hidden' | 'small' | 'big' } & DrawerProps) => {
  const classes = useDrawer();
  const isMobile = useMobile();
  const theme = useTheme();

  return (
    <>
      <MaterialDrawer
        {...other}
        variant='permanent'
        PaperProps={{
          style: {
            left: 0,
            marginLeft: secondary && !isMobile ? SMALL_WIDTH : 0,
            transition: theme.transitions?.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        }}
        classes={{
          paper: clsx(classes.root, {
            [classes.secondary]: !!secondary,
            [classes.secondaryMobile]: !!secondary && isMobile,
            [classes.primary]: !secondary,
            [classes.big]: state === 'big',
            [classes.bigMobile]: state === 'big' && isMobile,
            [classes.small]: state === 'small',
            [classes.hidden]: state === 'hidden',
          }),
        }}
      >
        {children}
      </MaterialDrawer>
    </>
  );
};

const useSidebar = makeStyles((theme: Theme) =>
  ({
    menuButton: {
      marginRight: 36,
      marginTop: 8,
      marginLeft: 8,
      zIndex: 10,
    },
    content: {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100vh',
      width: '100vw',
    },
    contentDesktop: {
      left: SMALL_WIDTH,
      width: `calc(100vw - ${SMALL_WIDTH}px)`,
      overflow: 'hidden'
    },
    backdrop: {
      position: 'fixed',
      top: 0,
      left: 0,
      backgroundColor: '#222',
      width: 0,
      height: '100vh',
      transition: theme.transitions?.create('opacity', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      opacity: 0,
    },
    backdropOpen: {
      width: '100vw',
      opacity: 0.5,
    },
  }),
);

export const Sidebar = ({
  main,
  sidebar,
  secondarySidebar,
  clickedAway,
}: {
  main: ReactNode;
  sidebar: ReactNode;
  secondarySidebar?: ReactNode;
  clickedAway: () => void;
}) => {
  const classes = useSidebar();
  const isMobile = useMobile();
  const [focusMain, setFocusMain] = useState(false);

  useEffect(() => {
    setFocusMain(false);
  }, [secondarySidebar]);

  return (
    <>
      <div
        className={clsx(classes.content, {
          [classes.contentDesktop]: !isMobile,
        })}
      >
        {main}
      </div>
      <div
        className={clsx(classes.backdrop, {
          [classes.backdropOpen]: focusMain || secondarySidebar,
        })}
        onClick={() => {
          clickedAway();
          setFocusMain(false);
        }}
      ></div>
      <Drawer state={secondarySidebar ? 'big' : 'hidden'} secondary>
        {secondarySidebar}
      </Drawer>
      <>
        {isMobile && (
          <IconButton
            aria-label='show sidebar'
            className={classes.menuButton}
            onClick={() => setFocusMain(true)}
          >
            <Menu />
          </IconButton>
        )}
        <Drawer state={focusMain ? 'big' : isMobile ? 'hidden' : 'small'}>
          <div onMouseEnter={() => setFocusMain(true)} onMouseLeave={() => setFocusMain(false)}>
            {sidebar}
          </div>
        </Drawer>
      </>
    </>
  );
};
