import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { HEIGHT as BANNER_HEIGHT } from '../HorizontalOverlay/BannerOverlay';
import { HEIGHT as HORIZ_OVERLAY_HEIGHT } from '../HorizontalOverlay/HorizontalOverlay';
import { SMALL_WIDTH as SIDEBAR_WIDTH } from '../Sidebar/Sidebar';

const OFFSET_GAP = 16;
const OFFSET_VER = OFFSET_GAP + HORIZ_OVERLAY_HEIGHT;
const OFFSET_HOR = OFFSET_GAP;

const FULLWIDTH = `calc(100vw - ${SIDEBAR_WIDTH + 2 * OFFSET_GAP}px)`;
const FULLHEIGHT = `calc(100vh - ${OFFSET_VER + OFFSET_GAP + BANNER_HEIGHT}px)`;

const FULLWIDTH_MOBILE = '100vw';
const FULLHEIGHT_MOBILE = `calc(100vh - ${OFFSET_GAP + BANNER_HEIGHT}px)`;

export const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      bottom: OFFSET_VER,
      right: OFFSET_HOR,
      width: 0,
      height: 0,
      overflow: 'hidden',
    },
    rootMobile: {
      bottom: 0,
      right: 0,
      width: `100vw`,
      overflow: 'hidden',
    },
    open: {
      width: 425,
      height: 500,
      overflow: 'auto',
    },
    openMobile: {
      width: '100vw',
      height: '30vh',
      overflow: 'auto',
    },
    openFull: {
      width: `min(1200px,${FULLWIDTH})`,
      height: FULLHEIGHT,
      overflow: 'auto',
    },
    openFullMobile: {
      width: FULLWIDTH_MOBILE,
      height: FULLHEIGHT_MOBILE,
      overflow: 'auto',
    },
    icon: {},
  }),
);
