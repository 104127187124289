import { Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAtomValue } from 'jotai';
import { clientProjectHack } from '../auth/clientProjectHack';
import { CID_PID_PAIRS } from '../store/user';
import { HEIGHT, HorizontalOverlay } from '../util/HorizontalOverlay/HorizontalOverlay';
import { Report, useSidebarInteraction } from './sidebars/useSidebarInteraction';

const useStyles = makeStyles({
  root: {
    height: '100%',
    padding: '0 8px',
  },
  text: {
    color: '#ddd',
  },
  warningText: {
    color: '#faa',
  },
  logo: {
    height: HEIGHT - 8,
  },
  divider: {
    margin: '0 8px',
  },
});

export const BottomHorizontalOverlay = () => {
  const classes = useStyles();
  const { report } = useSidebarInteraction();
  const clients = Array.from(new Set(useAtomValue(CID_PID_PAIRS).map(pair => clientProjectHack(pair.clientId, pair.projectId)[0])));
  

  return (
    <HorizontalOverlay onScrollablePage={true} opaque={true}>
      <Grid className={classes.root} container justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Grid container alignItems='center'>
            <img src='/Logo.png' className={classes.logo} />
            <Divider
              className={classes.divider}
              orientation='vertical'
              flexItem
              sx={{ margin: '0 10px' }}
            />
            {clients.length === 1 && (<>
              <Grid item>
                <Typography className={classes.text}>{clients[0]}</Typography>
              </Grid>
              <Divider
                className={classes.divider}
                orientation='vertical'
                flexItem
                sx={{ margin: '0 10px' }}
              />
            </>)}
            <Grid item>
              <Typography className={classes.text}>Global {Report[report]}</Typography>
            </Grid>
            <Divider
              className={classes.divider}
              orientation='vertical'
              flexItem
              sx={{ margin: '0 10px' }}
            />
          </Grid>
        </Grid>
      </Grid>
    </HorizontalOverlay>
  );
};
