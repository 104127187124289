import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AUTHN_URL } from '../store/url';
import { useAsyncTask } from '../util/AsyncTask';

type OidcState = { canUseOidc: true; provider: string } | { canUseOidc: false; provider?: never };

export const useOidcEnabled = (email: string) => {
  const httpBaseUrl = useAtomValue(AUTHN_URL);

  const getOidcEnabled = useCallback(
    async () =>
      (await axios.get<OidcState>(`${httpBaseUrl}/open/oidc?username=${encodeURIComponent(email)}`))
        .data,
    [httpBaseUrl, email],
  );

  return useAsyncTask<OidcState, typeof getOidcEnabled>(getOidcEnabled);
};
