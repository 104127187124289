import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Divider, FormControl, InputLabel, List, ListItem, ListItemIcon, MenuItem, Select, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { Vector3 } from 'three';
import { AssetState } from '../../../util/Events/Messages';
import { CartesianCoordinates } from '../../../util/Events/schema';
import { stringifyIdRecord } from '../../../util/stringUtils';
import { locatedAssetOnLevelFilter, portableAssetOnLevelFilter } from '../../util/assetUtils';
import { levelIdOfPoint } from '../../util/findNavmeshPath';
import { isActive } from '../../util/portableAssetUtils';
import { vector3ToCartesian } from '../../util/vectorUtils';
import { useInterpolatedMapData } from '../MapData/useInterpolatedData';
import { locatorsFromLiveData, portbaleAssetsFromLiveData, useMapData } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

export const NavigationSidebar = () => {
  const map = useMapData();
  const { highlighted, highlight, navigation, setNavigation, setCamera, unsetChaseCamera, level } = useMapInteraction();
  const { showSecondary } = useSidebarInteraction();
  const { assetsLevels } = useInterpolatedMapData();
  const portableAssetOnSelectedLevel = portableAssetOnLevelFilter(level, assetsLevels);
  const locatorOnSelectedLevel = locatedAssetOnLevelFilter(level, map?.data?.navMesh);

  const highlightedBeacon =
    highlighted && highlighted.category === 'beacon' ? highlighted.id : undefined;

  const now = map.getNow();
  const portableAssets = (map.data?.liveData ? portbaleAssetsFromLiveData(map.data.liveData) : [])
    .filter(portableAssetOnSelectedLevel)
    .filter((asset) => isActive(asset, now))
    .sort(({ label: a }, { label: b }) => a.localeCompare(b));
  const locators = (map.data?.liveData ? locatorsFromLiveData(map.data.liveData, { includeSurface: false }) : []).filter(locatorOnSelectedLevel).sort(({ label: a }, { label: b }) => a.localeCompare(b));
  const [fromAsset, setFromAsset] = useState(navigation?.id ?? highlightedBeacon ?? '');
  const [toLocation, setToLocation] = useState(navigation?.toLocation ? JSON.stringify(navigation.toLocation) : '')
  const toLocationCoords: CartesianCoordinates = useMemo(
    () => toLocation ? JSON.parse(toLocation) : { x: 0, y: 0, z: 0 },
    [toLocation]
  )

  return (
    <List component='div' disablePadding>
      <ListItem button onClick={() => showSecondary(Secondary.NONE)}>
        <ListItemIcon>
          <ArrowBack />
        </ListItemIcon>
      </ListItem>
      <Divider />
      <Box p={2} component='div'>
        <Typography variant='h6'>Directions</Typography>
      </Box>
      <Box p={2} component='div'>
        <FormControl fullWidth>
          <InputLabel id="directions-from-select-label">From location</InputLabel>
          <Select
            labelId="directions-from-select-label"
            id="directions-from-select"
            value={fromAsset}
            label="From location"
            onChange={e => { setFromAsset(e.target.value); setNavigation(undefined); }}
          >
            {
              portableAssets.filter((a): a is AssetState & { lastLocation: CartesianCoordinates } => !!a.lastLocation).map(asset => (
                <MenuItem key={stringifyIdRecord(asset.id)} value={stringifyIdRecord(asset.id)}>{asset.label}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Box>
      <Box px={2} component='div'>
        <FormControl fullWidth>
          <InputLabel id="directions-to-select-label">To location</InputLabel>
          <Select
            labelId="directions-to-select-label"
            id="directions-to-select"
            value={toLocation}
            label="To location"
            onChange={e => { setToLocation(e.target.value); setNavigation(undefined) }}
          >
            {
              locators.filter((a): a is AssetState & { positionVector: Vector3 } => !!a.positionVector).map(asset => (
                <MenuItem key={stringifyIdRecord(asset.id)} value={JSON.stringify(vector3ToCartesian(asset.positionVector))}>{asset.label}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Box>
      <Box p={2} component='div'>
        {!navigation && <Button fullWidth variant="contained" disabled={!fromAsset || !toLocation} onClick={() => {
          setNavigation({ id: fromAsset, toLocation: toLocationCoords });
          setCamera('3DchaseLocked');
          showSecondary(Secondary.NONE);
          highlight(undefined)
        }}>Start</Button>}
        {!!navigation && <Button fullWidth variant="contained" onClick={() => {
          setNavigation(undefined);
          unsetChaseCamera();
          setFromAsset('');
          setToLocation('');
        }}>Clear</Button>}
      </Box>
    </List>

  );
};
