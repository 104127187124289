import { AccountBox, PhoneIphone } from '@mui/icons-material';
import { Box, Link, SvgIcon, SvgIconProps, Typography } from '@mui/material';
import React, { useMemo, ComponentProps } from 'react';
import { AssetState } from '../../util/Events/Messages';
import { MiningTruck, LightVehicle } from '../../util/IconFromSVG';
import { stringifyIdRecord } from '../../util/stringUtils';
import { visuallyHidden } from '../map/HighlightedInfo/Report/Report';
import { Highlighted } from '../map/MapInteraction/useMapInteraction'; 
import { activeBeaconsText, isLightVehicleBeacon, isPersonnelBeacon, isPersonnelDevice, isTruckBeacon } from './portableAssetUtils';

export enum PortableAssetCategory {
  'personnelBeacon',
  'device',
  'truck',
  'lightVehicle',
}

const CountWithIcon = (count:number, category:PortableAssetCategory) => (
  <Typography key={category.toString()} display='inline-flex' alignItems='center' paddingRight={1}>
    {count}
    {category === PortableAssetCategory.personnelBeacon && <span aria-hidden><AccountBox fontSize='small' role='presentation' /></span>}
    {category === PortableAssetCategory.personnelBeacon && <Box component="span" sx={visuallyHidden}>{count===1 ? 'personnel (beacon)' : 'personnel (beacons)'}</Box>}
    
    {category === PortableAssetCategory.device && <span aria-hidden><PhoneIphone fontSize='small' style={{transform:'translateX(-1px)', marginRight:'-4px'}} role='presentation' /></span>}
    {category === PortableAssetCategory.device && <Box component="span" sx={visuallyHidden}>{count===1 ? 'device' : 'devices'}</Box>}
    
    {category === PortableAssetCategory.truck && <span aria-hidden><MiningTruck width={24} height={24} style={{transform:'translateX(1px)', marginRight:'1px'}} role={'presentation' as const} /></span>}
    {category === PortableAssetCategory.truck && <Box component="span" sx={visuallyHidden}>{count===1 ? 'truck' : 'trucks'}</Box>}

    {category === PortableAssetCategory.lightVehicle && <span aria-hidden><LightVehicle width={24} height={24} style={{transform: 'translate(1px,2px)'}} role='presentation' /></span>}
    {category === PortableAssetCategory.lightVehicle && <Box component="span" sx={visuallyHidden}>{count===1 ? 'light vehicle' : 'light vehicles'}</Box>}
  </Typography>
);

export const ActiveBeaconsSymbols = ({activeAssets}: {activeAssets: AssetState[]}) => {
  const activePersonnelBeacons = activeAssets.filter(isPersonnelBeacon).length;
  const activePersonnelDevices = activeAssets.filter(isPersonnelDevice).length;
  const activeTrucks = activeAssets.filter(isTruckBeacon).length;
  const activeLightVehicles = activeAssets.filter(isLightVehicleBeacon).length;  

  const pBeacons = useMemo(() => CountWithIcon(activePersonnelBeacons, PortableAssetCategory.personnelBeacon), [activePersonnelBeacons])
  const devices = useMemo(() => CountWithIcon(activePersonnelDevices, PortableAssetCategory.device), [activePersonnelDevices])
  const trucks = useMemo(() => CountWithIcon(activeTrucks, PortableAssetCategory.truck), [activeTrucks])
  const lightVs = useMemo(() => CountWithIcon(activeLightVehicles, PortableAssetCategory.lightVehicle), [activeLightVehicles])

  return (
    <div title={activeBeaconsText(activeAssets)}>
      {pBeacons}
      {devices}
      {lightVs}
      {trucks}
    </div>
  )
};

export const AssetGridItem = (asset: AssetState, highlight: (item: Highlighted)=>void, plain:boolean) => (
  <Typography key={stringifyIdRecord(asset.id)} display='flex' alignItems='stretch' paddingLeft={1}>
    <Box component="span" sx={visuallyHidden}> 
      {/* Text alternatives to icons, for accessibility */}
      {isPersonnelBeacon(asset) && 'Personnel'}
      {isPersonnelDevice(asset) && 'Device'}
      {isTruckBeacon(asset) && 'Truck'}
      {isLightVehicleBeacon(asset) && 'Light vehicle'}
    </Box>
    {plain
      ? <span>{asset.label}</span>
      : <Link onClick={() => highlight({ id: stringifyIdRecord(asset.id), category: 'beacon', label:asset.label })}>{asset.label}</Link>
    }
    {isPersonnelBeacon(asset) && <span aria-hidden><AccountBox fontSize='small' titleAccess='Personnel beacon' style={{transform:'translateX(1px)', marginRight:'1px'}} role='presentation' /></span>}
    {isPersonnelDevice(asset) && <span aria-hidden><PhoneIphone fontSize='small' titleAccess='Device' role='presentation' /></span>}
    {isTruckBeacon(asset) && <span aria-hidden title='Truck'><MiningTruck width={24} height={24} style={{transform:'translateX(3px)', marginRight:'1px'}} role='presentation' /></span>}
    {isLightVehicleBeacon(asset) && <span aria-hidden title='Light vehicle'><LightVehicle width={24} height={24} style={{transform: 'translate(3px,2px) scaleX(-1)'}} role='presentation' /></span>}
  </Typography>
);