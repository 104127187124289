import { ArrowBack, LocationSearching, MyLocation } from '@mui/icons-material';
import { Box,  Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMapData } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

export const ReportSidebar = () => {
  const { showSecondary } = useSidebarInteraction();
  const { highlight, highlighted } = useMapInteraction();
  const navigate = useNavigate();
  const hide = () => showSecondary(Secondary.NONE);
  const {data: mapData} = useMapData();

  const options: {label:string, id:string, selected:boolean, target?:string}[] = useMemo(() => [
    {label: 'Active tags', id:'report-active-tags' },
    {label: 'Tag board', id:'report-tag-board' },
    // {label: 'Global tag board', id:'report-global-tag-board', target:'/tag-board'},
    {label: 'Safe zones', id:'report-safe-zones' },
    {label: 'Crossings', id:'report-crossings', hide:mapData?.microfencePairs.length === 0 },
  ]
  .filter(o => !o.hide)
  .map(({label, id, target}: {label:string, id:string, target?:string}) => ({label, id, target, selected: highlighted?.category === 'report' && highlighted.id === id})),
  [mapData?.microfencePairs.length, highlighted?.category, highlighted?.id]
  );

  return (
    <List component='div' disablePadding>
      <ListItem button onClick={() => hide()}>
        <ListItemIcon>
          <ArrowBack />
        </ListItemIcon>
      </ListItem>
      <Divider />
      <Box p={2} component='div'>
        <Typography variant='h6'>Reports</Typography>
      </Box>
      {options
        .map(({ label, id, selected, target }) => (
          <ListItem
            button
            key={id}
            onClick={() => {
              if (target) {
                navigate(target);
              } else {
                highlight({ category: 'report', id, label });
                hide();
              }
            }}
          >
            <ListItemIcon>{selected ? <MyLocation /> : <LocationSearching />}</ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
    </List>
  );
};
