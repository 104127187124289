const clientProjectRenames: Map<string, [renamedClient: string, renamedProject: string]> = new Map([
  ['umerc/trial', ['fmrgs', 'rtlcs']],
  ['geomoby/sandbox', ['renamed', 'sample']],
]);

export const clientProjectHack = (client: string, project: string) => {
  return clientProjectRenames.get(`${client}/${project}`) ?? [client, project];
};

export const applyClientProjectHack = (pairs: { clientId: string; projectId: string }[]) =>
  pairs
    .map(({ clientId, projectId }) => {
      const [displayClientId, displayProjectId] = clientProjectHack(clientId, projectId);
      return { clientId, projectId, displayClientId, displayProjectId };
    })
    .sort(
      (a, b) =>
        a.displayClientId.localeCompare(b.displayClientId) ||
        a.displayProjectId.localeCompare(b.displayProjectId),
    );
