import { Atom, PrimitiveAtom, useAtom, useAtomValue } from 'jotai';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Euler, Vector2, Vector3 } from 'three';
import { LIVE_LAST_EVENTS_FROM_PERSISTOR, LIVE_UPDATES_LATEST_TEN } from '../../../store/liveEvents';
import { REPLAY_NOW } from '../../../store/replayEvents';
import { AssetState, LiveStateUpdate } from '../../../util/Events/Messages';
import { stringifyIdRecord } from '../../../util/stringUtils';
import { AsyncTask } from '../../api/AsyncTask';
import { LocatedAsset } from '../../api/LocatedAsset.validator';
import { Microfence } from '../../api/Microfence.validator';
import { MicrofencePair } from '../../api/MicrofencePair.validator';
import { Navmesh as RawNavMesh } from '../../api/Navmesh.validator';
import { PortableAsset } from '../../api/PortableAsset.validator';
import { ServiceConfig } from '../../api/ServiceConfig.validator';
import { TagboardTag } from '../../api/Tagboard.validator';
import { StreamData, useGeomobyLiveStream } from '../../api/stream';
import { useGeomobyReplayStream } from "../../api/stream-replay";
import { useProject } from '../../api/useProject';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { projectToMap } from './projectToMap';

type Primitive = {
  position: Vector3;
  size?: Vector3;
  rotation?: Euler;
};
export type Cube = Omit<Primitive, 'size'> & {
  type: 'cube';
  position: Vector3;
  size: Vector3;
};
export type Gltf = Primitive & {
  type: 'gltf';
  asset: string;
};
type Objects = Cube | Gltf | NavMesh;

export type NavMesh = Primitive & {
  type: 'navMesh';
  navMesh: {
    links: { id: number; a: number; b: number; level?: number | undefined; unnavigable?: boolean | null }[];
    nodes: { id: number; pos: Vector3 }[];
  };
};
export type Locator = {
  id: string;
  label?: string;
  position: Vector3;
  uncertainPosition: Vector3;
  batteryPercentage?: number; // Number from 0 to 1
  lastHeartbeat?: Date;
};

export type Position = {
  time: Date;
  position: Vector3;
  velocity?: Vector3;
};

export type Positions = Position[];

export type Beacon = {
  id: string;
  type: 'beacon' | 'device' | 'pitram' | 'plod';
  icon: 'heavy' | 'light' | 'personnel';
  model?: string | null;
  position?: Vector3;
  velocity?: Vector3;
  positions: Positions; // Queue of locally stored positions (including the current position)
  positionsBuffer: Positions // Buffer of most recent positions, gets reset after reaching max queue size 
  particles: Particle[];
  batteryPercentage?: number; // Number from 0 to 1
  lastUpdate?: Date;
  lastPositionDate?: Date;
};

export type Device = {
  id: string;
  associatedBeaconId?: string;
};

export type Particle = {
  weight: number;
  position: Vector3;
  accumulated: number;
};

export type MapData = {
  clientId: string;
  projectId: string;
  lastUpdate: number;
  metaData: unknown;
  navMesh?: RawNavMesh;
  objects: Objects[];
  portableAssets: PortableAsset[];
  locatedAssets: (LocatedAsset & { position: Vector3 })[];
  microfences: Microfence[];
  microfencePairs: MicrofencePair[];
  serviceConfigs: ServiceConfig[];
  liveData?: StreamData;
  tagboard: TagboardTag[];
};

export type AsyncMapData = AsyncTask<MapData> & {
  getNow: () => Date,
  inWelfareCheck: boolean,
  cid: string,
  pid: string,
  refreshData: () => void,
}

export const MapDataContext = createContext<AsyncMapData>({
  loading: true,
  data: undefined,
  error: undefined,
  getNow: () => new Date(),
  inWelfareCheck: false,
  cid: '',
  pid: '',
  refreshData: () => void 0,
});

export const beaconsFromLiveData = (liveData: StreamData) => {
  return Array.from(liveData.state.assets.entries()).map(([, asset]) => asset).filter(asset => asset.type === 'beacon')
}

export const devicesFromLiveData = (liveData: StreamData) => {
  return Array.from(liveData.state.assets.entries()).map(([, asset]) => asset).filter(asset => asset.type === 'device')
}

export const allPortableAssetsFromLiveData = (liveData: StreamData): AssetState[] => {
  return Array.from(liveData.state.assets.entries()).map(([, asset]) => asset)
    .map(asset => {
      const associatedDevice = liveData.state.assets.get(stringifyIdRecord(asset.id).replace('beaconId', 'deviceId')) ?? {};
      return { ...associatedDevice, ...asset }
    });
}

export const portbaleAssetsFromLiveData = (liveData: StreamData): AssetState[] => {
  return allPortableAssetsFromLiveData(liveData)
    .filter(asset => (asset.lastLocation !== undefined))
}

export const crossingAssetsFromLiveData = (liveData: StreamData): AssetState[] => {
  return Array.from(liveData.state.assets.entries()).map(([, asset]) => asset)
    .filter(asset => asset.lastCrossed !== undefined)
    .map(asset => {
      const associatedDevice = liveData.state.assets.get(stringifyIdRecord(asset.id).replace('beaconId', 'deviceId')) ?? {};
      return { ...associatedDevice, ...asset }
    });
}

export const locatorsFromLiveData = (liveData: StreamData, { includeSurface }: { includeSurface: boolean }) => {
  return Array.from(liveData.state.assets.entries()).map(([, asset]) => asset).filter(asset => asset.type === 'locator' && (includeSurface ? true : !asset.surface));
}

export const gatewaysFromLiveData = (liveData: StreamData): AssetState[] => {
  return Array.from(liveData.state.assets.entries()).map(([, asset]) => asset)
    .filter(asset => (asset.type === 'gateway' || asset.type === 'mqttgateway') && asset.positionVector !== undefined)
    .map(asset => {
      const associatedLocator = liveData.state.assets.get(stringifyIdRecord(asset.id).replace('gatewayId', 'locatorId')) ?? {};
      return { ...associatedLocator, ...asset }
    });
}

export const locatedSensorsFromLiveData = (liveData: StreamData) => {
  return Array.from(liveData.state.assets.entries()).map(([, asset]) => asset).filter(asset => asset.type === 'ems' || asset.type === 'mdt' || asset.type === 'rfiReader')
}

export const MapDataProvider = ({ cid, pid, lastEventsFromPersistorAtom, liveUpdatesLatestsAtom, children }: { cid: string, pid: string, children: ReactNode, lastEventsFromPersistorAtom?: PrimitiveAtom<LiveStateUpdate[]>, liveUpdatesLatestsAtom?: Atom<LiveStateUpdate[]> }) => {
  const [refresh, setRefresh] = useState(new Date().getTime());
  const refreshData = useCallback(() => {
    setRefresh(r => r + 1)
  }, []);

  const { data: staticData, error, loading } = useProject({ cid, pid, refresh });
  const { highlight } = useMapInteraction();
  const staticMapData = useMemo(
    () => (staticData ? projectToMap(staticData) : undefined),
    [staticData],
  );
  const liveData = useGeomobyLiveStream(staticMapData, lastEventsFromPersistorAtom ?? LIVE_LAST_EVENTS_FROM_PERSISTOR, liveUpdatesLatestsAtom ?? LIVE_UPDATES_LATEST_TEN);
  const inWelfareCheck = liveData.state.lastWelfareCheck ? new Date(liveData.state.lastWelfareCheck.iso8601) <= new Date() && new Date() < new Date(liveData.state.lastWelfareCheck.expiryIso8601) : false;

  const mapDataAsync = useMemo(
    (): AsyncMapData => ({
      data: staticMapData ? {
        ...staticMapData,
        liveData,
      } : undefined,
      error: error,
      loading: loading,
      getNow: () => new Date(),
      inWelfareCheck,
      cid,
      pid,
      refreshData,
    }),
    [liveData, error, loading, staticMapData, inWelfareCheck, cid, pid, refreshData],
  );

  return <MapDataContext.Provider value={mapDataAsync}>{children}</MapDataContext.Provider>;
};


export const MapDataReplayProvider = ({ cid, pid, children }: { cid: string, pid: string, children: ReactNode }) => {
  const { data: staticData, error, loading } = useProject({ cid, pid, refresh: 0 });
  const { highlight } = useMapInteraction();
  const staticMapData = useMemo(
    () => (staticData ? projectToMap(staticData) : undefined),
    [staticData],
  );
  const replayData = useGeomobyReplayStream(staticMapData);
  const now = useAtomValue(REPLAY_NOW);
  const getNow = useCallback(() => now, [now]);
  const inWelfareCheck = useMemo(() => {
    if (!replayData.state.lastWelfareCheck) return false;

    return new Date(replayData.state.lastWelfareCheck.iso8601) <= now && now < new Date(replayData.state.lastWelfareCheck.expiryIso8601)
  }, [replayData.state.lastWelfareCheck, now])

  const mapDataAsync = useMemo(
    (): AsyncMapData => ({
      data: staticMapData ? {
        ...staticMapData,
        liveData: replayData,
      } : undefined,
      error: error,
      loading: loading,
      getNow,
      inWelfareCheck,
      cid,
      pid,
      refreshData: () => void 0,
    }),
    [replayData, error, loading, staticMapData, getNow, inWelfareCheck, cid, pid],
  );

  return <MapDataContext.Provider value={mapDataAsync}>{children}</MapDataContext.Provider>;
};

export const useMapData = () => useContext(MapDataContext);
