import { registerType, createDetailedValidator } from 'typecheck.macro';

export type NavmeshLink = {
  id: number;
  a: number;
  b: number;
  unnavigable?: boolean | null;
  level?: number;
};

export type NavmeshNode = {
  id: number;
  pos: [number, number, number];
};

export type NavmeshLevel = {
  id: number;
  name: string;
};

export type RectWorld = {
  min: { x: number; y: number };
  max: { x: number; y: number };
};

export type Navmesh = {
  clientId: string;
  projectId: string;
  nodes?: NavmeshNode[] | null;
  links?: NavmeshLink[] | null;
  levels?: NavmeshLevel[] | null;
  rect_world?: RectWorld[] | null;
  createdAt: string;
  updatedAt?: string | null;
};

registerType('Navmesh');

export const isNavmesh = createDetailedValidator<Navmesh>({
  expectedValueFormat: 'type-ir',
});
