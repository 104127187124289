import { Grid, Link, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { CommonItem } from './CommonInfo';

export const TitleAndIds = ({
  title,
  icon,
  ids,
}: {
  icon: ReactNode;
  title: string;
  ids: { id: string; onClick?: () => void }[];
}) => (
  <CommonItem
    icon={icon}
    title={title}
  >
    {ids.map(({ id, onClick }, i) => (
      <Grid key={i} item>
        {onClick && <Link onClick={onClick}>{id}</Link>}
        {!onClick && <Typography>{id}</Typography>}
      </Grid>
    ))}
  </CommonItem>
);
