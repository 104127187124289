import { Alert, Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar, Typography } from '@mui/material';
import { AxiosError, HttpStatusCode } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LocatedAsset } from '../../api/LocatedAsset.validator';
import { Microfence } from '../../api/Microfence.validator';
import { MicrofencePair } from '../../api/MicrofencePair.validator';
import { PortableAsset } from '../../api/PortableAsset.validator';
import { ServiceConfig } from '../../api/ServiceConfig.validator';
import { useMapData } from '../MapData/useMapData';
import { DeleteAssetById, DeleteAssetByUuid, useEditingData } from './useEditingData';
import { useSaveLocatedAssets } from './useSaveLocatedAssets';
import { useSaveMicrofencePairs } from './useSaveMicrofencePairs';
import { useSaveMicrofences } from './useSaveMicrofences';
import { useSaveNavmesh } from './useSaveNavmesh';
import { useSavePortableAssets } from './useSavePortableAssets';
import { useSaveServcieConfigs } from './useSaveServiceConfigs';


const messageFromAxiosOrUnknownError = (error: AxiosError | unknown) => {
  if (error instanceof AxiosError) {
    const respDataMessage = error.response?.data?.message;
    if (respDataMessage && Object(respDataMessage)['message']) {
      return Object(respDataMessage)['message']
    } else if (respDataMessage) {
      return respDataMessage
    }
  }
  return Object(error)['message'] ?? 'Unknown error';
};

const helpTipFromAxiosOrUnknownError = (error: AxiosError | unknown) => {
  const respStatus = error instanceof AxiosError ? error.response?.status : undefined;
  if (!respStatus) {
    return 'Ensure device is connected to the internet'
  } else if (respStatus === HttpStatusCode.Conflict) {
    return 'Ensure asset identifiers are unique';
  } else if (respStatus === HttpStatusCode.Unauthorized || respStatus === HttpStatusCode.Forbidden) {
    return 'You do not have permission to make changes, or need to reauthenticate';
  } else if (respStatus === HttpStatusCode.BadRequest) {
    return 'Ensure entered values are valid';
  } else {
    return 'Try again later, or contact support@geomoby.com for assistance'
  }
};

export const SaveEditDialog = () => {
  const { data: mapData, refreshData, cid: clientId, pid: projectId } = useMapData();
  const {
    tool, setTool,
    locatedAssetEdits, dispatchLocatedAssetEdit,
    portableAssetEdits, dispatchPortableAssetEdit,
    navmeshLinkEdits, dispatchNavmeshLinkEdit,
    navmeshNodeEdits, dispatchNavmeshNodeEdit,
    navmeshLevelEdits, dispatchNavmeshLevelEdit,
    applyNavmeshEdits, setOverwritingNavmesh,
    servieConfigEdits, dispatchServiceConfigEdit,
    microfenceEdits, dispatchMircofenceEdit,
    microfencePairEdits, dispatchMircofencePairEdit,
  } = useEditingData();
  const locatedAssets = useMemo(() => Object.values(locatedAssetEdits).filter((l): l is LocatedAsset | DeleteAssetByUuid => !!l), [locatedAssetEdits])
  const portableAssets = useMemo(() => Object.values(portableAssetEdits).filter((l): l is PortableAsset | DeleteAssetByUuid => !!l), [portableAssetEdits]);
  const hasNavmeshEdits = Object.values(navmeshLinkEdits).filter(l => !!l).length + Object.values(navmeshNodeEdits).filter(n => !!n).length + Object.values(navmeshLevelEdits).filter(n => !!n).length > 0;
  const navmeshEdit = useMemo(() => hasNavmeshEdits ? applyNavmeshEdits(mapData?.navMesh ?? { clientId, projectId }) : undefined, [hasNavmeshEdits, applyNavmeshEdits, mapData?.navMesh, clientId, projectId]);
  const serviceConfigs = useMemo(() => Object.values(servieConfigEdits).filter((sc): sc is ServiceConfig | DeleteAssetById => !!sc), [servieConfigEdits]);
  const microfences = useMemo(() => Object.values(microfenceEdits).filter((m): m is Microfence | DeleteAssetById => !!m), [microfenceEdits])
  const microfencePairs = useMemo(() => Object.values(microfencePairEdits).filter((m): m is MicrofencePair | DeleteAssetById => !!m), [microfencePairEdits])

  const isNewNavmesh = !mapData?.navMesh;

  const {
    execute: saveLocatedAssets,
    data: saveLocatedAssetsData,
    error: saveLocatedAssetsError,
  } = useSaveLocatedAssets(locatedAssets);
  const {
    execute: savePortableAssets,
    data: savePortableAssetsData,
    error: savePortableAssetsError,
  } = useSavePortableAssets(portableAssets);
  const {
    execute: saveNavmesh,
    data: saveNavmeshData,
    error: saveNavmeshError,
  } = useSaveNavmesh(navmeshEdit, isNewNavmesh);
  const {
    execute: saveServiceConfigs,
    data: saveServiceConfigsData,
    error: saveServiceConfigsError,
  } = useSaveServcieConfigs(serviceConfigs);
  const {
    execute: saveMicrofences,
    data: saveMicrofencesData,
    error: saveMicrofencesError,
  } = useSaveMicrofences(microfences);
  const {
    execute: saveMicrofencePairs,
    data: saveMicrofencePairsData,
    error: saveMicrofencePairsError,
  } = useSaveMicrofencePairs(microfencePairs);


  const [executed, setExecuted] = useState(false);
  const [settled, setSettled] = useState(false);

  useEffect(() => { // update settled state when everything has succeded or failed
    const saveLocatedSettled = !!saveLocatedAssetsData || !!saveLocatedAssetsError;
    const savePortableSettled = !!savePortableAssetsData || !!savePortableAssetsError;
    const saveNavmeshSettled = !!saveNavmeshData || !!saveNavmeshError;
    const saveServiceConfigsSettled = !!saveServiceConfigsData || !!saveServiceConfigsError;
    const saveMicrofencesSettled = !!saveMicrofencesData || !!saveMicrofencesError;
    const saveMicrofencePairsSettled = !!saveMicrofencePairsData || !!saveMicrofencePairsError;
    setSettled(saveLocatedSettled && savePortableSettled && saveNavmeshSettled && saveServiceConfigsSettled && saveMicrofencesSettled && saveMicrofencePairsSettled);
  }, [
    saveLocatedAssetsData, saveLocatedAssetsError,
    savePortableAssetsData, savePortableAssetsError,
    saveNavmeshData, saveNavmeshError,
    saveServiceConfigsData, saveServiceConfigsError,
    saveMicrofencesData, saveMicrofencesError,
    saveMicrofencePairsData, saveMicrofencePairsError,
  ])

  const open = tool === 'SAVE';

  const handleClose = () => {
    setExecuted(false);
    setTool('INFO');
  }

  const handleSave = () => {
    setExecuted(true);
    setSettled(false);
    saveLocatedAssets();
    savePortableAssets();
    saveNavmesh();
    saveServiceConfigs();
    saveMicrofences();
    saveMicrofencePairs();
    setOverwritingNavmesh(false);
  }

  useEffect(() => { // refresh data after all settled
    if (executed && settled) {
      refreshData();
    }
  }, [executed, settled, refreshData])

  useEffect(() => { // clear located asset edits once saved sucessfully
    if (saveLocatedAssetsData) {
      dispatchLocatedAssetEdit({ clearAll: true });
    }
  }, [saveLocatedAssetsData, dispatchLocatedAssetEdit]);

  useEffect(() => { // clear portable asset edits once saved sucessfully
    if (savePortableAssetsData) {
      dispatchPortableAssetEdit({ clearAll: true });
    }
  }, [savePortableAssetsData, dispatchPortableAssetEdit]);

  useEffect(() => { // clear navmesh edits once saved sucessfully
    if (saveNavmeshData) {
      dispatchNavmeshLinkEdit({ clearAll: true });
      dispatchNavmeshNodeEdit({ clearAll: true });
      dispatchNavmeshLevelEdit({ clearAll: true });
      setOverwritingNavmesh(false);
    }
  }, [saveNavmeshData, dispatchNavmeshLinkEdit, dispatchNavmeshNodeEdit, dispatchNavmeshLevelEdit, setOverwritingNavmesh]);

  useEffect(() => { // clear service config edits once saved sucessfully
    if (saveServiceConfigsData) {
      dispatchServiceConfigEdit({ clearAll: true });
    }
  }, [saveServiceConfigsData, dispatchServiceConfigEdit]);

  useEffect(() => { // clear microfence edits once saved sucessfully
    if (saveMicrofencesData) {
      dispatchMircofenceEdit({ clearAll: true });
    }
  }, [saveMicrofencesData, dispatchMircofenceEdit]);

  useEffect(() => { // clear microfence pair edits once saved sucessfully
    if (saveMicrofencePairsData) {
      dispatchMircofencePairEdit({ clearAll: true });
    }
  }, [saveMicrofencePairsData, dispatchMircofencePairEdit]);

  const saveError = saveLocatedAssetsError || savePortableAssetsError || saveNavmeshError || saveServiceConfigsError || saveMicrofencesError || saveMicrofencePairsError;

  return (<>
    <Dialog
      open={open && !executed}
      aria-labelledby="save-alert-dialog-title"
      aria-describedby="save-alert-dialog-description"
    >
      <DialogTitle id="save-alert-dialog-title">
        Save all changes?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="save-alert-dialog-description">
          This can not be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={executed} onClick={handleClose}>Cancel</Button>
        <Button disabled={executed} variant="contained" onClick={handleSave} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open && executed && !settled}
      onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    <Snackbar
      open={open && executed && settled}
      autoHideDuration={saveError ? null : 3000}
      onClose={handleClose}
      sx={{ marginBottom: '6px' }}
    >
      {saveError
        ? <Alert
          severity="error"
          sx={{
            width: '100%',
            border: '1px solid rgb(244, 67, 54)',
            cursor: 'pointer',
          }}
          onClose={handleClose}
        ><strong>Error: {messageFromAxiosOrUnknownError(saveError)}</strong><Typography fontSize='small'>{helpTipFromAxiosOrUnknownError(saveError)}</Typography></Alert>
        : <Alert
          severity="success"
          sx={{
            width: '100%',
            border: '1px solid rgb(102, 187, 106)',
            cursor: 'pointer',
          }}
          onClose={handleClose}
        ><strong>Save successful</strong>, reloading data...</Alert>
      }
    </Snackbar>
  </>);
};
