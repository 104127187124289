import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { SMALL_WIDTH } from '../Sidebar/Sidebar';
import { useMobile } from '../useMobile';

export const HEIGHT = 56;

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      right: 0,
      overflow: 'hidden',
      width: `calc(100vw - ${SMALL_WIDTH}px)`,
      height: HEIGHT,
      background: '#5555',
      transition: theme.transitions?.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    rootMobile: {
      width: '100vw',
    },
    hidden: {
      height: 0,
    },
  }),
);

export const BannerOverlay = ({ children }: { children: ReactNode }) => {
  const classes = useStyle();
  const isMobile = useMobile();

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootMobile]: isMobile,
        [classes.hidden]: !children,
      })}
    >
      {children}
    </div>
  );
};
