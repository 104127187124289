import { useContextBridge } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import { Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Object3D, Vector3 } from 'three';
import { UploadNavmeshDialog } from '../EditTools/UploadNavmeshDialog';
import { EditingContext } from '../EditTools/useEditingData';
import { MapInterpolationContext } from '../MapData/useInterpolatedData';
import { MapDataContext, useMapData } from '../MapData/useMapData';
import { SpeedAdjustmentContext } from '../MapData/useSpeedAdjustment';
import { TagboardInactivityContext } from '../MapData/useTagboardInactivityThreshold';
import { MapInteractionContext } from '../MapInteraction/useMapInteraction';
import { MapPageArgs } from '../MapPage/MapPage';
import { Default } from '../scenes/Default/Default';
import { Edit } from '../scenes/Edit/Edit';
import { Empty } from '../scenes/Empty/Empty';

export const Map = ({ mode }: { mode: MapPageArgs['mode'] }) => {
  const map = useMapData();
  const ContextBridge = useContextBridge(MapDataContext, MapInteractionContext, MapInterpolationContext, SpeedAdjustmentContext, EditingContext, TagboardInactivityContext);

  const navigate = useNavigate()

  const navmeshMissing = !!map.data && !(map.data.navMesh);
  useEffect(() => {
    if (navmeshMissing && mode !== 'edit') {
      navigate('/map/edit')
    }
  }, [navmeshMissing, mode, navigate])

  Object3D.DefaultUp = new Vector3(0, 0, 1);
  return (
    <Canvas>
      <ContextBridge>
        <Suspense fallback={<></>}>
          {!map.data && <Empty />}
          {map.data && navmeshMissing && <Edit />}
          {map.data && !navmeshMissing && mode !== 'edit' && <Default />}
          {map.data && !navmeshMissing && mode === 'edit' && <Edit />}
        </Suspense>
      </ContextBridge>
    </Canvas>
  );
};
