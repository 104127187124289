import axios, { AxiosError } from 'axios';
import { Atom, useAtomValue, useSetAtom, WritableAtom } from 'jotai';
import { ReactNode, useEffect } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { PERSISTOR_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { LiveStateUpdate as MessageContainer } from './Messages';

// Use at the top of the "logged in" app tree; to manage latest events lifecycle
export const LatestEventsProvider = ({
  children,
  hoursAgo,
  latestEventsAtom,
  timeNowAtom,
  clientId,
  projectId
}: {
  children: ReactNode;
  hoursAgo: number;
  latestEventsAtom: WritableAtom<MessageContainer[], MessageContainer[]>;
  timeNowAtom: WritableAtom<Date, Date> | WritableAtom<{ now: () => Date }, { now: () => Date }>;
  clientId?: string;
  projectId?: string;
}) => {
  const setLatestEvents = useSetAtom(latestEventsAtom);
  const authedRequestConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const persistorUrl = useAtomValue(PERSISTOR_URL);
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);

  const clientProject = `${clientId ?? cid}/${projectId ?? pid}`

  const nowAtomValue = useAtomValue(timeNowAtom)

  const nowUnixTs = Math.floor(('now' in nowAtomValue ? nowAtomValue.now() : nowAtomValue).getTime() / 1000);
  const fromUnixTs = nowUnixTs - 60 * 60 * hoursAgo;

  useEffect(() => {
    let cancelled = false;
    const abortController = new AbortController();
    setLatestEvents([]);
    if (!isNaN(fromUnixTs) && !isNaN(nowUnixTs) && fromUnixTs !== nowUnixTs) {
      (async () => {
        try {
          const events = (
            await axios.get<MessageContainer[]>(
              `${persistorUrl}/${clientProject}/latest/${fromUnixTs}/${nowUnixTs}`,
              {
                headers: authedRequestConfig.headers,
                signal: abortController.signal,
              },
            )
          ).data;
          if (!cancelled) {
            setLatestEvents(events);
          }
        } catch (e) {
          if (!cancelled) throw e;
        }
      })();
    }

    return () => {
      cancelled = true;
      abortController.abort();
    };
  }, [setLatestEvents, authedRequestConfig, clientProject, fromUnixTs, nowUnixTs, persistorUrl]);

  return <>{children}</>;
};
