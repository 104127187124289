import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { METADATA_URL } from '../../../store/url';
import { useAsyncTask } from '../../../util/AsyncTask';
import { LocatedAsset } from '../../api/LocatedAsset.validator';
import { DeleteAssetByUuid } from './useEditingData';

const isFreshDeletion = (asset: LocatedAsset | DeleteAssetByUuid) =>
  'delete' in asset && asset.uuid.includes('fresh');

export const useSaveLocatedAssets = (assets: (LocatedAsset | DeleteAssetByUuid)[]) => {
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const httpBaseUrl = useAtomValue(METADATA_URL);

  const saveLocatedAssets = useCallback(async () => {
    return Promise.all(
      assets
        .filter(a => !isFreshDeletion(a))
        .map(async asset => {
          const { uuid, clientId, projectId, createdAt, updatedAt, ...body } = {
            createdAt: undefined,
            updatedAt: undefined,
            ...asset,
          };
          if ('delete' in asset) {
            return (
              await axios.delete<LocatedAsset>(
                `${httpBaseUrl}/${clientId}/${projectId}/locatedasset/${uuid}`,
                authedConfig,
              )
            ).data;
          } else if (asset.uuid.includes('fresh')) {
            return (
              await axios.post<LocatedAsset>(
                `${httpBaseUrl}/${clientId}/${projectId}/locatedasset`,
                body,
                authedConfig,
              )
            ).data;
          } else {
            return (
              await axios.patch<LocatedAsset>(
                `${httpBaseUrl}/${clientId}/${projectId}/locatedasset/${uuid}`,
                body,
                authedConfig,
              )
            ).data;
          }
        }),
    );
  }, [httpBaseUrl, authedConfig, assets]);

  return useAsyncTask<unknown, typeof saveLocatedAssets>(saveLocatedAssets);
};
