export const timeToSliderValue = (hours: number, minutes: number) => (hours + minutes / 60) * 100;

export const timeFromSliderValue = (index: number) => ({
  hours: Math.floor(index / 100),
  minutes: Math.floor((index / 100 - Math.floor(index / 100)) * 60),
});

export const labelFromSliderValue = (value: number) => {
  const { hours, minutes } = timeFromSliderValue(value);

  // account for timeIndex being for previous or next day
  const hoursOfDay = (hours + 24) % 24;

  const hh = hoursOfDay % 12 || 12;
  const mm = minutes.toString().padStart(2, '0');
  const amOrPm = hoursOfDay < 12 ? 'am' : 'pm';
  const left = value < 0 ? '← ' : '';
  const right = value >= 2400 ? ' →' : '';

  return `${left}${hh}:${mm} ${amOrPm}${right}`;
};
