import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { beaconLabelHack } from '../../util/beaconIdHack';
import { useMapData } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { BeaconInfo } from './BeaconInfo';
import { DeviceInfo } from './DeviceInfo';
import { LocatorInfo } from './LocatorInfo';
import { ActiveTagsReport } from './Report/ActiveTagsReport';
import { CrossingsReport } from './Report/CrossingsReport';
import { SafeZonesReport } from './Report/SafeZonesReport';
import { TagboardReport } from './Report/TagboardReport';
import { SensorInfo } from './SensorInfo';
import { WelfareInfo } from './WelfareInfo';

const heightHack = 0; // hack so browsers don't add unneccesary whitespace and scrollbar to reports

export const HighlightedInfo = () => {
  const { highlighted } = useMapInteraction();
  const { data: mapData } = useMapData();

  const gatewayAsset = useMemo(
    () => highlighted?.category === 'gateway' ? mapData?.liveData?.state.assets.get(highlighted.id) : undefined,
    [highlighted, mapData?.liveData?.state.assets]
  );

  if (!highlighted) {
    return <Typography>Nothing currently selected</Typography>;
  }

  const title = gatewayAsset?.type === 'mqttgateway'
    ? 'MQTT Gateway'
    : highlighted.category.replace(/./, match => match.toUpperCase());

  const subtitle = highlighted.category === 'gateway' && highlighted.label.toLocaleLowerCase() === 'gateway' ? '' : beaconLabelHack(highlighted.label)

  return (
    <Grid container spacing={3} direction="column">
      <Grid item>
        <Box paddingBottom={2} component="div">
          <Typography variant="h4">{title}</Typography>
          <Typography>{subtitle}</Typography>
        </Box>
      </Grid>
      {(highlighted.category === 'locator' || highlighted.category === 'gateway') && <LocatorInfo id={highlighted.id} />}
      {highlighted.category === 'device' && <DeviceInfo id={highlighted.id} />}
      {highlighted.category === 'beacon' && <BeaconInfo id={highlighted.id} />}
      {highlighted.category === 'report' && highlighted.id === 'report-active-tags' && <ActiveTagsReport height={heightHack} />}
      {highlighted.category === 'report' && highlighted.id === 'report-tag-board' && <TagboardReport height={heightHack} />}
      {highlighted.category === 'report' && highlighted.id === 'report-safe-zones' && <SafeZonesReport height={heightHack} />}
      {highlighted.category === 'report' && highlighted.id === 'report-crossings' && <CrossingsReport height={heightHack} asTagboards={false} />}
      {highlighted.category === 'sensor' && <SensorInfo id={highlighted.id} />}
      {highlighted.category === 'welfare' && <WelfareInfo />}
    </Grid>
  );
};
