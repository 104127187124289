import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { NAVMESHBUILDER_URL } from '../../../store/url';
import { useAsyncTask } from '../../../util/AsyncTask';
import { FullOrPartialNavmesh } from './useEditingData';

export const useBuildNavmesh = ({
  clientId,
  projectId,
  dxfFile,
  smoothing,
  simplification,
  extend,
  existingNavmesh,
}: {
  clientId: string;
  projectId: string;
  dxfFile?: File;
  smoothing: number;
  simplification: number;
  extend: boolean;
  existingNavmesh?: FullOrPartialNavmesh;
}) => {
  const authedRequestConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const {
    headers: { Authorization },
  } = authedRequestConfig;
  const httpBaseUrl = useAtomValue(NAVMESHBUILDER_URL);

  const saveNavmesh = useCallback(async () => {
    if (!dxfFile) throw new Error('dxf file is required');

    if (extend && !existingNavmesh) throw new Error('existing navmesh is required for extending');

    const newNavmesh = (
      await axios.post(`${httpBaseUrl}/${clientId}/${projectId}/generate-navmesh`, dxfFile, {
        params: { smoothing, simplification },
        headers: {
          'Content-Type': 'image/vnd.dxf',
          Authorization,
        },
      })
    ).data;

    if (!extend) return newNavmesh;

    return (
      await axios.post(
        `${httpBaseUrl}/${clientId}/${projectId}/merge-navmeshes`,
        [existingNavmesh, newNavmesh],
        authedRequestConfig,
      )
    ).data;
  }, [
    httpBaseUrl,
    authedRequestConfig,
    Authorization,
    clientId,
    projectId,
    dxfFile,
    simplification,
    smoothing,
    extend,
    existingNavmesh,
  ]);

  return useAsyncTask<FullOrPartialNavmesh, typeof saveNavmesh>(saveNavmesh);
};
