/* eslint-disable @typescript-eslint/no-namespace */
import { extend, ReactThreeFiber } from '@react-three/fiber';
import CameraControls from 'camera-controls';
import * as THREE from 'three';
import { Vector3 } from 'three';
import { CameraType } from '../../underground/map/MapInteraction/useMapInteraction';
import { releaseVector3, temporaryVector3 } from '../../underground/util/vectorUtils';

export const setupCameraControls = () => {
  CameraControls.install({ THREE: THREE });
  extend({ CameraControls });
};

declare global {
  namespace JSX {
    interface IntrinsicElements {
      cameraControls: ReactThreeFiber.Object3DNode<CameraControls, typeof CameraControls>;
    }
  }
}

export const lerpTo = (controls: CameraControls, position: Vector3, lookAt: Vector3, options?: { preventRotation?: boolean }) => {
  const currentTarget = temporaryVector3();
  const currentPosition = temporaryVector3();
  controls.getTarget(currentTarget);
  controls.getPosition(currentPosition);
  const currentAzimuthAngle = controls.azimuthAngle;
  const [stx, sty, stz] = currentTarget.toArray();
  const [spx, spy, spz] = currentPosition.toArray();
  const [epx, epy, epz] = position.toArray();
  const [etx, ety, etz] = lookAt.toArray();
  controls.lerpLookAt(spx, spy, spz, stx, sty, stz, epx, epy, epz, etx, ety, etz, 1, true);
  if (options?.preventRotation) {
    controls.rotateAzimuthTo(currentAzimuthAngle);
  }
  releaseVector3(currentTarget, currentPosition);
};


export const updateControls = (camera: CameraType, cameraControls: CameraControls) => {
  switch (camera) {
    case '3D':
      apply3DControls(cameraControls); break;
    case '3Dchase':
      apply3DNonTruckControls(cameraControls); break;
    case '3DchaseLocked':
      removeAllControls(cameraControls); break;
  }
};

export const apply3DControls = (controls: CameraControls) => {
  controls.mouseButtons.left = CameraControls.ACTION.ROTATE;
  controls.mouseButtons.right = CameraControls.ACTION.TRUCK;
  controls.mouseButtons.middle = CameraControls.ACTION.TRUCK;
  controls.mouseButtons.wheel = CameraControls.ACTION.DOLLY;
  controls.touches.one = CameraControls.ACTION.TOUCH_ROTATE;
  controls.touches.two = CameraControls.ACTION.TOUCH_DOLLY_TRUCK;
  controls.touches.two = CameraControls.ACTION.TOUCH_DOLLY_TRUCK;
};
export const apply3DNonTruckControls = (controls: CameraControls) => {
  controls.mouseButtons.left = CameraControls.ACTION.ROTATE;
  controls.mouseButtons.right = CameraControls.ACTION.NONE;
  controls.mouseButtons.middle = CameraControls.ACTION.NONE;
  controls.mouseButtons.wheel = CameraControls.ACTION.DOLLY;
  controls.touches.one = CameraControls.ACTION.TOUCH_ROTATE;
  controls.touches.two = CameraControls.ACTION.TOUCH_DOLLY;
  controls.touches.two = CameraControls.ACTION.TOUCH_DOLLY;
};
export const removeAllControls = (controls: CameraControls) => {
  controls.mouseButtons.left = CameraControls.ACTION.NONE;
  controls.mouseButtons.right = CameraControls.ACTION.NONE;
  controls.mouseButtons.middle = CameraControls.ACTION.NONE;
  controls.mouseButtons.wheel = CameraControls.ACTION.NONE;
  controls.touches.one = CameraControls.ACTION.NONE;
  controls.touches.two = CameraControls.ACTION.NONE;
  controls.touches.three = CameraControls.ACTION.NONE;
};

export { CameraControls };
