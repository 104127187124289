import { ReactNode } from "react";
import { LiveEventsProvider } from "../util/Events/LiveEventsProvider";
import { ExpireSessionOn401 } from "./ExpireSessionOn401";
import { ExpireSessionOnInactivity } from "./ExpireSessionOnInactivity";

export const Authed = ({ children }: { children: ReactNode }) => (
  <ExpireSessionOnInactivity>
    <ExpireSessionOn401>
        {children}
    </ExpireSessionOn401>
  </ExpireSessionOnInactivity>
);