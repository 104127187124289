import { useFBX } from '@react-three/drei';
import { useMemo } from 'react';
import { Group } from 'three';
import { DEFAULT_BEACON_MODEL, DEFAULT_DEVICE_MODEL } from '../../../../../config';
import { ModelName, toKnownModelOrUndefined } from '../../../../api/PortableAsset.helpers';

export type Icon = 'heavy' | 'light' | 'personnel';

const HAT_SCALE = 0.06;
const VEHICLE_SCALE = 0.0067;
const LIGHT_VEHICLE_SCALE = 0.005;

export const useModelType = (
  model: ModelName | undefined,
  type: string | undefined,
): [Group, Icon] => {
  const defaultModel = type !== 'beacon' ? DEFAULT_DEVICE_MODEL : DEFAULT_BEACON_MODEL;

  const modelName = toKnownModelOrUndefined(model) ?? defaultModel;

  const fbx = useFBX(`https://ug-3d-models.geomoby.com/${modelName}.fbx`);

  const scaledFbx = useMemo(() => {
    switch (modelName) {
      case 'Miners_Helmet':
        fbx.scale.set(HAT_SCALE, HAT_SCALE, HAT_SCALE);
        fbx.position.set(-0.23, 2.1, -0.2);
        break;
      case 'Light_Vehicle':
        fbx.scale.set(LIGHT_VEHICLE_SCALE, LIGHT_VEHICLE_SCALE, LIGHT_VEHICLE_SCALE);
        break;
      default:
        fbx.scale.set(VEHICLE_SCALE, VEHICLE_SCALE, VEHICLE_SCALE);
    }
    return fbx.clone();
  }, [fbx, modelName]);

  const iconName: Icon = useMemo(() => {
    if (modelName === 'Miners_Helmet') {
      return 'personnel';
    } else if (modelName === 'Light_Vehicle') {
      return 'light';
    } else {
      return 'heavy';
    }
  }, [modelName]);

  return [scaledFbx, iconName];
};
