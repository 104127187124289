import { PortableAsset as PortableAssetsValidatorType } from './PortableAsset.validator';

// These are separate from the .validator.ts file so that they can be imported and used
// in unit tests ("registerType" doesn't work when running tests)

export const knownModels = [
  'Articulated_Dump_Truck',
  'Big_Bulldozer',
  'Dump_Truck',
  'Heavy_Excavator',
  'Landfill_Compactor',
  'Little_Dump_Truck',
  'Mining_Excavator',
  'Mining_Truck',
  'Telehandler',
  'Underground_Loader',
  'Wheel_Loader_Small',
  'Miners_Helmet',
  'Light_Vehicle',
] as const;

export type ModelName = typeof knownModels[number];
export const toKnownModelOrUndefined = (name: string | null | undefined): ModelName | undefined =>
  knownModels.find(m => m === name) ?? undefined;

export enum PortableAssetGroup {
  ERT = 'ert',
}
