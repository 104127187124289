import { addDays, addHours, setSeconds } from 'date-fns';
import { atom } from 'jotai';
import { LiveStateUpdate } from '../util/Events/Messages';

export const REPLAY_LAST_EVENTS_FROM_PERSISTOR = atom<LiveStateUpdate[]>([]);

const DEFAULT_REPLAY_START = setSeconds(addHours(new Date(), -2), 0);
export const REPLAY_START = atom(DEFAULT_REPLAY_START);
export const REPLAY_END = atom(get => setSeconds(addDays(get(REPLAY_START), 1), 0));
export const REPLAY_NOW = atom(DEFAULT_REPLAY_START);
export const REPLAY_PLAYING = atom(false);
export const REPLAY_SECONDS_PER_SECOND = atom(2); // TODO: set to 8 once replay streaming bug is fixed (LTP-432)

export const REPLAY_MESSAGES = atom<LiveStateUpdate[]>([]);
export const NEW_REPLAY_MESSAGES = atom<LiveStateUpdate[]>([]);
