import { Typography } from '@mui/material';
import { compareDesc } from 'date-fns';
import { Fragment, useCallback } from 'react';
import { AssetState } from '../../../../util/Events/Messages';
import { stringifyIdRecord } from '../../../../util/stringUtils';
import { AssetGridItem } from '../../../util/portableAsset';
import { describeCount, isActive, isActiveForCrossing, isLightVehicleBeacon, isPersonnelBeacon, isPersonnelDevice, isTruckBeacon } from '../../../util/portableAssetUtils';
import { crossingAssetsFromLiveData, portbaleAssetsFromLiveData, useMapData } from '../../MapData/useMapData';
import { useMapInteraction } from '../../MapInteraction/useMapInteraction';
import { UpdateInfo } from '../CommonInfo';
import { Report, ReportGridHeading } from './Report';

type Crossings = { label: string, upstreamExits: AssetState[], downstreamExits: AssetState[], activeAssets: Record<string, boolean | undefined> }

const localeCompareByLabel = (a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label);

export const ActiveCrossings = ({ crossings: { label, activeAssets, upstreamExits, downstreamExits }, plain, key }: { crossings: Crossings, plain?: boolean, key: string | number }) => {
  const { highlight } = useMapInteraction();

  const enteredPersonnelBeacons = upstreamExits.filter(asset => activeAssets[stringifyIdRecord(asset.id)] && isPersonnelBeacon(asset)).sort(localeCompareByLabel);
  const enteredPersonnelDevices = upstreamExits.filter(asset => activeAssets[stringifyIdRecord(asset.id)] && isPersonnelDevice(asset)).sort(localeCompareByLabel);
  const enteredVehicles = upstreamExits.filter(asset => activeAssets[stringifyIdRecord(asset.id)] && isTruckBeacon(asset)).sort(localeCompareByLabel);
  const enetredPersonnelCount = enteredPersonnelBeacons.length + enteredPersonnelDevices.length;

  const exitedPersonnelBeacons = downstreamExits.filter(isPersonnelBeacon).sort(localeCompareByLabel);
  const exitedPersonnelDevices = downstreamExits.filter(isPersonnelDevice).sort(localeCompareByLabel);
  const exitedVehicles = downstreamExits.filter(isTruckBeacon).sort(localeCompareByLabel);
  const exitedPersonnelCount = exitedPersonnelBeacons.length + exitedPersonnelDevices.length;

  const toAssetGridItem = useCallback(
    (asset: AssetState) => AssetGridItem(asset, highlight, !!plain),
    [highlight, plain]
  );
  const toAssetGridItemPlain = useCallback(
    (asset: AssetState) => AssetGridItem(asset, highlight, true),
    [highlight]
  );

  return (
    <Fragment key={key}>
      <ReportGridHeading title={`Entered from ${label}:`}>
        <Typography>{enetredPersonnelCount} personnel, {describeCount(enteredVehicles.length, 'vehicle')}</Typography>
      </ReportGridHeading>
      {[...enteredPersonnelBeacons, ...enteredPersonnelDevices, ...enteredVehicles].map(toAssetGridItem)}
      <ReportGridHeading title={`Exited to ${label}:`}>
        <Typography>{exitedPersonnelCount} personnel, {describeCount(exitedVehicles.length, 'vehicle')}</Typography>
      </ReportGridHeading>
      {[...exitedPersonnelBeacons, ...exitedPersonnelDevices, ...exitedVehicles].map(toAssetGridItemPlain)}
    </Fragment>
  )
}

export const TagboardCrossings = ({ crossings: { label, downstreamExits }, plain, key }: { crossings: Crossings, plain?: boolean, key: string | number }) => {
  const { highlight } = useMapInteraction();

  const personnelBeacons = downstreamExits.filter(isPersonnelBeacon).sort(localeCompareByLabel);
  const personnelDevices = downstreamExits.filter(isPersonnelDevice).sort(localeCompareByLabel);
  const trucks = downstreamExits.filter(isTruckBeacon).sort(localeCompareByLabel);
  const lightVehicles = downstreamExits.filter(isLightVehicleBeacon).sort(localeCompareByLabel);
  const personnelCount = personnelBeacons.length + personnelDevices.length;
  const personnelBreakdown = personnelCount === 0
    ? ''
    : `(${describeCount(personnelBeacons.length, 'beacon')}, ${describeCount(personnelDevices.length, 'device')})`;
  const truckCount = trucks.length;
  const lightVehicleCount = lightVehicles.length;
  const vehiclesCount = truckCount + lightVehicleCount;
  const vehiclesBreakdown = vehiclesCount === 0
    ? ''
    : `(${describeCount(lightVehicleCount, 'light vehicle')}, ${describeCount(truckCount, 'truck')})`;

  const toAssetGridItem = useCallback(
    (asset: AssetState) => AssetGridItem(asset, highlight, !!plain),
    [highlight, plain]
  );

  return (
    <Fragment key={key}>
      <Typography variant='h6' ml={-3} sx={{ gridColumn: '1/-1' }}>{label}</Typography>
      <ReportGridHeading title="Personnel:">
        <Typography>{personnelCount} {personnelBreakdown}</Typography>
      </ReportGridHeading>
      {[...personnelBeacons, ...personnelDevices].sort(localeCompareByLabel).map(toAssetGridItem)}
      <ReportGridHeading title="Vehicles:">
        <Typography>{vehiclesCount} {vehiclesBreakdown}</Typography>
      </ReportGridHeading>
      {[...lightVehicles, ...trucks].sort(localeCompareByLabel).map(toAssetGridItem)}
    </Fragment>
  )
}

export const CrossingsReport = ({ height, plain, asTagboards }: { height?: number, plain?: boolean, asTagboards: boolean }) => {
  const { data: mapData, getNow } = useMapData();
  const now = getNow();
  const assets = (mapData?.liveData ? crossingAssetsFromLiveData(mapData?.liveData) : []);

  const allCrossings: Crossings[] = (mapData?.microfencePairs || [])
    .filter(pair => asTagboards ? pair.isTagboard === true : true)
    .sort((a, b) => (a.name ?? a.id).localeCompare(b.name ?? b.id))
    .map(pair => {
      const crossedAssets = assets.filter(asset => asset.lastCrossed?.pairIds.id === pair.id);
      return {
        label: pair.name ?? pair.id,
        upstreamExits: crossedAssets.filter(asset => asset?.lastCrossed?.exitedUpstream),
        downstreamExits: crossedAssets.filter(asset => asset?.lastCrossed?.exitedUpstream === false),
        activeAssets: Object.fromEntries(crossedAssets
          .filter(asset => isActiveForCrossing(asset, now))
          .map(asset => [stringifyIdRecord(asset.id), true]))
      }
    });

  const lastUpdated = allCrossings.flatMap(({ upstreamExits, downstreamExits }) => [...upstreamExits, ...downstreamExits])
    .flatMap(asset => asset?.lastCrossed?.iso8601 ? new Date(asset.lastCrossed.iso8601) : [])
    .sort(compareDesc).at(0) ?? mapData?.liveData?.state?.lastUpdate;

  return (
    <>
      {!plain && <UpdateInfo lastUpdate={lastUpdated} />}
      {allCrossings.length > 0 && (<Report style={{ height }}>
        {allCrossings.map((crossings, i) => asTagboards ? TagboardCrossings({ crossings, plain, key: i }) : ActiveCrossings({ crossings, plain, key: i }))}
      </Report>)}
    </>
  );
};
