import { PublicKeyCredentialCreationOptionsJSON } from '@simplewebauthn/typescript-types';
import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../store/auth';
import { AUTHN_URL } from '../store/url';
import { useAsyncTask } from '../util/AsyncTask';

export const usePasskeyCount = () => {
  const httpBaseUrl = useAtomValue(AUTHN_URL);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);

  const count = useCallback(async () => {
    const { data } = await axios.get<PublicKeyCredentialCreationOptionsJSON>(
      `${httpBaseUrl}/open/passkey/register`,
      authedConfig,
    );

    return data.excludeCredentials?.length ?? 0;
  }, [httpBaseUrl, authedConfig]);

  return useAsyncTask<number, typeof count>(count);
};
