import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { METADATA_URL } from '../../../store/url';
import { useAsyncTask } from '../../../util/AsyncTask';
import { ServiceConfig } from '../../api/ServiceConfig.validator';
import { DeleteAssetById } from './useEditingData';

const isFreshDeletion = (asset: ServiceConfig | DeleteAssetById) =>
  'delete' in asset && asset.id.includes('fresh');

export const useSaveServcieConfigs = (assets: (ServiceConfig | DeleteAssetById)[]) => {
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const httpBaseUrl = useAtomValue(METADATA_URL);

  const saveServiceConfigs = useCallback(async () => {
    return Promise.all(
      assets
        .filter(a => !isFreshDeletion(a))
        .map(async asset => {
          const { id, clientId, projectId, createdAt, updatedAt, ...body } = {
            createdAt: undefined,
            updatedAt: undefined,
            ...asset,
          };
          if ('delete' in asset) {
            return (
              await axios.delete<ServiceConfig>(
                `${httpBaseUrl}/${clientId}/${projectId}/serviceconfig/${id}`,
                authedConfig,
              )
            ).data;
          } else if (asset.id.includes('fresh')) {
            return (
              await axios.post<ServiceConfig>(
                `${httpBaseUrl}/${clientId}/${projectId}/serviceconfig`,
                body,
                authedConfig,
              )
            ).data;
          } else {
            return (
              await axios.patch<ServiceConfig>(
                `${httpBaseUrl}/${clientId}/${projectId}/serviceconfig/${id}`,
                body,
                authedConfig,
              )
            ).data;
          }
        }),
    );
  }, [httpBaseUrl, authedConfig, assets]);

  return useAsyncTask<unknown, typeof saveServiceConfigs>(saveServiceConfigs);
};
