import {
  PersonPinCircle,
  PhoneIphone,
  Settings,
  Work,
  AccountCircleRounded,
  Replay,
  ListAlt,
  DeviceThermostat,
  Directions,
  Toc,
  SensorOccupied,
  Layers,
  AppRegistration as EditMany,
  Tune,
  Sensors,
} from '@mui/icons-material';
import { Badge, Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMapData } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { MapPageArgs } from '../MapPage/MapPage';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

export const MapSidebar = ({ mode }: { mode: MapPageArgs['mode'] }) => {
  const { showSecondary } = useSidebarInteraction();
  const { data: mapData } = useMapData();
  const { level } = useMapInteraction();
  const hasSafeZones = useMemo(() => !!(mapData?.locatedAssets.find(a => a.safe)), [mapData?.locatedAssets]);
  const navigate = useNavigate();
  const hasLevels = mapData?.navMesh?.levels?.length !== undefined;

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      {mode === 'replay' && (<ListItem button onClick={() => showSecondary(Secondary.REPLAY)}>
        <ListItemIcon>
          <Replay />
        </ListItemIcon>
        <ListItemText primary="Replay" />
      </ListItem>)}
      {mode === 'replay' && <Divider />}
      {(hasLevels || mode === 'edit') && (<>
        <ListItem button onClick={() => showSecondary(mode === 'edit' ? Secondary.EDIT_LEVELS : Secondary.LEVELS)}>
          <ListItemIcon>
            <Badge color="primary" variant="dot" invisible={level === undefined}>
              <Layers />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Levels" />
        </ListItem>
        <Divider />
      </>)}
      {mode === 'edit' && (<>
        <ListItem button onClick={() => showSecondary(Secondary.EDIT_DEVICES)}>
          <ListItemIcon>
            <PhoneIphone />
          </ListItemIcon>
          <ListItemText primary="Devices" />
        </ListItem>
        <ListItem button onClick={() => showSecondary(Secondary.EDIT_BEACONS)}>
          <ListItemIcon>
            <PersonPinCircle />
          </ListItemIcon>
          <ListItemText primary="Beacons" />
        </ListItem>
        <ListItem button onClick={() => showSecondary(Secondary.EDIT_LOCATORS)}>
          <ListItemIcon>
            <Work />
          </ListItemIcon>
          <ListItemText primary="Locators" />
        </ListItem>
        <ListItem button onClick={() => showSecondary(Secondary.EDIT_MICROFENCES)}>
          <ListItemIcon>
            <Sensors />
          </ListItemIcon>
          <ListItemText primary="Microfences" />
        </ListItem>
        <ListItem button onClick={() => showSecondary(Secondary.EDIT_SERVICE_CONFIGS)}>
          <ListItemIcon>
            <Tune />
          </ListItemIcon>
          <ListItemText primary="Configurations" />
        </ListItem>
        <ListItem button onClick={() => showSecondary(Secondary.EDIT_BULK)}>
          <ListItemIcon>
            <EditMany />
          </ListItemIcon>
          <ListItemText primary="Bulk edit" />
        </ListItem>
        <Divider />
      </>)}
      {mode !== 'edit' && (<>
        <ListItem button onClick={() => showSecondary(Secondary.DEVICES)}>
          <ListItemIcon>
            <PhoneIphone />
          </ListItemIcon>
          <ListItemText primary="Devices" />
        </ListItem>
        <ListItem button onClick={() => showSecondary(Secondary.BEACONS)}>
          <ListItemIcon>
            <PersonPinCircle />
          </ListItemIcon>
          <ListItemText primary="Beacons" />
        </ListItem>
        <ListItem button onClick={() => showSecondary(Secondary.LOCATORS)}>
          <ListItemIcon>
            <Work />
          </ListItemIcon>
          <ListItemText primary="Locators" />
        </ListItem>
        <ListItem button onClick={() => showSecondary(Secondary.SENSORS)}>
          <ListItemIcon>
            <DeviceThermostat />
          </ListItemIcon>
          <ListItemText primary="Sensors" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => showSecondary(Secondary.DIRECTIONS)}>
          <ListItemIcon>
            <Directions />
          </ListItemIcon>
          <ListItemText primary="Directions" />
        </ListItem>
        <ListItem button onClick={() => showSecondary(Secondary.REPORTS)}>
          <ListItemIcon>
            <ListAlt />
          </ListItemIcon>
          <ListItemText primary="Reports" />
        </ListItem>
      </>)}
      {hasSafeZones && mode === 'live' && <ListItem button onClick={() => showSecondary(Secondary.WELFARE)}>
        <ListItemIcon>
          <SensorOccupied />
        </ListItemIcon>
        <ListItemText primary="Welfare" />
      </ListItem>}
      {mode !== 'edit' && <Divider />}
      <ListItem button onClick={() => showSecondary(Secondary.MAP_SETTINGS)}>
        <ListItemIcon>
          <Settings />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem>
      <ListItem button onClick={() => showSecondary(Secondary.ACCOUNT)}>
        <ListItemIcon>
          <AccountCircleRounded />
        </ListItemIcon>
        <ListItemText primary="Account" />
      </ListItem>
      <ListItem button onClick={() => navigate('/global')}>
        <ListItemIcon>
          <Toc />
        </ListItemIcon>
        <ListItemText primary="All projects" />
      </ListItem>
    </List>
  );
};
