import { registerType, createDetailedValidator } from 'typecheck.macro';
export type PortableAsset = {
  uuid: string;
  clientId: string;
  projectId: string;
  id: string;
  ids?: object | null;
  label?: string | null;
  mac?: string | null;
  type: string;
  tool?: string;
  model?: string | null; // Doesn't need to be validated by `isPortableAssets`, is handled in code using `toKnownModelOrUndefined`
  group?: string | null;
  createdAt?: string;
  updatedAt?: string | null;
};
export type PortableAssets = PortableAsset[];

registerType('PortableAssets');

export const isPortableAssets = createDetailedValidator<PortableAssets>({
  expectedValueFormat: 'type-ir',
});
