import { useRef } from 'react';
import { Vector3 } from 'three';
import { useEditingData } from './useEditingData';

export const useCurrentEditVec3 = () => {
  const { currentEdit } = useEditingData();
  if (currentEdit?.type !== 'located') return;

  const { x, y, z } = currentEdit.asset.position;

  return new Vector3(x, y, z)
};

// Used when you want a reference to a position that doesn't cause state updates
export const useCurrentEditVec3Ref = () => {
  const currentEdit = useCurrentEditVec3();
  const highlightedRef = useRef<Vector3 | undefined>();

  highlightedRef.current = currentEdit;

  return highlightedRef;
};
