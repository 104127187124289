import { Delete, Edit, Search } from '@mui/icons-material';
import { TextField, Typography, Checkbox, TableContainer, Box, Table, TableHead, TableRow, TableCell, TableBody, InputAdornment, IconButton, Tooltip, Paper, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { Dropdown } from '../../../util/FormControl/Dropdown';
import { knownModels } from '../../api/PortableAsset.helpers';
import { PortableAsset } from '../../api/PortableAsset.validator';
import { useMapData } from '../MapData/useMapData';
import { DeleteAssetByUuid, useEditingData } from './useEditingData';

const TYPES = [
  { value: 'beacon', label: 'Beacon' },
  { value: 'device', label: 'GeoMoby – UG Zero Harm' },
  { value: 'pitram', label: 'Micromine – Pitram' },
  { value: 'plod', label: 'Digital Terrain – Plod' },
  { value: 'draeger', label: 'Draeger – Portable Gas Detector' },
];
const MODELS = knownModels
  .map(model => ({ value: model, label: model.replace(/_/g, ' ') }))
  .sort(({ label: a }, { label: b }) => a.localeCompare(b));
const GROUPS = [
  { value: 'ert', label: 'Emergency Response Team' }
];

const NotSet = () => <Typography component='em' color='GrayText' fontSize='small'>Not set</Typography>

export const EditInfoBulkPortable = () => {
  const { portableAssetEdits, dispatchPortableAssetEdit, setCurrentEdit } = useEditingData();
  const { data: mapData } = useMapData();

  const [search, setSearch] = useState('');

  const assets: PortableAsset[] = useMemo(() => {
    const existing = mapData?.portableAssets.filter(pa => !portableAssetEdits[pa.uuid]) ?? [];
    const creating = Object.values(portableAssetEdits).filter((pa): pa is PortableAsset => !!pa && !('delete' in pa))
    return [...existing, ...creating].map(pa => portableAssetEdits[pa.uuid] ? { ...pa, edited: true } : { ...pa, edited: false })
  }, [mapData?.portableAssets, portableAssetEdits]);

  const filteredAssets = useMemo(() => assets.filter(asset => {
    const searchFor = search.trim().toLocaleLowerCase();
    if (!searchFor) return true;

    return (
      asset.label?.toLocaleLowerCase().includes(searchFor) ||
      (TYPES.find(({ value }) => value === asset.type)?.label ?? asset.type).toLocaleLowerCase().includes(searchFor) ||
      asset.model?.replace(/_/g, ' ').toLocaleLowerCase().includes(searchFor) ||
      (GROUPS.find(({ value }) => value === asset.group)?.label ?? asset.group)?.toLocaleLowerCase().includes(searchFor) ||
      (asset.ids ? JSON.stringify(asset.ids) : asset.id).toLocaleLowerCase().includes(searchFor)
    );
  }).sort(({ label: la, id: ida }, { label: lb, id: idb }) => (la ?? ida).localeCompare(lb ?? idb)),
    [assets, search]);

  const [checkedIds, dispatchCheck] = useReducer((state: Record<string, boolean>, { check, id }: { check: boolean | 'updateFilter', id?: string }) => {
    if (check === 'updateFilter') {
      return Object.fromEntries(
        Object.entries(state).filter(([id]) => filteredAssets.find(f => f.id === id))
      );
    } else if (!id) {
      return Object.fromEntries(assets.map(({ id }) => [id, check]));
    }

    return { ...state, [id]: check };

  }, {});

  useEffect(() => {
    if (filteredAssets) {
      dispatchCheck({ check: 'updateFilter' })
    }
  }, [filteredAssets]);


  const onSetType = useCallback((type?: string) => {
    if (!type) return;

    const edits: PortableAsset[] = filteredAssets.filter(a => checkedIds[a.id]).map(asset => ({
      ...asset,
      type,
      ids: type !== 'draeger' ? undefined : asset.ids ?? ({ draegerSerial: asset.id.split('|')[0], partNumber: asset.id.split('|')[1] ?? '' })
    }));

    if (edits.length) {
      dispatchPortableAssetEdit(edits);
    }

  }, [filteredAssets, checkedIds, dispatchPortableAssetEdit])

  const onSetModel = useCallback((model?: string) => {
    const edits: PortableAsset[] = filteredAssets.filter(a => checkedIds[a.id]).map(asset => ({
      ...asset,
      model: model ?? null
    }));

    if (edits.length) {
      dispatchPortableAssetEdit(edits);
    }

  }, [filteredAssets, checkedIds, dispatchPortableAssetEdit])

  const onSetGroup = useCallback((group?: string) => {
    const edits: PortableAsset[] = filteredAssets.filter(a => checkedIds[a.id]).map(asset => ({
      ...asset,
      group: group ?? null,
    }));

    if (edits.length) {
      dispatchPortableAssetEdit(edits);
    }

  }, [filteredAssets, checkedIds, dispatchPortableAssetEdit])

  const onDeleteClick = useCallback(() => {
    const deletions: DeleteAssetByUuid[] = filteredAssets.filter(a => checkedIds[a.id]).map(asset => ({
      uuid: asset.uuid,
      clientId: asset.clientId,
      projectId: asset.projectId,
      delete: true,
    }));

    if (deletions.length) {
      dispatchPortableAssetEdit(deletions)
    }
  }, [filteredAssets, checkedIds, dispatchPortableAssetEdit])


  const allChecked = Object.values(assets).every(asset => checkedIds[asset.id]);
  const someChecked = Object.values(checkedIds).some(checked => !!checked);

  return (<>
    <Paper elevation={3} sx={{ display: 'flex', margin: 2, marginTop: 0, padding: 1, gap: 1 }}>
      <TextField
        id="input-filter-assets"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        sx={{ flexGrow: 1 }}
        variant="standard"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </Paper>
    <TableContainer component={Box} sx={{ marginLeft: 1, paddingLeft: 1 }}>
      <Table size='small' sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '1em' }}>
              <Checkbox checked={allChecked} indeterminate={someChecked && !allChecked} onChange={(e, check) => dispatchCheck({ check })} />
            </TableCell>
            <TableCell sx={{ width: '25%', height: '100%' }}>
              <Typography fontWeight={500} sx={{ flexShrink: 1 }}>
                Label and ID
              </Typography>
            </TableCell>
            <TableCell sx={{ width: '25%' }}>
              <Dropdown label="Set type" items={TYPES} noNone disabled={!someChecked} setSelected={onSetType} />
            </TableCell>
            <TableCell sx={{ width: '25%' }}>
              <Dropdown label="Set model" items={MODELS} noNone disabled={!someChecked} setSelected={onSetModel} />
            </TableCell>
            <TableCell sx={{ width: '25%' }}>
              <Dropdown label="Set group" items={GROUPS} disabled={!someChecked} setSelected={onSetGroup} />
            </TableCell>
            <TableCell sx={{ width: '1em' }}>
              <Tooltip title="Delete">
                <IconButton color='error' aria-label="delete" disabled={!someChecked}>
                  <Delete onClick={onDeleteClick} />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredAssets.map((asset) => (
            <TableRow
              key={asset.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell><Checkbox size='small' checked={checkedIds[asset.id] ?? false} onChange={(e, check) => dispatchCheck({ check, id: asset.id })} /></TableCell>
              <TableCell component="th" scope="row">
                <Stack>
                  {asset.label ?? asset.id}
                  <Paper elevation={3} sx={{ display: 'inline-block', padding: 0.5 }}>
                    <Typography component='code' fontSize='small'>
                      {asset.ids ? Object.entries(asset.ids).sort(([a], [b]) => a.localeCompare(b)).map(([key, val]) => `${key}: ${val || 'NOT SET'}`).join('\n') : `ID: ${asset.id}`}
                    </Typography>
                  </Paper>
                </Stack>
              </TableCell>
              <TableCell>{TYPES.find(({ value }) => value === asset.type)?.label ?? asset.type}</TableCell>
              <TableCell>{asset.model?.replace(/_/g, ' ') ?? <NotSet />}</TableCell>
              <TableCell>{asset.group
                ? (GROUPS.find(({ value }) => value === asset.group)?.label ?? asset.group)
                : <NotSet />}</TableCell>
              <TableCell>
                {checkedIds[asset.id] && (<Tooltip title="Edit asset">
                  <IconButton aria-label="edit">
                    <Edit onClick={() => setCurrentEdit({ type: 'portable', asset })} />
                  </IconButton>
                </Tooltip>)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </>
  );
};
