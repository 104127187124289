import { AddCircle, CheckCircle, Error as ExclaimCircle, Pending, NewReleases, Sensors, ArrowCircleUp, ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Vector3 } from 'three';
import { GatewayPin, LocatorPin, LocatedWirelessPin, PersonBeacon, HeavyBeacon, LightBeacon, QuestionMarkCircle, MqttGatewayPin } from '../../../util/IconFromSVG';
import { WorldOverlay } from '../WorldOverlay/WorldOverlay';

export type PinType = 'beacon-heavy' | 'beacon-light' | 'locator' | 'beacon-personnel';

const useStyles = makeStyles<{ selected: boolean }>({
  root: {
    cursor: 'pointer',
    opacity: 0.8,
    width: 32,
    height: 32,
  },
  selected: {
    opacity: 1,
  },
  ghost: {
    opacity: 0.4
  },
  pin: {
    position: 'relative',
    top: -32,
    left: -16,
  },
  float: {
    position: 'relative',
    width: 24,
    height: 24,
    top: -20,
    left: -12,
  },
  pinUncertain: {
    position: 'absolute',
    top: -18,
    left: 2,
  },
  pinSafety: {
    position: 'absolute',
    top: -42,
    left: 2,
    backgroundColor: 'black',
    borderRadius: '100%',
    clipPath: 'circle(7px at center)',
    fontSize: '16px !important'
  },
  pinErt: {
    position: 'absolute',
    top: -44,
    left: -8,
    backgroundColor: 'black',
    borderRadius: '100%',
    clipPath: 'circle(7px at center)',
    fontSize: '16px !important'
  },
  pinMicrofence: {
    position: 'absolute',
    top: -39,
    left: -17,
    borderRadius: '100%',
    fontSize: '30px !important'
  },
  pinMicrofencePairArrow: {
    position: 'absolute',
    top: -31,
    left: 9,
    borderRadius: '100%',
    fontSize: '16px !important'
  },
});

export const Pin = ({
  position,
  onClick,
  icon,
  selected,
  uncertain,
  safe,
  ert,
  edit,
  microfence,
  isGhost,
}: {
  position: Vector3;
  onClick?: () => void;
  icon: 'beacon-heavy' | 'beacon-light' | 'locator' | 'beacon-personnel' | 'gateway' | 'mqttgateway' | 'located-wireless';
  selected: boolean;
  uncertain?: boolean;
  safe?: boolean;
  ert?: boolean;
  edit?: 'update' | 'new',
  microfence?: boolean,
  isGhost?: boolean,
}) => {
  const classes = useStyles();

  const rootClasses = [classes.root, isGhost ? classes.ghost : selected ? classes.selected : undefined].filter((n): n is string => !!n);

  const rootClassName = clsx(...rootClasses);
  return (
    <WorldOverlay position={position}>
      <div className={rootClassName}>
        {microfence && <Sensors htmlColor='#90EE9088' className={classes.pinMicrofence} />}
        {icon === 'beacon-heavy' && <HeavyBeacon className={classes.pin} onClick={onClick} />}
        {icon === 'beacon-light' && <LightBeacon className={classes.pin} onClick={onClick} />}
        {icon === 'beacon-personnel' && <PersonBeacon className={classes.pin} onClick={onClick} />}
        {icon === 'locator' && <LocatorPin className={classes.float} onClick={onClick} />}
        {icon === 'gateway' && <GatewayPin className={classes.float} onClick={onClick} />}
        {icon === 'mqttgateway' && <MqttGatewayPin className={classes.float} onClick={onClick} />}
        {icon === 'located-wireless' && <LocatedWirelessPin className={classes.float} onClick={onClick} />}
        {uncertain && <QuestionMarkCircle className={classes.pinUncertain} />}
        {safe === true && <CheckCircle color='success' className={classes.pinSafety} />}
        {safe === false && <ExclaimCircle color='error' className={classes.pinSafety} />}
        {ert && <AddCircle htmlColor='orange' className={classes.pinErt} />}
        {edit === 'update' && <Pending htmlColor='white' className={classes.pinUncertain} />}
        {edit === 'new' && <NewReleases htmlColor='white' className={classes.pinUncertain} />}
      </div>
    </WorldOverlay>
  );
};
