import { Button, FormControl, FormControlLabel, Grid, InputLabel, Switch, TextField, Select, MenuItem } from '@mui/material';
import { Canvas } from '@react-three/fiber';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown } from '../../../util/FormControl/Dropdown';
import { knownModels, ModelName, toKnownModelOrUndefined } from '../../api/PortableAsset.helpers';
import { PortableAsset } from '../../api/PortableAsset.validator';
import { ModelViewer } from '../scenes/ModelViewer/ModelViewer';
import { useEditingData } from './useEditingData';

const DEVICE_TYPES = [
  { value: 'device', label: 'GeoMoby – UG Zero Harm' },
  { value: 'pitram', label: 'Micromine – Pitram' },
  { value: 'plod', label: 'Digital Terrain – Plod' },
  { value: 'draeger', label: 'Draeger – Portable Gas Detector' },
];
const GROUPS = [
  { value: 'ert', label: 'Emergency Response Team' }
];

export const EditInfoBeacon = ({ asset }: { asset: PortableAsset }) => {
  const { setCurrentEdit, dispatchPortableAssetEdit, setCurrentDeletion } = useEditingData();
  const [newId, setNewId] = useState<string>(asset.id);
  const [newIds, setNewIds] = useState<Record<string, string>>(asset.ids ? Object.fromEntries(Object.entries(asset.ids).map(([key, val]) => [key, String(val)])) : {});
  const [newLabel, setNewLabel] = useState<string>(asset.label ?? '');
  const [newMac, setNewMac] = useState<string>(asset.mac ?? '');
  const [newModel, setNewModel] = useState<ModelName | null>(toKnownModelOrUndefined(asset.model) ?? null)
  const [newGroup, setNewGroup] = useState<string | null>(asset.group ?? null)
  const [newType, setNewType] = useState<string>(asset.type);
  const isPersonnel = newType !== 'beacon' || newModel === 'Miners_Helmet';
  const deviceType = DEVICE_TYPES.find(({ value }) => value === asset.type);
  const isValid = useMemo(() => {
    if (newType === 'draeger') {
      return !!newIds['draegerSerial'] && !!newIds['partNumber']
    }
    return !!newId
  }, [newId, newIds, newType]
  );

  useEffect(() => { // handle asset changing while open
    if (asset.uuid) {
      setNewId(asset.id);
      setNewLabel(asset.label ?? '');
      setNewMac(asset.mac ?? '');
      setNewModel(toKnownModelOrUndefined(asset.model) ?? null);
      setNewGroup(asset.group ?? null);
      setNewType(asset.type);
      setNewIds(asset.ids ? Object.fromEntries(Object.entries(asset.ids).map(([key, val]) => [key, String(val)])) : {})
    }
  }, [asset.uuid, asset.id, asset.ids, asset.label, asset.mac, asset.model, asset.group, asset.type])

  const dispatchEdit = useCallback(() => {
    if (!isValid) throw new Error('UI should not try to dispatch invalid data');

    const update: PortableAsset = {
      ...asset,
      id: newId,
      ids: newIds,
      label: newLabel || null,
      mac: newMac || null,
      model: newModel || null,
      group: newGroup || null,
      type: asset.type || newType,
    };
    dispatchPortableAssetEdit(update)
    setCurrentEdit(undefined);

  }, [asset, setCurrentEdit, dispatchPortableAssetEdit, newId, newIds, isValid, newLabel, newModel, newGroup, newType, newMac])

  const requestDeletion = useCallback(() => {
    setCurrentDeletion(asset)
  }, [asset, setCurrentDeletion])

  const revertChanges = useCallback(() => {
    dispatchPortableAssetEdit({ uuid: asset.uuid, revert: true });
    setCurrentEdit(undefined);
  }, [asset.uuid, setCurrentEdit, dispatchPortableAssetEdit]);

  return (<>
    {deviceType && <Grid item maxWidth="100%">
      <TextField disabled label="Integration" variant="outlined" fullWidth value={deviceType.label} required />
    </Grid>}
    {!deviceType && asset.type !== 'beacon' && <Grid item maxWidth="100%">
      <Dropdown label='Integration' selected={newType ?? ''} setSelected={val => setNewType(val || '')} items={DEVICE_TYPES} required />
    </Grid>}
    {newType !== 'draeger' && (
      <Grid item maxWidth="100%">
        <TextField label="ID" variant="outlined" fullWidth value={newId} onChange={e => setNewId(e.target.value)} required />
      </Grid>
    )}
    {newType === 'draeger' && (<>
      <Grid item maxWidth="100%" container direction="row" spacing={2}>
        <Grid item xs={6}>
          <TextField label="Draeger Serial" variant="outlined" fullWidth value={newIds['draegerSerial'] ?? ''} onChange={e => setNewIds(ids => ({ ...ids, draegerSerial: e.target.value }))} required />
        </Grid>
        <Grid item xs={6}>
          <TextField label="Part Number" variant="outlined" fullWidth value={newIds['partNumber'] ?? ''} onChange={e => setNewIds(ids => ({ ...ids, partNumber: e.target.value }))} required />
        </Grid>
      </Grid>
    </>)}
    <Grid item maxWidth="100%">
      <TextField label="Label" variant="outlined" fullWidth value={newLabel} onChange={e => setNewLabel(e.target.value)} />
    </Grid>
    <Grid item maxWidth="100%">
      <TextField label="MAC address" variant="outlined" fullWidth value={newMac} onChange={e => setNewMac(e.target.value)} />
    </Grid>
    <Grid item maxWidth="100%">
      <FormControlLabel control={<Switch disabled={asset.type !== 'beacon'} checked={isPersonnel} onChange={e => setNewModel(e.target.checked ? 'Miners_Helmet' : 'Mining_Truck')} />} label="Personnel" />
    </Grid>
    <Grid item maxWidth="100%">
      <>
        {!isPersonnel && (
          <Dropdown
            label='Vehicle model'
            selected={newModel ?? ''}
            setSelected={val => setNewModel(toKnownModelOrUndefined(val) ?? null)}
            items={knownModels
              .filter(m => m !== 'Miners_Helmet')
              .sort((a, b) => a.localeCompare(b))
              .map(model => ({ value: model, label: model.replace(/_/g, ' ') }))
            }
          />
        )}
        {isPersonnel && <TextField disabled label="Model" variant="outlined" fullWidth value="Miner's Helmet" />}
        <div style={{ height: isPersonnel ? '100px' : '240px', overflow: 'hidden', transition: 'height 0.4s ease-out' }}>
          <Canvas style={{ background: "#111e", minHeight: '240px' }}>
            <ModelViewer name={newModel ?? undefined} type={asset.type} />
          </Canvas>
        </div>
      </>
    </Grid>
    <Grid item maxWidth="100%">
      <Dropdown label='Group' selected={newGroup ?? ''} setSelected={val => setNewGroup(val ?? null)} items={GROUPS} />
    </Grid>
    <Grid item container maxWidth="100%">
      <Button variant="contained" disabled={!isValid} onClick={dispatchEdit}>Set</Button>
      <Button variant="outlined" onClick={revertChanges}>Undo changes</Button>
      <Button color='warning' variant="outlined" onClick={requestDeletion}>Delete</Button>
    </Grid>
  </>
  );
};
