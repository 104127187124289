import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { NAVMESHBUILDER_URL } from '../../../store/url';
import { useAsyncTask } from '../../../util/AsyncTask';
import { FullOrPartialNavmesh } from './useEditingData';

export type Placement = { id: number; pos: [number, number, number] };

export const useAutoPlaceLocators = ({
  clientId,
  projectId,
  navmesh,
  gatewayNodeIds,
  existingLocatorPositions,
  minDistance,
  maxDistance,
}: {
  clientId: string;
  projectId: string;
  navmesh?: FullOrPartialNavmesh;
  gatewayNodeIds: number[];
  existingLocatorPositions: [number, number, number][];
  minDistance: number;
  maxDistance: number;
}) => {
  const authedRequestConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const httpBaseUrl = useAtomValue(NAVMESHBUILDER_URL);

  const positionLocators = useCallback(async () => {
    if (!navmesh) throw new Error('navmesh is required');

    return (
      await axios.post(
        `${httpBaseUrl}/${clientId}/${projectId}/position-locators`,
        {
          navmesh,
          gateway_nodes: gatewayNodeIds,
          existing_locator_positions: existingLocatorPositions,
          min_distance: minDistance,
          max_distance: maxDistance,
        },
        authedRequestConfig,
      )
    ).data;
  }, [
    httpBaseUrl,
    authedRequestConfig,
    clientId,
    projectId,
    navmesh,
    gatewayNodeIds,
    existingLocatorPositions,
    minDistance,
    maxDistance,
  ]);

  return useAsyncTask<Placement[], typeof positionLocators>(positionLocators);
};
