import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../store/auth';
import { AUTHN_URL } from '../store/url';
import { useAsyncTask } from '../util/AsyncTask';
import { AccessItem } from './useInviteUser';

type User = {
  id: string;
  label: string;
  createdAt: string;
  updatedAt: string;
  access: AccessItem[];
};

export const useAllUsers = () => {
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const httpBaseUrl = useAtomValue(AUTHN_URL);

  const login = useCallback(
    async () => (await axios.get<User[]>(`${httpBaseUrl}/open/user`, authedConfig)).data,
    [httpBaseUrl, authedConfig],
  );

  return useAsyncTask<User[], typeof login>(login);
};
