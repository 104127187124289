import { registerType, createDetailedValidator } from 'typecheck.macro';

export type Microfence = {
  clientId: string;
  projectId: string;
  id: string;
  assetId: { [identifier: string]: string | undefined };
  name: string;
  boundaryRssi: number;
  timeoutSeconds: number;
  point?: { type: 'Point'; coordinates: [number, number] } | null;
  type: string;
  zone?: string;
  createdAt?: string;
  updatedAt?: string | null;
};
export type Microfences = Microfence[];

registerType('Microfences');

export const isMicrofences = createDetailedValidator<Microfences>({
  expectedValueFormat: 'type-ir',
});
