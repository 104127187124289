import { useMemo } from 'react';
import { SearchSidebar } from '../../../util/SearchSidebar/SearchSidebar';
import { PortableAsset } from '../../api/PortableAsset.validator';
import { useEditingData } from '../EditTools/useEditingData';
import { useMapData } from '../MapData/useMapData';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

export const EditDeviceSidebar = () => {
  const map = useMapData();
  const { currentEdit, setCurrentEdit, portableAssetEdits } = useEditingData();
  const { showSecondary } = useSidebarInteraction();

  const highlightedUuid = currentEdit?.type === 'portable' ? currentEdit.asset.uuid : undefined;

  const beacons = useMemo(() => {
    const existing = map.data?.portableAssets.filter(pa => pa.type !== 'beacon' && !portableAssetEdits[pa.uuid]) ?? [];
    const creating = Object.values(portableAssetEdits).filter((pa): pa is PortableAsset => !!pa && !('delete' in pa) && 'type' in pa && pa.type !== 'beacon')
    return [...existing, ...creating].map(pa => portableAssetEdits[pa.uuid] ? { ...pa, edited: true } : { ...pa, edited: false })
  }, [map.data?.portableAssets, portableAssetEdits])

  const addNewBeacon: PortableAsset & { edited: boolean } = {
    uuid: 'fresh-*',
    id: '',
    label: '+ Add new device...',
    clientId: map.cid,
    projectId: map.pid,
    type: '',
    edited: false,
  };

  return (
    <SearchSidebar
      title="Beacons"
      onClick={(id: string, label: string) => {
        const asset = id === addNewBeacon.uuid
          ? { ...addNewBeacon, uuid: `fresh-${new Date().getTime()}`, label: '' }
          : beacons.find(b => b.uuid === id);
        setCurrentEdit(asset ? { type: 'portable', asset } : undefined);
        if (asset) {
          showSecondary(Secondary.NONE);
        }
      }}
      hide={() => showSecondary(Secondary.NONE)}
      options={
        [addNewBeacon, ...beacons]
          .map(({ uuid, id, label, edited }) => ({
            label: `${edited ? '* ' : ''}${label ?? id}${edited ? ` [${uuid.includes('fresh') ? 'creating' : 'edited'}]` : ''}`,
            id: uuid,
            selected: highlightedUuid === uuid,
          }))
          .sort(({ label: a }, { label: b }) => a.replace(/^\+ /, ' ').localeCompare(b.replace(/^\+ /, ' ')))
      }
    />
  );
};
