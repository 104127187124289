import { registerType, createDetailedValidator } from 'typecheck.macro';

export type ServiceConfig = {
  clientId: string;
  projectId: string;
  id: string;
  name: string;
  state_json: unknown;
  createdAt?: string;
  updatedAt?: string | null;
};
export type ServiceConfigs = ServiceConfig[];

registerType('ServiceConfigs');

export const isServiceConfigs = createDetailedValidator<ServiceConfigs>({
  expectedValueFormat: 'type-ir',
});
