import axios from 'axios';
import { subHours } from 'date-fns';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { PERSISTOR_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { useAsyncTask } from '../../util/AsyncTask';
import { AssetTagboard } from '../../util/Events/schema';

export const useLatestTagEvents = (before: Date) => {
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const httpBaseUrl = useAtomValue(PERSISTOR_URL);
  const authRequestConfig = useAtomValue(AUTHED_REQUEST_CONFIG);

  const latestTagEvents = useCallback(async () => {
    const toIso8601 = before.toISOString();
    const fromIso8601 = subHours(before, 48).toISOString();

    const query = new URLSearchParams({ fromIso8601, toIso8601, latest: '1' }).toString();

    return (
      await axios.get<AssetTagboard[]>(
        `${httpBaseUrl}/${cid}/${pid}/analytics/tag-events?${query}`,
        authRequestConfig,
      )
    ).data;
  }, [httpBaseUrl, cid, pid, authRequestConfig, before]);

  return useAsyncTask<AssetTagboard[], typeof latestTagEvents>(latestTagEvents);
};
