import { Atom, useAtomValue } from 'jotai';
import { createContext, ReactNode, useContext, useReducer } from 'react';

export type SpeedAdjustment = {
  multiplier: number,
  paused: boolean,
};

export const SpeedAdjustmentContext = createContext<SpeedAdjustment>({
  multiplier: 1,
  paused: false,
});


export const SpeedAdjustmentProvider = ({ children, speedAtom, playingAtom }: { speedAtom: Atom<number>, playingAtom: Atom<boolean>, children: ReactNode }) => {
  const multiplier = useAtomValue(speedAtom);
  const paused = !useAtomValue(playingAtom);

  return (
    <SpeedAdjustmentContext.Provider
      value={{
        multiplier,
        paused,
      }}
    >
      {children}
    </SpeedAdjustmentContext.Provider>
  );
};

export const useSpeedAdjustment = () => useContext(SpeedAdjustmentContext);
