
import React, { CSSProperties } from 'react';

export type IconFromSVGProps = { style?: CSSProperties, role?: 'presentation', height?: number, width?: number, className?: string, onClick?: React.MouseEventHandler<HTMLImageElement> };

const IconFromSVG = ({ style, role, height, width, path, className, onClick }: IconFromSVGProps & { path: string }) => (
  <img role={role} src={process.env.PUBLIC_URL + '/' + path} style={style} width={width} height={height} className={className} onClick={onClick} />
);

export const MiningTruck = (props: IconFromSVGProps) => IconFromSVG({ ...props, path: 'icon-mining-truck.svg' });
export const LightVehicle = (props: IconFromSVGProps) => IconFromSVG({ ...props, path: 'icon-pickup-truck.svg' });

export const GatewayPin = (props: IconFromSVGProps) => IconFromSVG({ ...props, path: 'GatewayPin.svg' });
export const MqttGatewayPin = (props: IconFromSVGProps) => IconFromSVG({ ...props, path: 'MqttGatewayPin.svg' });
export const LocatedWirelessPin = (props: IconFromSVGProps) => IconFromSVG({ ...props, path: 'LocatedPin.svg' });
export const LocatorPin = (props: IconFromSVGProps) => IconFromSVG({ ...props, path: 'LocatorPin.svg' });
export const PersonBeacon = (props: IconFromSVGProps) => IconFromSVG({ ...props, path: 'PersonPin.svg' });
export const HeavyBeacon = (props: IconFromSVGProps) => IconFromSVG({ ...props, path: 'TruckPin.svg' });
export const LightBeacon = (props: IconFromSVGProps) => IconFromSVG({ ...props, path: 'UtePin.svg' });
export const QuestionMarkCircle = (props: IconFromSVGProps) => IconFromSVG({ ...props, path: 'questionMarkCircle.svg' });