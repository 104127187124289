import { Map, Navigation, NearMe, NearMeOutlined, Public, Rotate90DegreesCcw, CheckCircle, Error as ExclaimCircle, FitScreen, } from '@mui/icons-material';
import { Chip, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Vector3 } from 'three';
import { HEIGHT } from '../../../util/HorizontalOverlay/HorizontalOverlay';
import { SMALL_WIDTH } from '../../../util/Sidebar/Sidebar';
import { useMobile } from '../../../util/useMobile';
import { useInterpolatedMapData } from '../MapData/useInterpolatedData';
import { useMapData } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    left: SMALL_WIDTH,
    width: `calc(100% - ${SMALL_WIDTH}px) !important`, // UR-723
    bottom: HEIGHT,
    height: '50px',
    pointerEvents: 'none',
  },
  rootMobile: {
    left: 0,
    width: '100vw !important', // UR-723
    // bottom: '50px',
    zIndex: 'initial',
    height: 'auto',
  },
  item: {
    zIndex: 2,
    pointerEvents: 'initial',
  },
  itemMobile: {
    zIndex: 'initial',
  },
  navContainer: {
    display: 'flex',
    justifyContent: 'center',
    zIndex: 2,
  },
  navContainerMobile: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingRight: '5px',
    paddingBottom: '5px',
    zIndex: 'initial',
  },
  welfareContainer: {
    display: 'flex',
    justifyContent: 'end',
    zIndex: 2,
  },
  welfareContainerMobile: {
    display: 'flex',
    justifyContent: 'end',
    zIndex: 'initial',
    order: -1,
  },
  welfare: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: '0.4em',
    padding: '0 0.5em',
    marginRight: '1em',
    background: '#0008',
  },
});

const formatDistance = (metres: number) => {
  const unit = metres < 1000 ? 'm' : 'km'
  const value = metres < 1000 ? metres.toFixed() : (metres / 1000).toFixed(1);
  return `${value} ${unit}`;
}

export const MapViewTools = () => {
  const { camera, setCamera, unsetChaseCamera, lockNormal, lockedNormal, highlighted, navigation, navDistance, highlight, fitScreenRequest, setFitScreenRequest } = useMapInteraction();
  const { assetsWelfare } = useInterpolatedMapData();
  const { inWelfareCheck } = useMapData();
  const { showSecondary } = useSidebarInteraction();
  const classes = useStyles();
  const isMobile = useMobile();

  const safeCount = Object.values(assetsWelfare).filter(({ safe }) => safe === true).length;
  const notSafeCount = Object.values(assetsWelfare).filter(({ safe }) => safe === false).length;

  const canChase = highlighted?.category === 'beacon' || navigation;
  const currentChaseMode = !canChase ? undefined : camera;

  return (
    <Grid container className={clsx(classes.root, { [classes.rootMobile]: isMobile })}>
      <Grid item xs={6} sm={4}>
        <Tooltip title="Rotate camera" className={clsx(classes.item, { [classes.itemMobile]: isMobile })}>
          <IconButton
            aria-label="rotate camera"
            onClick={() => {
              if (lockedNormal?.z === 1) {
                lockNormal(new Vector3(1, 0, 0));
              } else if (lockedNormal?.x === 1) {
                lockNormal(new Vector3(0, 1, 0));
              } else {
                lockNormal(new Vector3(0, 0, 1));
              }
            }}
          >
            <Rotate90DegreesCcw />
          </IconButton>
        </Tooltip>

        <Tooltip title="Fit to screen" className={clsx(classes.item, { [classes.itemMobile]: isMobile })}>
          <IconButton
            aria-label="fit camera to screen"
            disabled={fitScreenRequest}
            onClick={() => {
              if (camera !== '3D') {
                setCamera('3D');
              }
              setFitScreenRequest(true);
            }}
          >
            <FitScreen />
          </IconButton>
        </Tooltip>
        
        {currentChaseMode === '3D' && (
          <Tooltip title="Follow selected" className={clsx(classes.item, { [classes.itemMobile]: isMobile })}>
            <IconButton
              aria-label="follow selected"
              onClick={() => setCamera('3Dchase')}
            >
              <NearMeOutlined />
            </IconButton>
          </Tooltip>
        )}
        {currentChaseMode === '3Dchase' && (
          <Tooltip title="Naviagtion view" className={clsx(classes.item, { [classes.itemMobile]: isMobile })}>
            <IconButton
              aria-label="navigation view"
              onClick={() => setCamera('3DchaseLocked')}
            >
              <NearMe />
            </IconButton>
          </Tooltip>
        )}
        {currentChaseMode === '3DchaseLocked' && (
          <Tooltip title="Exit navigation" className={clsx(classes.item, { [classes.itemMobile]: isMobile })}>
            <IconButton
              aria-label="exit navigation view"
              onClick={() => unsetChaseCamera()}
            >
              <Navigation />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
      {(!isMobile || (navigation && navDistance)) && (<Grid item xs={6} sm={4} className={clsx(classes.navContainer, { [classes.navContainerMobile]: isMobile })}>
        {navigation && navDistance && (
          <Chip
            className={clsx(classes.item, { [classes.itemMobile]: isMobile })}
            color="secondary"
            variant='filled'
            label={formatDistance(navDistance) + ' to destination'}
            onClick={() => showSecondary(Secondary.DIRECTIONS)}
          />
        )}
      </Grid>)}
      <Grid item xs={(navigation && navDistance) ? 12 : 6} sm={4} className={clsx(classes.welfareContainer, { [classes.welfareContainerMobile]: isMobile })}
        style={{ order: (isMobile && (navigation && navDistance)) ? -1 : 'initial' }}>
        {inWelfareCheck && (
          <Typography
            component='div'
            className={clsx(classes.item, classes.welfare, { [classes.itemMobile]: isMobile })}
            onClick={() => highlight({ category: 'welfare', id: 'check', label: 'Welfare check' })}
          >
            <Typography component='span' color="success">{safeCount}</Typography><CheckCircle color="success" sx={{ fontSize: '15px' }} titleAccess="Safe" />
            <Divider
              orientation="vertical"
              flexItem
              sx={{ margin: '0 5px' }}
            />
            <Typography component='span'>{notSafeCount}</Typography><ExclaimCircle color="error" sx={{ fontSize: '15px' }} titleAccess="Not safe" />
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
