import { ArrowBack, LocationSearching } from '@mui/icons-material';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useAtomValue } from 'jotai';
import { CID_PID_PAIRS } from '../../store/user';
import { Secondary, useSidebarInteraction } from './useSidebarInteraction';

export const ProjectsSidebar = () => {
  const { showSecondary, navigateTo } = useSidebarInteraction();
  const projects = useAtomValue(CID_PID_PAIRS);

  const projectsItems = projects.map(({clientId, projectId}) => (
    <ListItem
    button
    key={`${clientId}/${projectId}`}
    onClick={() => navigateTo(clientId, projectId)}>
    <ListItemIcon><LocationSearching /></ListItemIcon>
    <ListItemText primary={`${clientId} / ${projectId}`} />
  </ListItem>
  ))

  return (
    <List component='div' disablePadding>
      <ListItem button onClick={() => showSecondary(Secondary.NONE)}>
        <ListItemIcon>
          <ArrowBack />
        </ListItemIcon>
      </ListItem>
      {projectsItems}
    </List>
  );
};
