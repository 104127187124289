import { ExpandMore } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, LinearProgress, Paper, Radio, RadioGroup, Slider, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NAVMESHBUILDER_DEFAULT_SIMPLIFICATION, NAVMESHBUILDER_DEFAULT_SMOOTHING } from '../../../config';
import { FileInput } from '../../../util/FileInput/FileInput';
import { useMapData } from '../MapData/useMapData';
import { useBuildNavmesh } from './useBuildNavmesh';
import { FullOrPartialNavmesh, useEditingData } from './useEditingData';

export const UploadNavmeshDialog = () => {
  const navigate = useNavigate()
  const { setTool, dispatchNavmeshLinkEdit, dispatchNavmeshNodeEdit, setOverwritingNavmesh, applyNavmeshEdits, bulkEdit, setBulkEdit } = useEditingData();
  const { data: mapData, cid: clientId, pid: projectId } = useMapData();

  const [open, setOpen] = useState(false);
  const [dxfFile, setDxfFile] = useState<File>()
  const [overwrite, setOverwrite] = useState(true);
  const [smoothing, setSmoothing] = useState(NAVMESHBUILDER_DEFAULT_SMOOTHING);
  const [simplificationPercent, setSimplificationPercent] = useState(NAVMESHBUILDER_DEFAULT_SIMPLIFICATION);

  const existingNavmesh = useMemo(() => applyNavmeshEdits(mapData?.navMesh ?? { clientId, projectId }), [applyNavmeshEdits, mapData?.navMesh, clientId, projectId]);
  const { execute, data: result, error, loading } = useBuildNavmesh({
    clientId,
    projectId,
    dxfFile,
    smoothing,
    simplification: simplificationPercent / 100,
    extend: !overwrite,
    existingNavmesh,
  })

  const [settled, setSettled] = useState(false);

  useEffect(() => {
    if (result) {
      setSettled(true);
    }
  }, [result, setSettled]);

  const anyNavmesh = !!existingNavmesh?.links?.length && !!existingNavmesh?.nodes?.length;

  useEffect(() => { // Open the dialog if project does not yet have a navmesh
    if (mapData && !anyNavmesh) {
      setSettled(false);
      setDxfFile(undefined);
      setOpen(true);
    }
  }, [mapData, anyNavmesh, setOpen])

  useEffect(() => { // Open the dialog if selected from sidebar
    if (bulkEdit === 'navmesh') {
      setSettled(false);
      setDxfFile(undefined);
      setOpen(true);
    }
  }, [bulkEdit, setOpen])

  useEffect(() => { // Ensure the dialog is closed if a navmesh has been uploaded & saved
    if (anyNavmesh && bulkEdit !== 'navmesh') {
      setOpen(false)
    }
  }, [mapData, anyNavmesh, bulkEdit, existingNavmesh, setOpen])

  const handleCancel = () => {
    setTool('INFO');
    setBulkEdit(undefined);
    const navmeshIsEmpty = !result && (!existingNavmesh?.nodes?.length || !existingNavmesh?.links?.length)
    if (navmeshIsEmpty) {
      navigate('/');
    } else {
      setSettled(false);
      setOpen(false);
      setDxfFile(undefined);
    }
  }

  const dispatchNavmeshEdits = useCallback(({ nodes, links }: FullOrPartialNavmesh) => {
    setOverwritingNavmesh(true);
    dispatchNavmeshNodeEdit({ clearAll: true });
    dispatchNavmeshLinkEdit({ clearAll: true });
    dispatchNavmeshNodeEdit(nodes ?? []);
    dispatchNavmeshLinkEdit(links ?? []);
  }, [setOverwritingNavmesh, dispatchNavmeshNodeEdit, dispatchNavmeshLinkEdit])

  useEffect(() => {
    if (!settled || !result?.nodes?.length || !result?.links?.length) return;

    dispatchNavmeshEdits(result);
    setOpen(false);
    setDxfFile(undefined);
    setSettled(false);
    setTool('INFO')
    setBulkEdit(undefined);
  }, [settled, overwrite, result, dispatchNavmeshEdits, setOpen, setDxfFile, setSettled, setTool, setBulkEdit])

  return (
    <Dialog
      open={open}
      aria-labelledby="upload-navmesh-dialog-title"
      aria-describedby="upload-navmesh-dialog-description"
    >
      <DialogTitle id="upload-navmesh-dialog-title">
        Upload map
      </DialogTitle>
      <DialogContent><>
        <DialogContentText id="upload-navmesh-dialog-description">
          Select a DXF file
        </DialogContentText>
        <FileInput id='dxf' onFileSet={(f) => setDxfFile(f)} allowTypes={['image/vnd.dxf', '.dxf']} />
        {error && <Typography variant='caption' color='error'>{Object(error).message}</Typography>}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="upload-navmesh-smoothing-content"
            id="upload-navmesh-smoothing-header"
          >
            <Typography>Options</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Paper elevation={2} sx={{ padding: 1, margin: 1, marginTop: -1 }}>
              <Typography>Smoothing: {smoothing} {smoothing === 1 ? 'iteration' : 'iterations'}</Typography>
              <Slider
                valueLabelDisplay="auto"
                value={smoothing}
                min={0}
                max={20}
                onChange={(_event, value) => setSmoothing(Array.isArray(value) ? value[0] : value)}
              />
            </Paper>
            <Paper elevation={2} sx={{ padding: 1, margin: 1 }}>
              <Typography>Simplification: {simplificationPercent}%</Typography>
              <Slider
                valueLabelDisplay="auto"
                value={simplificationPercent}
                min={0}
                max={100}
                onChange={(event, value) => setSimplificationPercent(Array.isArray(value) ? value[0] : value)}
              />
            </Paper>
          </AccordionDetails>
        </Accordion>
        {anyNavmesh && (<Paper elevation={2} sx={{ paddingTop: 1, paddingRight: 2, paddingBottom: 1, paddingLeft: 2, marginTop: 1.5 }}>
          <Typography sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>Mode</Typography>
          <RadioGroup
            name="controlled-radio-buttons-group"
            value={overwrite ? 'overwrite' : 'expand'}
            onChange={(e) => { setSettled(false); setOverwrite(e.target.value === 'overwrite'); }}
          >
            <FormControlLabel value="overwrite" control={<Radio />} label="Overwrite existing map" />
            <FormControlLabel value="expand" control={<Radio />} label="Extend existing map" />
          </RadioGroup>
        </Paper>)}
      </></DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <LoadingButton disabled={!dxfFile || loading} loading={loading} variant="contained" onClick={execute} autoFocus>
          Upload
        </LoadingButton>
      </DialogActions>
      <LinearProgress color={error ? 'error' : undefined} sx={{ opacity: loading ? 1 : 0 }} />
    </Dialog>
  );
};
