import { ExpandMore, CheckCircle, Error as ExclaimCircle } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Typography } from '@mui/material';
import { formatDuration } from 'date-fns';
import { clamp } from 'lodash';
import { useState } from 'react';
import { useInterpolatedMapData } from '../MapData/useInterpolatedData';
import { useMapData } from '../MapData/useMapData';
import { MessageWelfareCheck } from './MessageWelfareCheck';

const MIN_DURATION_MINS = 5;
const MAX_DURATION_MINS = 180;
const DEFAULT_DURATION_MINS = 120;

export const WelfareInfo = () => {
  const { data: mapData, inWelfareCheck } = useMapData();
  const { assetsWelfare } = useInterpolatedMapData();

  const [durationMinutes, setDurationMinutes] = useState(DEFAULT_DURATION_MINS);

  const lastWelfareCheck = mapData?.liveData?.state.lastWelfareCheck;
  const safeCount = Object.values(assetsWelfare).filter(({ safe }) => safe === true).length;
  const notSafeCount = Object.values(assetsWelfare).filter(({ safe }) => safe === false).length;

  return (
    <Grid item spacing={2} container direction="column">
      {inWelfareCheck && lastWelfareCheck && <Grid item width="100%" marginBottom="1em">
        <Typography variant="h6">Current Welfare Check</Typography>
        <Typography sx={{ marginLeft: '0.5em' }}>Started at {new Date(lastWelfareCheck.iso8601).toLocaleTimeString(undefined, { hour: "numeric", minute: "2-digit", hour12: true })}, ends at {new Date(lastWelfareCheck.expiryIso8601).toLocaleTimeString(undefined, { hour: "numeric", minute: "2-digit", hour12: true })}</Typography>
        <Typography color="error" sx={{ display: 'flex', alignContent: 'center', gap: '0.25em', marginLeft: '0.5em' }}><ExclaimCircle fontSize="small" color="error" /> {notSafeCount} not in safe zones</Typography>
        <Typography sx={{ display: 'flex', alignContent: 'center', gap: '0.25em', marginLeft: '0.5em' }}><CheckCircle fontSize="small" color="success" /> {safeCount} in safe zones</Typography>
      </Grid>}
      <Grid item width="100%">
        <Typography variant="h6">Start {inWelfareCheck && lastWelfareCheck ? 'new ' : ' '}welfare check</Typography>

        <Accordion sx={{ boxShadow: 'initial' }}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{ padding: 0 }}
          >
            <Typography>Duration: {formatDuration({ minutes: durationMinutes % 60, hours: Math.floor(durationMinutes / 60) }, { format: ['hours', 'minutes'] })}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              fullWidth
              id="wfc-duration"
              label="Minutes"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: MIN_DURATION_MINS,
                max: MAX_DURATION_MINS
              }}
              variant="filled"
              value={durationMinutes}
              onChange={event => {
                const userValue = Number(event.target.value.replace(/\D*/g, ''))
                if (isNaN(userValue)) return;
                setDurationMinutes(clamp(userValue, MIN_DURATION_MINS, MAX_DURATION_MINS))
              }}
            />
          </AccordionDetails>
        </Accordion>

        <Typography>Broadcast message to all devices</Typography>
        <MessageWelfareCheck durationMinutes={durationMinutes} />
      </Grid>
    </Grid>
  );
};
