import { useGLTF } from '@react-three/drei';
import { Gltf } from '../../../MapData/useMapData';

export const GltfObject = (obj: Gltf) => {
  const asset = useGLTF(obj.asset);
  return (
    <primitive object={asset.scene} position={[0, 0, 0.05]} scale={100}>
      <meshStandardMaterial color={'red'} opacity={0.5} />
    </primitive>
  );
};
