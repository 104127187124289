import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { TIMED_OUT } from '../store/auth';
import { useLogout } from './useLogout';

export const useTimedOut = () => {
  const logout = useLogout();
  const setTimedOut = useSetAtom(TIMED_OUT);
  const navigate = useNavigate();

  return useCallback(() => {
    logout();
    setTimedOut(true);
    navigate('/sign-in');
  }, [logout, navigate, setTimedOut]);
};
