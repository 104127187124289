import { OrthographicCamera, PerspectiveCamera } from '@react-three/drei';
import { useThree, useFrame } from '@react-three/fiber';
import CameraControls from 'camera-controls';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Vector3,
  OrthographicCamera as OrthographicCameraImpl,
  PerspectiveCamera as PerspectiveCameraImpl,
} from 'three';
import { apply3DControls, lerpTo, updateControls } from '../../../../util/CameraControls/CameraControls';


export const Camera = () => {
  const camera = useRef<OrthographicCameraImpl | PerspectiveCameraImpl>();
  const cameraControls = useRef<CameraControls>(null);

  useEffect(() => {
    let cancelled = false;
    const init = async () => {
      while (!cameraControls.current) {
        await new Promise(resolve => setTimeout(resolve, 10));
        if (cancelled) return;
      }
      if (!cancelled) {
        apply3DControls(cameraControls.current);
      }
    }
    init();

    return () => {
      cancelled = true
    }
  }, [])

  const dom = useThree(state => state.gl.domElement);
  return (
    <>
      <PerspectiveCamera ref={camera} makeDefault position={new Vector3(0, 6, 3.8)} />
      {camera.current && <cameraControls ref={cameraControls} args={[camera.current, dom]} />}
    </>
  );
};
