import { ArrowBack } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, List, ListItem, ListItemIcon, Slider, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

export const ReplaySidebar = ({
  secondsPerSecond,
  setSecondsPerSecond,
  startDateTime: initialStartDateTime,
  onResetClick,
}: {
  secondsPerSecond: number,
  setSecondsPerSecond: (value: number) => void,
  startDateTime: Date | null,
  onResetClick: (startDate: Date) => void,
}) => {
  const { showSecondary } = useSidebarInteraction();
  const [startDateTime, setStartDateTime] = useState<Date | null>(initialStartDateTime)

  return (
    <List component="div" disablePadding>
      <ListItem button onClick={() => showSecondary(Secondary.NONE)}>
        <ListItemIcon>
          <ArrowBack />
        </ListItemIcon>
      </ListItem>
      <Divider />
      <Box p={2} marginBottom={-2} component="div">
        <Typography variant="h6">Replay</Typography>
      </Box>
      <Box p={2} marginBottom={-2} component="div">
        <Typography variant="subtitle1">Playback start</Typography>
        <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Stack direction="column" spacing={1} width={'100%'}>
            <DateTimePicker
              disableFuture
              value={startDateTime}
              onChange={setStartDateTime}
              label="Date and time"
              renderInput={params => <TextField {...params} />}
            />
            <Tooltip title="The replay will be setup to start at this point">
              <LoadingButton
                onClick={() => {
                  if (startDateTime) {
                    onResetClick(startDateTime);
                  }
                }}
                size="large"
                color="secondary"
                variant="contained"
                disabled={!startDateTime || isNaN(startDateTime.getTime())}
              >
                Set
              </LoadingButton>
            </Tooltip>
          </Stack>
        </ListItem>
      </Box>
      <Box p={2} marginBottom={-2} component="div">
        <Typography variant="subtitle1">Playback speed</Typography>
      </Box>
      <Box
        px={4}
        maxWidth={250} // TODO: remove maxWidth once replay streaming bug is fixed (LTP-432)
        component="div">
        <Slider
          value={Math.log2(secondsPerSecond)}
          min={0}
          valueLabelDisplay="auto"
          valueLabelFormat={value => `${Math.pow(2, value)} seconds per second`}
          max={5} // TODO: set to 7 once replay streaming bug is fixed (LTP-432)
          step={null}
          marks={Array(6) // TODO: set to 8 once replay streaming bug is fixed (LTP-432)
            .fill(1)
            .map((_, value) => ({
              value,
              label: `${Math.pow(2, value)}x`,
            }))}
          onChange={(_, value) => setSecondsPerSecond(Math.pow(2, Number(value)))}
        />
      </Box>
    </List>
  );
};