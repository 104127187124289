import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useEffect } from 'react';
import { CornerOverlay } from '../../../util/CornerOverlay/CornerOverlay';
import { useMobile } from '../../../util/useMobile';
import { EditInfo } from '../EditTools/EditInfo';
import { useEditingData } from '../EditTools/useEditingData';
import { HighlightedInfo } from '../HighlightedInfo/HighlightedInfo';
import { useMapData } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    any: {
      padding: 16,
      zIndex: 1099,
    },
    anyMobile: {
      padding: 8,
    },
    error: {
      background: '#800',
    },
    pre: { whiteSpace: 'pre-wrap', wordBreak: 'break-word' },
  }),
);

export const MapOverlay = () => {
  const { error } = useMapData();
  const { highlighted, highlight, largerInfoOverlay, setLargerInfoOverlay } = useMapInteraction();
  const { currentEdit, setCurrentEdit, bulkEdit, setBulkEdit, } = useEditingData();
  const isMobile = useMobile();
  const classes = useStyle();

  let overlayNode = undefined;
  const isEdit = currentEdit || (bulkEdit && bulkEdit !== 'navmesh')

  if (error) {
    overlayNode = (
      <>
        <Typography>Project error: </Typography>
        <pre className={classes.pre}>{JSON.stringify(error, null, 2)}</pre>
      </>
    );
  } else if (highlighted) {
    overlayNode = <HighlightedInfo />;
  } else if (isEdit) {
    overlayNode = <EditInfo />;
  }

  const isLargerEdit = currentEdit?.type === 'portable' || !!bulkEdit;

  useEffect(() => {
    if (!isEdit) return;

    setLargerInfoOverlay(isLargerEdit)
  }, [isEdit, isLargerEdit, setLargerInfoOverlay])

  return (
    <CornerOverlay
      maximisedClassName={clsx({
        [classes.any]: !!overlayNode,
        [classes.anyMobile]: !!overlayNode && isMobile,
        [classes.error]: error,
      })}
      toggleMinimised={error
        ? undefined
        : () => {
          setCurrentEdit(undefined);
          setBulkEdit(undefined);
          highlight(undefined);
        }}
      minimised={false}
      full={largerInfoOverlay}
      setFull={bulkEdit ? undefined : setLargerInfoOverlay}
    >
      {overlayNode}
    </CornerOverlay>
  );
};
