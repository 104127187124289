import { useCallback, useState } from 'react';

export type AsyncTask<T> = {
  loading: boolean;
  data?: T;
  error?: unknown;
};

export const useAsyncTask = <TaskResult, Task extends (...args: unknown[]) => Promise<TaskResult>>(
  task: Task,
) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TaskResult>();
  const [error, setError] = useState<unknown>();

  const execute = useCallback(
    async (...params: Parameters<Task>) => {
      setLoading(true);
      setError(undefined);
      setData(undefined);
      try {
        setData(await task(...params));
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setData, setError, task],
  );

  return { execute, loading, data, error };
};
