import { Cancel } from "@mui/icons-material";
import { SxProps, Theme, Typography, Link } from "@mui/material"
import { makeStyles } from '@mui/styles';
import clsx from "clsx";
import { useRef, useState } from "react";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.3em',
    width: '100%',
    padding: '1em',
    margin: '1em 0',
    border: '2px dotted ivory',
    borderRadius: '0.5em',
    minWidth: '25vw',
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover': {
      border: '2px dashed ivory',
      background: '#fffff08'
    },
  },
  draggingOver: {
    border: '2px dashed lightblue',
    background: '#ffd1'
  }
});

export const FileInput = ({ id, onFileSet, sx, allowTypes }: { id: string, onFileSet: (file?: File) => void, sx?: SxProps<Theme>, allowTypes?: string[] }) => {
  const [draggingOver, setDraggingOver] = useState(false);
  const [error, setError] = useState<string>()
  const [file, setFileInternal] = useState<File>();
  const setFile = (f?: File) => {
    setFileInternal(f);
    onFileSet(f);
    if (file) {
      setError(undefined);
    }
  };
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const classes = useStyles();

  const onInputChange = () => {
    setFile(fileInputRef.current?.files?.[0]);
  };

  const onDragDrop = (dataTransfer: DataTransfer) => {
    // Based on https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/File_drag_and_drop
    let file: File | undefined = undefined;
    if (dataTransfer?.items) {
      const item = dataTransfer.items[0];
      file = item.kind === "file" && item.getAsFile() || undefined;
    } else {
      file = dataTransfer?.files[0];
    }

    if (!file) {
      setFile(undefined);
      return;
    }

    const typeMatches = !allowTypes || allowTypes.includes(file.type) || allowTypes.find(type => file?.name.toLocaleLowerCase().endsWith(type.toLocaleLowerCase()));
    if (!typeMatches) {
      setError("Invalid file type: " + (file.type || file.name.split('.').at(-1)))
      setFile(undefined);
      return;
    }

    setFile(file)
  }

  return (
    <Typography sx={sx}>
      <label
        htmlFor={`upload-${id}`}
        className={clsx(classes.root, { [classes.draggingOver]: draggingOver })}
        onDragOver={(e) => {
          e.preventDefault(); // Prevent navigation
          setDraggingOver(true);
          setError(undefined);
        }}
        onDragLeave={() => setDraggingOver(false)}
        onDragEnter={() => {
          setDraggingOver(true);
          setError(undefined);
        }}
        onDragExit={() => setDraggingOver(false)}
        onDrop={(event) => {
          event.preventDefault();
          onDragDrop(event.dataTransfer);
          setDraggingOver(false);
        }}
      >
        {(file?.name && !draggingOver)
          ? <>{file.name} <Cancel onClick={(e) => { e.stopPropagation(); e.preventDefault(); setFile(undefined) }} fontSize="small" htmlColor="white" /></>
          : <><Link>Choose a file</Link> or drag it here</>
        }
      </label>
      <input
        type="file"
        ref={fileInputRef}
        id={`upload-${id}`}
        accept={allowTypes ? allowTypes.join(',') : undefined}
        style={{ display: 'none' }}
        onChange={onInputChange}
      />
      {error && <Typography component='div' variant='caption' color='error'>{error}</Typography>}
    </Typography>
  )
}
