import { startAuthentication } from '@simplewebauthn/browser';
import { AuthenticationResponseJSON } from '@simplewebauthn/typescript-types';
import axios from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { AUTH_NULLABLE, TIMED_OUT } from '../store/auth';
import { AUTHN_URL } from '../store/url';
import { useAsyncTask } from '../util/AsyncTask';

export const usePasskeyAuthenticate = () => {
  const setAuth = useSetAtom(AUTH_NULLABLE);
  const setTimedOut = useSetAtom(TIMED_OUT);
  const httpBaseUrl = useAtomValue(AUTHN_URL);

  const authenticate = useCallback(async () => {
    const { data } = await axios.get(`${httpBaseUrl}/open/passkey/authenticate`);
    let authentication: AuthenticationResponseJSON;
    try {
      // Pass the options to the authenticator and wait for a response
      authentication = await startAuthentication(data.options);
    } catch (error) {
      // A simple error message to show users
      throw new Error('Failed to access passkey');
    }

    const { data: verification } = await axios
      .post<{ verified: false } | { verified: true; accessJwt: string; refreshJwt: string }>(
        `${httpBaseUrl}/open/passkey/authenticate`,
        { ...authentication, attemptId: data.attemptId },
      )
      .catch(() => ({ data: undefined }));

    if (!verification?.verified) {
      throw new Error('Failed to authenticate passkey');
    }
    const { accessJwt, refreshJwt } = verification;

    setAuth({ accessJwt, refreshJwt });
    setTimedOut(false);
    return data.verified;
  }, [httpBaseUrl, setAuth, setTimedOut]);

  return useAsyncTask<boolean, typeof authenticate>(authenticate);
};
