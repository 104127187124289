import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';

type DisplayType = 'info' | 'error' | 'warning' | 'confirm' | 'note';

const useStyles = makeStyles({
  all: {
    margin: 0,
    overflow: 'auto',
    padding: 5,
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  info: {
    backgroundColor: '#00000077',
  },
  confirm: {
    backgroundColor: '#0000ff77',
  },
  warning: {
    backgroundColor: '#ffff0044',
  },
  error: {
    backgroundColor: '#ff000055',
  },
});

export const MapText = ({
  displayType = 'info',
  opacity,
  children,
}: {
  displayType?: DisplayType;
  opacity?: number,
  children: ReactNode;
}) => {
  return (
    <pre
      style={{
        backgroundColor: '#00000077',
        margin: 0,
        overflow: 'auto',
        padding: 5,
        fontSize: 12,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: displayType === 'note' ? '1px solid #fff' : undefined,
        opacity
      }}
    >
      {children}
    </pre>
  );
};
