import { registerType, createDetailedValidator } from 'typecheck.macro';

export type LocatedAsset = {
  uuid: string;
  clientId: string;
  projectId: string;
  id: string;
  label?: string | null;
  position: {
    x: number;
    y: number;
    z: number;
  };
  type: string;
  safe?: boolean;
  createdAt?: string;
  updatedAt?: string | null;
  surface?: boolean;
};
export type LocatedAssets = LocatedAsset[];

registerType('LocatedAssets');

export const isLocatedAssets = createDetailedValidator<LocatedAssets>({
  expectedValueFormat: 'type-ir',
});
