import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { METADATA_URL } from '../../../store/url';
import { useAsyncTask } from '../../../util/AsyncTask';
import { Navmesh } from '../../api/Navmesh.validator';
import { FullOrPartialNavmesh } from './useEditingData';

export const useSaveNavmesh = (navmesh: FullOrPartialNavmesh | undefined, isNew: boolean) => {
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const httpBaseUrl = useAtomValue(METADATA_URL);

  const saveNavmesh = useCallback(async () => {
    if (!navmesh) return 'no-op';

    const { clientId, projectId, createdAt, updatedAt, ...body } = navmesh;
    if (body.levels?.length === 0) {
      body.levels = null;
    }

    return (
      await axios[isNew ? 'post' : 'patch']<Navmesh>(
        `${httpBaseUrl}/${clientId}/${projectId}/navmesh`,
        body,
        authedConfig,
      )
    ).data;
  }, [httpBaseUrl, authedConfig, navmesh, isNew]);

  return useAsyncTask<Navmesh | undefined | 'no-op', typeof saveNavmesh>(saveNavmesh);
};
