import { Checkbox, FormControlLabel, Typography } from "@mui/material";

export const CheckboxAutoFormLabel = (
  { checked, disabled, text, value, units, unit, onChange, formatter }:
    { checked?: boolean, disabled?: boolean, text: string, value: number | undefined, units: string, unit: string, onChange?: (checked: boolean) => void, formatter?: (value: number) => string }
) => {
  return <FormControlLabel
    control={<Checkbox checked={checked} disabled={disabled} onChange={(event, checked) => onChange?.(checked)} />}
    label={<AutoFormLabel text={text} value={value} units={units} unit={unit} formatter={formatter}  />}
  />
}

export const AutoFormLabel = (
  { text, value, units, unit, formatter }:
    { text: string, value: number | undefined, units: string, unit: string, formatter?: (value: number) => string }
) => {
  const color = value === undefined ? 'gray' : 'white';
  const formattedValue = value === undefined ? 'Not set' : formatter?.(value) ?? `${value} ${value === 1 ? unit : units}`;
  return <Typography color={color}>{text} <Typography component='div' variant='caption'>
      {formattedValue}
    </Typography></Typography>;
}
