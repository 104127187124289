/**
 * This source code is 99% copy pasted from: https://discourse.threejs.org/t/profiledcontourgeometry/2330
 *
 * The changes include:
 *   - improve typescript friendliness
 *   - add z dimension to the translation matrix, allowing 3d extrusion
 */

/* eslint-disable */

import {
  BufferAttribute,
  BufferGeometry,
  Matrix4,
  Shape,
  ShapeBufferGeometry,
  Vector2,
  Vector3,
} from 'three';
import { makeNoise2D, makeNoise3D } from 'open-simplex-noise';

const vector3To2 = (vector: Vector3) => new Vector2(...vector.toArray().slice(0, 2));

const baseTunnelShape = new Shape();
baseTunnelShape.lineTo(2.5, 0);
baseTunnelShape.absarc(0, 2, 2.5, 0, Math.PI, false);
baseTunnelShape.lineTo(-2.5, 0);

function midPoint(a: Vector3, b: Vector3) {
  return a.clone().add(b.clone().sub(a).multiplyScalar(0.5));
}

const splitTunnel = (input: Vector3[], maxDistance: number) => {
  let contour = [...input.map(vector => vector.clone())];

  let i = 1;
  while (i < contour.length) {
    const a = contour[i - 1];
    const b = contour[i];

    if (a.distanceTo(b) > maxDistance) {
      const x = contour.slice(0, i);
      const y = midPoint(a, b);
      const z = contour.slice(i);
      contour = [...x, y, ...z];

      // Start again
      i = 1;
    } else {
      i++;
    }
  }

  return contour;
};

const halfPi = Math.PI / 2;

const noise3d = makeNoise3D(0);
const bumpyShape = (shape: Shape, i: number, distanceBetweenShapes: number) => {
  const noisyPoints = shape
    .getPoints()
    .map(point =>
      point.clone().addScalar(noise3d(point.x, point.y, (i * distanceBetweenShapes) / 2) * 0.15),
    );
  return new Shape(noisyPoints);
};

export function TunnelGeometry(baseContour: Vector3[]) {
  const maxDistance = 1;
  const contour = splitTunnel(baseContour, maxDistance);
  const tunnelShapeBuffer = new ShapeBufferGeometry(bumpyShape(baseTunnelShape, 1, maxDistance));
  // Set the "up" of the tunnel
  tunnelShapeBuffer.rotateX(halfPi);
  // Flattened number array containing all tunnel shape vectors
  const tunnelShapePositions = tunnelShapeBuffer.attributes.position;
  const tunnelShapePoints = new Float32Array(tunnelShapePositions.count * contour.length * 3);

  for (let i = 0; i < contour.length; i++) {
    const isFirst = i === 0;
    const isLast = i === contour.length - 1;

    const previousVector = new Vector2().subVectors(
      vector3To2(contour[i - 1 < 0 ? contour.length - 1 : i - 1]),
      vector3To2(contour[i]),
    );
    const nextVector = new Vector2().subVectors(
      vector3To2(contour[i + 1 == contour.length ? 0 : i + 1]),
      vector3To2(contour[i]),
    );
    const angle = nextVector.angle() - previousVector.angle();
    let halfAngle = isFirst || isLast ? halfPi : angle * 0.5;
    const nextAngle = (isLast ? previousVector.angle() : nextVector.angle()) + halfPi;

    const shift = Math.tan(halfAngle - halfPi);
    const shiftMatrix = new Matrix4().set(1, 0, 0, 0, -shift, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);

    const rotationMatrix = new Matrix4().set(
      Math.cos(nextAngle),
      -Math.sin(nextAngle),
      0,
      0,
      Math.sin(nextAngle),
      Math.cos(nextAngle),
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1,
    );

    const translationMatrix = new Matrix4().set(
      1,
      0,
      0,
      contour[i].x,
      0,
      1,
      0,
      contour[i].y,
      0,
      0,
      1,
      contour[i].z,
      0,
      0,
      0,
      1,
    );

    const tunnelShapeBuffer = new ShapeBufferGeometry(bumpyShape(baseTunnelShape, i, maxDistance));
    // Set the "up" of the tunnel
    tunnelShapeBuffer.rotateX(halfPi);
    // Flattened number array containing all tunnel shape vectors
    const tunnelShapePositions = tunnelShapeBuffer.attributes.position;

    const cloneProfile = tunnelShapePositions.clone();
    cloneProfile.applyMatrix4(shiftMatrix);
    cloneProfile.applyMatrix4(rotationMatrix);
    cloneProfile.applyMatrix4(translationMatrix);

    tunnelShapePoints.set(cloneProfile.array, cloneProfile.count * i * 3);
  }

  const fullProfileGeometry = new BufferGeometry();
  fullProfileGeometry.setAttribute('position', new BufferAttribute(tunnelShapePoints, 3));
  const index = [];

  const lastCorner = contour.length - 1;
  for (let i = 0; i < lastCorner; i++) {
    for (let j = 0; j < tunnelShapeBuffer.attributes.position.count; j++) {
      const currCorner = i;
      const nextCorner = i + 1 == contour.length ? 0 : i + 1;
      const currPoint = j;
      const nextPoint = j + 1 == tunnelShapeBuffer.attributes.position.count ? 0 : j + 1;

      const a = nextPoint + tunnelShapeBuffer.attributes.position.count * currCorner;
      const b = currPoint + tunnelShapeBuffer.attributes.position.count * currCorner;
      const c = currPoint + tunnelShapeBuffer.attributes.position.count * nextCorner;
      const d = nextPoint + tunnelShapeBuffer.attributes.position.count * nextCorner;

      index.push(a, b, d);
      index.push(b, c, d);
    }
  }

  fullProfileGeometry.setIndex(index);
  fullProfileGeometry.computeVertexNormals();

  return fullProfileGeometry;
}
