import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { ReactNode } from 'react';

const PRIMARY = '#4CB8C4';
const SECONDARY = '#924439';

const BACKGROUND = '#23272D';
const BACKGROUND_OFFSET = '#2D3748';

const WHITE = '#fff';
const WHITE_OFFSET = '#ddd';

export const geomobyBase = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: PRIMARY },
    secondary: { main: SECONDARY },
    background: { default: BACKGROUND, paper: BACKGROUND_OFFSET },
    text: { primary: WHITE, secondary: WHITE_OFFSET },
  },
  typography: {
    fontFamily: `"Open Sans", "Montserrat", "Arial", sans-serif`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
});

export const undergroundTheme = createTheme({
  palette: { mode: 'dark', primary: { main: '#9cf' } },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          pre: {
            margin: 0,
          },
          '::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
          },
          '::-webkit-scrollbar-track': {
            background: '#555',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#888',
          },
          '::-webkit-scrollbar-corner': {
            background: '#888',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {},
      },
    },
  },
});

export const geomobyBaseTheme = createTheme({
  ...geomobyBase,
  components: {
    MuiPaper: {
      styleOverrides: {
        root: { backgroundImage: 'none' },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: { backgroundColor: geomobyBase.palette.primary.dark },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          height: '100%',
          ['MuiTabs-flexContainer']: {
            height: '100%',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        pre: {
          margin: 0,
        },
        '::-webkit-scrollbar': {
          width: '20px',
          height: '20px',
        },
        '::-webkit-scrollbar-track': {
          background: '#101214',
        },
        '::-webkit-scrollbar-thumb': {
          background: '#2a2d31',
        },
        '::-webkit-scrollbar-corner': {
          background: '#101214',
        },
      },
    },
  },
});

export const Themed = ({ children, mode }: { children: ReactNode, mode: 'base' | 'underground' }) => (
  <ThemeProvider theme={mode === 'base' ? geomobyBaseTheme : undergroundTheme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);
