import { CatmullRomCurve3, Vector3 } from 'three';
import { NavMesh as NavMeshProps } from '../MapData/useMapData';

type NavLink = NavMeshProps['navMesh']['links'][0];

const isSameVec = (a: Vector3, b: Vector3) => a.x === b.x && a.y === b.y && a.z === b.z;

const adjoiningIndexesOf = (a: Vector3[], b: Vector3[]): [0 | -1, 0 | -1] | never[] => {
  if (!a || !b) {
    return [];
  } else if (isSameVec(a[0], b[0])) {
    return [0, 0];
  } else if (isSameVec(a[0], b[b.length - 1])) {
    return [0, -1];
  } else if (isSameVec(a[a.length - 1], b[0])) {
    return [-1, 0];
  } else if (isSameVec(a[a.length - 1], b[b.length - 1])) {
    return [-1, -1];
  }
  return [];
};

const directionFromPositions = (a: Vector3, b: Vector3) => {
  const direction = new Vector3();
  direction.subVectors(a, b);
  return direction;
};

const angleFromPoints = (a: Vector3, b: Vector3, c: Vector3) => {
  const dirAB = directionFromPositions(a, b);
  const dirBC = directionFromPositions(b, c);
  return Math.abs(dirAB.angleTo(dirBC));
};

const joinWithReasonableAngles = (
  a: Vector3,
  b: Vector3,
  c: Vector3,
): [Vector3, Vector3, Vector3] | [Vector3, Vector3, Vector3, Vector3] => {
  const angle = angleFromPoints(a, b, c);
  if (angle < (Math.PI * 2) / 3) {
    return [a, b, c];
  }

  const ab = directionFromPositions(b, a).negate().normalize().multiplyScalar(2).add(b);
  const bc = directionFromPositions(b, c).negate().normalize().multiplyScalar(2).add(b);

  return [a, ab, bc, c];
};

export const linesFromNavmesh = ({ links, nodes }: NavMeshProps['navMesh']): Vector3[][] => {
  const nodesMap = Object.fromEntries(
    nodes.map((node): [number, { pos: Vector3; links: NavLink[] }] => [
      node.id,
      { pos: node.pos, links: links.filter(l => l.a === node.id || l.b === node.id) },
    ]),
  );
  const lines: Vector3[][] = [];
  const seenLinkIds = new Map<number, boolean>();
  Object.entries(nodesMap).forEach(([id, { pos, links }]) => {
    links.forEach(link => {
      if (!seenLinkIds.has(link.id)) {
        seenLinkIds.set(link.id, true);
        const points = [pos];
        const seenNodeIds = [parseInt(id)];
        let nextNodeId: number | undefined = link.a === parseInt(id) ? link.b : link.a;
        let nextNode = nodesMap[nextNodeId];
        let curLinkId = link.id;
        while (nextNodeId) {
          if (points.length < 2) {
            points.push(nextNode.pos);
          } else {
            const joinPoints = joinWithReasonableAngles(
              points[points.length - 2],
              points[points.length - 1],
              nextNode.pos,
            );
            if (!joinPoints.length) break;
            points.splice(-2, 2, ...joinPoints);
          }

          seenNodeIds.push(nextNodeId);
          if (nextNode.links.length < 2) {
            break;
          }
          const linkDir = directionFromPositions(
            points[points.length - 2],
            points[points.length - 1],
          );
          if (nextNode.links.length === 2) {
            const otherLink = nextNode.links.find(l => l.id !== curLinkId);
            nextNodeId = [otherLink?.a, otherLink?.b].find(
              n => n !== undefined && !seenNodeIds.includes(n),
            );
            if (
              nextNodeId !== undefined &&
              otherLink !== undefined &&
              !seenLinkIds.get(otherLink.id)
            ) {
              seenLinkIds.set(otherLink.id, true);
              nextNode = nodesMap[nextNodeId];
              curLinkId = otherLink.id;
            }
          } else {
            const nextLinks = nextNode.links
              .filter(l => l.id !== curLinkId)
              .map(l => ({
                link: l,
                angle: Math.abs(
                  linkDir.angleTo(directionFromPositions(nodesMap[l.a].pos, nodesMap[l.b].pos)),
                ),
              }))
              .map(({ link, angle }) => ({
                link,
                angle: Math.min(angle, Math.PI - angle) % (Math.PI / 2),
              }))
              .filter(({ link, angle }) => angle < Math.PI / 6 && !seenLinkIds.has(link.id))
              .sort((a, b) => a.angle - b.angle);

            if (nextLinks.length < 1) {
              break;
            }
            nextNodeId = [nextLinks[0].link.a, nextLinks[0].link.b].find(
              n => !seenNodeIds.includes(n),
            );
            if (nextNodeId !== undefined && nextNode.links.some(l => !seenLinkIds.get(l.id))) {
              seenLinkIds.set(nextLinks[0].link.id, true);
              nextNode = nodesMap[nextNodeId];
              curLinkId = nextLinks[0].link.id;
            }
          }
        }
        if (points.length > 1) {
          lines.push(points);
        }
      }
    });
  });

  // Ensure there are no duplicated points within each line,
  // which would result in zero-length tunnels
  const deduplicatedLines = lines
    .map(line =>
      line.reduce((acc: Vector3[], cur: Vector3) => {
        const prev = acc.length && acc[acc.length - 1];
        if (!prev) return [cur];

        return isSameVec(prev, cur) ? acc : [...acc, cur];
      }, []),
    )
    .filter(line => line.length > 1);

  // Combine lines with the same endpoints (if the angle is reasonable)
  const seenLineIndex: number[] = [];
  return deduplicatedLines
    .map((line, i) => {
      if (seenLineIndex.includes(i)) {
        return [];
      }
      for (let ii = i + 1; ii < deduplicatedLines.length; ii++) {
        const [indexA, indexB] = adjoiningIndexesOf(line, deduplicatedLines[ii]);
        if (indexA === undefined || indexB === undefined) continue;

        const part1 = indexA === -1 ? line.slice() : line.slice().reverse();
        const part2 =
          indexB === 0
            ? deduplicatedLines[ii].slice(1)
            : deduplicatedLines[ii].slice(0, -1).reverse();

        const joinAngle = angleFromPoints(
          part1[part1.length - 2],
          part1[part1.length - 1],
          part2[0],
        );
        if (joinAngle > (Math.PI * 5) / 6) continue;

        const joinPoints = joinWithReasonableAngles(
          part1[part1.length - 2],
          part1[part1.length - 1],
          part2[0],
        );

        seenLineIndex.push(ii);

        return [...part1.slice(0, -2), ...joinPoints, ...part2.slice(1)];
      }
      return line;
    })
    .filter(x => x.length > 1);
};

export const extrusionFromPoints = (points: Vector3[]) => ({
  steps: points.length * 16,
  curveSegments: 200,
  extrudePath: new CatmullRomCurve3(points, false, 'centripetal', 0.9),
});
