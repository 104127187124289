type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

// "unsafe" because in very uncommon cases, this type will be incorrect.
/* eslint-disable @typescript-eslint/consistent-type-assertions */
export const unsafeEntries = <T extends Record<string, unknown>>(obj: T) =>
  Object.entries(obj) as Entries<T>;

export const definedArrayEntries = <T>(items: (T | undefined)[]): T[] =>
  items.filter(item => item !== undefined) as T[];

export const recordOfStringsFromObject = (o: object): Record<string, string> =>
  Object.fromEntries(Object.entries(o).map(([key, val]) => [key, String(val)]));
