import { ExpandMore } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Paper, Slider, Snackbar, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NAVMESHBUILDER_DEFAULT_LOCATOR_SPACING, NAVMESHBUILDER_MIN_LOCATOR_SPACING_RANGE } from '../../../config';
import { LocatedAsset } from '../../api/LocatedAsset.validator';
import { findLinkAndNodes } from '../../util/findNavmeshPath';
import { releaseVector3, temporaryVector3 } from '../../util/vectorUtils';
import { useMapData } from '../MapData/useMapData';
import { Placement, useAutoPlaceLocators } from './useAutoPlaceLocators';
import { useEditingData } from './useEditingData';

export const AutoPlaceLocatorsDialog = () => {
  const { tool, setTool, dispatchLocatedAssetEdit, applyNavmeshEdits, locatedAssetEdits, multiselectedNavmeshLinkIds } = useEditingData();
  const { data: mapData, cid: clientId, pid: projectId } = useMapData();

  const [open, setOpen] = useState(false);
  const [spacing, setSpacing] = useState(NAVMESHBUILDER_DEFAULT_LOCATOR_SPACING);

  const navmesh = useMemo(() => {
    const nav = applyNavmeshEdits(mapData?.navMesh ?? { clientId, projectId });
    if (!nav) return undefined;

    const selectedLinkIds = Object.entries(multiselectedNavmeshLinkIds).filter(([_, selected]) => !!selected).map(([n]) => parseInt(n));
    if (!selectedLinkIds.length) return nav;

    const links = nav?.links?.filter(link => multiselectedNavmeshLinkIds[link.id])
    const nodes = nav?.nodes?.filter(node => links?.find(link => link.a === node.id || link.b === node.id));

    // Workaround for UR-914
    const nodeRenumbering = Object.fromEntries((nodes ?? []).map((node, i) => [node.id, i]));
    const renumberedNodes = nodes?.map(node => ({ ...node, id: nodeRenumbering[node.id] }))
    const renumberedLinks = links?.map((link, i) => ({
      ...link,
      a: nodeRenumbering[link.a],
      b: nodeRenumbering[link.b],
      id: i
    }))

    return { ...nav, links: renumberedLinks, nodes: renumberedNodes, };
  }, [mapData?.navMesh, clientId, projectId, applyNavmeshEdits, multiselectedNavmeshLinkIds]);

  const gateways = useMemo(() => [
    ...(mapData?.locatedAssets.filter(la => la.type === 'gateway' && !locatedAssetEdits[la.uuid]) ?? []),
    ...(Object.values(locatedAssetEdits).filter((la): la is LocatedAsset => !!la && 'type' in la && la.type === 'gateway'))
  ], [mapData?.locatedAssets, locatedAssetEdits]);

  const locators = useMemo(() => [
    ...(mapData?.locatedAssets.filter(la => la.type === 'locator' && !locatedAssetEdits[la.uuid]) ?? []),
    ...(Object.values(locatedAssetEdits).filter((la): la is LocatedAsset => !!la && 'type' in la && la.type === 'locator'))
  ], [mapData?.locatedAssets, locatedAssetEdits]);

  const gatewayNodeIds: number[] = useMemo(() => {
    if (!navmesh) return [];

    const ids = gateways.map(g => {
      const pos = temporaryVector3(g.position.x, g.position.y, g.position.z);
      const { nodeA, nodeAId, nodeB, nodeBId } = findLinkAndNodes(pos, navmesh);
      const closerId = nodeA.distanceTo(pos) < nodeB.distanceTo(pos) ? nodeAId : nodeBId;
      releaseVector3(pos);
      return closerId;
    });

    return Array.from(new Set(ids));
  }, [navmesh, gateways])

  const existingLocatorPositions: [x: number, y: number, z: number][] = useMemo(() => {
    if (!navmesh) return [];

    return locators.map(({ position: { x, y, z } }) => [x, y, z]);
  }, [navmesh, locators])


  const { execute, data: positions, error, loading } = useAutoPlaceLocators({ clientId, projectId, navmesh, minDistance: spacing[0], maxDistance: spacing[1], gatewayNodeIds, existingLocatorPositions })
  const [settled, setSettled] = useState(false);

  useEffect(() => {
    if (positions || error) {
      setSettled(true);
    }
  }, [positions, error, setSettled]);

  useEffect(() => {
    if (tool === 'AUTO_PLACE') {
      setSettled(false);
      setOpen(true);
    }
  }, [tool, setOpen])

  const handleClose = () => {
    setTool('INFO');
    setSettled(false);
    setOpen(false);
  }

  const dispatchLocatorEdits = useCallback((newPositions: Placement[]) => {
    const now = new Date().getTime();
    const maxIdLength = newPositions[newPositions.length - 1].id.toString().length;
    dispatchLocatedAssetEdit(
      newPositions.map(({ id, pos: [x, y, z] }) => ({
        uuid: `fresh-${now}-${id}`,
        clientId,
        projectId,
        id: '',
        label: `NEW LOCATOR ${id.toString().padStart(maxIdLength, '0')}`,
        type: 'locator',
        position: { x, y, z }
      }))
    )
  }, [clientId, projectId, dispatchLocatedAssetEdit])

  useEffect(() => {
    if (!settled || !positions?.length) return;

    dispatchLocatorEdits(positions);
  }, [settled, positions, dispatchLocatorEdits, setOpen, setSettled, setTool])

  return (<>
    <Dialog
      open={open}
      aria-labelledby="auto-locators-dialog-title"
      aria-describedby="auto-locators-dialog-description"
    >
      <DialogTitle id="auto-locators-dialog-title">
        Position locators
      </DialogTitle>
      <DialogContent><>
        <DialogContentText id="auto-locators-dialog-description">
          Automatically place locators on the map
        </DialogContentText>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="auto-locators-options-content"
            id="auto-locators-options-header"
          >
            <Typography>Options</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            <Paper elevation={2} sx={{ padding: 1, margin: 1, marginTop: -1 }}>
              <Typography>Spacing: {spacing.join('—')} metres</Typography>
              <Slider
                valueLabelDisplay="auto"
                value={spacing}
                min={10}
                max={150}
                onChange={(_event, value, activeThumb) => {
                  if (!Array.isArray(value)) return;

                  setSpacing(([oldMin, oldMax]) => {
                    const newMin = activeThumb === 0 ? Math.min(value[0], oldMax - NAVMESHBUILDER_MIN_LOCATOR_SPACING_RANGE, NAVMESHBUILDER_DEFAULT_LOCATOR_SPACING[0]) : value[0];
                    const newMax = activeThumb === 0 ? value[1] : Math.max(value[1], oldMin + NAVMESHBUILDER_MIN_LOCATOR_SPACING_RANGE);

                    return [newMin, newMax];
                  });
                }}
              />
            </Paper>
          </AccordionDetails>
        </Accordion>
        {!gatewayNodeIds.length && (
          <Typography mt={1} color='error.light'>At least one gateway must be placed on the map.</Typography>
        )}
      </></DialogContent>
      <DialogActions>
        <Button disabled={settled} onClick={handleClose}>Cancel</Button>
        <LoadingButton disabled={!navmesh || !gatewayNodeIds.length || settled} loading={loading} variant="contained" onClick={execute} autoFocus>
          Submit
        </LoadingButton>
      </DialogActions>
      <LinearProgress color={error ? 'error' : undefined} sx={{ opacity: loading ? 1 : 0 }} />
    </Dialog>
    <Snackbar
      open={open && settled}
      autoHideDuration={error ? null : 3000}
      onClose={handleClose}
    >
      {error
        ? <Alert
          severity="error"
          sx={{
            width: '100%',
            border: '1px solid rgb(244, 67, 54)',
            cursor: 'pointer',
          }}
          onClose={handleClose}
        >Error: {Object(error)['message']}</Alert>
        : <Alert
          severity="success"
          sx={{
            width: '100%',
            border: '1px solid rgb(102, 187, 106)',
            cursor: 'pointer',
          }}
          onClose={handleClose}
        >Automatically positioned {positions?.length} locators</Alert>
      }
    </Snackbar>
  </>);
};
