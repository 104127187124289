import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useEditingData } from './useEditingData';

export const RevertAllChangesDialog = () => {
  const { tool, setTool, dispatchLocatedAssetEdit, dispatchPortableAssetEdit, dispatchNavmeshLinkEdit, dispatchNavmeshNodeEdit, setOverwritingNavmesh, dispatchServiceConfigEdit } = useEditingData();

  const open = tool === 'REVERT';

  const handleClose = () => {
    setTool('INFO');
  }

  const handleConfirm = () => {
    dispatchLocatedAssetEdit({ 'clearAll': true });
    dispatchPortableAssetEdit({ 'clearAll': true });
    dispatchNavmeshLinkEdit({ 'clearAll': true });
    dispatchNavmeshNodeEdit({ 'clearAll': true });
    setOverwritingNavmesh(false);
    dispatchServiceConfigEdit({ 'clearAll': true })
    handleClose();
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="revert-alert-dialog-title"
      aria-describedby="revert-alert-dialog-description"
    >
      <DialogTitle id="revert-alert-dialog-title">
        Revert all changes?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="revert-alert-dialog-description">
          All unsaved changes will be lost.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
