import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Divider, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { useLogout } from '../../auth/useLogout';
import { USER_LABEL } from '../../store/user';
import { Secondary, useSidebarInteraction } from './useSidebarInteraction';

export const AccountSidebar = () => {
  const { showSecondary } = useSidebarInteraction();

  const userLabel = useAtomValue(USER_LABEL);
  const logout = useLogout();
  const navigate = useNavigate();

  return (
    <List component='div' disablePadding>
      <ListItem button onClick={() => showSecondary(Secondary.NONE)}>
        <ListItemIcon>
          <ArrowBack />
        </ListItemIcon>
      </ListItem>
      <Divider />
      <Box p={2} component='div'>
        <Typography variant='subtitle1'>{userLabel}</Typography>
      </Box>
      <ListItem button onClick={() => navigate('/my-account')}>
        <Button variant="outlined" fullWidth={true}>My account</Button>
      </ListItem>
      <ListItem button onClick={() => navigate('/project-selection')}>
        <Button variant='outlined' fullWidth={true}>Choose project</Button>
      </ListItem>
      <ListItem button onClick={() => logout()}>
        <Button variant='outlined' fullWidth={true}>Log out</Button>
      </ListItem>
    </List>
  );
};
