import React, { useEffect, useMemo } from 'react';
import { Group, Matrix4, Quaternion, Vector3 } from 'three';
import { AssetCoordinatesRelative } from '../../../../../util/Events/schema';
import { findLinkAndNodes } from '../../../../util/findNavmeshPath';
import {
  horizontalQuaternion,
  quaternionFromPositions,
  releaseVector3,
  temporaryVector3,
} from '../../../../util/vectorUtils';
import { useMapData } from '../../../MapData/useMapData';

export const usePositionRotationFromLocation = (
  location: Omit<AssetCoordinatesRelative, 'ids'> | undefined,
  groupRef: React.RefObject<Group>,
  active: boolean,
) => {
  const mapdata = useMapData();
  const navmesh = mapdata.data?.navMesh;

  const quaternion: Quaternion = useMemo(() => {
    if (location && navmesh && active) {
      const posVec = temporaryVector3(
        location.position.x,
        location.position.y,
        location.position.z,
      );
      const velocVec = temporaryVector3();
      const { link, nodeA, nodeAId, nodeB, nodeBId } = findLinkAndNodes(posVec, navmesh);
      if (link !== -1 && nodeAId !== -1 && nodeBId !== -1) {
        velocVec.set(location.velocity.x, location.velocity.y, location.velocity.z);
        const nodeAClone = temporaryVector3(nodeA.x, nodeA.y, nodeA.z);
        const angleOfLinkToVelocity = nodeAClone.sub(nodeB).angleTo(velocVec);
        const nodeToLookTowards = angleOfLinkToVelocity < Math.PI / 2 ? nodeA : nodeB;
        const qt = quaternionFromPositions(posVec, nodeToLookTowards);
        if (qt) {
          releaseVector3(posVec, velocVec, nodeA, nodeB, nodeAClone);
          return qt;
        }
      }
      releaseVector3(posVec, velocVec);
    }
    // Just start off horizontal for now (so not to be pointing up to the sky).
    return horizontalQuaternion;
  }, [active, location, navmesh]);

  useEffect(() => {
    const ref = groupRef.current;
    if (!ref || !active) return;

    if (location) {
      ref.position.set(location.position.x, location.position.y, location.position.z);
    }
    ref.rotation.setFromQuaternion(quaternion);
  }, [active, groupRef, location, quaternion]);
};
