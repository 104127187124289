import { useCallback, useEffect, useState } from 'react';
import { useResettingState } from './useResettingState';

export const useSecondsAgo = ({ from, getNow }: { from: Date | undefined; getNow: () => Date }) => {
  const getSecondsAgo = useCallback(() => {
    if (from === undefined) return undefined;

    const nowUnix = Math.floor(getNow().getTime() / 1000);
    const fromUnix = Math.floor(from.getTime() / 1000);
    return Math.max(0, nowUnix - fromUnix);
  }, [from, getNow]);

  const [seconds, setSeconds] = useResettingState(getSecondsAgo);

  useEffect(() => {
    const intervalRef = setInterval(() => setSeconds(getSecondsAgo), 1000);
    return () => {
      clearInterval(intervalRef);
    };
  }, [setSeconds, getSecondsAgo]);

  return from === undefined ? undefined : seconds;
};
