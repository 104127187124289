import axios from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useRef } from 'react';
import { AUTH_NULLABLE } from '../store/auth';
import { AUTHN_URL } from '../store/url';
import { useLogout } from './useLogout';

type RefreshPromise = Promise<{ data: { accessJwt: string; refreshJwt: string } }>;

export const useRefresh = () => {
  const setAuth = useSetAtom(AUTH_NULLABLE);
  const httpBaseUrl = useAtomValue(AUTHN_URL);
  const logout = useLogout();
  const refreshPromiseRef = useRef<RefreshPromise>();

  const axiosRef = useRef(axios.create());

  const accept = useCallback(
    async (refreshToken: string) => {
      try {
        if (refreshPromiseRef.current !== undefined) {
          return (await refreshPromiseRef.current).data;
        }
        refreshPromiseRef.current = axiosRef.current.post(`${httpBaseUrl}/open/jwt/refresh`, {
          refreshToken,
        });

        const {
          data: { accessJwt, refreshJwt },
        } = await refreshPromiseRef.current;
        setAuth({ accessJwt, refreshJwt });
        setTimeout(() => (refreshPromiseRef.current = undefined), 60 * 1000);
        return { accessJwt, refreshJwt };
      } catch (e) {
        logout();
        throw e;
      }
    },
    [httpBaseUrl, setAuth, logout],
  );
  return accept;
};
