import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { METADATA_URL, TRIGGERS_URL } from '../../store/url';
import { useAxiosFetch } from '../../util/useAxiosFetch';
import { AsyncTask } from './AsyncTask';
import { isLocatedAssets, LocatedAsset } from './LocatedAsset.validator';
import { Microfence, isMicrofences } from './Microfence.validator';
import { MicrofencePair, isMicrofencePairs } from './MicrofencePair.validator';
import { Navmesh, isNavmesh } from './Navmesh.validator';
import { isPortableAssets, PortableAsset } from './PortableAsset.validator';
import { isServiceConfigs, ServiceConfig } from './ServiceConfig.validator';
import { isTagboardTags, TagboardTag } from './Tagboard.validator';

export type Project = Navmesh & {
  locatedAssets: LocatedAsset[];
  portableAssets: PortableAsset[];
  microfences: Microfence[];
  microfencePairs: MicrofencePair[];
  serviceConfigs: ServiceConfig[];
  tagboard?: TagboardTag[];
};

export const useProject = ({
  cid,
  pid,
  refresh,
}: {
  cid: string;
  pid: string;
  refresh: number;
}): AsyncTask<Project> => {
  const [project, setProject] = useState<undefined | Project>(undefined);

  const metadataUrl = useAtomValue(METADATA_URL);
  const triggersUrl = useAtomValue(TRIGGERS_URL);

  const [error, setError] = useState<
    | undefined
    | {
        message: string;
        context?: unknown;
      }
  >(undefined);

  const {
    loading: navLoading,
    data: navData,
    response: navResponse,
    error: navFetchError,
  } = useAxiosFetch(`${metadataUrl}/${cid}/${pid}/navmesh?refresh=${refresh}`);

  const {
    loading: laLoading,
    data: laData,
    response: laResponse,
    error: laFetchError,
  } = useAxiosFetch(`${metadataUrl}/${cid}/${pid}/locatedasset?refresh=${refresh}`);

  const {
    loading: paLoading,
    data: paData,
    response: paResponse,
    error: paFetchError,
  } = useAxiosFetch(`${metadataUrl}/${cid}/${pid}/portableasset?refresh=${refresh}`);

  const {
    loading: mfLoading,
    data: mfData,
    response: mfResponse,
    error: mfFetchError,
  } = useAxiosFetch(`${triggersUrl}/${cid}/${pid}/microfences?refresh=${refresh}`);

  const {
    loading: mpLoading,
    data: mpData,
    response: mpResponse,
    error: mpFetchError,
  } = useAxiosFetch(`${triggersUrl}/${cid}/${pid}/microfence-pair?refresh=${refresh}`);

  const {
    loading: tbLoading,
    data: tbData,
    response: tbResponse,
    error: tbFetchError,
  } = useAxiosFetch(`${triggersUrl}/${cid}/${pid}/tagboard?refresh=${refresh}`, {
    perPage: 500,
    dataKey: 'tags',
    countKey: 'count',
  });

  const {
    loading: scLoading,
    data: scData,
    response: scResponse,
    error: scFetchError,
  } = useAxiosFetch(`${metadataUrl}/${cid}/${pid}/serviceconfig?refresh=${refresh}`);

  useEffect(() => {
    const loading =
      navLoading || laLoading || paLoading || mfLoading || mpLoading || tbLoading || scLoading;
    if (
      !loading &&
      (navFetchError ||
        laFetchError ||
        paFetchError ||
        mfFetchError ||
        mpFetchError ||
        tbFetchError ||
        scFetchError)
    ) {
      setError({
        message: 'Failed to fetch project',
        context: navFetchError
          ? navResponse
          : laFetchError
          ? laResponse
          : paFetchError
          ? paResponse
          : mfFetchError
          ? mfResponse
          : mpFetchError
          ? mpResponse
          : tbFetchError
          ? tbResponse
          : scResponse,
      });
    }
  }, [
    navLoading,
    laLoading,
    paLoading,
    mfLoading,
    mpLoading,
    tbLoading,
    scLoading,
    navFetchError,
    laFetchError,
    paFetchError,
    mfFetchError,
    mpFetchError,
    tbFetchError,
    scFetchError,
    navResponse,
    laResponse,
    paResponse,
    mfResponse,
    mpResponse,
    tbResponse,
    scResponse,
  ]);

  useEffect(() => {
    const navmeshOkOrMissing = (navResponse.ok && navData) || navResponse.status === 404;

    if (
      navmeshOkOrMissing &&
      laResponse.ok &&
      laData &&
      paResponse.ok &&
      paData &&
      mfResponse.ok &&
      mfData &&
      mpResponse.ok &&
      mpData &&
      tbResponse.ok &&
      tbData
    ) {
      const navErrors: Parameters<typeof isNavmesh>[1] = [];
      const laErrors: Parameters<typeof isLocatedAssets>[1] = [];
      const paErrors: Parameters<typeof isPortableAssets>[1] = [];
      const mfErrors: Parameters<typeof isMicrofences>[1] = [];
      const mpErrors: Parameters<typeof isMicrofencePairs>[1] = [];
      const tbErrors: Parameters<typeof isTagboardTags>[1] = [];
      const scErrors: Parameters<typeof isServiceConfigs>[1] = [];

      if (navResponse.ok && !isNavmesh(navData, navErrors)) {
        setError({
          message: 'Failed while validating project navmesh request',
          context: navErrors,
        });
        return;
      } else if (!isLocatedAssets(laData, laErrors)) {
        setError({
          message: 'Failed while validating project located assets request',
          context: laErrors,
        });
        return;
      } else if (!isPortableAssets(paData, paErrors)) {
        setError({
          message: 'Failed while validating project portable assets request',
          context: paErrors,
        });
        return;
      } else if (!isMicrofences(mfData, mfErrors)) {
        setError({
          message: 'Failed while validating project microfences request',
          context: mfErrors,
        });
        return;
      } else if (!isMicrofencePairs(mpData, mpErrors)) {
        setError({
          message: 'Failed while validating project microfence pairs request',
          context: mpErrors,
        });
        return;
      } else if (!isTagboardTags(tbData, tbErrors)) {
        setError({
          message: 'Failed while validating tagboard request',
          context: tbErrors,
        });
        return;
      } else if (!isServiceConfigs(scData, scErrors)) {
        setError({
          message: 'Failed while validating service configs request',
          context: scErrors,
        });
        return;
      } else {
        setError(undefined);
      }

      setProject({
        ...(navData ? Object(navData) : {}),
        locatedAssets: laData,
        portableAssets: paData,
        tagboard: tbData,
        microfences: mfData,
        microfencePairs: mpData,
        clientId: cid,
        projectId: pid,
        navmeshMissing: navResponse.status === 404,
        serviceConfigs: scData,
      });
    }
  }, [
    setProject,
    setError,
    navData,
    navResponse,
    laData,
    laResponse,
    paData,
    paResponse,
    mfData,
    mfResponse,
    mpData,
    mpResponse,
    tbData,
    tbResponse,
    scData,
    scResponse,
    cid,
    pid,
  ]);

  return {
    loading: navLoading || laLoading || paLoading || mpLoading || tbLoading || scLoading,
    data: project,
    error,
  };
};
