import { useSetAtom } from 'jotai';
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CID, PID } from '../../store/user';

export enum Secondary {
  NONE,
  REPORTS,
  PROJECTS,
  ACCOUNT,
}

export enum Report {
  Tagboards,
  Locators,
}

export type Interactions = {
  showSecondary: (secondary: Secondary) => void;
  secondary: Secondary;
  report: Report;
  setReport: (report: Report) => void;
  navigateTo: (cid: string, pid: string) => void;
};

export const SidebarInteractionContext = createContext<Interactions>({
  showSecondary: () => null,
  secondary: Secondary.NONE,
  report: Report.Tagboards,
  setReport: () => null,
  navigateTo: () => null,
});

export const SidebarInteractionProvider = ({ children }: { children: ReactNode }) => {
  const [secondary, showSecondary] = useState<Secondary>(Secondary.NONE);
  const [report, setReport] = useState<Report>(Report.Tagboards)
  const setCid = useSetAtom(CID);
  const setPid = useSetAtom(PID);
  const navigate = useNavigate();

  const navigateTo = useCallback((cid: string, pid:string) => {
    setCid(cid);
    setPid(pid);
    navigate('/map/');
  }, [setCid, setPid, navigate])

  return (
    <SidebarInteractionContext.Provider
      value={{
        showSecondary,
        secondary,
        report,
        setReport,
        navigateTo,
      }}
    >
      {children}
    </SidebarInteractionContext.Provider>
  );
};

export const useSidebarInteraction = () => useContext(SidebarInteractionContext);
