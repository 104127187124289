import axios from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { AUTH_NULLABLE, TIMED_OUT } from '../store/auth';
import { AUTHN_URL } from '../store/url';
import { useAsyncTask } from '../util/AsyncTask';

export const useResetPassword = ({
  password,
  jwts,
}: {
  password: string;
  jwts: { accessJwt: string; refreshJwt: string } | undefined;
}) => {
  const setAuth = useSetAtom(AUTH_NULLABLE);
  const setTimedOut = useSetAtom(TIMED_OUT);
  const httpBaseUrl = useAtomValue(AUTHN_URL);
  const { accessJwt, refreshJwt } = jwts ?? {};

  const reset = useCallback(async () => {
    if (!accessJwt || !refreshJwt) throw new Error('Missing expected access/refresh JWTs');

    await axios.patch(
      `${httpBaseUrl}/open/user/me/basic-auth`,
      { password },
      {
        headers: {
          Authorization: `Bearer ${accessJwt}`,
        },
      },
    );

    setAuth({ accessJwt, refreshJwt });
    setTimedOut(false);
  }, [httpBaseUrl, setAuth, setTimedOut, password, accessJwt, refreshJwt]);

  return useAsyncTask(reset);
};
