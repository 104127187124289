import { Layers } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { compareDesc } from 'date-fns';
import { useCallback } from 'react';
import { AssetState } from '../../../../util/Events/Messages';
import { stringifyIdRecord } from '../../../../util/stringUtils';
import { AssetGridItem } from '../../../util/portableAsset';
import { describeCount, isActive, isLightVehicleBeacon, isPersonnelBeacon, isPersonnelDevice, isTruckBeacon } from '../../../util/portableAssetUtils';
import { useInterpolatedMapData } from '../../MapData/useInterpolatedData';
import { portbaleAssetsFromLiveData, useMapData } from '../../MapData/useMapData';
import { useMapInteraction } from '../../MapInteraction/useMapInteraction';
import { CommonItem, UpdateInfo } from '../CommonInfo';
import { Report, ReportGridHeading } from './Report';

export const TagboardReportInner = ({ height, plain, level }: { height?: number, plain?: boolean, level?:number }) => {
  const { highlight, level: selectedLevel } = useMapInteraction();
  const { assetsLevels } = useInterpolatedMapData();
  const { data: mapData, getNow } = useMapData();
  const now = getNow();

  const levelLabel = level !== undefined ? (mapData?.navMesh?.levels?.find(l => l.id === level)?.name ?? level) : 'All levels';

  const assets = (mapData?.liveData ? portbaleAssetsFromLiveData(mapData?.liveData) : [])
    .filter(asset => {
      if (level === undefined) return true;

      return assetsLevels[stringifyIdRecord(asset.id)] === level;
    });
  const activeAssets = assets.filter(state => isActive(state, now)).sort((a, b) => a.label.localeCompare(b.label));
  const lastUpdated = activeAssets.flatMap(state => state.lastUpdate ?? []).sort(compareDesc).at(0) ?? mapData?.liveData?.state?.lastUpdate;

  const activePersonnelBeacons = activeAssets.filter(isPersonnelBeacon);
  const activeDevices = activeAssets.filter(isPersonnelDevice);
  const activeTrucks = activeAssets.filter(isTruckBeacon);
  const activeLightVehicles = activeAssets.filter(isLightVehicleBeacon);

  const personnelBeaconCount = activePersonnelBeacons.length;
  const personnelDeviceCount = activeDevices.length;
  const personnelCount = personnelBeaconCount + personnelDeviceCount;
  const personnelBreakdown = personnelCount === 0
    ? ''
    : `(${describeCount(personnelBeaconCount, 'beacon')}, ${describeCount(personnelDeviceCount, 'device')})`;

  const truckCount = activeTrucks.length;
  const lightVehicleCount = activeLightVehicles.length;
  const vehiclesCount = truckCount + lightVehicleCount;
  const vehiclesBreakdown = vehiclesCount === 0
    ? ''
    : `(${describeCount(lightVehicleCount, 'light vehicle')}, ${describeCount(truckCount, 'truck')})`;


  const toAssetGridItem = useCallback(
    (asset: AssetState) => AssetGridItem(asset, highlight, !!plain),
    [highlight, plain]
  );

  return (
    <>
      {selectedLevel !== undefined && (
        <CommonItem title="Level:" icon={<Layers />}>
          <Typography>{levelLabel}</Typography>
        </CommonItem>
      )}
      <Report style={{ height }}>
        <ReportGridHeading title="Personnel:">
          <Typography>{personnelCount} active {personnelBreakdown}</Typography>
        </ReportGridHeading>
        {[...activePersonnelBeacons, ...activeDevices].map(toAssetGridItem)}
        <ReportGridHeading title="Vehicles:">
          <Typography>{vehiclesCount} active {vehiclesBreakdown}</Typography>
        </ReportGridHeading>
        {[...activeLightVehicles, ...activeTrucks].map(toAssetGridItem)}
      </Report>
    </>
  );
};

export const ActiveTagsReport = ({ height, plain, groupByLevel, level: specifiedLevel }: { height?: number, plain?: boolean, groupByLevel?: boolean, level?:number|undefined }) => {
  const { level: selectedLevel } = useMapInteraction();
  const { data: mapData, getNow } = useMapData();
  const now = getNow();

  const mapLevels =  mapData?.navMesh?.levels?.map(l => l.id);

  const level = specifiedLevel ?? selectedLevel;

  const assets = (mapData?.liveData ? portbaleAssetsFromLiveData(mapData?.liveData) : [])
  const activeAssets = assets.filter(state => isActive(state, now)).sort((a, b) => a.label.localeCompare(b.label));
  const lastUpdated = activeAssets.flatMap(state => state.lastUpdate ?? []).sort(compareDesc).at(0) ?? mapData?.liveData?.state?.lastUpdate;

  return (
    <>
      <UpdateInfo lastUpdate={lastUpdated} />
      {level !== undefined && <TagboardReportInner height={height} plain={plain} level={level} />}
      {level === undefined && groupByLevel && mapLevels && mapLevels.map(l => (
        <Paper elevation={3} key={l}>
          <Stack p={2}>
            <TagboardReportInner height={height} plain={plain} level={l} />
          </Stack>
        </Paper>
      ))}
      {level === undefined && (!groupByLevel || !mapLevels) && <TagboardReportInner height={height} plain={plain} />}
    </>
  );
};
