import { useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import { Group, Object3D, } from 'three';
import { ModelName } from '../../../api/PortableAsset.helpers';
import { useModelType } from '../Default/Beacon/useModelType';
import { Camera } from './Camera';

export const ModelViewer = ({ name, type }: { name?: ModelName, type: string }) => {
  const [model, icon] = useModelType(name, type)
  const groupRef = useRef<Group>(null);
  const modelRef = useRef<Object3D>(null);

  useFrame(() => {
    if (groupRef.current) {
      groupRef.current.rotation.x = Math.PI / 2
      groupRef.current.rotation.y += 0.01;
      groupRef.current.rotation.z = 0;
    }
  });

  return (
    <>
      <Camera />
      <ambientLight intensity={0.3} />
      <directionalLight position={[1, 1.5, 2]} />
      <group ref={groupRef}>
        <primitive ref={modelRef} object={model} />
      </group>
    </>
  );
};
