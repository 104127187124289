import {
  Battery20,
  Battery30,
  Battery50,
  Battery60,
  Battery80,
  BatteryAlert,
  BatteryFull,
  BatteryUnknown,
} from '@mui/icons-material';
import { Box } from '@mui/material';

export const BatteryIconVerbose = ({ percentage }: { percentage: number | undefined }) =>
  percentage === undefined || percentage < 0 ? (
    <Box component="div" color="warning.light">
      <BatteryUnknown />
    </Box>
  ) : (
    <BatteryIcon percentage={percentage} />
  );

export const BatteryIcon = ({ percentage }: { percentage: number | undefined }) =>
  percentage === undefined || percentage < 0 ? (
    <></>
  ) : percentage > 90 ? (
    <BatteryFull />
  ) : percentage > 70 ? (
    <Battery80 />
  ) : percentage > 50 ? (
    <Battery60 />
  ) : percentage > 40 ? (
    <Battery50 />
  ) : percentage > 20 ? (
    <Battery30 />
  ) : percentage > 10 ? (
    <Box component="div" color="warning.light">
      <Battery20 />
    </Box>
  ) : (
    <Box component="div" color="error.light">
      <BatteryAlert color="error" />
    </Box>
  );
