import { useMemo } from 'react';
import { SearchSidebar } from '../../../util/SearchSidebar/SearchSidebar';
import { NavmeshLevel } from '../../api/Navmesh.validator';
import { useEditingData } from '../EditTools/useEditingData';
import { useMapData } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

export const EditLevelsSidebar = () => {
  const map = useMapData();
  const { level: selectedLevel, setLevel } = useMapInteraction();
  const { currentEdit, setCurrentEdit, applyNavmeshEdits } = useEditingData();
  const { showSecondary } = useSidebarInteraction();

  const levels = useMemo(() => {
    return (
      applyNavmeshEdits(map.data?.navMesh ?? { clientId: map.cid, projectId: map.pid })?.levels ?? []
    ).sort(({ id: a }, { id: b }) => a - b)
  }, [applyNavmeshEdits, map.data?.navMesh, map.cid, map.pid])

  return (
    <SearchSidebar
      title="Levels"
      onClick={(id: string, label: string) => {
        const idNumber = Number(id);
        setLevel(isNaN(idNumber) ? undefined : idNumber);
        if (id === 'all') {
          if (currentEdit?.type === 'level') { setCurrentEdit(undefined); }
        } else {
          const asset: NavmeshLevel & { uuid: undefined } = {
            id: idNumber,
            name: id.includes('fresh') ? '' : label,
            uuid: undefined,
          }
          setCurrentEdit({ type: 'level', asset });
        }
        showSecondary(Secondary.NONE);
      }}
      hide={() => showSecondary(Secondary.NONE)}
      options={
        [
          { id: 'all', name: levels.length ? "All levels" : "No levels" },
          { id: `fresh-${new Date().getTime()}`, name: '+ Add new level' },
          ...levels
        ]
          .map((level) => ({
            label: level.name,
            id: level.id?.toString(),
            selected: level.id === selectedLevel,
          })) || []
      }
    />
  );
};
