import { MonitorHeart, PersonPinCircle } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAtomValue } from 'jotai';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Viewer from 'react-viewer';
import { UG_LEGACY_API } from '../../../store/url';
import { stringifyIdRecord } from '../../../util/stringUtils';
import { beaconLabelHack } from '../../util/beaconIdHack';
import { useMapData } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { CommonItem, UpdateInfo } from './CommonInfo';
import { MessageDevice } from './MessageDevice';
import { TitleAndIds } from './TitleAndIds';
import { viewerToolbarFactory } from './viewerToolbarFactory';

const useStyles = makeStyles({
  viewer: { backgroundColor: '#f00' },
});

export const HeartrateInfo = ({ bpm }: { bpm: number }) => (
  <CommonItem
    icon={<MonitorHeart />}
    title={'Heart rate:'}
  >
    <Typography>
      {bpm.toFixed(0)} bpm
    </Typography>
  </CommonItem>
);

export const DeviceInfo = ({ id }: { id: string }) => {
  const { highlight } = useMapInteraction();
  const { data: mapData } = useMapData();
  const [fullscreenImage, setFullscreenImage] = useState(false);
  // const [showImage, setShowImage] = useState(true); // Feature not implemented in backend
  // const ugLegacyApi = useAtomValue(UG_LEGACY_API)

  // useEffect(() => {
  //   setShowImage(true);
  // }, [id]);

  const device = mapData?.liveData?.state.assets.get(id);

  // const imageSource = device?.label && `${ugLegacyApi}/image_observation/latest_img/${mapData?.projectId}/${device.label}`;

  const associatedBeacon = mapData?.liveData?.state.assets.get(id.replace('deviceId', 'beaconId'));

  // const viewerImages = useMemo(
  //   () => imageSource ? [{ src: imageSource, alt: 'Last device image', downloadUrl: imageSource }] : [],
  //   [imageSource],
  // );
  const closeFullscreen = useCallback(() => setFullscreenImage(false), [setFullscreenImage]);

  const styles = useStyles();

  return (
    <>
      <Grid item spacing={2} container direction="column">
        <Grid item width="100%">
          <Typography variant="h6">Send message</Typography>
          <MessageDevice id={device?.label} />
        </Grid>
        {/* {imageSource && showImage && (
          <Grid item>
            <Typography variant="h6">Latest image</Typography>
            <img
              style={{ cursor: 'pointer' }}
              onClick={() => setFullscreenImage(true)}
              src={imageSource}
              onError={() => {
                setShowImage(false);
              }}
              width="100%"
            />
          </Grid>
        )} */}
        {associatedBeacon && (
          <Grid item>
            <TitleAndIds
              title="Linked beacon:"
              icon={<PersonPinCircle />}
              ids={[
                {
                  id: beaconLabelHack(associatedBeacon.label),
                  onClick: () => highlight({ id: stringifyIdRecord(associatedBeacon.id), category: 'beacon', label: associatedBeacon.label }),
                },
              ]}
            />
          </Grid>
        )}
        {device?.lastHeartrate && (
          <>
            <HeartrateInfo bpm={device.lastHeartrate.bpm} />
            <UpdateInfo lastUpdate={new Date(device.lastHeartrate.iso8601)} />
          </>
        )}
      </Grid>
      {/* {imageSource && (
        <Viewer
          downloadable
          className={styles.viewer}
          showTotal={false}
          noNavbar={true}
          noImgDetails={true}
          customToolbar={viewerToolbarFactory}
          visible={fullscreenImage}
          images={viewerImages}
          onClose={closeFullscreen}
        />
      )} */}
    </>
  );
};
