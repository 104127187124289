import { Html } from '@react-three/drei';
import { HtmlProps } from '@react-three/drei/web/Html';
import { useThree } from '@react-three/fiber';
import { useCallback, useRef, useState } from 'react';
import { Camera, Object3D, Vector3 } from 'three';

export const WorldOverlay = ({ unselectable, children, position, name, ...args }: HtmlProps) => {


  const htmlRef = useRef<HTMLDivElement>(null);
  const o3dRef = useRef<Object3D>(null);
  const { camera } = useThree();
  
  const [v1] = useState(new Vector3());
  const [v2] = useState(new Vector3());
  const [v3] = useState(new Vector3());

  const isObjectBehindCamera = useCallback((el: Object3D, camera: Camera) => {
    const objectPos = v1.setFromMatrixPosition(el.matrixWorld);
    const cameraPos = v2.setFromMatrixPosition(camera.matrixWorld);
    const deltaCamObj = objectPos.sub(cameraPos);
    const camDir = camera.getWorldDirection(v3);
    return deltaCamObj.angleTo(camDir) > Math.PI / 2;
  }, [v1, v2, v3]);

  if (o3dRef.current && htmlRef.current) {
    // The following fixes UR-679 (locator pins sometimes in wrong position).
    // isObjectBehindCamera function is basically the same as in drei's Html.tsx
    // https://github.com/pmndrs/drei/blob/d3282cdd02d170ef603a5e096505d83dc93cd57a/src/web/Html.tsx#L34C21-L34C21
    // I don't know why, but doing the same check here that drei's HTML.tsx does internally somehow fixes the bug.
    htmlRef.current.style.display = isObjectBehindCamera(o3dRef.current, camera) ? 'none' : 'initial'
  }

  return (
    <object3D ref={o3dRef} position={position}>
      <Html ref={htmlRef}
        {...args}
        frustumCulled
        style={{ pointerEvents: unselectable === 'on' ? 'none' : undefined }}
        zIndexRange={[0, 0]}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore // "belnding" is a valid value per https://github.com/pmndrs/drei
        occlude="blending"
      >
        {children}
      </Html>
    </object3D>
  );
};
