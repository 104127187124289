import { AccountCircleRounded, ArrowBack, ListAlt, Map } from '@mui/icons-material';
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Secondary, useSidebarInteraction } from './useSidebarInteraction';

export const GlobalSidebar = () => {
  const { showSecondary } = useSidebarInteraction();
  const naviagte = useNavigate();

  return (
    <List component='nav' aria-labelledby='nested-list-subheader'>
      <ListItemButton onClick={() => naviagte(-1)}>
        <ListItemIcon>
          <ArrowBack />
        </ListItemIcon>
        <ListItemText primary='Back' />
      </ListItemButton>
      <Divider/>
      <ListItemButton onClick={() => showSecondary(Secondary.REPORTS)}>
        <ListItemIcon>
          <ListAlt />
        </ListItemIcon>
        <ListItemText primary='Views' />
      </ListItemButton>
      <Divider/>
      <ListItemButton onClick={() => showSecondary(Secondary.ACCOUNT)}>
        <ListItemIcon>
          <AccountCircleRounded />
        </ListItemIcon>
        <ListItemText primary='Account' />
      </ListItemButton>
      <ListItemButton onClick={() => showSecondary(Secondary.PROJECTS)}>
        <ListItemIcon>
          <Map />
        </ListItemIcon>
        <ListItemText primary='Projects' />
      </ListItemButton>
    </List>
  );
};
