import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemIcon,
  Radio,
  RadioGroup,
  Switch,
} from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { unsafeEntries } from '../../../util/typeUtils';
import { DEFAULT_SETTINGS, useMapInteraction } from '../MapInteraction/useMapInteraction';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

export const SettingsSidebar = () => {
  const { settings, setSettings } = useMapInteraction();
  const { showSecondary } = useSidebarInteraction();
  const location = useLocation()
  const navigate = useNavigate()

  const onMapTypeChange = (_: React.ChangeEvent, value: string) => navigate(value);

  return (
    <List component="div" disablePadding>
      <ListItem button onClick={() => showSecondary(Secondary.NONE)}>
        <ListItemIcon>
          <ArrowBack />
        </ListItemIcon>
      </ListItem>
      <Divider />
      <Box component="div" p={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend" id="maptype-group">Map type</FormLabel>
          <RadioGroup
            aria-labelledby="maptype-group"
            name="maptype-group"
            value={location.pathname.replace(/\/$/, '')}
            onChange={onMapTypeChange}
          >
            <FormControlLabel value="/map" control={<Radio />} label="Live" />
            <FormControlLabel value="/map/replay" control={<Radio />} label="Replay" />
            <FormControlLabel value="/map/edit" control={<Radio />} label="Edit" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box component="div" p={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Visual settings</FormLabel>
          {unsafeEntries(DEFAULT_SETTINGS)
            .filter(([feature]) => feature !== 'navigationMeshLine')
            .map(([feature]) => ({
              label: feature.replace(/([A-Z])/g, ' $1').toLowerCase(),
              toggle: () => setSettings(feature, !settings[feature]),
              value: settings[feature],
            }))
            .map(({ label, toggle, value }) => (
              <FormControlLabel
                key={label}
                control={<Switch checked={value} onChange={toggle} />}
                label={label.replace(/./, match => match.toUpperCase())}
              />
            ))}
        </FormControl>
      </Box>
    </List>
  );
};
