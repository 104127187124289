import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material"

export const Dropdown = ({ label, selected, setSelected, items, required, noNone, disabled, helperText }: {
  label: string,
  selected?: string,
  setSelected: (val?: string) => void,
  items: { value: string, label: string }[],
  required?: boolean,
  noNone?: boolean,
  disabled?: boolean,
  helperText?: string,
}) => (
  <FormControl sx={{ width: '100%' }}>
    <InputLabel required={required} id={`dropdown-${label}-inputlabel`}>{label}</InputLabel>
    <Select
      labelId={`dropdown-${label}-inputlabel`}
      id={`dropdown-${label}-select`}
      value={selected ?? ''}
      label={label}
      onChange={e => setSelected(e.target.value)}
      required={required}
      disabled={disabled}
    >
      {!noNone && (<MenuItem value="">
        <em>None</em>
      </MenuItem>)}
      {items.map(item => (
        <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
      ))}
    </Select>
    {helperText && (<FormHelperText>{helperText}</FormHelperText>)}
  </FormControl>
)


export const DropdownNumberValues = ({ label, selected, setSelected: setSelectedNumber, items, required, noNone, disabled, helperText }: {
  label: string,
  selected?: number,
  setSelected: (val?: number) => void,
  items: { value: number, label: string }[],
  required?: boolean
  noNone?: boolean,
  disabled?: boolean,
  helperText?: string,
}) => Dropdown({
  label,
  selected: selected !== undefined ? String(selected) : "",
  setSelected: (val?: string) => {
    const num = Number(val || undefined);
    if (Number.isFinite(num)) {
      setSelectedNumber(num)
    } else {
      setSelectedNumber(undefined)
    }
  },
  items: items.map(({ value, label }) => ({ value: String(value), label })),
  required,
  noNone,
  disabled,
});