import { PrimitiveAtom, useAtomValue, useSetAtom } from 'jotai';
import { ReactNode, useCallback, useState } from 'react';
import { LIVE_STREAM_MESSAGES } from '../../store/liveEvents';
import { STREAMER_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { useAuthedStream, useStreamMessages } from '../stream';
import { LiveStateUpdate } from './Messages';

// Use at the top of the "logged in" app tree; to manage live events lifecycle
export const LiveEventsProvider = ({ children }: { children: ReactNode }) => {
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const streamerUrl = useAtomValue(STREAMER_URL);

  const [start] = useState(Math.floor(new Date().getTime() / 1000));

  const stream = useAuthedStream(
    `${streamerUrl}/${cid}/${pid}/live`,
    `${streamerUrl}/${cid}/${pid}/public/live/${start}`,
  );

  const setMessages = useSetAtom(LIVE_STREAM_MESSAGES);
  const consumeEvent = useCallback(
    (message: LiveStateUpdate) => setMessages(messages => [...messages.slice(-4000), message]),
    [setMessages],
  );

  useStreamMessages<LiveStateUpdate>(stream, consumeEvent);

  return <>{children}</>;
};

export const ProjectLiveEventsProvider = ({ cid, pid, liveMessagesAtom, children }: { cid: string, pid: string, liveMessagesAtom: PrimitiveAtom<LiveStateUpdate[]>, children: ReactNode }) => {
  const streamerUrl = useAtomValue(STREAMER_URL);

  const [start] = useState(Math.floor(new Date().getTime() / 1000));

  const stream = useAuthedStream(
    `${streamerUrl}/${cid}/${pid}/live`,
    `${streamerUrl}/${cid}/${pid}/public/live/${start}`,
  );

  const setMessages = useSetAtom(liveMessagesAtom);
  const consumeEvent = useCallback(
    (message: LiveStateUpdate) => setMessages((messages: LiveStateUpdate[]) => [...messages, message]),
    [setMessages],
  );

  useStreamMessages<LiveStateUpdate>(stream, consumeEvent);

  return <>{children}</>;
};
