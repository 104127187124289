import { useFrame } from '@react-three/fiber';
import { useEffect, useMemo, useRef } from 'react';
import { BufferGeometry, Group, Material, Mesh, Vector3 } from 'three';
import { LocatedAsset } from '../../../../api/LocatedAsset.validator';
import { useMapInteraction } from '../../../MapInteraction/useMapInteraction';
import { MapText } from '../../../MapText/MapText';
import { Pin } from '../../../Pin/Pin';
import { WorldOverlay } from '../../../WorldOverlay/WorldOverlay';

const ASSET_FLOAT_HEIGHT = 1;

export const Locator = (asset: LocatedAsset & { edited?: boolean; onClick?: (asset: LocatedAsset) => void, microfence?: boolean }) => {
  const {
    settings: { locatorModels, locatorLabels, locatorIcons },
  } = useMapInteraction();
  const groupRef = useRef<Group>(null);
  const zDeltaRef = useRef<Vector3>(new Vector3())

  const locator = useRef<Mesh<BufferGeometry, Material | Material[]>>(null);

  const assetPinIcon = (asset.type === 'gateway' || asset.type === 'locator' || asset.type === 'mqttgateway')
    ? asset.type
    : 'located-wireless';
  const modelColorByType: Record<string, string | undefined> = {
    'gateway': '#bbb',
    'locator': 'lightgreen',
    'mqttgateway': '#fff',
    'DEFAULT': "#96b8df"
  };

  const position: Vector3 = useMemo(
    () => new Vector3(asset.position.x, asset.position.y, asset.position.z + ASSET_FLOAT_HEIGHT),
    [asset.position]
  )
  const label = useMemo(
    () => (asset.label ?? asset.id),
    [asset.label, asset.id]
  );

  useFrame(({ clock }) => {
    if (locator.current) {
      const time = clock.getElapsedTime();
      zDeltaRef.current.set(0, 0, Math.sin(time) / 20);
      locator.current.rotation.z = Math.sin(time / 2) / 3;
      locator.current.position.z = position.clone().add(zDeltaRef.current).z;
    }
  });

  return (
    <group ref={groupRef} frustumCulled={true}>
      {locatorModels && (
        <>
          <mesh ref={locator} scale={0.5} position={position} castShadow receiveShadow>
            <boxGeometry args={[0.5, 1, 0.75]} />
            <meshStandardMaterial attach="material" color={modelColorByType[asset.type] ?? modelColorByType.DEFAULT} wireframe={asset.surface ? true : false} />
          </mesh>
        </>
      )}
      {locatorIcons && (
        <Pin
          position={position}
          icon={assetPinIcon}
          selected={false /*todo*/}
          onClick={() => asset.onClick?.(asset)}
          edit={(asset.edited && asset.uuid?.startsWith('fresh'))
            ? 'new'
            : asset.edited ? 'update' : undefined}
          microfence={asset.microfence}
          isGhost={asset.surface}
        />
      )}
      {locatorLabels && (
        <WorldOverlay position={position} unselectable={'on'}>
          <MapText opacity={asset.surface ? 0.5 : 1}>
            {asset.edited && <em>{asset.uuid.startsWith('fresh') ? 'Creating' : 'Editing'}</em>}
            {asset.edited && label && <span>{' '}</span>}
            {label}
          </MapText>
        </WorldOverlay>
      )}
    </group>
  );
};
