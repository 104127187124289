import { useMemo, useRef } from 'react';
import { Vector3 } from 'three';
import { AssetState } from '../../../util/Events/Messages';
import { Highlighted, useMapInteraction } from '../MapInteraction/useMapInteraction';
import { MapData, useMapData } from './useMapData';

const positionFromAsset = (asset?: AssetState) => {
  if (asset?.positionVector) {
    return asset.positionVector;
  } else if (asset?.lastLocation) {
    const { x, y, z } = asset.lastLocation.position;
    return new Vector3(x, y, z);
  }
}

export const highlightFromMap = (map?: MapData, highlighted?: Highlighted) => {
  const asset = highlighted?.id ? map?.liveData?.state.assets.get(highlighted?.id) : undefined;
  if (!asset) {
    return undefined;
  }
  return { ...asset, position: positionFromAsset(asset) }
};

export const useHighlighted = () => {
  const { data: map } = useMapData();
  const { highlighted } = useMapInteraction();

  return highlightFromMap(map, highlighted);
};

// Used when you want a reference to a position that doesn't cause state updates
export const useHighlightedRef = () => {
  const highlighted = useHighlighted();
  const highlightedRef = useRef<AssetState & { position?: Vector3 } | undefined>();

  highlightedRef.current = highlighted;

  return highlightedRef;
};

export const useHighlightedPosition = () => {
  const highlighted = useHighlighted();
  const position = positionFromAsset(highlighted);
  return useMemo(
    () => position,
    // eslint-disable-next-line
    [position && position.x, position && position.y, position && position.z],
  );
};
