import { ArrowBack, LocationSearching, MyLocation } from '@mui/icons-material';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Report, Secondary, useSidebarInteraction } from './useSidebarInteraction';

export const ReportsSidebar = () => {
  const { showSecondary, report, setReport } = useSidebarInteraction();

  const reportItems = Object.values(Report).filter((x): x is Report => typeof x !== 'string')
  .map(rpt => (
    <ListItem
    button
    key={rpt}
    onClick={() => {
        setReport(rpt);
        showSecondary(Secondary.NONE);
      }
    }>
    <ListItemIcon>{report === rpt ? <MyLocation /> : <LocationSearching />}</ListItemIcon>
    <ListItemText primary={Report[rpt]} />
  </ListItem>
  ))

  return (
    <List component='div' disablePadding>
      <ListItem button onClick={() => showSecondary(Secondary.NONE)}>
        <ListItemIcon>
          <ArrowBack />
        </ListItemIcon>
      </ListItem>
      {reportItems}
    </List>
  );
};
