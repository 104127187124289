import { useFrame } from '@react-three/fiber';
import React, { useMemo, useRef } from 'react';
import { BufferGeometry, Group, Material, Mesh, Object3D, Vector3 } from 'three';
import { AssetState } from '../../../../../util/Events/Messages';
import { stringifyIdRecord } from '../../../../../util/stringUtils';
import { useSecondsAgo } from '../../../../../util/useSecondsAgo';
import { Locator as LocatorProps, useMapData } from '../../../MapData/useMapData';
import { useMapInteraction } from '../../../MapInteraction/useMapInteraction';
import { MapText } from '../../../MapText/MapText';
import { Pin } from '../../../Pin/Pin';
import { BatteryIcon } from '../../../WorldOverlay/BatteryIcon/BatteryIcon';
import { HeartbeatIcon } from '../../../WorldOverlay/HeartbeatIcon/HeartbeatIcon';
import { WorldOverlay } from '../../../WorldOverlay/WorldOverlay';

const ASSET_FLOAT_HEIGHT = 1;

export const Locator = (state: AssetState) => {
  const {
    highlighted,
    highlight,
    settings: { locatorModels, locatorLabels, locatorIcons },
  } = useMapInteraction();
  const { getNow, data: mapData } = useMapData();
  const groupRef = useRef<Group>(null);
  const zDeltaRef = useRef<Vector3>(new Vector3())

  const highlightCategory = state.type?.includes('gateway') ? 'gateway' : 'locator';

  const locator = useRef<Mesh<BufferGeometry, Material | Material[]>>(null);

  // const isMicrofence =  (state.type === 'locator' || state.type === 'mqttgateway') && !!(
  //   mapData?.microfences.find(m => m.assetId[`${state.type}Id`] === state.id[`${state.type}Id`]) ||
  //   mapData?.microfencePairs.find(m =>
  //     m.downstreamId[`${state.type}Id`] === state.id[`${state.type}Id`] ||
  //     m.upstreamId[`${state.type}Id`] === state.id[`${state.type}Id`]
  //   )
  // )

  const assetPinIcon = (state.type === 'gateway' || state.type === 'locator' || state.type === 'mqttgateway')
    ? state.type
    : 'located-wireless';
  const modelColorByType: Record<string, string | undefined> = {
    'gateway': '#bbb',
    'locator': 'lightgreen',
    'mqttgateway': '#fff',
    'DEFAULT': "#96b8df"
  };

  const position: Vector3 = useMemo(
    () => {
      const v = state.positionVector ?? new Vector3();
      v.setZ(v.z + ASSET_FLOAT_HEIGHT);
      return v;
    },
    [state.positionVector]
  )
  const label = useMemo(
    () => state.label ?? state.id['locatorId'] ?? stringifyIdRecord(state.id),
    [state.label, state.id]
  );

  useFrame(({ clock }) => {
    if (locator.current) {
      const time = clock.getElapsedTime();
      zDeltaRef.current.set(0, 0, Math.sin(time) / 20);
      locator.current.rotation.z = Math.sin(time / 2) / 3;
      locator.current.position.z = position.clone().add(zDeltaRef.current).z;
    }
  });

  const lastHeartbeatIso8601 = state.lastHeartbeat?.iso8601 ?? state?.lastBattery?.iso8601;
  const secondsSinceLastHeartbeat = useSecondsAgo({
    from: state.type === 'mqttgateway'
      ? state.lastUpdate
      : lastHeartbeatIso8601
        ? new Date(lastHeartbeatIso8601)
        : mapData?.liveData?.startDate,
    getNow
  });

  return (
    <group ref={groupRef} frustumCulled={true}>
      {locatorModels && (
        <>
          <mesh ref={locator} scale={0.5} position={position} castShadow receiveShadow>
            <boxGeometry args={[0.5, 1, 0.75]} />
            <meshStandardMaterial attach="material" color={modelColorByType[state.type ?? 'DEFAULT'] ?? modelColorByType.DEFAULT} />
          </mesh>
        </>
      )}
      {locatorIcons && (
        <Pin
          position={position}
          icon={assetPinIcon}
          microfence={state.isMicrofence}
          selected={highlighted?.category === 'locator' && highlighted?.id === stringifyIdRecord(state.id)}
          onClick={() => highlight({ id: stringifyIdRecord(state.id), category: highlightCategory, label })}
        />
      )}
      {locatorLabels && (
        <WorldOverlay position={position} unselectable={'on'}>
          <MapText>
            {label}
            {state.type !== 'gateway' && state.type !== 'rfiReader' && <BatteryIcon percentage={state.lastBattery?.percent} />}
            <HeartbeatIcon secondsSinceLast={secondsSinceLastHeartbeat} />
          </MapText>
        </WorldOverlay>
      )}
    </group>
  );
};
