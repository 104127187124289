import { OrthographicCamera, PerspectiveCamera } from '@react-three/drei';
import { useThree, useFrame } from '@react-three/fiber';
import CameraControls from 'camera-controls';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  Vector3,
  OrthographicCamera as OrthographicCameraImpl,
  PerspectiveCamera as PerspectiveCameraImpl,
  Box3,
} from 'three';
import { lerpTo, updateControls } from '../../../util/CameraControls/CameraControls';
import { useCurrentEditVec3Ref } from '../EditTools/useCurrentEdit';
import { useEditingData } from '../EditTools/useEditingData';
import {
  useHighlightedRef,
} from '../MapData/useHighlighted';
import { useMapData } from '../MapData/useMapData';
import { CameraType, loadCameraSettings, saveCameraSettings, useMapInteraction } from '../MapInteraction/useMapInteraction';

const applyVectorRounding = (vector: Vector3, roundToNearest: number) => {
  const [x, y, z] = vector.toArray().map(i => Math.round(i / roundToNearest) * roundToNearest);
  vector.set(x, y, z);
}

export const Camera = () => {
  const camera = useRef<OrthographicCameraImpl | PerspectiveCameraImpl>();
  const cameraControls = useRef<CameraControls>(null);
  const { camera: cameraType, lockedNormal, targetObject, highlighted, fitScreenRequest, setFitScreenRequest } = useMapInteraction();
  const { navmeshNodeEdits, applyNavmeshEdits } = useEditingData();
  const { cid, pid, data: mapData } = useMapData();
  const highlightedRef = useHighlightedRef();
  const { currentEdit } = useEditingData();
  const currentEditVec3Ref = useCurrentEditVec3Ref();
  const is3dChasing = cameraType.startsWith('3Dchase');
  const is3dChasingAndLocked = cameraType === '3DchaseLocked'
  const nextPosition = useRef(new Vector3());
  const nextTarget = useRef(new Vector3());
  const previousTarget = useRef(new Vector3());
  const previousPosition = useRef(new Vector3());
  const appliedPreviousCameraSettings = useRef(false);
  const saveTargetVec3 = useRef(new Vector3());
  const savePosVec3 = useRef(new Vector3());
  const saveZoomRef = useRef<number | undefined>();
  const loadedSettings = useMemo(() => loadCameraSettings(cid, pid), [cid, pid]);

  const [px, py, pz] = loadedSettings.position;
  const posVector = useMemo(
    () => new Vector3(px, py, pz),
    [px, py, pz]
  )

  const navmeshLimits = useMemo(() => {
    const nodes = Object.values(navmeshNodeEdits).length ? applyNavmeshEdits(mapData?.navMesh ?? { clientId: '', projectId: '' })?.nodes : mapData?.navMesh?.nodes;
    if (!nodes) return;

    const xValues = nodes.map(n => n.pos[0])
    const yValues = nodes.map(n => n.pos[1])
    const zValues = nodes.map(n => n.pos[2])

    return {
      xMin: Math.min(...xValues),
      xMax: Math.max(...xValues),
      yMin: Math.min(...yValues),
      yMax: Math.max(...yValues),
      zMin: Math.min(...zValues),
      zMax: Math.max(...zValues),
    }

  }, [mapData?.navMesh, navmeshNodeEdits, applyNavmeshEdits])

  const fitToNavmesh = useCallback(() => {
    if (!navmeshLimits || !cameraControls.current) return;

    const minVec3 = new Vector3(navmeshLimits.xMin, navmeshLimits.yMin, navmeshLimits.zMin);
    const maxVec3 = new Vector3(navmeshLimits.xMax, navmeshLimits.yMax, navmeshLimits.zMax);
    const enableTransition = true;
    return cameraControls.current.fitToBox(
      new Box3(minVec3, maxVec3),
      enableTransition,
      {
        paddingTop: 100,
        paddingRight: 100,
        paddingBottom: 100,
        paddingLeft: 100,
      }
    );
  }, [navmeshLimits])


  // Apply loaded settings when component mounts
  useEffect(() => {
    let cancelled = false;
    if (appliedPreviousCameraSettings.current) return;
    // Use another copy so that its not a dependenacy for the useEffect
    const loadedSettings = loadCameraSettings(cid, pid);

    const doSetup = async () => {
      // Wait for refs to be defined
      while (!cameraControls.current || !camera.current) {
        if (cancelled) return;
        await new Promise(resolve => setTimeout(resolve, 5));
      }
      // Make sure we only do the setup once
      if (appliedPreviousCameraSettings.current) return;
      // Apply the settings
      cameraControls.current.setPosition(...loadedSettings.position);
      cameraControls.current.setTarget(...loadedSettings.target);
      if (loadedSettings.zoom !== undefined) {
        camera.current.zoom = loadedSettings.zoom;
      }
      // Fit to navmesh if loaded settings were only the default
      if (loadedSettings.default) {
        const result = fitToNavmesh();
        appliedPreviousCameraSettings.current = result !== undefined;
      } else {
        appliedPreviousCameraSettings.current = true
      }
    }
    doSetup();

    return () => { cancelled = true; }
  }, [cid, pid, fitToNavmesh])

  // Save camera settings when component umnounts, or expectedCameraType changes
  useEffect(() => () => {
    if (!appliedPreviousCameraSettings.current) {
      return;
    }
    saveCameraSettings({
      camera: '3D',
      position: savePosVec3.current.toArray(),
      target: saveTargetVec3.current.toArray(),
      zoom: saveZoomRef.current,
    }, cid, pid)
  }, [cameraType, cid, pid])

  // Important note: this is called very frequently (on every frame!), so do not
  // instantiate new Vector3's (or anything else) that will need to be garbage
  // collected. Instead, mutate existing Vector3 refs.
  useFrame((_, delta) => {
    cameraControls.current?.update(delta);
    camera.current?.updateProjectionMatrix(); // Needed to switch between levels of detail

    setTimeout(() => { // Avoid blocking UI interaction
      // Update refs for saving camera settings
      if (cameraControls.current && appliedPreviousCameraSettings.current) {
        cameraControls.current.getTarget(saveTargetVec3.current);
        cameraControls.current.getPosition(savePosVec3.current);
        saveZoomRef.current = camera.current?.zoom
      }

      // Chase selected if needed
      if (cameraControls.current) {
        if (is3dChasingAndLocked) {
          // Next camera position is offset from the target position, in the direction of the target quaternion
          nextPosition.current.set(0, 3.5, -6).applyQuaternion(targetObject.quaternion).add(targetObject.position);
          // Next camera target is beyond the target object position, in the direction of the target quaternion
          nextTarget.current.set(0, 0, 25).applyQuaternion(targetObject.quaternion).add(targetObject.position);
        } else if (is3dChasing) {
          // Next camera position is the next target position offset by the diff between previous position and target
          cameraControls.current.getTarget(previousTarget.current);
          cameraControls.current.getPosition(previousPosition.current);
          nextPosition.current.copy(targetObject.position).add(previousPosition.current).sub(previousTarget.current);
          nextTarget.current.copy(targetObject.position);
        } else {
          return;
        }
        lerpTo(cameraControls.current, nextPosition.current, nextTarget.current);
      }
    }, 0)
  })

  const rotateCamera = (
    normal: Vector3,
    controls: CameraControls,
    camera: OrthographicCameraImpl | PerspectiveCameraImpl,
  ) => {
    // Get distance from camera to target
    const existingTarget = new Vector3();
    controls.getTarget(existingTarget);
    const distance = camera.position.distanceTo(existingTarget);

    // Calculate new camera position with matching offset
    const scaledNormal = normal.clone().multiplyScalar(distance);
    const newPosition = existingTarget.clone().add(scaledNormal);

    lerpTo(controls, newPosition, existingTarget);
  };

  const moveCamera = (controls: CameraControls, target: Vector3) => {
    const cameraPosition = new Vector3();
    controls.getPosition(cameraPosition);
    const cameraTarget = new Vector3();
    controls.getTarget(cameraTarget);

    const newTarget = target.clone();
    const newPosition = newTarget.clone().add(cameraPosition).sub(cameraTarget);

    lerpTo(controls, newPosition, newTarget, { preventRotation: true });
  };

  const updateCamera = (controls: CameraControls, type: CameraType) => {
    updateControls(type, controls);
  };

  // Track when the camera type changes
  const cameraControlsRef = cameraControls.current;
  useEffect(() => {
    if (cameraControlsRef) {
      updateCamera(cameraControlsRef, cameraType);
    }
  }, [cameraControlsRef, cameraType, is3dChasing]);

  // Move camera when highlight reference changes
  useEffect(() => {
    if (cameraControls.current && highlightedRef.current && highlightedRef.current.position) {
      moveCamera(cameraControls.current, highlightedRef.current.position);
    }
  }, [highlighted?.id, highlightedRef, cameraType]);

  // Move camera when current edit reference changes
  useEffect(() => {
    if (cameraControls.current && currentEditVec3Ref.current && currentEditVec3Ref.current) {
      moveCamera(cameraControls.current, currentEditVec3Ref.current);
    }
  }, [currentEdit?.asset?.uuid, currentEditVec3Ref, cameraType]);

  // Rotate camera around target when normal changes
  useEffect(() => {
    if (cameraControls.current && camera.current) {
      rotateCamera(lockedNormal, cameraControls.current, camera.current);
    }
  }, [lockedNormal, camera]);

  // Fit navmesh to screen when requested
  useEffect(() => {
    if (fitScreenRequest) {
      const result = fitToNavmesh();

      (result ?? Promise.resolve()).then(() => {
        setFitScreenRequest(undefined);
      });
    }
  }, [fitScreenRequest, setFitScreenRequest, fitToNavmesh])

  const dom = useThree(state => state.gl.domElement);
  return (
    <>
      {cameraType.startsWith('2D') && (
        <OrthographicCamera ref={camera} makeDefault position={posVector} zoom={loadedSettings.zoom} />
      )}
      {cameraType.startsWith('3D') && (
        <PerspectiveCamera ref={camera} makeDefault position={posVector} far={10_000} />
      )}
      {camera.current && <cameraControls ref={cameraControls} args={[camera.current, dom]} />}
    </>
  );
};
