import { createContext, ReactNode, useContext, useState } from 'react';

export enum Secondary {
  NONE,
  DEVICES,
  LOCATORS,
  BEACONS,
  SENSORS,
  MAP_SETTINGS,
  ACCOUNT,
  REPLAY,
  REPORTS,
  DIRECTIONS,
  WELFARE,
  LEVELS,
  EDIT_LOCATORS,
  EDIT_BEACONS,
  EDIT_DEVICES,
  EDIT_BULK,
  EDIT_SERVICE_CONFIGS,
  EDIT_MICROFENCES,
  EDIT_LEVELS,
}

export type Interactions = {
  showSecondary: (secondary: Secondary) => void;
  secondary: Secondary;
};

export const SidebarInteractionContext = createContext<Interactions>({
  showSecondary: () => null,
  secondary: Secondary.NONE,
});

export const SidebarInteractionProvider = ({ children }: { children: ReactNode }) => {
  const [secondary, showSecondary] = useState<Secondary>(Secondary.NONE);

  return (
    <SidebarInteractionContext.Provider
      value={{
        showSecondary,
        secondary,
      }}
    >
      {children}
    </SidebarInteractionContext.Provider>
  );
};

export const useSidebarInteraction = () => useContext(SidebarInteractionContext);
