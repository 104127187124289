import { createContext, ReactNode, useContext, useReducer } from 'react';

type Welfare = {
  safe: boolean,
  nearestZone: [x: number, y: number, z: number] | undefined
};

export type Interpolations = {
  assetsWelfare: Record<string, Welfare>,
  dispatchWelfareUpdate: (update: { ids: string } & Welfare) => void,
  assetsLevels: Record<string, number|undefined>,
  dispatchLevelUpdate: (update: { ids: string; level: number|undefined}) => void,
};

export const MapInterpolationContext = createContext<Interpolations>({
  assetsWelfare: {},
  dispatchWelfareUpdate: () => null,
  assetsLevels: {},
  dispatchLevelUpdate: () => null
});


export const MapInterpolationProvider = ({ children }: { children: ReactNode }) => {
  const [assetsWelfare, dispatchWelfareUpdate] = useReducer((state: Record<string, Welfare>, update: { ids: string } & Welfare) => {
    const { ids, ...welfare } = update;
    return { ...state, [ids]: welfare };
  }, {})
  const [assetsLevels, dispatchLevelUpdate] = useReducer((state: Record<string, number|undefined>, update: { ids: string; level: number|undefined}) => {
    const { ids, level } = update;
    return { ...state, [ids]: level };
  }, {})

  return (
    <MapInterpolationContext.Provider
      value={{
        assetsWelfare,
        dispatchWelfareUpdate,
        assetsLevels,
        dispatchLevelUpdate
      }}
    >
      {children}
    </MapInterpolationContext.Provider>
  );
};

export const useInterpolatedMapData = () => useContext(MapInterpolationContext);
