import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useMemo, useReducer, useRef } from 'react';
import {
  NEW_REPLAY_MESSAGES,
  REPLAY_LAST_EVENTS_FROM_PERSISTOR,
  REPLAY_NOW,
  REPLAY_START,
} from '../../store/replayEvents';
import {
  AssetUpdateType,
  LiveMapState,
  LiveStateOrFullUpdate,
  LiveStateUpdate,
} from '../../util/Events/Messages';
import { MapData } from '../map/MapData/useMapData';
import { StreamData, liveStateReducer, INITIAL_STATE, intitalStateFromStaticMap } from './stream';
import { useLatestTagEvents } from './useLatestTagEvents';

export const useGeomobyReplayStream = (staticMap?: MapData | undefined): StreamData => {
  const startDate = useMemo(() => new Date(), []);
  const [liveState, liveDispatch] = useReducer(liveStateReducer, INITIAL_STATE);
  const lastLMU = useRef<LiveStateUpdate[]>([]);

  const lastEvents = useAtomValue(REPLAY_LAST_EVENTS_FROM_PERSISTOR);
  const now = useAtomValue(REPLAY_NOW);
  const start = useAtomValue(REPLAY_START);
  const lastNow = useRef(now);
  const lastNegative = useRef(Symbol());
  lastNegative.current = now < lastNow.current ? Symbol() : lastNegative.current;
  const lastNegativeCurrent = lastNegative.current;
  lastNow.current = now;

  const { execute: fetchTagEvents, data: latestTagEvents } = useLatestTagEvents(start);

  useEffect(() => {
    fetchTagEvents();
  }, [fetchTagEvents]);

  // Sets the initial state from the static map data and initial batch of events
  useEffect(() => {
    if (lastEvents && staticMap && latestTagEvents) {
      console.log('SETTING INITIAL STATE', latestTagEvents);
      const tagboardMessages: LiveStateUpdate[] = latestTagEvents.map(data => ({
        type: AssetUpdateType.Tagboard,
        data,
      }));
      const msgs: LiveStateUpdate[] = [...lastEvents, ...tagboardMessages];
      const actualInitialState: LiveMapState = msgs.reduce(
        (state: LiveMapState, msg: LiveStateUpdate) => liveStateReducer(state, msg),
        intitalStateFromStaticMap(staticMap, lastNow.current),
      );
      const updateEvent: LiveStateOrFullUpdate = {
        type: AssetUpdateType.FullStateUpdate,
        data: actualInitialState,
      };
      liveDispatch(updateEvent);
      lastLMU.current = [...lastLMU.current, ...msgs];
    }
  }, [lastEvents, staticMap, latestTagEvents, lastNegativeCurrent]);

  const [queue, setQueue] = useAtom(NEW_REPLAY_MESSAGES);

  // Dispatches replay events as they come in
  useEffect(() => {
    let cancelled = false;
    const effectTimoutId = setTimeout(() => {
      if (cancelled) return;

      let messages: LiveStateUpdate[] = [];
      if (queue.length > 0) {
        setQueue(queue => {
          messages = queue;
          return [];
        });
      }
      messages.forEach(update => liveDispatch(update));
      lastLMU.current = messages;
    }, 10);

    return () => {
      cancelled = true;
      if (effectTimoutId) {
        clearTimeout(effectTimoutId);
      }
    };
  }, [queue, setQueue]);

  return {
    state: liveState,
    startDate,
  };
};
