import axios from 'axios';
import { useAtomValue } from 'jotai';
import { createContext, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { DEFAULT_INACTIVITY_THRESHOLD_MINUTES } from '../../../config';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { METADATA_URL } from '../../../store/url';
import { useMapData } from './useMapData';

export type ServiceConfigData = {
  scanning_interval: number;
  scanning_window: number;
  environmentalFactor?: number;
  obsLookBackSeconds?: number;
  clampingThresholdMetres?: number;
  tagboardInactivityWarningThresholdMinutes?: number;
};

export const TagboardInactivityContext = createContext<number>(DEFAULT_INACTIVITY_THRESHOLD_MINUTES);

export const TagboardInactivityProvider = ({ cid, pid, children }: { cid: string, pid: string, children: ReactNode }) => {
  const { getNow } = useMapData();

  // Get fresh service config every minute
  const nowIso8601ToTheMinute = getNow().toISOString().slice(0, 16) + ':00Z';

  const [minutes, setMinutes] = useState<number>(DEFAULT_INACTIVITY_THRESHOLD_MINUTES)

  const { headers: { Authorization } } = useAtomValue(AUTHED_REQUEST_CONFIG);

  const metadataUrl = useAtomValue(METADATA_URL);

  const doFetch = useCallback(() => {
    axios.post(
      `${metadataUrl}/${cid}/${pid}/serviceconfig/for?refresh=${new Date(nowIso8601ToTheMinute).getTime()}`,
      { assetId: { 'origin': window.location.origin }, type: 'underground', iso8601: nowIso8601ToTheMinute },
      { headers: { Authorization } },
    )
      .then(res => res.data)
      .then(data => {
        const raw = Number(Object(data ?? {}).tagboardInactivityWarningThresholdMinutes);
        setMinutes((Number.isFinite(raw) && raw > 0 && raw <= DEFAULT_INACTIVITY_THRESHOLD_MINUTES) ? raw : DEFAULT_INACTIVITY_THRESHOLD_MINUTES);
      });
  }, [metadataUrl, cid, pid, Authorization, nowIso8601ToTheMinute])

  useEffect(() => {
    doFetch()
  }, [doFetch])

  return <TagboardInactivityContext.Provider value={minutes}>{children}</TagboardInactivityContext.Provider>;
};

export const useTagboardInactivityThreshold = () => useContext(TagboardInactivityContext);
