import { atom } from 'jotai';
import { LiveStateUpdate } from '../util/Events/Messages';

export const LIVE_LAST_EVENTS_FROM_PERSISTOR = atom<LiveStateUpdate[]>([]);
export const LIVE_TIME = atom({ now: () => new Date() });

export const LIVE_STREAM_MESSAGES = atom<LiveStateUpdate[]>([]);

export const LIVE_STREAM_SORTED = atom(get =>
  get(LIVE_STREAM_MESSAGES).sort(
    event => new Date(event.data.iso8601).getTime() - new Date(event.data.iso8601).getTime(),
  ),
);

export const LIVE_UPDATES_LATEST = atom(get => get(LIVE_STREAM_SORTED).slice(-1)[0]);
export const LIVE_UPDATES_LATEST_TEN = atom(get => get(LIVE_STREAM_SORTED).slice(-10));

export const LIVE_SPEED_MULTIPLIER = atom(1);
export const LIVE_PLAYING = atom(true);