import { MyLocation, LocationSearching, ArrowBack } from '@mui/icons-material';
import {
  Box,
  Divider,
  FormLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import {
  Secondary,
  useSidebarInteraction,
} from '../../underground/map/SidebarInteraction/useSidebarInteraction';
import { useWideScreen } from '../useWideScreen';

export const SearchSidebar = ({
  title,
  options,
  onClick,
  hide,
}: {
  title: string;
  onClick: (id: string, label:string) => void;
  options: { label: string; id: string; selected: boolean }[];
  hide: () => void;
}) => {
  const isWideScreen = useWideScreen();

  const [idFilter, setIdFilter] = useState<string>('');

  return (
    <List component="div" disablePadding>
      <ListItem button onClick={() => hide()}>
        <ListItemIcon>
          <ArrowBack />
        </ListItemIcon>
      </ListItem>
      <Divider />
      <Box p={2} component="div">
        <Typography variant="h6">{title}</Typography>
        <Box mt={1} component="div">
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Filter by ID"
            size="small"
            value={idFilter}
            onChange={e => setIdFilter(e.target.value)}
          />
        </Box>
      </Box>
      {options
        .filter(({ label }) => label.includes(idFilter))
        .map(({ label, id, selected }) => (
          <ListItem
            button
            key={id}
            onClick={() => {
              onClick(id, label);

              if (!isWideScreen) {
                hide();
              }
            }}
          >
            <ListItemIcon>{selected ? <MyLocation /> : <LocationSearching />}</ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
    </List>
  );
};
