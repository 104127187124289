import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../store/auth';
import { AUTHN_URL } from '../store/url';
import { CID_PID_PAIRS, ME } from '../store/user';
import { useAsyncTask } from '../util/AsyncTask';
import { AccessItem, ClientProjectAccessItem } from './useInviteUser';

type User = {
  id: string;
  label: string;
  access: AccessItem[];
  createdAt: string;
  updatedAt: string;
  expiry: string;
  secret: string;
  inviteUrl: string;
};

export const useCreateProject = (accessItem: ClientProjectAccessItem) => {
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const httpBaseUrl = useAtomValue(AUTHN_URL);
  const { id: myId, label: myLabel, access: myAccess } = useAtomValue(ME);
  const existingCidPidPairs = useAtomValue(CID_PID_PAIRS);

  // Project is "created" by adding it to the user's access; this will ensure it shows
  // on the project selection screen
  const patchUser = useCallback(async () => {
    const existingCidPid = existingCidPidPairs.find(
      ({ clientId, projectId }) =>
        clientId === accessItem.clientId && projectId === accessItem.projectId,
    );
    if (existingCidPid) {
      return [existingCidPid];
    }
    return (
      await axios.patch<User>(
        `${httpBaseUrl}/open/user/${myId}`,
        {
          label: myLabel,
          access: [...myAccess, accessItem],
        },
        authedConfig,
      )
    ).data.access;
  }, [httpBaseUrl, authedConfig, existingCidPidPairs, myId, myLabel, myAccess, accessItem]);

  return useAsyncTask<AccessItem[], typeof patchUser>(patchUser);
};
