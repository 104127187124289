import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { SMALL_WIDTH } from '../../../util/Sidebar/Sidebar';
import { useMobile } from '../../../util/useMobile';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      left: SMALL_WIDTH,
      width: SMALL_WIDTH,
      background: '#5555',
      transition: theme.transitions?.create('height', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      alignItems: 'flex-start',
      gap: 10,
      padding: '5px 5px 10px 5px',
    },
    rootMobile: {
      left: 0,
      top: 60,
    },
    hidden: {
      height: 0,
    },
  }),
);

export const EditToolsOverlay = ({ children }: { children: ReactNode[] }) => {
  const classes = useStyle();
  const isMobile = useMobile();

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootMobile]: isMobile,
        [classes.hidden]: !children,
      })}
    >
      {children}
    </div>
  )
};
