import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { TRIGGERS_URL } from '../../../store/url';
import { useAsyncTask } from '../../../util/AsyncTask';
import { Microfence } from '../../api/Microfence.validator';
import { DeleteAssetById } from './useEditingData';

const isFreshDeletion = (asset: Microfence | DeleteAssetById) =>
  'delete' in asset && asset.id.includes('fresh');

export const useSaveMicrofences = (assets: (Microfence | DeleteAssetById)[]) => {
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const httpBaseUrl = useAtomValue(TRIGGERS_URL);

  const saveMicrofences = useCallback(async () => {
    return Promise.all(
      assets
        .filter(m => !isFreshDeletion(m))
        .map(async microfence => {
          const { id, clientId, projectId, createdAt, updatedAt, ...body } = {
            createdAt: undefined,
            updatedAt: undefined,
            ...microfence,
          };
          if ('delete' in microfence) {
            return (
              await axios.delete<Microfence>(
                `${httpBaseUrl}/${clientId}/${projectId}/microfences/${id}`,
                authedConfig,
              )
            ).data;
          } else if (microfence.id.includes('fresh')) {
            return (
              await axios.post<Microfence>(
                `${httpBaseUrl}/${clientId}/${projectId}/microfences`,
                body,
                authedConfig,
              )
            ).data;
          } else {
            return (
              await axios.patch<Microfence>(
                `${httpBaseUrl}/${clientId}/${projectId}/microfences/${id}`,
                body,
                authedConfig,
              )
            ).data;
          }
        }),
    );
  }, [httpBaseUrl, authedConfig, assets]);

  return useAsyncTask<unknown, typeof saveMicrofences>(saveMicrofences);
};
