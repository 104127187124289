import axios from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { AUTH_NULLABLE, TIMED_OUT } from '../store/auth';
import { AUTHN_URL } from '../store/url';
import { useAsyncTask } from '../util/AsyncTask';

export const useLoginCallback = (username: string, password: string) => {
  const setAuth = useSetAtom(AUTH_NULLABLE);
  const setTimedOut = useSetAtom(TIMED_OUT);
  const httpBaseUrl = useAtomValue(AUTHN_URL);
  const Authorization = `Basic ${btoa(`${username}:${password}`)}`;

  const login = useCallback(async () => {
    const {
      data: { accessJwt, refreshJwt },
    } = await axios.post(
      `${httpBaseUrl}/open/jwt/basic`,
      {},
      {
        headers: {
          Authorization,
        },
      },
    );
    if (!accessJwt || !refreshJwt) throw new Error('Missing expected access/refresh JWTs');

    setAuth({ accessJwt, refreshJwt });
    setTimedOut(false);
  }, [httpBaseUrl, setAuth, setTimedOut, Authorization]);

  return useAsyncTask(login);
};
