import { Box } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import React, { useRef } from 'react';
import { Object3D } from 'three';
import { useMapData } from '../../MapData/useMapData';
import { MapText } from '../../MapText/MapText';
import { WorldOverlay } from '../../WorldOverlay/WorldOverlay';

export const Empty = () => {
  const cube = useRef<Object3D>(null);
  useFrame(() => {
    if (cube.current) {
      cube.current.rotation.x += 0.01;
      cube.current.rotation.y += 0.01;
    }
  });

  return (
    <>
      <ambientLight intensity={0.3} />
      <directionalLight position={[1, 1.5, 2]} />
      <Box ref={cube}>
        <meshStandardMaterial attach="material" color="lightblue" />
      </Box>
      <WorldOverlay center>
        <MapText displayType="info">Loading...</MapText>
      </WorldOverlay>
    </>
  );
};
