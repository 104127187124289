import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DeleteAssetByUuid, DeleteAssetById, useEditingData } from './useEditingData';

export const ConfirmDeleteDialog = () => {
  const { currentDeletion, setCurrentDeletion, setCurrentEdit, dispatchLocatedAssetEdit, dispatchPortableAssetEdit, dispatchServiceConfigEdit, dispatchMircofenceEdit, dispatchMircofencePairEdit } = useEditingData();

  const isOpen = !!currentDeletion;

  const handleClose = () => {
    setCurrentDeletion();
  }

  const handleConfirm = () => {
    if (!currentDeletion) throw new Error('UI should not ask to confirm deletion of nothing');

    if ('state_json' in currentDeletion) {
      const { id, clientId, projectId } = currentDeletion;
      const deletion: DeleteAssetById = {
        id,
        clientId,
        projectId,
        delete: true,
      };
      dispatchServiceConfigEdit(deletion);
    } else if ('boundaryRssi' in currentDeletion) {
      const { id, clientId, projectId } = currentDeletion;
      const deletion: DeleteAssetById = {
        id,
        clientId,
        projectId,
        delete: true,
      };
      if ('assetId' in currentDeletion) {
        dispatchMircofenceEdit(deletion);
      } else {
        dispatchMircofencePairEdit(deletion);
      }    
    } else {
      const { uuid, clientId, projectId } = currentDeletion;
      const deletion: DeleteAssetByUuid = {
        uuid,
        clientId,
        projectId,
        delete: true,
      };
      if ('position' in currentDeletion) {
        dispatchLocatedAssetEdit(deletion);
      } else {
        dispatchPortableAssetEdit(deletion);
      }
    }
    setCurrentDeletion(undefined);
    setCurrentEdit(undefined);
    handleClose();
  }

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="delete-alert-dialog-title"
      aria-describedby="delete-alert-dialog-description"
    >
      <DialogTitle id="delete-alert-dialog-title">
        Really delete this asset?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-alert-dialog-description">
          The asset will be deleted when changes are next saved.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
