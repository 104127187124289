import { Vector3 } from 'three';
import { AssetState } from '../../util/Events/Messages';
import { stringifyIdRecord } from '../../util/stringUtils';
import { Navmesh } from '../api/Navmesh.validator';
import { levelIdOfPoint } from './findNavmeshPath';

export const portableAssetOnLevelFilter =
  (level: number | undefined, assetsLevels: Record<string, number | undefined>) =>
  (asset: Pick<AssetState, 'id'>) => {
    if (level === undefined) return true;

    return assetsLevels[stringifyIdRecord(asset.id)] === level;
  };

export const locatedAssetOnLevelFilter =
  (level: number | undefined, navmesh: Navmesh | undefined) =>
  (asset: Pick<AssetState, 'positionVector'> | { positionVector: Vector3 | undefined }) => {
    if (level === undefined) return true;
    if (!navmesh || !asset.positionVector) return false;

    return levelIdOfPoint(asset.positionVector, navmesh) === level;
  };
