import { Container, Grid, Paper, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Copyright from '../Components/Global/Copyright';
import { hardRedirectToLoginPage } from './hardRedirectToLoginPage';

export const AuthError = () => {
  return (
    <Box component='div'
      sx={{
        width: '100vw',
        height: '100vh',
        background: 'linear-gradient(90.26deg, #4CB8C4 0.14%, #3CD3AD 99.83%)',
      }}
    >
      <Grid container direction="column" justifyContent="center" height="100%">
        <Container component="main" maxWidth="xs">
          <Stack direction="column" spacing={2}>
            <img src='/Logo_large.png' alt="Logo Geomoby" width="100%" />
            <Paper elevation={5}>
              <Box component="div" p={2} style={{ display: 'flex', flexDirection: 'column', minHeight: '20em' }}>
                <Typography variant="h6" textAlign="center">
                  Authorisation Required
                </Typography>
                <Typography mt={2}>
                  You are not authorised to access any projects. Please contact your network administrator to request access.
                </Typography>
                <div style={{ flexGrow: 1 }} />
                <Grid
                  container
                  direction="row-reverse"
                  justifyContent="space-between"
                  height="100%"
                  mt={1}
                  ml={0}
                  sx={{ maxWidth: '100%' }}
                  spacing={2}
                >
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => hardRedirectToLoginPage('unauthorized')}
                  >
                    Back
                  </Button>
                </Grid>
              </Box>
            </Paper>
          </Stack>
          <Box component='div' mt={6}>
            <Copyright />
          </Box>
        </Container>
      </Grid>
    </Box>
  );
}
