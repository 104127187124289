import { AccountBox, GpsFixed, GpsNotFixed, GpsOff, PhoneIphone, Warning } from '@mui/icons-material';
import { Link, Table, TableCell, TableRow, Typography } from '@mui/material';
import { compareDesc } from 'date-fns';
import { Vector3 } from 'three';
import { AssetState } from '../../../../util/Events/Messages';
import { AssetTagboard } from '../../../../util/Events/schema';
import { MiningTruck, LightVehicle } from '../../../../util/IconFromSVG';
import { stringifyIdRecord } from '../../../../util/stringUtils';
import { levelIdOfPoint } from '../../../util/findNavmeshPath';
import { describeCount, isActive, isLightVehicleBeacon, isPersonnelBeacon, isPersonnelDevice, isTruckBeacon } from '../../../util/portableAssetUtils';
import { useInterpolatedMapData } from '../../MapData/useInterpolatedData';
import { allPortableAssetsFromLiveData, useMapData } from '../../MapData/useMapData';
import { useTagboardInactivityThreshold } from '../../MapData/useTagboardInactivityThreshold';
import { useMapInteraction } from '../../MapInteraction/useMapInteraction';
import { UpdateInfo } from '../CommonInfo';
import { Report, ReportGridHeading, visuallyHidden } from './Report';

export const FullTagboardReportInner = ({ height, plain }: { height?: number, plain?: boolean }) => {
  const { highlight } = useMapInteraction();
  const { assetsLevels } = useInterpolatedMapData();
  const { data: mapData, getNow } = useMapData();
  const inactivityThreshold = useTagboardInactivityThreshold();
  const now = getNow();

  const levelById = Object.fromEntries((mapData?.navMesh?.levels ?? []).map(level => [level.id, level.name]));

  const allAssetTags = (mapData?.liveData?.state.tagboard ? Array.from(mapData.liveData.state.tagboard.values()) : []).map(tag => ({
    tag, asset: mapData?.liveData?.state?.assets.get(stringifyIdRecord(tag.ids.id))
  }))
  const assetTags = allAssetTags.filter((t): t is typeof allAssetTags[number] & { asset: AssetState } => !!(t.asset?.fromMetadata));

  const personnelBeacons = assetTags.filter(({ asset }) => isPersonnelBeacon(asset));
  const devices = assetTags.filter(({ asset }) => isPersonnelDevice(asset));
  const trucks = assetTags.filter(({ asset }) => isTruckBeacon(asset));
  const lightVehicles = assetTags.filter(({ asset }) => isLightVehicleBeacon(asset));

  const personnelBeaconCount = personnelBeacons.length;
  const personnelDeviceCount = devices.length;
  const personnelCount = personnelBeaconCount + personnelDeviceCount;
  const personnelBreakdown = personnelCount === 0
    ? ''
    : `(${describeCount(personnelBeaconCount, 'beacon')}, ${describeCount(personnelDeviceCount, 'device')})`;

  const truckCount = trucks.length;
  const lightVehicleCount = lightVehicles.length;
  const vehiclesCount = truckCount + lightVehicleCount;
  const vehiclesBreakdown = vehiclesCount === 0
    ? ''
    : `(${describeCount(lightVehicleCount, 'light vehicle')}, ${describeCount(truckCount, 'truck')})`;

  const toTableRow = ({ tag, asset }: { tag: AssetTagboard | { ids: AssetTagboard['ids'] }; asset: AssetState }) => {
    const state = 'state' in tag ? tag.state : undefined;
    const item = {
      label: asset.label ?? tag.ids.label ?? JSON.stringify(tag.ids.id),
      id: tag.ids.id,
      type: isPersonnelDevice(asset) ? 'Device' : isTruckBeacon(asset) ? 'Truck' : isLightVehicleBeacon(asset) ? 'Light vehicle' : 'Personnel',
      plain: !!plain,
      state,
      lastLocation: asset.lastLocation && isActive(asset, now) ? asset.lastLocation : undefined,
      active: isActive(asset, now),
      inactiveWarning: !isActive(asset, now, inactivityThreshold),
      levelId: (
        assetsLevels[stringifyIdRecord(asset.id)] ??
        asset.lastKnownLevelId ??
        (asset.lastLocation && mapData?.navMesh ? levelIdOfPoint(new Vector3(asset.lastLocation.position.x, asset.lastLocation.position.y, asset.lastLocation.position.z), mapData.navMesh) : undefined)
      ),
      lastKnownIso8601: asset.lastLocation?.iso8601 ?? asset.lastKnownIso8601,
    }

    const status = item.state !== 'IN'
      ? 'NOT_IN' as const
      : (item.inactiveWarning)
        ? 'IN_WITH_WARNING' as const
        : item.active
          ? 'IN_AND_ACTIVE' as const
          : 'IN_AND_INACTIVE' as const;

    const statusHtmlColors: Record<typeof status, string> = {
      NOT_IN: '#888',
      IN_WITH_WARNING: '#EA9923',
      IN_AND_ACTIVE: '#77C277',
      IN_AND_INACTIVE: 'white',
    }

    return (
      <TableRow key={stringifyIdRecord(item.id)} sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:td, &:th': { fontVarient: 'small-caps' }, opacity: item.state === 'IN' ? 1 : 0.65 }}>
        <TableCell align="center" sx={{ width: '2.5em' }}>
          {status === 'IN_WITH_WARNING' && <Warning color='warning' titleAccess={'iso8601' in tag ? `IN (at ${new Date(tag.iso8601).toLocaleString()})` : 'IN'} />}
          {status === 'IN_AND_ACTIVE' && <GpsFixed titleAccess='IN' />}
          {status === 'IN_AND_INACTIVE' && <GpsNotFixed titleAccess='IN (not active)' />}
          {status === 'NOT_IN' && (item.state ?? <GpsOff color='error' titleAccess='Unknown' />)}
        </TableCell>
        <TableCell align="center" sx={{ width: '2.5em' }}>
          {item.type === 'Personnel' && <span aria-hidden><AccountBox fontSize='small' titleAccess='Personnel beacon' style={{ transform: 'translateX(1px)', marginRight: '1px' }} role='presentation' /></span>}
          {item.type === 'Device' && <span aria-hidden><PhoneIphone fontSize='small' titleAccess='Device' role='presentation' /></span>}
          {item.type === 'Truck' && <span aria-hidden title='Truck'><MiningTruck width={24} height={24} style={{ transform: 'translateX(3px)', marginRight: '1px' }} role='presentation' /></span>}
          {item.type === 'Light vehicle' && <span aria-hidden title='Light vehicle'><LightVehicle width={24} height={24} style={{ transform: 'translate(3px,2px)' }} role='presentation' /></span>}
        </TableCell>
        <TableCell component="th" scope="row" sx={{ width: '35%' }}>
          <Link onClick={() => highlight({ id: stringifyIdRecord(item.id), category: 'beacon', label: item.label })}>{item.label}</Link>
        </TableCell>
        <TableCell>{item.levelId !== undefined && <Typography variant='caption'>Level: {levelById[item.levelId] ?? item.levelId}</Typography>}</TableCell>
        <TableCell>
          {item.state === 'IN' && item.lastKnownIso8601 && <Typography variant='caption' color={statusHtmlColors[status]}>Last seen {new Date(item.lastKnownIso8601).toLocaleTimeString('en-au', { hour: 'numeric', minute: 'numeric' })}</Typography>}
        </TableCell>
      </TableRow>
    )
  };

  return (
    <>
      <Report style={{ height }}>
        <ReportGridHeading title="Personnel:">
          <Typography>{personnelCount} {personnelCount === 1 ? 'tag' : 'tags'} {personnelBreakdown}</Typography>
        </ReportGridHeading>
        <Table sx={{ width: '100%', gridColumn: '1 / -1' }} size="small" aria-label="Personnel tags">
          <tbody>
            {[...personnelBeacons, ...devices]
              .sort((a, b) => (a.asset?.label ?? a.tag.ids.label ?? JSON.stringify(a.tag.ids.id)).localeCompare(b.asset?.label ?? b.tag.ids.label ?? JSON.stringify(b.tag.ids.id)))
              .map(({ tag, asset }) => toTableRow({ tag, asset }))}
          </tbody>
        </Table>
        <ReportGridHeading title="Vehicles:">
          <Typography>{vehiclesCount} {vehiclesCount === 1 ? 'tag' : 'tags'} {vehiclesBreakdown}</Typography>
        </ReportGridHeading>
        <Table sx={{ width: '100%', gridColumn: '1 / -1' }} size="small" aria-label="Vehicle tags">
          <tbody>
            {[...lightVehicles, ...trucks]
              .sort((a, b) => (a.asset?.label ?? a.tag.ids.label ?? JSON.stringify(a.tag.ids.id)).localeCompare(b.asset?.label ?? b.tag.ids.label ?? JSON.stringify(b.tag.ids.id)))
              .map(toTableRow)}
          </tbody>
        </Table>
      </Report>
    </>
  );
};
export const TagboardReport = ({ height, plain, }: { height?: number, plain?: boolean, }) => {
  const { data: mapData } = useMapData();

  const assets = (mapData?.liveData ? allPortableAssetsFromLiveData(mapData?.liveData) : [])
  const lastUpdated = assets.flatMap(state => state.lastUpdate ?? []).sort(compareDesc).at(0) ?? mapData?.liveData?.state?.lastUpdate;

  return (
    <>
      <UpdateInfo lastUpdate={lastUpdated} />
      <FullTagboardReportInner height={height} plain={plain} />
    </>
  );
};
