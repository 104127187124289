import { Vector3 } from 'three';
import { Navmesh as RawNavMesh } from '../../api/Navmesh.validator';
import { Project } from '../../api/useProject';
import { Cube, Gltf, MapData, NavMesh } from './useMapData';

const GEO_LAB_OBJECT: Gltf = { type: 'gltf', asset: '/testModel.glb', position: new Vector3() };

export const projectToMap = (project: Project): MapData => {
  const gltfs = project.projectId === 'geo_lab' ? [GEO_LAB_OBJECT] : [];
  const navMeshes = project.nodes ? [parseNavMesh(project)] : [];
  const cubes = project.rect_world
    ? project.rect_world.map(
        ({ min, max }): Cube => ({
          type: 'cube',
          size: new Vector3(max.x, max.y, 0),
          position: new Vector3(min.x + (max.x - min.x) / 2, min.y + (max.y - min.y) / 2, 0),
        }),
      )
    : [];

  return {
    clientId: project.clientId,
    projectId: project.projectId,
    lastUpdate: new Date().getTime(),
    navMesh: project.nodes ? project : undefined,
    objects: [...gltfs, ...navMeshes, ...cubes],
    portableAssets: project.portableAssets,
    locatedAssets: project.locatedAssets.map(locator => {
      const { x, y, z } = locator.position;
      return {
        ...locator,
        position: new Vector3(x, y, z),
      };
    }),
    metaData: {}, // not used,
    microfences: project.microfences,
    microfencePairs: project.microfencePairs,
    serviceConfigs: project.serviceConfigs,
    tagboard: project.tagboard ?? [],
  };
};

const parseNavMesh = (navmesh: RawNavMesh): NavMesh => {
  return {
    type: 'navMesh',
    navMesh: {
      links: navmesh.links || [],
      nodes: (navmesh.nodes || []).map(node => ({
        ...node,
        pos: new Vector3(...node.pos),
      })),
    },
    position: new Vector3(),
  };
};
