import { Box, Grid, Typography } from '@mui/material';
import { EditInfoBeacon } from './EditInfoBeacon';
import { EditInfoBulkPortable } from './EditInfoBulkPortable';
import { EditInfoLevel } from './EditInfoLevel';
import { EditInfoLink } from './EditInfoLink';
import { EditInfoLocator } from './EditInfoLocator';
import { EditInfoMicrofence } from './EditInfoMicrofence';
import { EditInfoNode } from './EditInfoNode';
import { EditInfoServiceConfig } from './EditInfoServiceConfig';
import { useEditingData } from './useEditingData';

const isFresh = (asset?: { uuid: string } | { id: string }) => {
  if (!asset) return false;

  const identifiers = {
    id: '',
    uuid: '',
    ...asset,
  }

  return (identifiers.uuid ? identifiers.uuid : identifiers.id).includes('fresh');
}

export const EditInfo = () => {
  const { currentEdit, bulkEdit } = useEditingData();

  if (!currentEdit && !bulkEdit) {
    return <Typography>Nothing currently selected</Typography>;
  }

  const title = !currentEdit ? 'Bulk edit' : (currentEdit.type === 'link' || currentEdit.type === 'node' || currentEdit.type === 'level' || currentEdit.type === 'new link')
    ? 'Editing navmesh'
    : (currentEdit.type === 'service config')
      ? `${isFresh(currentEdit.asset) ? 'Creating' : 'Editing'} Configuration`
      : `${isFresh(currentEdit.asset) ? 'Creating' : 'Editing'} Asset`;

  const subtitle = (
    !currentEdit ? (bulkEdit ?? '').replace('portable', 'Beacons and devices') :
      currentEdit.type === 'service config' ? 'Service configuration' :
        currentEdit.type === 'microfence' && 'assetId' in currentEdit.asset ? 'Microfence' :
          currentEdit.type === 'microfence' && !('assetId' in currentEdit.asset) ? 'Microfence pair' :
            currentEdit.type === 'portable' && currentEdit.asset.type !== 'beacon'
              ? 'device'
              : currentEdit.type === 'located' && !['locator', 'gateway', 'mqttgateway'].includes(currentEdit.asset.type)
                ? 'sensor'
                : 'type' in currentEdit.asset
                  ? currentEdit.asset.type.replace('mqttgateway', 'MQTT Gateway')
                  : currentEdit.type
  ).replace(/./, match => match.toUpperCase())

  return (
    <Grid container spacing={3} direction="column">
      <Grid item>
        <Box paddingBottom={2} component="div">
          <Typography variant="h4">{title}</Typography>
          <Typography>{subtitle}</Typography>
        </Box>
      </Grid>
      {bulkEdit === 'portable' && <EditInfoBulkPortable />}
      {currentEdit?.type === 'located' && <EditInfoLocator asset={currentEdit.asset} />}
      {currentEdit?.type === 'portable' && <EditInfoBeacon asset={currentEdit.asset} />}
      {currentEdit?.type === 'link' && <EditInfoLink link={currentEdit.asset} />}
      {currentEdit?.type === 'new link' && <EditInfoLink link={currentEdit.asset} newLinkTo={currentEdit.linkTo} />}
      {currentEdit?.type === 'node' && <EditInfoNode node={currentEdit.asset} />}
      {currentEdit?.type === 'level' && <EditInfoLevel level={currentEdit.asset} />}
      {currentEdit?.type === 'service config' && <EditInfoServiceConfig asset={currentEdit.asset} />}
      {currentEdit?.type === 'microfence' && <EditInfoMicrofence asset={currentEdit.asset} />}
    </Grid>
  );
};
