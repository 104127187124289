import { startRegistration } from '@simplewebauthn/browser';
import { RegistrationResponseJSON } from '@simplewebauthn/typescript-types';
import axios from 'axios';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { AUTH_NULLABLE } from '../store/auth';
import { AUTHN_URL } from '../store/url';
import { useAsyncTask } from '../util/AsyncTask';

export const usePasskeyRegister = (jwts: { accessJwt: string; refreshJwt: string } | undefined) => {
  const httpBaseUrl = useAtomValue(AUTHN_URL);
  const setAuth = useSetAtom(AUTH_NULLABLE);

  const { accessJwt, refreshJwt } = jwts ?? {};

  const register = useCallback(async () => {
    if (!accessJwt || !refreshJwt) throw new Error('Missing expected access/refresh JWTs');

    const { data: registerOptions } = await axios.get(`${httpBaseUrl}/open/passkey/register`, {
      headers: {
        Authorization: `Bearer ${accessJwt}`,
      },
    });

    let registration: RegistrationResponseJSON;
    try {
      // Pass the options to the authenticator and wait for a response
      registration = await startRegistration(registerOptions);
    } catch (error) {
      // A simple error message to show users
      throw new Error('Failed to create passkey');
    }

    const { data } = await axios.post(`${httpBaseUrl}/open/passkey/register`, registration, {
      headers: {
        Authorization: `Bearer ${accessJwt}`,
      },
    });

    if (!data.verified) {
      throw new Error('Failed to register passkey');
    }
    setAuth({ accessJwt, refreshJwt });

    return !!data.verified;
  }, [httpBaseUrl, setAuth, accessJwt, refreshJwt]);

  return useAsyncTask<boolean, typeof register>(register);
};
