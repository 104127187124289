import Graph from 'node-dijkstra';
import { Line3, Vector3 } from 'three';
import { NavmeshNode } from '../api/Navmesh.validator';
import { FullOrPartialNavmesh } from '../map/EditTools/useEditingData';
import {
  distanceViaPoints,
  pointIsBetweenTwoOthers,
  releaseVector3,
  temporaryVector3,
} from './vectorUtils';

const pointLineDist = (p: Vector3, line: Line3) => {
  const target = temporaryVector3();
  line.closestPointToPoint(p, true, target);
  const distance = target.distanceTo(p);
  releaseVector3(target);
  return distance;
};

export const pld = (
  p: Vector3 | [number, number, number],
  a: Vector3 | [number, number, number],
  b: Vector3 | [number, number, number],
): number => {
  const pVec3 = Array.isArray(p) ? temporaryVector3(...p) : p;
  const aVec3 = Array.isArray(a) ? temporaryVector3(...a) : a;
  const bVec3 = Array.isArray(b) ? temporaryVector3(...b) : b;
  const distance = pointLineDist(pVec3, new Line3(aVec3, bVec3));

  if (Array.isArray(p)) {
    releaseVector3(pVec3);
  }
  if (Array.isArray(a)) {
    releaseVector3(aVec3);
  }
  if (Array.isArray(b)) {
    releaseVector3(bVec3);
  }
  return distance;
};

const graphCache: Map<string, Graph> = new Map();
const createGraph = (navMesh: FullOrPartialNavmesh): Graph => {
  const key = `${navMesh.clientId}/${navMesh.projectId}/${navMesh.updatedAt ?? navMesh.createdAt}`;
  const cached = graphCache.get(key);
  if (cached) return cached;

  const route = new Graph();
  const vecA = temporaryVector3();
  const vecB = temporaryVector3();

  const nodesById = Object.fromEntries((navMesh.nodes ?? []).map(n => [n.id, n]));

  navMesh.nodes?.forEach(node => {
    const neighbours = new Map();
    navMesh.links
      ?.filter(l => !l.unnavigable)
      .forEach(link => {
        const nodeA = nodesById[link.a]?.pos;
        const nodeB = nodesById[link.b]?.pos;
        if (nodeA && nodeB) {
          vecA.set(nodeA[0], nodeA[1], nodeA[2]);
          vecB.set(nodeB[0], nodeB[1], nodeB[2]);
          const diff = vecA.distanceTo(vecB);
          if (link.a !== link.b && typeof diff === 'number' && diff > 0) {
            if (node.id === link.a) {
              neighbours.set(String(link.b), diff);
            } else if (node.id === link.b) {
              neighbours.set(String(link.a), diff);
            }
          }
        }
      });
    if (neighbours.size > 0) {
      route.addNode(String(node.id), neighbours);
    }
  });

  releaseVector3(vecA, vecB);

  graphCache.set(key, route);

  return route;
};

const weightedPath = (path: string[], fromPosition: Vector3, navMesh: FullOrPartialNavmesh) => {
  let weight = 0;
  const vecCurrent = temporaryVector3();
  const vecNext = temporaryVector3();
  const nodesById = Object.fromEntries((navMesh.nodes ?? []).map(n => [n.id, n]));

  for (let n = 0; n < path.length; n++) {
    if (n < path.length - 1) {
      const currentNode: NavmeshNode | undefined = nodesById[path[n]];
      const nextNode: NavmeshNode | undefined = nodesById[path[n + 1]];
      vecCurrent.set(currentNode?.pos[0] ?? 0, currentNode?.pos[1] ?? 0, currentNode?.pos[2] ?? 0);
      vecNext.set(nextNode?.pos[0] ?? 0, nextNode?.pos[1] ?? 0, nextNode?.pos[2] ?? 0);
      weight += vecCurrent.distanceTo(vecNext);
    } else {
      const firstNode: NavmeshNode | undefined = nodesById[path[0]];
      vecCurrent.set(firstNode?.pos[0] ?? 0, firstNode?.pos[1] ?? 0, firstNode?.pos[2] ?? 0);
      weight += vecCurrent.distanceTo(fromPosition);
    }
  }
  releaseVector3(vecCurrent, vecNext);
  return { path, weight };
};

const linkAndNodeCache = new Map<
  string,
  {
    link: number;
    nodeAId: number;
    nodeBId: number;
    aX: number;
    aY: number;
    aZ: number;
    bX: number;
    bY: number;
    bZ: number;
  }
>();
const SHORT_CIRCUIT_DISTANCE = 0.5;
export const findLinkAndNodes = (pos: Vector3, navMesh: FullOrPartialNavmesh) => {
  const key = `${navMesh.clientId}/${navMesh.projectId}/${navMesh.updatedAt}/${Math.round(
    pos.x,
  )}/${Math.round(pos.y)}/${Math.round(pos.z)}`;

  const cached = linkAndNodeCache.get(key);
  if (cached) {
    return {
      link: cached.link,
      nodeA: temporaryVector3(cached.aX, cached.aY, cached.aZ),
      nodeB: temporaryVector3(cached.bX, cached.bY, cached.bZ),
      nodeAId: cached.nodeAId,
      nodeBId: cached.nodeBId,
    };
  }

  let distFromLine = Number.MAX_SAFE_INTEGER;
  const found = {
    link: -1,
    nodeA: temporaryVector3(),
    nodeB: temporaryVector3(),
    nodeAId: -1,
    nodeBId: -1,
  };

  const { nodes, links } = navMesh;
  const nodesById = Object.fromEntries((nodes ?? []).map(n => [n.id, n]));
  if (nodes && links) {
    outerloop: for (const nA of nodes) {
      innerloop: for (const link of links) {
        const isLinkA: boolean = link.a === nA.id;
        const isLinkB: boolean = link.b === nA.id;
        if (pos && (isLinkA || isLinkB)) {
          const nB = nodesById[isLinkA ? link.b : link.a];
          if (!nB || nB.pos.join() === nA.pos.join()) continue innerloop;

          const distToAB = pld(pos, nA.pos, nB.pos);
          if (distToAB < distFromLine) {
            distFromLine = distToAB;
            found.link = link.id;
            found.nodeA.set(nA.pos[0], nA.pos[1], nA.pos[2]);
            found.nodeB.set(nB.pos[0], nB.pos[1], nB.pos[2]);
            found.nodeAId = nA.id;
            found.nodeBId = nB.id;
          }
          if (distFromLine < SHORT_CIRCUIT_DISTANCE) {
            break outerloop;
          }
        }
      }
    }
  }

  linkAndNodeCache.set(key, {
    link: found.link,
    nodeAId: found.nodeAId,
    nodeBId: found.nodeBId,
    aX: found.nodeA.x,
    aY: found.nodeA.y,
    aZ: found.nodeA.z,
    bX: found.nodeB.x,
    bY: found.nodeB.y,
    bZ: found.nodeB.z,
  });

  return found;
};

const closestPointCache = new Map<string, { x: number; y: number; z: number }>();
export const closestNavmeshPointToPoint = (navMesh: FullOrPartialNavmesh, point: Vector3) => {
  const key = `${navMesh.clientId}/${navMesh.projectId}/${navMesh.updatedAt}/${Math.round(
    point.x,
  )}/${Math.round(point.y)}/${Math.round(point.z)}`;

  const cached = closestPointCache.get(key);
  if (cached) {
    return new Vector3(cached.x, cached.y, cached.z);
  }

  const linkAndNode: {
    link: number;
    nodeA: Vector3;
    nodeB: Vector3;
    nodeAId: number;
    nodeBId: number;
  } = findLinkAndNodes(point, navMesh);

  const linkLine = new Line3(linkAndNode.nodeA, linkAndNode.nodeB);
  const closestNavmeshPoint = new Vector3();
  linkLine.closestPointToPoint(point, true, closestNavmeshPoint);

  releaseVector3(linkAndNode.nodeA, linkAndNode.nodeB);

  closestPointCache.set(key, {
    x: closestNavmeshPoint.x,
    y: closestNavmeshPoint.y,
    z: closestNavmeshPoint.z,
  });

  return closestNavmeshPoint;
};

export const findNavmeshPath = (
  navMesh: FullOrPartialNavmesh,
  toPositionRaw: Vector3,
  fromPosition?: Vector3 | undefined,
) => {
  if (!fromPosition) {
    return;
  }

  const nodesById = Object.fromEntries((navMesh.nodes ?? []).map(n => [n.id, n]));

  const toLinkAndNode: {
    link: number;
    nodeA: Vector3;
    nodeB: Vector3;
    nodeAId: number;
    nodeBId: number;
  } = findLinkAndNodes(toPositionRaw, navMesh);

  // UR-782 clamp to navmesh
  const toPosition = closestNavmeshPointToPoint(navMesh, toPositionRaw);

  const fromLinkAndNode: {
    link: number;
    nodeA: Vector3;
    nodeB: Vector3;
    nodeAId: number;
    nodeBId: number;
  } = findLinkAndNodes(fromPosition, navMesh);

  if (fromLinkAndNode.link === -1 || toLinkAndNode.link === -1) {
    return;
  }
  if (fromLinkAndNode.link === toLinkAndNode.link) {
    return [fromPosition, toPosition];
  }

  const nodes: Vector3[] = [];

  if (
    fromLinkAndNode.nodeAId === toLinkAndNode.nodeAId ||
    fromLinkAndNode.nodeAId === toLinkAndNode.nodeBId
  ) {
    nodes.push(fromLinkAndNode.nodeA);
  } else if (
    fromLinkAndNode.nodeBId === toLinkAndNode.nodeAId ||
    fromLinkAndNode.nodeBId === toLinkAndNode.nodeBId
  ) {
    nodes.push(fromLinkAndNode.nodeB);
  } else {
    const graph = createGraph(navMesh);

    const routePaths = [
      graph.path(String(fromLinkAndNode.nodeAId), String(toLinkAndNode.nodeAId)),
      graph.path(String(fromLinkAndNode.nodeAId), String(toLinkAndNode.nodeBId)),
      graph.path(String(fromLinkAndNode.nodeBId), String(toLinkAndNode.nodeAId)),
      graph.path(String(fromLinkAndNode.nodeBId), String(toLinkAndNode.nodeBId)),
    ]
      .filter(result => !!result)
      .map(result => (Array.isArray(result) ? result : result.path));

    const bestPath = routePaths
      .map(path => weightedPath(path, fromPosition, navMesh))
      .sort(({ weight: a }, { weight: b }) => a - b)
      .at(0);

    if (!bestPath) {
      // no navigable path... probably due to unnavigable links or gaps in the navmesh
      return;
    }

    bestPath.path.forEach(n => {
      const node: NavmeshNode | undefined = nodesById[n];
      if (node) {
        nodes.push(new Vector3(node.pos[0], node.pos[1], node.pos[2]));
      }
    });
  }

  const lastLinkIsUnnavigable = !!navMesh.links?.find(
    l => toLinkAndNode.link === l.id && l.unnavigable,
  );
  const path = lastLinkIsUnnavigable
    ? [fromPosition, ...nodes]
    : [fromPosition, ...nodes, toPosition];

  // Avoid doubling back at start or end
  if (path.length > 2 && pointIsBetweenTwoOthers(path[0], [path[1], path[2]])) {
    path.splice(1, 1);
  }
  if (
    path.length > 2 &&
    pointIsBetweenTwoOthers(path[path.length - 1], [path[path.length - 2], path[path.length - 3]])
  ) {
    path.splice(-2, 1);
  }

  // Avoid zero-length sections at start or end
  if (path.length > 1 && path[0].equals(path[1])) {
    path.splice(0, 1);
  }
  if (path.length > 1 && path[path.length - 2].equals(path[path.length - 1])) {
    path.splice(-1, 1);
  }

  // Less than two points is not a path that can be navigated
  if (path.length < 2) return;

  return path;
};

export const findNavmeshPathToNearest = (
  navMesh: FullOrPartialNavmesh,
  toPositions: Vector3[],
  fromPosition?: Vector3 | undefined,
) => {
  if (!fromPosition || !toPositions.length) {
    return;
  }
  const atDestination = toPositions.find(toPosition => toPosition.distanceTo(fromPosition) < 1);

  if (atDestination) {
    return {
      path: [fromPosition, atDestination],
      length: fromPosition.distanceTo(atDestination),
    };
  }

  return toPositions
    .map(toPosition => findNavmeshPath(navMesh, toPosition, fromPosition))
    .filter((path): path is Vector3[] => !!path && path.length > 1)
    .map(path => ({
      path,
      length: distanceViaPoints(path),
    }))
    .filter((x): x is { path: Vector3[]; length: number } => x.length !== undefined)
    .sort((a, b) => a.length - b.length)
    .at(0);
};

const levelIdCache = new Map<string, number | null>();
export const levelIdOfPoint = (position: Vector3 | undefined, navMesh: FullOrPartialNavmesh) => {
  if (!position) return undefined;

  const key = `${navMesh.clientId}/${navMesh.projectId}/${navMesh.updatedAt}/${Math.round(
    position.x,
  )}/${Math.round(position.y)}/${Math.round(position.z)}`;
  const cached = levelIdCache.get(key);
  if (cached !== undefined) return cached ?? undefined;

  const nearestLink = findLinkAndNodes(position, navMesh);
  const nearestLinkLevel = navMesh.links?.find(l => l.id === nearestLink.link)?.level;
  if (nearestLinkLevel === undefined) {
    levelIdCache.set(key, null);
    releaseVector3(nearestLink.nodeA, nearestLink.nodeB);
    return undefined;
  }

  const distanceToLink = pld(position, nearestLink.nodeA, nearestLink.nodeB);
  releaseVector3(nearestLink.nodeA, nearestLink.nodeB);

  if (distanceToLink > 8) {
    levelIdCache.set(key, null);
    return undefined;
  }

  levelIdCache.set(key, nearestLinkLevel);

  return nearestLinkLevel;
};
