import { AddBox, AddLink, AutoFixHigh, Brush, Close, ControlCamera, DeviceThermostat, Info, Insights, Link, PanTool, PhotoFilter, Save, SettingsBackupRestore, Share, ShopTwo, Work, WorkOutline } from "@mui/icons-material";
import { Badge, Divider, Fab, Grid, SpeedDial, SpeedDialAction, SpeedDialIcon, Tooltip, Typography } from "@mui/material";
import { useMemo } from "react";
import { EditToolsOverlay } from "../overlays/EditToolsOverlay";
import { useEditingData } from "./useEditingData";

export type ToolType = 'INFO' | 'MOVE' | 'LOCATOR' | 'GATEWAY' | 'SENSOR' | 'LINK' | 'NODE' | 'SAVE' | 'REVERT' | 'UPLOAD' | 'AUTO_PLACE' | 'AUTO_LEVELS' | 'MULTISELECT';

export type Tool = [icon: JSX.Element, type: ToolType, label: string, disabled?: boolean];

export type ToolGroup = {
  groupLabel: string;
  groupIcon?: JSX.Element;
  tools: Tool[];
}

export const makeTools: (assetSelected: boolean) => ToolGroup[] = (assetSelected: boolean) => [
  {
    groupLabel: 'Selection tools',
    tools: [
      [<Info key={0} />, ('INFO' as const), `Info tool`],
      [<ControlCamera key={1} />, ('MOVE' as const), 'Move asset', !assetSelected],
      [<Brush key={7} />, ('MULTISELECT' as const), 'Select multiple navmesh links'],
    ]
  },
  {
    groupLabel: 'Placement tools',
    tools: [
      [<Work key={2} />, ('LOCATOR' as const), `Place locator`],
      [<WorkOutline key={3} />, ('GATEWAY' as const), `Place gateway`],
      [<DeviceThermostat key={4} />, ('SENSOR' as const), `Place sensor`],
    ]
  },
  {
    groupLabel: 'Navmesh tools',
    tools: [
      [<Link key={5} />, ('LINK' as const), 'Edit navmesh link'],
      [<Share key={6} />, ('NODE' as const), 'Edit navmesh node'],
    ]
  },
  {
    groupLabel: 'Wizard tools',
    groupIcon: <AutoFixHigh />,
    tools: [
      [<ShopTwo key={8} />, ('AUTO_PLACE' as const), 'Auto place locators'],
      [<Insights key={8} sx={{ rotate: '45deg' }} />, ('AUTO_LEVELS' as const), 'Auto assign levels'],
    ]
  }
];

export const EditTools = () => {
  const { tool: currentTool, currentEdit, setTool, locatedAssetEdits, portableAssetEdits, navmeshLinkEdits, navmeshNodeEdits, navmeshLevelEdits, servieConfigEdits, microfenceEdits, microfencePairEdits } = useEditingData();

  const toolGroups = useMemo(() => makeTools(!!currentEdit), [currentEdit]);

  const changeCount = useMemo(
    () => (
      Object.values(locatedAssetEdits).filter(la => !!la).length +
      Object.values(portableAssetEdits).filter(pa => !!pa).length +
      Object.values(navmeshLinkEdits).filter(nl => !!nl).length +
      Object.values(navmeshNodeEdits).filter(nn => !!nn).length +
      Object.values(navmeshLevelEdits).filter(lv => !!lv).length +
      Object.values(servieConfigEdits).filter(sc => !!sc).length +
      Object.values(microfenceEdits).filter(m => !!m).length +
      Object.values(microfencePairEdits).filter(mp => !!mp).length
    ),
    [locatedAssetEdits, portableAssetEdits, navmeshLinkEdits, navmeshNodeEdits, navmeshLevelEdits, servieConfigEdits, microfenceEdits, microfencePairEdits]
  );

  return (
    <EditToolsOverlay>
      <Grid item alignSelf='center'>
        <Tooltip title={'Save changes'} placement="bottom-start" slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -8],
                },
              },
            ],
          },
        }}>
          <Badge
            color="secondary"
            badgeContent={changeCount}
            max={99}
            overlap="circular"
            slotProps={{ badge: { style: { zIndex: 1051 } } }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
          >
            <Fab
              color={currentTool === 'SAVE' ? 'primary' : undefined}
              size="small"
              onClick={() => setTool('SAVE')}
              disabled={changeCount === 0}
            >
              <Save />
            </Fab>
          </Badge>
        </Tooltip>
      </Grid>
      <Grid item alignSelf='center'>
        <Tooltip title={'Revert all changes'} placement="bottom-start" slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -8],
                },
              },
            ],
          },
        }}>
          <span>
            <Fab
              color={currentTool === 'REVERT' ? 'primary' : undefined}
              size="small"
              onClick={() => setTool('REVERT')}
              disabled={changeCount === 0}
            >
              <SettingsBackupRestore />
            </Fab>
          </span>
        </Tooltip>
      </Grid>
      <Divider variant="middle" />
      <Typography variant="caption" key="tools" sx={{ alignSelf: 'center' }}>
        Tools
      </Typography>
      {toolGroups.map(({ groupIcon, groupLabel, tools }) => (
        <Tooltip key={groupLabel} title={groupLabel} placement="bottom-start" slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -14],
                },
              },
            ],
          },
        }}>
          <SpeedDial
            key={groupLabel}
            ariaLabel={groupLabel}
            icon={<SpeedDialIcon
              icon={tools.find(([_0, drawType]) => drawType === currentTool)?.[0] ?? groupIcon ?? tools[0][0]}
              openIcon={<Close />}
            />}
            FabProps={{
              color: tools.find(([_0, drawType]) => drawType === currentTool) ? 'primary' : undefined,
              size: "small",
            }}
            direction='right'
            sx={{ position: 'relative', margin: '-7px 0 -5px 3.6px' }}
          >
            {tools.map(([icon, drawType, tooltip, disabled]) => (
              <SpeedDialAction
                key={drawType}
                icon={icon}
                tooltipTitle={tooltip}
                onClick={() => setTool(drawType)}
                FabProps={{
                  color: currentTool === drawType ? 'success' : undefined,
                  disabled
                }}
              />
            ))}
          </SpeedDial>
        </Tooltip>
      ))}
    </EditToolsOverlay>
  )
}