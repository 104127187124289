import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { applyClientProjectHack } from '../auth/clientProjectHack';
import { CID_PID_PAIRS } from '../store/user';
import { HEIGHT as overlayHeight } from '../util/HorizontalOverlay/HorizontalOverlay'
import { Sidebar } from '../util/Sidebar/Sidebar';
import { useMobile } from '../util/useMobile';
import { BottomHorizontalOverlay } from './BottomHorizontalOverlay';
import { ProjectView } from './ProjectView';
import { AccountSidebar } from './sidebars/AccountSidebar';
import { GlobalSidebar } from './sidebars/GlobalSidebar';
import { ProjectsSidebar } from './sidebars/ProjectsSidebar';
import { ReportsSidebar } from './sidebars/ReportsSidebar';
import { Secondary, SidebarInteractionProvider, useSidebarInteraction } from './sidebars/useSidebarInteraction';

const getSecondarySidebar = (visible: Secondary) => {
  if (visible === Secondary.ACCOUNT) return <Suspense><AccountSidebar /></Suspense>;
  if (visible === Secondary.REPORTS) return <ReportsSidebar />;
  if (visible === Secondary.PROJECTS) return <ProjectsSidebar />;

  return undefined;
};

const GlobalInner = () => {
  const projects = applyClientProjectHack(useAtomValue(CID_PID_PAIRS));
  const isMobile = useMobile();
  const { secondary, showSecondary } = useSidebarInteraction();

  return (
      <Sidebar
        clickedAway={() => {
          showSecondary(Secondary.NONE);
        }}
        main={    
          <div style={{overflow:'hidden', marginTop: isMobile ? `${overlayHeight}px` : 0}}>
            <Stack p={2} direction='column' spacing={2} height={`calc(100vh - ${overlayHeight}px)`} sx={{overflowY:'auto'}}>
              {projects.map(({clientId, projectId}) => (
                <ProjectView key={`${clientId}/${projectId}`} clientId={clientId} projectId={projectId} />
              ))}
            </Stack>
            <BottomHorizontalOverlay/>
          </div>    
        }
        sidebar={<GlobalSidebar/>}
        secondarySidebar={getSecondarySidebar(secondary)}
      />
  );
};

export const GlobalView = () => (
  <SidebarInteractionProvider>
    <GlobalInner />
  </SidebarInteractionProvider>
)
