import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { TRIGGERS_URL } from '../../../store/url';
import { useAsyncTask } from '../../../util/AsyncTask';
import { MicrofencePair } from '../../api/MicrofencePair.validator';
import { DeleteAssetById } from './useEditingData';

const isFreshDeletion = (asset: MicrofencePair | DeleteAssetById) =>
  'delete' in asset && asset.id.includes('fresh');

export const useSaveMicrofencePairs = (assets: (MicrofencePair | DeleteAssetById)[]) => {
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const httpBaseUrl = useAtomValue(TRIGGERS_URL);

  const saveMicrofencePairs = useCallback(async () => {
    return Promise.all(
      assets
        .filter(m => !isFreshDeletion(m))
        .map(async microfence => {
          const { id, clientId, projectId, createdAt, updatedAt, ...body } = {
            createdAt: undefined,
            updatedAt: undefined,
            ...microfence,
          };
          if ('delete' in microfence) {
            return (
              await axios.delete<MicrofencePair>(
                `${httpBaseUrl}/${clientId}/${projectId}/microfence-pair/${id}`,
                authedConfig,
              )
            ).data;
          } else if (microfence.id.includes('fresh')) {
            return (
              await axios.post<MicrofencePair>(
                `${httpBaseUrl}/${clientId}/${projectId}/microfence-pair`,
                body,
                authedConfig,
              )
            ).data;
          } else {
            return (
              await axios.patch<MicrofencePair>(
                `${httpBaseUrl}/${clientId}/${projectId}/microfence-pair/${id}`,
                body,
                authedConfig,
              )
            ).data;
          }
        }),
    );
  }, [httpBaseUrl, authedConfig, assets]);

  return useAsyncTask<unknown, typeof saveMicrofencePairs>(saveMicrofencePairs);
};
