import { LoadingButton } from '@mui/lab';
import { Alert, Container, Fade, Grid, Paper, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { browserSupportsWebAuthn } from '@simplewebauthn/browser';
import { AxiosError } from 'axios';
import { useAtom, useAtomValue } from 'jotai';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Copyright from '../Components/Global/Copyright';
import { IS_SIGNED_IN, TIMED_OUT } from '../store/auth';
import { AUTHN_URL } from '../store/url';
import { useLoginCallback } from './useLogin';
import { useOidcEnabled } from './useOidcEnabled';
import { usePasskeyAuthenticate } from './usePasskeyAuthenticate';
import { useRequestRecoveryEmail } from './useRequestRecoveryEmail';

type Mode = 'initial' | 'checkOidc' | 'password' | 'help'

export const SignIn = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [mode, setMode] = useState<Mode>('initial');
  const passwordField = useRef<HTMLDivElement>(null);
  const oidcForm = useRef<HTMLFormElement>(null);
  const signinButtonEnabled = !!(username.trim() && password.trim());

  const canUsePasskeys = browserSupportsWebAuthn();

  const isSignedIn = useAtomValue(IS_SIGNED_IN);
  const [lastSessionTimedOut, setTimedOut] = useAtom(TIMED_OUT);
  const authnUrl = useAtomValue(AUTHN_URL);

  const oidcSubmitUrl = `${authnUrl}/open/oidc/login`;

  const navigate = useNavigate();

  const {
    execute: checkOidcEnabled,
    loading: checkOidcLoading,
    error: checkOidcError,
    data: oidc
  } = useOidcEnabled(username);

  const canUseOidc = oidc?.canUseOidc;

  const {
    execute: login,
    loading: loginLoading,
    error: loginError,
  } = useLoginCallback(username, password);
  const {
    execute: authenticatePasskey,
    loading: passkeyLoading,
    error: passkeyError,
  } = usePasskeyAuthenticate();
  const {
    execute: requestRecovery,
    loading: recoveryLoading,
    data: recoverySent,
    error: recoveryError,
  } = useRequestRecoveryEmail(username);

  const recoveryButtonEnabled = !recoverySent && !!username.trim();

  const checkEnteredUsername = useCallback(() => {
    setMode('checkOidc')
    checkOidcEnabled();
  }, [setMode, checkOidcEnabled]);

  useEffect(() => {
    if (mode !== 'checkOidc' || canUseOidc === undefined) return;

    if (canUseOidc) {
      oidcForm.current?.submit()
    } else {
      setMode('password')
    }
  }, [mode, canUseOidc])

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>, mode: Mode) => {
      if (e.code !== 'Enter') return false;

      if (mode === 'initial') {
        checkEnteredUsername();
      } else if (mode === 'password' && signinButtonEnabled) {
        login();
      } else if (mode === 'help' && recoveryButtonEnabled) {
        requestRecovery();
      }
    },
    [signinButtonEnabled, recoveryButtonEnabled, checkEnteredUsername, login, requestRecovery],
  );

  useEffect(() => {
    if (isSignedIn) {
      setTimedOut(false);
      navigate('/project-selection');
    }
  }, [isSignedIn, navigate, setTimedOut]);

  useEffect(() => {
    if (mode === 'password') {
      passwordField.current?.focus();
    }
  }, [mode]);

  const usernameError = !!(mode === 'password'
    ? loginError
    : mode === 'help'
      ? recoveryError
      : undefined);

  const recoveryErrorMessage = useMemo(() => {
    if (!recoveryError) return undefined;
    
    const innerError = !!Object(recoveryError).response?.data?.message && String(Object(recoveryError).response.data.message);
    if (innerError && innerError.includes('Single sign-on')) return innerError;
    
    return String(Object(recoveryError).message);
    
  }, [recoveryError])

  return (
    <Box component='div'
      sx={{
        width: '100vw',
        height: '100vh',
        background: 'linear-gradient(90.26deg, #4CB8C4 0.14%, #3CD3AD 99.83%)',
      }}
    >
      <Grid container direction="column" justifyContent="center" height="100%">
        <Container component="main" maxWidth="xs">
          <Stack direction="column" spacing={2}>
            <img src='/Logo_large.png' alt="Logo Geomoby" width="100%" />
            {lastSessionTimedOut && (
              <Alert color="warning" icon={false}>
                Your session timed out, please log in again.
              </Alert>
            )}
            <Paper elevation={5}>
              <Box component="div" p={2} style={{ display: 'flex', flexDirection: 'column', minHeight: '20em' }}>
                <Typography variant="h6" textAlign="center">
                  {mode === 'help' ? 'Recover your account' : 'Sign in to your platform'}
                </Typography>
                <form style={{height: 0, visibility: 'hidden'}} ref={oidcForm} className={'sign-in-form'} noValidate autoComplete="off" action={oidcSubmitUrl} method="POST">
                  <input type='hidden' name='username' value={username} />
                  <input type='hidden' name='origin' value={window.location.origin} />
                </form>
                <form className={'sign-in-form'} noValidate autoComplete="off"><>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    error={usernameError}
                    id="username"
                    type="email"
                    name="username"
                    label="Username"
                    placeholder="demo@geomoby.com"
                    autoFocus
                    disabled={mode !== 'initial' && mode !== 'help'}
                    onChange={e => setUsername(e.target.value)}
                    onKeyPress={e => handleKeyPress(e, mode)}
                  />

                  <Fade in={mode === 'password'}>
                    <TextField
                      inputRef={passwordField}
                      sx={{
                        height: mode === 'password' ? undefined : 0,
                        margin: mode === 'password' ? undefined : -0.5,
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      error={!!loginError}
                      id="password"
                      name="password"
                      type="password"
                      label="Password"
                      placeholder="Password"
                      margin="normal"
                      onChange={e => setPassword(e.target.value)}
                      onKeyPress={e => handleKeyPress(e, 'password')}
                    />
                  </Fade>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    height="100%"
                    mt={mode === 'password' ? 1 : -2.5}
                    ml={0}
                    sx={{ maxWidth: '100%' }}
                    spacing={2}
                  >
                    <Button
                      sx={{
                        display: mode === 'initial' || mode === 'checkOidc' ? undefined : 'none',
                        visibility: canUsePasskeys && mode !== 'checkOidc' ? 'visible' : 'hidden',
                      }}
                      variant="text"
                      type='button'
                      disabled={!canUsePasskeys || mode === 'checkOidc'}
                      onClick={authenticatePasskey}
                    >
                      Sign in with passkey
                    </Button>
                    <LoadingButton
                      sx={{ display: mode === 'initial' || mode === 'checkOidc' ? undefined : 'none' }}
                      variant="contained"
                      size="large"
                      type='button'
                      onClick={checkEnteredUsername}
                      loading={checkOidcLoading}
                      disabled={!/.+@.+\..+/.test(username) || (mode === 'checkOidc' && !checkOidcLoading) }
                    >
                      Continue
                    </LoadingButton>
                    <LoadingButton
                      sx={{ display: mode === 'password' ? undefined : 'none', flexGrow: 2 }}
                      variant="contained"
                      loading={loginLoading}
                      size="large"
                      color="secondary"
                      onClick={login}
                      type='button'
                      disabled={!signinButtonEnabled}
                    >
                      Sign In
                    </LoadingButton>
                    <LoadingButton
                      sx={{ display: mode === 'help' ? undefined : 'none', flexGrow: 2 }}
                      variant="contained"
                      loading={recoveryLoading}
                      size="large"
                      color="secondary"
                      onClick={requestRecovery}
                      type='button'
                      disabled={!recoveryButtonEnabled}
                    >
                      {recoverySent ? 'Submitted' : 'Submit'}
                    </LoadingButton>
                  </Grid>
                  {mode === 'initial' && passkeyError && (
                    <Typography variant="caption" color="red" component="p">
                      {Object(passkeyError).message}
                    </Typography>
                  )}
                  {mode === 'checkOidc' && checkOidcError && (
                    <Typography variant="caption" color="red" component="p">
                      {Object(checkOidcError).message}
                    </Typography>
                  )}
                  {mode === 'help' && recoveryErrorMessage && (
                    <Typography variant="caption" color="red" component="p">
                      {recoveryErrorMessage}
                    </Typography>
                  )}
                </></form>
                {mode === 'help' && recoverySent && (
                  <Typography>
                    You will receive an email with instructions if your email address is registered.
                  </Typography>
                )}
                <div style={{ flexGrow: 1 }} />
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  height="100%"
                  mt={1}
                  ml={0}
                  sx={{ maxWidth: '100%' }}
                  spacing={2}
                >
                  <Button
                    sx={{ display: mode === 'help' ? 'none' : undefined }}
                    variant="text"
                    size="small"
                    onClick={() => setMode('help')}
                  >
                    Get help signing in
                  </Button>
                  <Button
                    sx={{ display: mode === 'initial' ? 'none' : undefined }}
                    variant="text"
                    size="small"
                    onClick={() => setMode('initial')}
                  >
                    Back
                  </Button>
                </Grid>
              </Box>
            </Paper>
          </Stack>
          <Box component='div' mt={6}>
            <Copyright />
          </Box>
        </Container>
      </Grid>
    </Box>
  );
}
