import NearbyErrorIcon from '@mui/icons-material/NearbyError';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Grid, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { AssetState } from '../../../util/Events/Messages';
import { AssetEms } from '../../../util/Events/schema';
import { unsafeEntries } from '../../../util/typeUtils';
import { useSecondsAgo } from '../../../util/useSecondsAgo';
import { useMapData } from '../MapData/useMapData';
import { HeartbeatIconVerbose } from '../WorldOverlay/HeartbeatIcon/HeartbeatIcon';
import { BatteryInfo, CommonItem, UpdateInfo } from './CommonInfo';
import { beaconsNearAsset } from './LocatorInfo';
import { NearbyBeaconsReport } from './Report/NearbyBeacons';

const camelCaseToTitleCase = (camelCase: string) => camelCase
  .replace(/([A-Z])/g, (match) => ` ${match}`)
  .replace(/^./, (match) => match.toUpperCase())
  .trim();

const emsUnits = [
  ['Celcius', '°C'],
  ['Percent', '%'],
  ['MetresPerSecond', 'm/s'],
  ['Hectopascals', 'hPa'],
  ['Metres', 'm'],
  ['Ppm', 'ppm'],
  ['MicrogramsPerCubicMetre', 'µg/m³'],
]
const emsMeasurementToRow = (label: keyof AssetEms['measurements'], value: number) => {
  const [unitText, unit] = emsUnits.find(([u]) => label.endsWith(u)) ?? ['', '']
  return {
    name: camelCaseToTitleCase(label.replace(unitText, '')).replace('_', '.').replace(/^P M/, 'PM').replace("Amonia", "Ammonia"),
    value,
    unit
  }
}

export const SensorInfo = ({ id }: { id: string }) => {
  const { data: mapData, getNow } = useMapData();

  const asset = mapData?.liveData?.state.assets.get(id);

  const secondsSinceLastUpdate = useSecondsAgo({
    from: asset?.lastUpdate,
    getNow
  });

  if (!asset) {
    return <></>;
  }

  return (
    <>
      {asset.type === 'ems' && (<EmsInfo breach={!!asset.lastEms?.breach} measurements={asset.lastEms?.measurements} secondsAgo={secondsSinceLastUpdate} />)}
      {asset.type === 'mdt' && (<MdtInfo batteryPercent={asset.lastBattery?.percent} extensions={asset.lastExtensions?.extensionsMm} secondsAgo={secondsSinceLastUpdate} />)}
      {asset.type === 'rfiReader' && (<RfiInfo asset={asset} />)}
    </>
  );
};

export const RfiInfo = ({ asset }: { asset: AssetState }) => {
  const { data: mapData, getNow } = useMapData();

  const beaconsNearby = beaconsNearAsset({ asset, liveState: mapData?.liveData?.state, now: getNow() });
  return (<>
    <UpdateInfo lastUpdate={asset.lastUpdate} />
    {beaconsNearby?.length && <NearbyBeaconsReport nearby={beaconsNearby} />}
  </>)

}

export const MdtInfo = ({ batteryPercent, extensions, secondsAgo }: { batteryPercent?: number, extensions?: number[], secondsAgo?: number }) => {
  return (<>
    <BatteryInfo batteryPercentage={batteryPercent} />
    <CommonItem
      icon={<HeartbeatIconVerbose secondsSinceLast={secondsAgo} />}
      title="Last update:"
    >
      <Typography>
        {(batteryPercent || extensions) ? `${secondsAgo} seconds ago` : 'unknown'}
      </Typography>
    </CommonItem>
    {extensions && <DataTable
      rows={
        extensions.map((value, index) => ({
          name: `Anchor ${index + 1}`,
          value,
          unit: 'mm'
        }))
      } />}
  </>)
}

export const EmsInfo = ({ breach, measurements, secondsAgo }: { breach: boolean, measurements?: AssetEms["measurements"], secondsAgo: number | undefined }) => {
  return (
    <>
      {breach && (<CommonItem
        icon={<Box component="div" color="warning.light">
          <WarningIcon />
        </Box>}
        title="Breach detected"
      >
      </CommonItem>)}
      <CommonItem
        icon={<HeartbeatIconVerbose secondsSinceLast={secondsAgo} />}
        title="Last update:"
      >
        <Typography>
          {measurements ? `${secondsAgo} seconds ago` : 'unknown'}
        </Typography>
      </CommonItem>
      {measurements && <DataTable rows={unsafeEntries(measurements).flatMap((entry) => {
        if (!entry) return [];
        const [name, value] = entry;
        if (value === undefined) return [];
        return emsMeasurementToRow(name, value)
      })}
      />}
    </>
  );
};

export const DataTable = ({ rows }: { rows: { name: string, value: number, unit?: string }[] }) => (
  <TableContainer component={Paper} sx={{ paddingLeft: 2, paddingTop: 2 }}>
    <Table size="small" aria-label="a dense table">
      <TableBody>
        {rows.map((row) => (
          <TableRow
            key={row.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.name}
            </TableCell>
            <TableCell align="right">
              {row.value} {row.unit ?? 'C'}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)
