import { Line, Detailed } from '@react-three/drei';
import { extend, ThreeEvent } from '@react-three/fiber';
import { useMemo } from 'react';
import { Shape, Vector2, Vector3 } from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { ProfiledContourGeometry } from '../../util/ProfiledContourGeometry';
import { TunnelGeometry } from '../../util/TunnelGeometry';
import { NavMesh as NavMeshProps } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { MapPageArgs } from '../MapPage/MapPage';
import { linesFromNavmesh } from './utils';

extend({ EffectComposer, UnrealBloomPass });

export const NavMesh = ({
  navMesh,
  position,
  mode,
  onLineClick,
  onDoubleClick,
}: NavMeshProps & {
  mode?: MapPageArgs['mode'],
  onLineClick?: (e: ThreeEvent<MouseEvent>) => void,
  onDoubleClick?: (e: ThreeEvent<MouseEvent>) => void
}) => {
  const {
    settings: { generateTunnel, cinematicMode },
    level,
  } = useMapInteraction();

  // Random key that is regenerated whenever navmesh is changed or refreshed
  const meshKey = Math.random();

  const levelNavemsh = useMemo(() => ({
    ...navMesh,
    links: navMesh.links.filter(l => level === undefined || l.level === level)
  }), [navMesh, level])

  // Map the vector positions to the normalized navmesh link data
  const denormalizedLinks: [a: Vector3, b: Vector3, unnavigable: boolean][] = useMemo(() => {
    const nodes = Object.fromEntries(
      levelNavemsh.nodes.map((node): [number, Vector3] => [node.id, node.pos]),
    );
    return levelNavemsh.links.map(({ a, b, unnavigable }) => [nodes[a], nodes[b], !!unnavigable]);
  }, [levelNavemsh]);

  // Create an array of lines from the navmesh,
  // where new lines are created whenever the navmesh forks.
  const navMeshLines = useMemo(() => {
    return linesFromNavmesh(levelNavemsh);
  }, [levelNavemsh]);

  // Generate tunnel extrusions
  const tunnelGeometries = useMemo(() => {
    return navMeshLines.map(line => TunnelGeometry(line));
  }, [navMeshLines]);

  // Generate tunnel surface extrusions
  const surfaceGeometries = useMemo(() => {
    const shape = new Shape([
      new Vector2(-2.5, -0.1),
      new Vector2(-2.5, 0),
      new Vector2(2.5, 0),
      new Vector2(2.5, -0.1),
    ]);

    return navMeshLines.map(line => ProfiledContourGeometry(shape, line, false, true));
  }, [navMeshLines]);

  return (
    <group position={position}>
      {!generateTunnel && mode !== 'edit' &&
        denormalizedLinks.map(([a, b], i) => <Line key={i} points={[a, b]} color={'lightblue'} onDoubleClick={onDoubleClick} />)}
      {mode === 'edit' &&
        denormalizedLinks.map(([a, b, unnavigable], i) => <Line key={i} points={[a, b]} color={unnavigable ? 'red' : 'lightblue'} transparent opacity={generateTunnel ? 0.25 : 1} lineWidth={10} onClick={onLineClick} />)
      }
      {generateTunnel && !cinematicMode && (
        <>
          {tunnelGeometries.map((tunnel, i) => {
            const line = navMeshLines[i];
            const x = line.reduce((acc, cur) => acc + cur.x, 0) / line.length;
            const y = line.reduce((acc, cur) => acc + cur.y, 0) / line.length;
            const z = line.reduce((acc, cur) => acc + cur.z, 0) / line.length;
            const parentPos = new Vector3(x, y, z);
            const childPos = new Vector3(-x, -y, -z);

            return (
              <Detailed distances={[0, 1_000]} position={parentPos} key={`${level ?? 'all'}_tunnel__${i}_${meshKey}`}>
                <mesh args={[tunnel]} receiveShadow={false} onDoubleClick={onDoubleClick} position={childPos}>
                  <meshStandardMaterial color="#fff" opacity={0.1} transparent wireframe />
                </mesh>
                {(line?.length > 1 && !line[0].equals(line[1])) ? (<Line key={i} position={childPos} points={line} color={"#A9927D"} opacity={1} lineWidth={2} onDoubleClick={onDoubleClick} />) : <mesh />}
              </Detailed>
            )
          })}
          {surfaceGeometries.map((tunnel, i) => (
            <mesh key={`${level ?? 'all'}_surface_${i}_${meshKey}`} args={[tunnel]} receiveShadow>
              <meshStandardMaterial color={'#A9927D'} />
            </mesh>
          ))}
        </>
      )}
      {generateTunnel && cinematicMode && (
        <>
          {tunnelGeometries.map((tunnel, i) => {
            const line = navMeshLines[i];
            const x = line.reduce((acc, cur) => acc + cur.x, 0) / line.length;
            const y = line.reduce((acc, cur) => acc + cur.y, 0) / line.length;
            const z = line.reduce((acc, cur) => acc + cur.z, 0) / line.length;
            const parentPos = new Vector3(x, y, z);
            const childPos = new Vector3(-x, -y, -z);

            return (
              <Detailed distances={[0, 1_000]} position={parentPos} key={`${level ?? 'all'}_tunnel_cinematic_${i}_${meshKey}`}>
                <mesh receiveShadow={false} args={[tunnel]} onDoubleClick={onDoubleClick} position={childPos}>
                  <meshStandardMaterial color="#aaf" opacity={0.3} transparent wireframe />
                </mesh>
                {(line?.length > 1 && !line[0].equals(line[1])) ? (<Line key={'c' + i} position={childPos} points={line} color={"#7978B2"} opacity={1} lineWidth={2} onDoubleClick={onDoubleClick} />) : <mesh />}
              </Detailed>
            )
          })}
          {surfaceGeometries.map((tunnel, i) => (
            <mesh key={`${level ?? 'all'}_surface_cinematic_${i}_${meshKey}`} args={[tunnel]} receiveShadow>
              <meshStandardMaterial color={'#55f'} opacity={0.1} transparent />
            </mesh>
          ))}
        </>
      )}
    </group>
  );
};
