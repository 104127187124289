import { Cancel, ClearAll } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Box, Plane } from '@react-three/drei';
import { ThreeEvent } from '@react-three/fiber';
import React, { ReactNode, useCallback, useEffect, useMemo, useReducer } from 'react';
import { Vector3 } from 'three';
import { EMS_ENABLED } from '../../../../config';
import { AssetState } from '../../../../util/Events/Messages';
import { stringifyIdRecord } from '../../../../util/stringUtils';
import { levelIdOfPoint } from '../../../util/findNavmeshPath';
import { isActive } from '../../../util/portableAssetUtils';
import { Camera } from '../../Camera/Camera';
import { allPortableAssetsFromLiveData, locatedSensorsFromLiveData, gatewaysFromLiveData, locatorsFromLiveData, portbaleAssetsFromLiveData, useMapData } from '../../MapData/useMapData';
import { useMapInteraction } from '../../MapInteraction/useMapInteraction';
import { MapText } from '../../MapText/MapText';
import { NavMesh } from '../../NavMesh/NavMesh';
import { WorldOverlay } from '../../WorldOverlay/WorldOverlay';
import { Beacon } from './Beacon/Beacon';
import { GltfObject } from './GltfObject/GltfObject';
import { Locator } from './Locator/Locator';
import { Sensor } from './Sensor/Sensor';

export const Default = () => {
  const { data: map, getNow } = useMapData();
  const {
    settings: { generateTunnel },
    level,
    highlighted,
    lastKnownForHighlighted,
  } = useMapInteraction();

  if (!map) {
    console.error('Rendering map without any data');
  }

  const isVisible = useCallback((position: Vector3 | undefined) => {
    if (!map?.navMesh) return false;
    if (!position) return false;
    if (level === undefined) return true;

    return levelIdOfPoint(position, map.navMesh) === level;
  }, [map?.navMesh, level]);

  const beacons = map?.liveData ? portbaleAssetsFromLiveData(map.liveData) : [];
  const locators = map?.liveData ? locatorsFromLiveData(map.liveData, {includeSurface: false}).filter(l => isVisible(l.positionVector)) : [];
  const gateways = map?.liveData ? gatewaysFromLiveData(map.liveData).filter(g => isVisible(g.positionVector)) : [];
  const sensors = map?.liveData ? locatedSensorsFromLiveData(map.liveData).filter(s => isVisible(s.positionVector)) : [];

  const camera = useMemo(() => <Camera />, [])

  const levelForPoint = (point: Vector3) => {
    if (!map?.navMesh) return;

    const levelId = levelIdOfPoint(point, map.navMesh)

    if (levelId === undefined) return;

    return map.navMesh.levels?.find(l => l.id === levelId)
  }

  const [levelLabels, dispatchLevelLabelRequest] = useReducer((state: { pos: Vector3, label: string }[], update: ThreeEvent<MouseEvent> | { remove: Vector3 }) => {
    if ('remove' in update) {
      return state.filter(({ pos }) => !pos.equals(update.remove))
    }
    const level = levelForPoint(update.point);
    if (!level) return state;

    return [...state, { pos: update.point, label: level.name }]
  }, []);

  return (
    <>
      <ambientLight intensity={0.1} />
      <directionalLight position={[3, 5, 7]} intensity={0.5} />
      <directionalLight position={[-3, -5, -7]} intensity={0.1} />
      {camera}
      {map?.objects.map((p): ReactNode => {
        const key = JSON.stringify(p);
        if (p.type === 'cube') {
          return (
            <Box key={key} args={[p.size.x, p.size.y, p.size.z]} position={p.position}>
              <meshStandardMaterial color="#fff" opacity={0.1} transparent wireframe />
            </Box>
          );
        } else if (p.type === 'gltf') {
          return !generateTunnel && <GltfObject key={key} {...p} />;
        } else if (p.type === 'navMesh') {
          return <NavMesh key={key} {...p} onDoubleClick={dispatchLevelLabelRequest} />;
        }
      })}
      {locators.map(locator => (
        <Locator key={`locator${stringifyIdRecord(locator.id)}`} {...locator} />
      ))}
      {gateways.map(gateway => (
        <Locator key={`gateway${stringifyIdRecord(gateway.id)}`} {...gateway} />
      ))}
      {sensors.map(ems => (
        <Sensor key={`ems${stringifyIdRecord(ems.id)}`} {...ems} />
      ))}
      {levelLabels.map(({ pos, label }, i) =>
        <WorldOverlay key={i} position={pos} onDoubleClick={() => dispatchLevelLabelRequest({ remove: pos })}>
          <MapText displayType='note'>
            <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
              <strong style={{ pointerEvents: 'none' }}>Level: {label}</strong>
              <div style={{ cursor: 'pointer', display: 'grid', placeItems: 'center', color: '#aaa' }}>
                <Cancel fontSize='small' onClick={() => dispatchLevelLabelRequest({ remove: pos })} />
              </div>
            </div>
          </MapText>
        </WorldOverlay>
      )}

      {beacons
        .filter((asset) => isActive(asset, getNow()))
        .map(beacon => (
          <Beacon key={stringifyIdRecord(beacon.id)} isVisible={isVisible} {...beacon} />
        ))}

      {map?.liveData && !!lastKnownForHighlighted && allPortableAssetsFromLiveData(map.liveData)
        .filter((asset) => highlighted?.category === 'beacon' && highlighted?.id === stringifyIdRecord(asset.id) && !isActive(asset, getNow()) )
        .map(beacon => (
          <Beacon key={stringifyIdRecord(beacon.id)} isVisible={isVisible} {...beacon} />
        ))}
    </>
  );
};
