import { Button, FormControlLabel, Grid, Switch, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DropdownNumberValues } from '../../../util/FormControl/Dropdown';
import { NavmeshLink, NavmeshNode } from '../../api/Navmesh.validator';
import { useMapData } from '../MapData/useMapData';
import { useEditingData } from './useEditingData';

export const EditInfoLink = ({ link, newLinkTo }: { link: NavmeshLink, newLinkTo?: NavmeshNode, }) => {
  const { data: mapData, cid: clientId, pid: projectId } = useMapData();
  const { setCurrentEdit, dispatchNavmeshLinkEdit, dispatchNavmeshNodeEdit, navmeshLinkEdits, navmeshNodeEdits, applyNavmeshEdits } = useEditingData();
  const levelOptions = useMemo(() => (applyNavmeshEdits(mapData?.navMesh ?? { clientId, projectId })?.levels ?? [])
    .map(({ id, name }) => ({ value: id, label: name })),
    [applyNavmeshEdits, mapData?.navMesh, clientId, projectId]
  );
  const [newUnnavigable, setNewUnnavigable] = useState(!!link.unnavigable);
  const [newLevel, setNewLevel] = useState(link.level);

  const isNew = isNaN(link.id)

  const fromNode = useMemo(() => mapData?.navMesh?.nodes?.find(({ id }) => id === link.a) ?? undefined, [mapData?.navMesh?.nodes, link.a]);
  const toNode = useMemo(() => mapData?.navMesh?.nodes?.find(({ id }) => id === link.b) ?? undefined, [mapData?.navMesh?.nodes, link.b]);
  const isValid = !!fromNode && !!toNode && fromNode.id !== toNode.id;

  const nextNewLinkId = useMemo(() => {
    if (!mapData?.navMesh?.links) return;

    const maxId = Math.max(
      ...mapData.navMesh.links.map(l => l.id),
      ...Object.values(navmeshLinkEdits).flatMap(l => (l && 'id' in l) ? l.id : [])
    );
    return maxId + 1;
  }, [mapData?.navMesh?.links, navmeshLinkEdits]);

  const nextNewNodeId = useMemo(() => {
    if (!mapData?.navMesh?.nodes) return;

    const maxId = Math.max(
      ...mapData.navMesh.nodes.map(n => n.id),
      ...Object.values(navmeshNodeEdits).flatMap(n => (n && 'id' in n) ? n.id : [])
    );
    return maxId + 1;
  }, [mapData?.navMesh?.nodes, navmeshNodeEdits]);

  const newLinkIsValid = !!nextNewLinkId && !!newLinkTo && newLinkTo.id !== link.a;

  const splittingIsValid = isValid && !!nextNewLinkId && !!nextNewNodeId;

  useEffect(() => { // handle link changing while open
    setNewUnnavigable(!!link.unnavigable);
    setNewLevel(link.level);
  }, [link.unnavigable, link.level])

  useEffect(() => { // update current edit when unnavigable changes
    setCurrentEdit(cur => {
      if (cur?.type !== 'link' && cur?.type !== 'new link') {
        return cur;
      }
      return {
        ...cur,
        asset: {
          ...cur.asset,
          unnavigable: newUnnavigable
        }
      }
    });
  }, [setCurrentEdit, newUnnavigable])

  const dispatchEdit = useCallback(() => {
    if (!isValid) throw new Error('UI should not try to dispatch invalid data');

    const update: NavmeshLink & { uuid: undefined } = {
      ...link,
      unnavigable: newUnnavigable || undefined,
      level: newLevel,
      uuid: undefined,
    };
    dispatchNavmeshLinkEdit(update)
    setCurrentEdit(undefined);

  }, [isValid, link, setCurrentEdit, dispatchNavmeshLinkEdit, newUnnavigable, newLevel])

  const dispatchNewLinkEdit = useCallback(() => {
    if (!newLinkTo || !nextNewLinkId) throw new Error('UI should not try to dispatch invalid data');

    const newLink: NavmeshLink & { uuid: undefined } = {
      id: nextNewLinkId,
      a: link.a,
      b: newLinkTo.id,
      unnavigable: newUnnavigable || undefined,
      level: newLevel,
      uuid: undefined,
    };
    dispatchNavmeshLinkEdit(newLink)
    setCurrentEdit(undefined);

  }, [link, setCurrentEdit, dispatchNavmeshLinkEdit, newUnnavigable, newLevel, newLinkTo, nextNewLinkId])

  const dispatchDeletion = useCallback(() => {
    if (!isNaN(link.id)) {
      dispatchNavmeshLinkEdit({ id: link.id, delete: true });
    }
    setCurrentEdit(undefined);
  }, [link.id, dispatchNavmeshLinkEdit, setCurrentEdit])

  const dispatchRevert = useCallback(() => {
    dispatchNavmeshLinkEdit({ id: link.id, revert: true });
    setCurrentEdit(undefined);
  }, [link.id, dispatchNavmeshLinkEdit, setCurrentEdit]);

  const dispatchSplitLinkEdits = useCallback(() => {
    if (!fromNode || !toNode || !nextNewNodeId || !nextNewLinkId) throw new Error('UI should not try to dispatch invalid data');

    const newNode: NavmeshNode & { uuid: undefined } = {
      id: nextNewNodeId,
      pos: [
        (fromNode.pos[0] + toNode.pos[0]) / 2,
        (fromNode.pos[1] + toNode.pos[1]) / 2,
        (fromNode.pos[2] + toNode.pos[2]) / 2,
      ],
      uuid: undefined,
    };
    const newLink: NavmeshLink & { uuid: undefined } = {
      id: nextNewLinkId,
      a: newNode.id,
      b: link.b,
      unnavigable: newUnnavigable || undefined,
      level: newLevel,
      uuid: undefined,
    };
    const update: NavmeshLink & { uuid: undefined } = {
      id: link.id,
      a: link.a,
      b: newNode.id,
      unnavigable: newUnnavigable || undefined,
      level: newLevel,
      uuid: undefined,
    };
    dispatchNavmeshNodeEdit(newNode);
    dispatchNavmeshLinkEdit(newLink);
    dispatchNavmeshLinkEdit(update);
    setCurrentEdit({ type: 'link', asset: update });
  }, [
    fromNode,
    toNode,
    link.a,
    link.b,
    link.id,
    newLevel, newUnnavigable, nextNewLinkId, nextNewNodeId,
    dispatchNavmeshNodeEdit,
    dispatchNavmeshLinkEdit,
    setCurrentEdit
  ]);

  return (<>
    {isNew && (
      <Grid item>
        <Typography fontSize='90%'><em>Choose connecting node on map</em></Typography>
      </Grid>
    )}
    <Grid item maxWidth="100%" container direction="row" spacing={2}>
      <Grid item xs={4}>
        <TextField disabled label="ID" variant="outlined" fullWidth value={!isNaN(link.id) ? link.id : 'NEW'} />
      </Grid>
      <Grid item xs={4}>
        <TextField disabled label="From node" variant="outlined" fullWidth value={link.a} />
      </Grid>
      <Grid item xs={4}>
        <TextField disabled label="To node" variant="outlined" fullWidth value={isNew ? (newLinkTo?.id ?? ' ') : link.b} error={newLinkTo?.id === link.a} />
      </Grid>
    </Grid>

    {levelOptions?.length && <Grid item maxWidth="100% !important">
      <DropdownNumberValues label='Level' selected={newLevel} setSelected={val => setNewLevel(val ? Number(val) : undefined)} items={levelOptions} />
    </Grid>}
    <Grid item maxWidth='100%'>
      <FormControlLabel control={<Switch checked={!newUnnavigable} onChange={e => setNewUnnavigable(!e.target.checked)} />} label="Navigable" />
    </Grid>
    <Grid item container>
      {isNaN(link.id) && <Button disabled={!newLinkIsValid} variant="contained" onClick={dispatchNewLinkEdit}>Create</Button>}
      {!isNaN(link.id) && <Button disabled={!isValid} variant="contained" onClick={dispatchEdit}>Set</Button>}
      <Button variant="outlined" onClick={dispatchRevert}>Undo changes</Button>
      <Button color='warning' variant="outlined" onClick={dispatchDeletion}>Delete</Button>
    </Grid>
    {splittingIsValid && (
      <Grid item>
        <Button onClick={dispatchSplitLinkEdits}>Split link...</Button>
      </Grid>
    )}
  </>);
};

