import { Sensors } from '@mui/icons-material';
import { Button, ButtonGroup, FormControlLabel, Grid, Link, Slider, Switch, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Dropdown } from '../../../util/FormControl/Dropdown';
import { LocatedAsset } from '../../api/LocatedAsset.validator';
import { Microfence } from '../../api/Microfence.validator';
import { MicrofencePair } from '../../api/MicrofencePair.validator';
import { useMapData } from '../MapData/useMapData';
import { DeleteAssetByUuid, useEditingData } from './useEditingData';

export type Axis = 'x' | 'y' | 'z';

const SENSOR_TYPES = [
  { value: 'ems', label: 'Digital Terrain – Environmental Monitoring System' },
  { value: 'mdt', label: 'Mine Design Technologies – Extensometer' },
  { value: 'rfiReader', label: 'RFI – Leaky Feeder Reader' }
];

export const EditInfoLocator = ({ asset }: { asset: LocatedAsset }) => {
  const { tool, setCurrentEdit, dispatchLocatedAssetEdit, setCurrentDeletion, microfenceEdits, microfencePairEdits } = useEditingData();
  const { data: mapData } = useMapData();
  const [newId, setNewId] = useState<string>(asset.id);
  const [newLabel, setNewLabel] = useState<string>(asset.label ?? '');
  const [newSafeZone, setNewSafeZone] = useState<boolean>(asset.safe ?? false)
  const [newType, setNewType] = useState<string>(asset.type);
  const [newSurface, setNewSurface] = useState<boolean>(!!asset.surface)
  const sensorType = SENSOR_TYPES.find(({ value }) => value === asset.type);
  const isValid = useMemo(() => !!newId, [newId]);
  const isFreshGateway = asset.uuid.includes('fresh') && asset.type.includes('gateway');

  const associatedMicrofence = useMemo(() => {
    if (newType !== 'locator' && newType !== 'mqttgateway') return;

    const typeId = newType + 'Id';

    const existing = mapData?.microfences.filter(m => !(microfenceEdits[m.id])) ?? [];
    const editing = Object.values(microfenceEdits).filter((m): m is Microfence => !!m && !('delete' in m));
    return [...existing, ...editing].find(m => m.assetId[typeId] === newId);
  }, [mapData?.microfences, microfenceEdits, newType, newId])

  const associatedMicrofencePairs = useMemo(() => {
    if (newType !== 'locator' && newType !== 'mqttgateway') return [];

    const typeId = newType + 'Id';

    const existing: MicrofencePair[] = mapData?.microfencePairs.filter(m => !(microfencePairEdits[m.id])) ?? [];
    const editing = Object.values(microfencePairEdits).filter((m): m is MicrofencePair => !!m && !('delete' in m));
    return [...existing, ...editing].filter(m => m.upstreamId[typeId] === newId || m.downstreamId[typeId] === newId);
  }, [mapData?.microfencePairs, microfencePairEdits, newType, newId]);

  const associatedMicrofenceOrPairs = associatedMicrofence
    ? [associatedMicrofence, ...associatedMicrofencePairs]
    : associatedMicrofencePairs;

  const updateAssetPosition = (axis: Axis, newValue: number) => setCurrentEdit({
    type: 'located',
    asset: {
      ...asset,
      position: { ...asset.position, [axis]: newValue }
    }
  });

  const PositionAdjuster = ({ axis }: { axis: Axis }) => {
    return (
      <Grid item container direction='row'>
        <Grid item xs={3} sx={{ alignSelf: 'center' }}>
          <Typography>{axis.toUpperCase()}: {asset.position[axis].toFixed(1)}</Typography>
        </Grid>
        <Grid item xs={9}>
          <ButtonGroup variant="contained">
            <Button size='small' title={`Decrease ${axis.toUpperCase()}`} onClick={() => updateAssetPosition(axis, asset.position[axis] - 1)}>{'<<'}</Button>
            <Button size='small' title={`Decrease ${axis.toUpperCase()} slightly`} onClick={() => updateAssetPosition(axis, asset.position[axis] - 0.1)}>{'<'}</Button>
            <Button size='small' title={`Increase ${axis.toUpperCase()} slightly`} onClick={() => updateAssetPosition(axis, asset.position[axis] + 0.1)}>{'>'}</Button>
            <Button size='small' title={`Increase ${axis.toUpperCase()}`} onClick={() => updateAssetPosition(axis, asset.position[axis] + 1)}>{'>>'}</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    )
  };

  useEffect(() => { // handle asset changing while open
    if (asset?.uuid) {
      setNewId(asset.id);
      setNewLabel(asset.label ?? '');
      setNewSafeZone(asset.safe ?? false);
      setNewType(asset.type);
      setNewSurface(!!asset.surface)
    }
  }, [asset.uuid, asset.id, asset.label, asset.safe, asset.type, asset.surface])

  useEffect(() => { // make sure safe zone is unset when type is changed (and not locator nor mqttgateway)
    if (!newSafeZone || newType === 'locator' || newType === 'mqttgateway') return;

    setNewSafeZone(false);
  }, [newType, newSafeZone, setNewSafeZone])

  useEffect(() => { // make sure safe zone is unset when type is changed (and not locator nor mqttgateway)
    if (!newSurface || newType === 'locator' || newType === 'mqttgateway') return;

    setNewSurface(false);
  }, [newType, newSurface, setNewSurface])

  const dispatchEdit = useCallback(() => {
    if (!isValid) throw new Error('UI should not try to dispatch invalid data');

    const update: LocatedAsset = {
      ...asset,
      id: newId,
      label: newLabel || null,
      safe: newSafeZone,
      surface: newSurface,
      type: newType,
    };
    dispatchLocatedAssetEdit(update)
    setCurrentEdit(undefined);

  }, [asset, setCurrentEdit, dispatchLocatedAssetEdit, newId, isValid, newLabel, newSafeZone, newType, newSurface])

  const requestDeletion = useCallback(() => {
    setCurrentDeletion(asset)
  }, [asset, setCurrentDeletion])

  const revertChanges = useCallback(() => {
    dispatchLocatedAssetEdit({ uuid: asset.uuid, revert: true });
    setCurrentEdit(undefined);
  }, [asset.uuid, setCurrentEdit, dispatchLocatedAssetEdit]);

  return (<>
    {sensorType && <Grid item maxWidth="100% !important">
      <TextField disabled label="Integration" variant="outlined" fullWidth value={sensorType.label} required />
    </Grid>}
    {!sensorType && asset.type !== 'locator' && asset.type !== 'gateway' && asset.type !== 'mqttgateway' && <Grid item maxWidth="100% !important">
      <Dropdown label='Integration' selected={newType ?? ''} setSelected={val => setNewType(val || '')} items={SENSOR_TYPES} required />
    </Grid>}
    {tool !== 'MOVE' && (<>
      <Grid item>
        <TextField label="ID" variant="outlined" fullWidth value={newId} onChange={e => setNewId(e.target.value)} required />
      </Grid>
      <Grid item>
        <TextField label="Label" variant="outlined" fullWidth value={newLabel} onChange={e => setNewLabel(e.target.value)} />
      </Grid>
      {isFreshGateway && (
        <Grid item>
          <FormControlLabel control={<Switch checked={newType === 'mqttgateway'} onChange={e => setNewType(e.target.checked ? 'mqttgateway' : 'gateway')} />} label="MQTT Gateway" />
        </Grid>
      )}
      {(asset.type === 'locator' || newType === 'mqttgateway') && (<>
        <Grid item>
          <FormControlLabel control={<Switch checked={newSafeZone} onChange={e => setNewSafeZone(e.target.checked)} />} label="Safe zone" />
        </Grid>
        <Grid item>
          <FormControlLabel control={<Switch checked={newSurface} onChange={e => setNewSurface(e.target.checked)} />} label="Surface" />
        </Grid>
      </>
      )}
      {associatedMicrofenceOrPairs.length && (
        <Grid item container>
          {associatedMicrofenceOrPairs.map((mfence, index) =>
            <Grid key={mfence.id} item sx={{ display: 'flex', gap: '0.5em', alignItems: 'center' }}>
              <Sensors htmlColor='#90EE90aa' fontSize='large' />
              <Link onClick={() => setCurrentEdit({ type: 'microfence', asset: { ...mfence, uuid: undefined } })}>Edit associated microfence {mfence.name ?? index + 1}</Link>
            </Grid>
          )}
        </Grid>
      )}
    </>)}
    {tool === 'MOVE' && (<>
      <Grid item>
        <Typography fontSize='90%'><em>Choose new position on map, or adjust using controls</em></Typography>
      </Grid>
      <PositionAdjuster axis='x' />
      <PositionAdjuster axis='y' />
      <PositionAdjuster axis='z' />
    </>)}
    <Grid item container>
      <Button variant="contained" disabled={!isValid} onClick={dispatchEdit}>Set</Button>
      <Button variant="outlined" onClick={revertChanges}>Undo changes</Button>
      <Button color='warning' variant="outlined" onClick={requestDeletion}>Delete</Button>
    </Grid>
  </>
  );
};
