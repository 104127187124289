import { useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { AUTH_NULLABLE } from '../store/auth';

export const useLogout = () => {
  const setAuth = useSetAtom(AUTH_NULLABLE);

  return useCallback(() => {
    setAuth(null);
  }, [setAuth]);
};
