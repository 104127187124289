import axios from 'axios';
import { useAtomValue } from 'jotai';
import {useCallback, useEffect, useRef, useState} from 'react';
import { AUTHED_REQUEST_CONFIG } from '../store/auth';

export const useAxiosFetch = (url: string, recursion?: {perPage: number; dataKey: string, countKey: string }) => {
    const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
    const [response, setResponse] = useState<{
        data: unknown,
        error: Error | undefined,
        loading: boolean,
        response: { ok: boolean | undefined, status: number | undefined }
    }>({
        data: undefined,
        error: undefined,
        loading: true,
        response: { ok: undefined, status: undefined }
    });
    const authedConfigRef = useRef<{headers: {Authorization: string}}>(authedConfig);

    const useRecursion = !!recursion;
    const {perPage, dataKey, countKey } = recursion ?? {perPage: 0, dataKey:'', countKey:''};

    const getRequest = useCallback(
        async (requestUrl: string) => {
            const dataCollection: unknown[] = [];
            setResponse({
                data: undefined,
                error: undefined,
                loading: true,
                response: { ok: undefined, status: undefined }
            });

            const doRequest = async (urlString: string) => {
                const url = new URL(urlString);
                if (useRecursion) {
                    url.searchParams.set('perPage', perPage.toString());
                }
                const page = Number(url.searchParams.get('page') || '1');
                return (
                await axios.get<unknown>(url.href, authedConfigRef.current)
                    .then(async r => {
                        if (!r || !r.data) {
                            return;
                        }
                        
                        const moreToLoad = useRecursion && Object(r.data)[dataKey].length + perPage * (page - 1) < Object(r.data)[countKey];
                        const data = useRecursion ? Object(r.data)[dataKey] : r.data;
                        if (useRecursion) {
                            dataCollection.push(...data);
                        }
                        if (moreToLoad) {
                            url.searchParams.set('page', (page + 1).toString());
                            await doRequest(url.href);
                        } else {
                            setResponse({
                                data: useRecursion ? dataCollection : data,
                                error: undefined,
                                loading: !!moreToLoad,
                                response: moreToLoad ? {ok: undefined, status: undefined} : { ok: r.status >= 200 && r.status < 300, status: r.status }
                            });
                        }
                    })
                    .catch(error => {
                        setResponse({
                            data: undefined,
                            error: error,
                            loading: false,
                            response: { ok: false, status: error.response.data.statusCode }
                        });
                    })
            );}
            await doRequest(requestUrl);
        },
        [useRecursion, perPage, countKey, dataKey],
    );

    useEffect(() => {
        authedConfigRef.current = authedConfig;
    }, [authedConfig]);

    useEffect(() => {
        getRequest(url);
    }, [getRequest, url]);

    return response
};
