import axios from 'axios';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../store/auth';
import { AUTHN_URL } from '../store/url';
import { useAsyncTask } from '../util/AsyncTask';

export type ClientProjectAccessItem = {
  type: 'client-project';
  clientId: string;
  projectId: string;
};
export type SuperadminAccessItem = { type: 'superadmin' };

export type AccessItem = ClientProjectAccessItem | SuperadminAccessItem;

type Invite = {
  id: string;
  createdAt: string;
  updatedAt: string;
  access: AccessItem[];
};

export const useInviteUser = (access: AccessItem[], email: string) => {
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const httpBaseUrl = useAtomValue(AUTHN_URL);

  const invite = useCallback(
    async () =>
      (
        await axios.post<Invite>(
          `${httpBaseUrl}/open/email-invite`,
          {
            email,
            access,
            origin: window.location.origin,
          },
          authedConfig,
        )
      ).data,
    [httpBaseUrl, authedConfig, access, email],
  );

  return useAsyncTask<Invite, typeof invite>(invite);
};
