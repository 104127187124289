import { Close, NorthWest, SouthEast } from '@mui/icons-material';
import { IconButton, Paper, useTheme } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { useMobile } from '../useMobile';
import { useStyle } from './CornerOverlay.style';

export const CornerOverlay = ({
  children,
  maximisedClassName,
  toggleMinimised,
  minimised,
  full,
  setFull,
}: {
  children: ReactNode;
  maximisedClassName?: string;
  toggleMinimised?: () => void;
  minimised: boolean;
  full: boolean;
  setFull?: (goFull: boolean) => void;
}) => {
  const classes = useStyle();
  const isMobile = useMobile();
  const theme = useTheme();

  return (
    <>
      <Paper
        elevation={1}
        className={clsx(classes.root, {
          [maximisedClassName || '']: maximisedClassName && !minimised,
          [classes.rootMobile]: isMobile,
          [classes.open]: !!children && !minimised && !full,
          [classes.openMobile]: !!children && !minimised && !full && isMobile,
          [classes.openFull]: !!children && !minimised && full && !isMobile,
          [classes.openFullMobile]: !!children && !minimised && full && isMobile,
        })}
        style={{
          transition: theme.transitions?.create('all', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
        sx={{
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '0.4em',
          },
          '&::-webkit-scrollbar-track': {
            background: "#555",
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#f1f1f1'
          }
        }}
      >
        {children && !minimised && !!toggleMinimised && (
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              background: '#555c',
            }}
            size="small"
            onClick={toggleMinimised}
          >
            <Close />
          </IconButton>
        )}
        {children && !minimised && !!setFull && (
          <IconButton
            sx={{
              position: 'absolute',
              right: 48,
              top: 8,
              background: '#555c',
            }}
            size="small"
            onClick={() => setFull(!full)}
          >
            {full ? <SouthEast /> : <NorthWest />}
          </IconButton>
        )}
        {children}
      </Paper>
    </>
  );
};
