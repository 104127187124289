import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Divider, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { clientProjectHack } from '../../../auth/clientProjectHack';
import { useLogout } from '../../../auth/useLogout';
import { CID, PID, USER_LABEL, SUPER_ADMIN } from '../../../store/user';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

export const AccountSidebar = () => {
  const { showSecondary } = useSidebarInteraction();

  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const userLabel = useAtomValue(USER_LABEL);
  const superAdmin = useAtomValue(SUPER_ADMIN);
  const logout = useLogout();
  const navigate = useNavigate();

  const [displayCid, displayPid] = clientProjectHack(cid, pid);

  return (
    <List component="div" disablePadding>
      <ListItem button onClick={() => showSecondary(Secondary.NONE)}>
        <ListItemIcon>
          <ArrowBack />
        </ListItemIcon>
      </ListItem>
      <Divider />
      <Box p={2} component="div">
        <Typography variant="h6">{displayCid} / {displayPid}</Typography>
        <Typography variant='subtitle1'>{userLabel}</Typography>
      </Box>
      {superAdmin && (
        <ListItem button onClick={() => navigate('/super-admin')}>
          <Button variant="outlined" fullWidth={true}>Super admin</Button>
        </ListItem>
      )}
      <ListItem button onClick={() => navigate('/my-account')}>
        <Button variant="outlined" fullWidth={true}>My account</Button>
      </ListItem>
      <ListItem button onClick={() => navigate('/project-selection')}>
        <Button variant="outlined" fullWidth={true}>Change project</Button>
      </ListItem>
      <ListItem button onClick={() => logout()}>
        <Button variant="outlined" fullWidth={true}>Log out</Button>
      </ListItem>
    </List>
  );
};