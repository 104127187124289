import { Send } from '@mui/icons-material';
import { Box, Fab, TextareaAutosize, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import React, { useCallback, useState } from 'react';
import { AUTHED_REQUEST_CONFIG } from '../../../store/auth';
import { INGESTOR_URL, STREAMER_URL, UG_LEGACY_API } from '../../../store/url';
import { useMapData } from '../MapData/useMapData';

export const MessageDevice = ({ id }: { id?: string }) => {
  const [message, setMessage] = useState('');
  const [state, setState] = useState<'error' | 'success' | null>(null);
  const [loading, setLoading] = useState(false);
  const { data: mapData } = useMapData();
  const ingestorUrl = useAtomValue(INGESTOR_URL);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);

  const sendMessage = useCallback(async () => {
    try {
      setLoading(true);
      const body = {
        iso8601: new Date().toISOString(),
        devices: [id],
        text: message.trim(),
      };
      const resp = await fetch(`${ingestorUrl}/${mapData?.clientId}/${mapData?.projectId}/underground/message`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          ...authedConfig.headers,
          'content-type': 'application/json; charset=utf-8'
        }
      });

      if (resp.status < 200 || resp.status >= 300) {
        setState('error');
        return;
      }

      setState('success');
    } finally {
      setLoading(false);
    }
  }, [id, message, authedConfig.headers, ingestorUrl, mapData?.clientId, mapData?.projectId]);

  return (
    <>
      <Box display="flex" component="div">
        <TextareaAutosize
          disabled={!id || loading}
          style={{ width: '100%', height: 40 }}
          value={message}
          onChange={e => {
            setState(null);
            setMessage(e.target.value);
          }}
        />
        <Box ml={1} component="div">
          <Fab
            size="small"
            onClick={sendMessage}
            disabled={!id || !mapData || loading || message.trim() === '' || state === 'success'}
          >
            <Send />
          </Fab>
        </Box>
      </Box>
      {state === 'error' && (
        <Typography variant="caption" color="error">
          Failed to send message, please try again shortly.
        </Typography>
      )}
      {state === 'success' && (
        <Typography variant="caption" color="primary">
          Message was sent successfully.
        </Typography>
      )}
    </>
  );
};
