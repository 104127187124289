import { Button, FormControlLabel, Grid, Slider, Switch, TextField } from '@mui/material';
import { formatDuration } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AutoFormLabel } from '../../../util/FormControl/AutoFormLabel';
import { Dropdown } from '../../../util/FormControl/Dropdown';
import { Microfence } from '../../api/Microfence.validator';
import { MicrofencePair } from '../../api/MicrofencePair.validator';
import { useMapData } from '../MapData/useMapData';
import { useEditingData } from './useEditingData';

const minutesAndSecondsFormat = (seconds: number) => {
  return formatDuration({
    minutes: Math.floor(seconds / 60),
    seconds: seconds % 60
  })
}
const shortMinSecFormat = (seconds: number) =>
  `${Math.floor(seconds / 60)}:${(seconds % 60).toString().padStart(2, '0')}`;

const MICROFENCE_TYPES = [
  { value: 'locator', label: 'Locator' },
  { value: 'gateway', label: 'MQTT Gateway' },
];

export const EditInfoMicrofence = ({ asset }: { asset: Microfence | MicrofencePair }) => {
  const { tool, setCurrentEdit, dispatchMircofenceEdit, dispatchMircofencePairEdit, setCurrentDeletion } = useEditingData();
  const { data: mapData } = useMapData();
  const [newName, setNewName] = useState<string>(asset.name ?? '');
  const [newMicrofenceAssetId, setNewMicrofenceAssetId] = useState<Record<string, string | undefined>>('assetId' in asset ? asset.assetId : asset.upstreamId);
  const [newMicrofenceDownstreamAssetId, setNewMicrofenceDownstreamAssetId] = useState<Record<string, string | undefined>>('downstreamId' in asset ? asset.downstreamId : {});
  const [newBoundaryRssi, setNewBoundaryRssi] = useState<number>(asset.boundaryRssi);
  const [newTimeoutSeconds, setNewTimeoutSeconds] = useState<number>(asset.timeoutSeconds);
  const [newType, setNewType] = useState<string>(asset.type);
  const [newZone, setNewZone] = useState<string | undefined>(asset.zone);
  const [newIsTagboard, setNewIsTagboard] = useState<boolean>('isTagboard' in asset ? asset.isTagboard : false);

  const newTypePrefix = newType === 'gateway' ? 'mqtt' : '';
  const newTypeId = `${newTypePrefix}${newType}Id`;
  const isPair = 'downstreamId' in asset;

  useEffect(() => { // handle asset changing while open
    if (asset?.id) {
      setNewName(asset.name ?? '');
      setNewMicrofenceAssetId('assetId' in asset ? asset.assetId : asset.upstreamId);
      setNewMicrofenceDownstreamAssetId('downstreamId' in asset ? asset.downstreamId : {});
      setNewBoundaryRssi(asset.boundaryRssi);
      setNewTimeoutSeconds(asset.timeoutSeconds);
      setNewType(asset.type);
      setNewZone(asset.zone);
      setNewIsTagboard('isTagboard' in asset ? asset.isTagboard : false);
    }
  }, [asset]);

  const newMicrofenceAssetIsLocator = !!newMicrofenceAssetId['locator'];
  useEffect(() => { // handle changing type when setting assetIds
    if (newMicrofenceAssetIsLocator) {
      setNewType('locator');
    }
  }, [newMicrofenceAssetIsLocator])

  const isValid = useMemo(() => {
    if (!newName) return false;

    if (Object.values(newMicrofenceAssetId).filter(v => !!v).length === 0) return false;

    if (isPair && Object.values(newMicrofenceDownstreamAssetId).filter(v => !!v).length === 0) return false;

    return true
  }, [newName, isPair, newMicrofenceAssetId, newMicrofenceDownstreamAssetId]);

  const dispatchEdit = useCallback(() => {
    if (!isValid) throw new Error('UI should not try to dispatch invalid data');

    if ('assetId' in asset) {
      const update: Microfence = {
        ...asset,
        name: newName,
        assetId: newMicrofenceAssetId,
        boundaryRssi: newBoundaryRssi,
        timeoutSeconds: newTimeoutSeconds,
        type: newType,
        zone: newZone || undefined,
      };
      dispatchMircofenceEdit(update)
    } else {
      const update: MicrofencePair = {
        ...asset,
        name: newName,
        upstreamId: newMicrofenceAssetId,
        downstreamId: newMicrofenceDownstreamAssetId,
        boundaryRssi: newBoundaryRssi,
        timeoutSeconds: newTimeoutSeconds,
        type: newType,
        zone: newZone || undefined,
        isTagboard: newIsTagboard,
      };
      dispatchMircofencePairEdit(update)
    }
    setCurrentEdit(undefined);

  }, [asset, setCurrentEdit, dispatchMircofenceEdit, dispatchMircofencePairEdit, isValid, newName, newMicrofenceAssetId, newMicrofenceDownstreamAssetId, newBoundaryRssi, newTimeoutSeconds, newType, newZone, newIsTagboard])

  const requestDeletion = useCallback(() => {
    setCurrentDeletion(asset)
  }, [asset, setCurrentDeletion])

  const revertChanges = useCallback(() => {
    if (isPair) {
      dispatchMircofencePairEdit({ id: asset.id, revert: true });
    } else {
      dispatchMircofenceEdit({ id: asset.id, revert: true });
    }
    setCurrentEdit(undefined);
  }, [isPair, asset.id, setCurrentEdit, dispatchMircofenceEdit, dispatchMircofencePairEdit]);

  const locatedAssetType = newType === 'gateway' ? 'mqttgateway' : 'locator';
  const assets = mapData?.locatedAssets.filter(l => l.type === locatedAssetType) ?? [];

  const currentUpstreamAssetId = newMicrofenceAssetId[newTypeId] ?? (Object.values(newMicrofenceAssetId).filter(v => !!v).length > 0 ? JSON.stringify(newMicrofenceAssetId) : '');
  const upstreamLocatorOptions = assets.map(({ id, label }) => ({ value: id, label: label ?? id }))
  if (currentUpstreamAssetId && !upstreamLocatorOptions.find(({ value }) => value === currentUpstreamAssetId)) {
    upstreamLocatorOptions.push({ value: currentUpstreamAssetId, label: `Unknown asset ${currentUpstreamAssetId}` })
  }
  const currentDownstreamAssetId = newMicrofenceDownstreamAssetId[newTypeId] ?? (Object.values(newMicrofenceDownstreamAssetId).filter(v => !!v).length > 0 ? JSON.stringify(newMicrofenceDownstreamAssetId) : '');
  const downstreamLocatorOptions = assets.map(({ id, label }) => ({ value: id, label: label ?? id }))
  if (currentDownstreamAssetId && !downstreamLocatorOptions.find(({ value }) => value === currentDownstreamAssetId)) {
    downstreamLocatorOptions.push({ value: currentDownstreamAssetId, label: `Unknown asset ${currentDownstreamAssetId}` })
  }

  const zoneOptions = useMemo(() => isPair
    ? [{ value: 'cycleStart', label: 'Cycle start and end' }]
    : [
      { value: 'loading', label: 'Loading' },
      { value: 'dumping', label: 'Dumping' },
      { value: 'maintenance', label: 'Maintenance' },
      { value: 'checkin', label: 'Check In',}
    ],
    [isPair]);

  const typeDropdownDisabled = isPair
    ? Object.values(newMicrofenceAssetId).filter(v => !!v).length > 0
    : Object.values(newMicrofenceAssetId).filter(v => !!v).length + Object.values(newMicrofenceDownstreamAssetId).filter(v => !!v).length > 0;

  return (<>
    <Grid item>
      <TextField label="Name" variant="outlined" fullWidth value={newName} onChange={e => setNewName(e.target.value)} required />
    </Grid>
    <Grid item maxWidth="100% !important">
      <Dropdown
        required
        noNone
        disabled={typeDropdownDisabled}
        label='Type'
        selected={newType ?? ''}
        setSelected={val => {
          setNewMicrofenceAssetId({});
          setNewMicrofenceDownstreamAssetId({});
          setNewType(val || '');
        }}
        items={MICROFENCE_TYPES}
      />
    </Grid>
    <Grid item>
      <Dropdown
        required
        label={(isPair ? "Upstream " : "") + newType.slice(0, 1).toLocaleUpperCase() + newType.slice(1)}
        helperText={isPair ? `${newType.slice(0, 1).toLocaleUpperCase() + newType.slice(1)} on entering side of crossing` : undefined}
        selected={currentUpstreamAssetId}
        setSelected={assetId => {
          if (assetId !== JSON.stringify(newMicrofenceAssetId)) {
            setNewMicrofenceAssetId({ [newTypeId]: assetId });
          }
        }}
        items={upstreamLocatorOptions}
      />
    </Grid>
    {isPair && (
      <Grid item>
        <Dropdown
          required
          label={"Downstream " + newType.slice(0, 1).toLocaleUpperCase() + newType.slice(1)}
          helperText={`${newType.slice(0, 1).toLocaleUpperCase() + newType.slice(1)} on exiting side of crossing`}
          selected={currentDownstreamAssetId}
          setSelected={assetId => {
            if (assetId !== JSON.stringify(newMicrofenceDownstreamAssetId)) {
              setNewMicrofenceDownstreamAssetId({ [newTypeId]: assetId });
            }
          }}
          items={downstreamLocatorOptions}
        />
      </Grid>
    )}
    <Grid item maxWidth="100%" container direction="row" spacing={2} alignItems='center' pr={1}>
      <Grid item xs={6}>
        <AutoFormLabel text="Boundary RSSI" value={newBoundaryRssi} unit='dBm' units='dBm' />
      </Grid>
      <Grid item xs={6} alignItems='center' sx={{ display: 'flex' }}>
        <Slider
          aria-label="Boundary RSSI"
          value={newBoundaryRssi}
          valueLabelDisplay="auto"
          step={1}
          min={-100}
          max={-60}
          track="inverted"
          onChange={(event, value) => setNewBoundaryRssi(Array.isArray(value) ? value[0] : value)}
        />
      </Grid>
      <Grid item xs={6}>
        <AutoFormLabel text="Timeout" value={newTimeoutSeconds} unit='second' units='seconds' formatter={minutesAndSecondsFormat} />
      </Grid>
      <Grid item xs={6} alignItems='center' sx={{ display: 'flex' }}>
        <Slider
          aria-label="Timeout"
          value={newTimeoutSeconds}
          valueLabelDisplay="auto"
          getAriaValueText={minutesAndSecondsFormat}
          valueLabelFormat={shortMinSecFormat}
          step={5}
          min={0}
          max={300}
          onChange={(event, value) => setNewTimeoutSeconds(Math.max(5, Array.isArray(value) ? value[0] : value))}
        />
      </Grid>
    </Grid>
    <Grid item>
      <Dropdown
        label="Zone"
        selected={newZone}
        setSelected={zone => setNewZone(zone)}
        items={zoneOptions}
      />
    </Grid>

    {isPair && (
      <Grid item>
        <FormControlLabel control={<Switch checked={newIsTagboard} onChange={e => setNewIsTagboard(e.target.checked)} />} label="Report crossings" />
      </Grid>
    )}

    <Grid item container>
      <Button variant="contained" disabled={!isValid} onClick={dispatchEdit}>Set</Button>
      <Button variant="outlined" onClick={revertChanges}>Undo changes</Button>
      <Button color='warning' variant="outlined" onClick={requestDeletion}>Delete</Button>
    </Grid>
  </>
  );
};
