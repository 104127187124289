import { Link, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

export const PasskeyInfo = () => {
  const [expanded, expand] = useState(false);

  return (
    <Paper elevation={5} sx={{ background: '#fff1', padding: 2 }}>
      {expanded && (
        <>
          <Typography sx={{ fontWeight: '600' }}>What are passkeys?</Typography>
          <Typography variant="caption">
            Passkeys are encrypted digital keys you create using your fingerprint, face, or screen
            unlock.
          </Typography>
          <Typography sx={{ fontWeight: '600', marginTop: 1 }}>
            Where are passkeys stored?
          </Typography>
          <Typography variant="caption">
            Passkeys are saved to your password manager, so you can sign in on other devices.
          </Typography>
          <Typography sx={{ fontWeight: '600', marginTop: 1 }}>Are passkeys secure?</Typography>
          <Typography variant="caption">
            Passkeys provide the strongest protection. Unlike passwords, they can never be guessed,
            reused, or stolen.{' '}
            <Link sx={{ cursor: 'pointer' }} onClick={() => expand(false)}>
              Show less
            </Link>
            .
          </Typography>
        </>
      )}
      {!expanded && (
        <>
          <Typography sx={{ fontWeight: '600' }}>
            Create a passkey for faster, easier access
          </Typography>
          <Typography variant="caption">
            With passkeys, you don't need to remember complex passwords. Instead use your
            fingerprint, face, or screen unlock to sign in.{' '}
            <Link sx={{ cursor: 'pointer' }} onClick={() => expand(true)}>
              &#32;Learn more
            </Link>
            .
          </Typography>
        </>
      )}
    </Paper>
  );
}
