import { Line } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { boolean } from 'fp-ts';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Group, Object3D, Vector3 } from 'three';
import { BEACON_OLD_LOCATION_SECONDS, SAFE_DISTANCE_METRES, TRUCK_SPEED_METERS_PER_SECOND, WALKING_SPEED_METERS_PER_SECOND } from '../../../../../config';
import { AssetState } from '../../../../../util/Events/Messages';
import { stringifyIdRecord } from '../../../../../util/stringUtils';
import { useSecondsAgo } from '../../../../../util/useSecondsAgo';
import { PortableAssetGroup } from '../../../../api/PortableAsset.helpers';
import { beaconLabelHack } from '../../../../util/beaconIdHack';
import { findNavmeshPathToNearest, levelIdOfPoint } from '../../../../util/findNavmeshPath';
import useInterval from '../../../../util/useInterval';
import { useInterpolatedMapData } from '../../../MapData/useInterpolatedData';
import { useMapData } from '../../../MapData/useMapData';
import { useMapInteraction } from '../../../MapInteraction/useMapInteraction';
import { MapText } from '../../../MapText/MapText';
import { Pin } from '../../../Pin/Pin';
import { BatteryIcon } from '../../../WorldOverlay/BatteryIcon/BatteryIcon';
import { HeartbeatIcon } from '../../../WorldOverlay/HeartbeatIcon/HeartbeatIcon';
import { WorldOverlay } from '../../../WorldOverlay/WorldOverlay';
import { Directions } from '../Directions/Directions';
import { useInterpolatePosition } from './useInterpolatePosition';
import { useModelType } from './useModelType';
import { usePositionRotationFromLocation } from './usePositionRotationFromLocation';

export const Beacon = (state: AssetState & { isVisible: (position: Vector3 | undefined) => boolean }) => {
  const {
    highlighted,
    highlight,
    settings: { beaconModels, beaconLabels, beaconIcons, beaconLines, beaconParticles, interpolate },
    navigation,
    targetObject: cameraTarget,
    lastKnownForHighlighted,
  } = useMapInteraction();
  const { dispatchWelfareUpdate, dispatchLevelUpdate } = useInterpolatedMapData();
  const { getNow, data: mapData, inWelfareCheck } = useMapData();

  const initialsed = useRef<boolean>(false);

  const groupRef = useRef<Group>(null);
  const modelRef = useRef<Object3D>(null);
  const interpolatedPaths = useRef<Vector3[][]>([]);
  const selected = highlighted?.category === 'beacon' && highlighted?.id === stringifyIdRecord(state.id);
  const navigating = navigation?.id === stringifyIdRecord(state.id);
  const selectedOrNavigating = selected || (highlighted?.category !== 'beacon' && navigating);
  const secondsSinceLastUpdate = useSecondsAgo({ from: state.lastUpdate, getNow });
  const secondsSinceLastLocationUpdate = useSecondsAgo({ from: state.lastLocation ? new Date(state.lastLocation.iso8601) : undefined, getNow });
  const isOldLocation = secondsSinceLastLocationUpdate ? secondsSinceLastLocationUpdate > BEACON_OLD_LOCATION_SECONDS : false;
  const [beaconModel, beaconIcon] = useModelType(state.model ?? undefined, state.type ?? undefined);
  const [x, setX] = useState<number>();
  const [y, setY] = useState<number>();
  const [z, setZ] = useState<number>();
  const visibilityCheckVec3 = useMemo(() => new Vector3(), []);
  const [level, setLevel] = useState<number | undefined>(undefined);

  const lastKnownLocationForSelected = selected ? lastKnownForHighlighted : undefined;

  useInterval(() => {
    if (!groupRef.current) return;

    setX(groupRef.current.position.x)
    setY(groupRef.current.position.y)
    setZ(groupRef.current.position.z)
  }, 1501)

  const nearestSafeZone = useMemo(() => {
    if (x === undefined || y === undefined || z === undefined || !mapData?.navMesh || !groupRef.current) return undefined;

    const nearestSafeZone = findNavmeshPathToNearest(mapData.navMesh, mapData.locatedAssets.filter(a => a.safe).map(a => a.position), groupRef.current.position)
    if (!nearestSafeZone) return undefined;

    return nearestSafeZone;
  }, [x, y, z, mapData?.navMesh, mapData?.locatedAssets])


  const safe = !!(nearestSafeZone && nearestSafeZone.length <= SAFE_DISTANCE_METRES);
  const showSafe = inWelfareCheck ? safe : undefined

  const nearestZoneX = nearestSafeZone?.path.at(-1)?.x;
  const nearestZoneY = nearestSafeZone?.path.at(-1)?.y;
  const nearestZoneZ = nearestSafeZone?.path.at(-1)?.z;

  const ids = useMemo(() => stringifyIdRecord(state.id), [state.id]);

  useEffect(() => {
    const nearestZone: [number, number, number] | undefined = nearestZoneX !== undefined && nearestZoneY !== undefined && nearestZoneZ !== undefined ? [nearestZoneX, nearestZoneY, nearestZoneZ] : undefined;
    dispatchWelfareUpdate({ ids, safe, nearestZone })
  }, [ids, safe, nearestZoneX, nearestZoneY, nearestZoneZ, dispatchWelfareUpdate])

  const isVisible = state.isVisible;
  const visible = useMemo(() => {
    if (x === undefined || y === undefined || z === undefined) return false;

    visibilityCheckVec3.set(x, y, z);

    if (mapData?.navMesh) {
      setLevel(levelIdOfPoint(visibilityCheckVec3, mapData.navMesh));
    }

    const visibility = isVisible(visibilityCheckVec3);
    if (groupRef.current) {
      groupRef.current.visible = visibility
    }

    return visibility;
  }, [x, y, z, visibilityCheckVec3, isVisible, mapData?.navMesh])

  useEffect(() => {
    dispatchLevelUpdate({ ids, level })
  }, [ids, level, dispatchLevelUpdate]);

  usePositionRotationFromLocation(state.lastLocation, groupRef, !initialsed.current || !interpolate)

  const addToPath = useCallback((path: Vector3[]) => {
    if (path?.length > 0) {
      if (!initialsed.current) {
        initialsed.current = true;
      } else if (path.length === 2 && path[0].equals(path[1])) {
        return; // ignore path to same point
      } else {
        interpolatedPaths.current = [...interpolatedPaths.current, path].slice(-20);
      }
    }
  }, [])

  useInterval(() => {
    if (!groupRef.current || !selectedOrNavigating) return;

    cameraTarget.position.copy(groupRef.current.position)
    cameraTarget.quaternion.copy(groupRef.current.quaternion)
  }, 220)

  const {x: lkX, y: lkY, z: lkZ} = lastKnownLocationForSelected ?? {}
  useEffect(() => {
   if (groupRef.current && lkX !== undefined && lkY !== undefined && lkZ !== undefined) {
    groupRef.current.position.set(lkX, lkY, lkZ);
    groupRef.current.rotation.set(1,1,0);
   } 
  }, [lkX, lkY, lkZ])

  const speed = beaconIcon === 'personnel' ? WALKING_SPEED_METERS_PER_SECOND : TRUCK_SPEED_METERS_PER_SECOND;
  useInterpolatePosition(state.lastLocation?.position, groupRef, speed, addToPath, interpolate, state.label);

  const hide = !state.recentLocations?.length && !lastKnownLocationForSelected;

  return hide ? <Fragment><group ref={groupRef} /></Fragment> : (
    <Fragment>
      {groupRef.current && navigating && <Directions from={groupRef.current.position} />}
      {beaconLines && selected && interpolate && interpolatedPaths.current.length > 0 && (
        <Line points={interpolatedPaths.current[interpolatedPaths.current.length - 1]} color={'lightblue'} />
      )}
      {beaconLines && selected && !interpolate && state.recentLocations && state.recentLocations.length > 0 && (
        <Line points={state.recentLocations.map(({ position }) => new Vector3(position.x, position.y, position.z))} color={'lightblue'} />
      )}
      <group ref={groupRef}>
        {beaconModels && state.lastLocation && !lastKnownLocationForSelected && <primitive ref={modelRef} object={beaconModel}></primitive>}
        {beaconIcons && (visible || lastKnownLocationForSelected) && (
          <Pin
            position={new Vector3(0, 3, 0)}
            selected={selected}
            uncertain={state.lastLocation?.confidence !== undefined ? state.lastLocation.confidence < 0.5 : false}
            safe={showSafe}
            ert={state.group === PortableAssetGroup.ERT}
            icon={`beacon-${beaconIcon}` as const}
            isGhost={!!(isOldLocation || lastKnownLocationForSelected)}
            onClick={() =>
              highlight({
                category: 'beacon',
                id: stringifyIdRecord(state.id),
                label: state.label ?? state.id['beaconId'] ?? stringifyIdRecord(state.id)
              })
            }
          />
        )}
        {beaconLabels && (visible || lastKnownLocationForSelected) && (
          <WorldOverlay position={new Vector3(0, 3, 0)} unselectable={'on'}>
            <MapText opacity={isOldLocation ? 0.5 : 1}>
              {beaconLabelHack(state.label ?? state.id['beaconId'] ?? stringifyIdRecord(state.id))}
              <BatteryIcon percentage={state.lastBattery?.percent} />
              <HeartbeatIcon secondsSinceLast={secondsSinceLastUpdate} />
            </MapText>
          </WorldOverlay>
        )}
      </group>
      {beaconParticles && state.lastLocation?.position && visible && !lastKnownLocationForSelected && (
        <group>
          <mesh scale={1.5} position={new Vector3(state.lastLocation.position.x, state.lastLocation.position.y, state.lastLocation.position.z)} castShadow receiveShadow>
            <sphereGeometry args={[0.25, 15, 15]} />
            {/* <boxGeometry args={[0.5, 1, 0.75]} /> */}
            <meshStandardMaterial attach="material" color="red" />
          </mesh>
          <WorldOverlay
            position={new Vector3(state.lastLocation.position.x, state.lastLocation.position.y, state.lastLocation.position.z)}
            unselectable={'on'}>
            <MapText>
              Latest {beaconLabelHack(state.label ?? state.id['beaconId'] ?? stringifyIdRecord(state.id))}
            </MapText>
          </WorldOverlay>
        </group>
      )}
      {!!lastKnownLocationForSelected && (
        <group>
        <mesh scale={1.5} position={new Vector3(lastKnownLocationForSelected.x, lastKnownLocationForSelected.y, lastKnownLocationForSelected.z+0.5)} castShadow receiveShadow>
          <sphereGeometry args={[0.45, 15, 15]} />
          <meshStandardMaterial attach="material" color="gold" />
        </mesh>
      </group>
      )}
    </Fragment>
  );
};
