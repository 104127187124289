import { BadgeOutlined, Key, Password } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { NavigationBar } from '../Components/NavigationBar';
import { PasskeyInfo } from '../auth/PasskeyInfo';
import { useOidcEnabled } from '../auth/useOidcEnabled';
import { usePasskeyCount } from '../auth/usePasskeyCount';
import { usePasswordLastChanged } from '../auth/usePasswordLastChanged';
import { useRequestRecoveryEmail } from '../auth/useRequestRecoveryEmail';
import { CID_PID_PAIRS, ME, SUPER_ADMIN } from '../store/user';

export const UserAccount = () => {
  const user = useAtomValue(ME);
  const isSuperAdmin = useAtomValue(SUPER_ADMIN);
  const clientProjects = useAtomValue(CID_PID_PAIRS);
  const {
    execute: requestRecovery,
    loading: recoveryLoading,
    data: recoverySent,
    error: recoveryError,
  } = useRequestRecoveryEmail(user.label);
  const { execute: getPasskeyCount, data: passkeyCount, loading: passkeyCountLoading } = usePasskeyCount();
  const { execute: getPasswordLastChanged, data: passwordLastChanged, loading: passwordLastChangedLoading } = usePasswordLastChanged();
  const { execute: checkEmail, data: sso, loading: ssoCheckLoading } = useOidcEnabled(user.label);

  const ssoEnabled = !!sso?.canUseOidc;

  const loading = passkeyCountLoading || passwordLastChangedLoading || ssoCheckLoading;

  useEffect(() => {
    getPasskeyCount();
  }, [getPasskeyCount]);

  useEffect(() => {
    getPasswordLastChanged();
  }, [getPasswordLastChanged]);

  useEffect(() => {
    checkEmail();
  }, [checkEmail])

  return (
    <>
      <NavigationBar currentPath='my-account' />
      <Container component="div" maxWidth="md">
        <Stack p={2} spacing={4} marginTop={2}>
          <Paper>
            <Stack p={2} direction="column" spacing={2}>
              <Typography variant="h6">User account</Typography>
              <Typography>
                {user.label}
                {isSuperAdmin && <Typography variant="caption">{' (STAFF)'}</Typography>}
              </Typography>
            </Stack>
          </Paper>

          <Paper>
            <Stack p={2} direction="column" spacing={2}>
              <Typography variant="h6">Projects</Typography>
              {clientProjects.map(({ clientId, projectId }) => (
                <Typography
                  key={`${clientId}/${projectId}`}
                >{`${clientId} / ${projectId}`}</Typography>
              ))}
            </Stack>
          </Paper>

          <Paper>
            <Stack component='div' p={2} direction="column" spacing={2}><>
              <Typography variant="h6">Sign-in methods</Typography>
              <List dense={false}>
                {loading && (<ListItem>
                  <ListItemIcon>
                    <Skeleton variant="text" width={24} height={24} />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Skeleton variant="text" width='10rem' />}
                    secondary={<Skeleton variant="text" width='6rem' />}
                  />
                  </ListItem>)}
                {!loading && (<>
                <ListItem sx={{display: ssoEnabled ? 'none' : undefined}}>
                  <ListItemIcon>
                    <Key color={(passkeyCount || 0) !== 0 ? 'success' : 'disabled'} />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Passkey`}
                    secondary={
                      (passkeyCount || 0) === 0
                        ? 'None'
                        : passkeyCount === 1
                          ? '1 passkey'
                          : `${passkeyCount} passkeys`
                    }
                  />
                </ListItem>
                <ListItem sx={{display: ssoEnabled ? 'none' : undefined}}>
                  <ListItemIcon>
                    <Password color={passwordLastChanged !== undefined ? 'success' : 'disabled'} />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Password`}
                    secondary={
                      passwordLastChanged === undefined
                        ? 'Not set'
                        : 'Last changed ' + new Date(passwordLastChanged).toLocaleDateString()
                    }
                  />
                </ListItem>
                </>)}
                {!loading && (<ListItem>
                  <ListItemIcon>
                    <BadgeOutlined color={ssoEnabled ? 'success' : 'disabled'} />
                  </ListItemIcon>
                  <ListItemText
                    primary={`Single Sign-On`}
                    secondary={ssoEnabled ? `with ${sso.provider}` : 'Not available'}
                  />
                </ListItem>)}
              </List>
              {!loading && !ssoEnabled && <>
                <PasskeyInfo />
                <Typography>
                  To create a passkey or reset your password, request account recovery.
                </Typography>
                <Stack direction="row">
                  <LoadingButton
                    variant="contained"
                    fullWidth={false}
                    loading={recoveryLoading}
                    size="large"
                    color="secondary"
                    onClick={requestRecovery}
                    disabled={recoverySent}
                  >
                    {recoverySent ? 'Submitted' : 'Request account recovery'}
                  </LoadingButton>
                </Stack>
                {recoveryError && (
                  <Typography variant="caption" color="red">
                    {Object(recoveryError).message}
                  </Typography>
                )}
                {recoverySent && <Typography>Check your email for instructions.</Typography>}
              </>}
            </></Stack>
          </Paper>
        </Stack>
      </Container>
    </>
  );
};
