import { HelpOutlined } from '@mui/icons-material';
import { Grid, Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { useSecondsAgo } from '../../../util/useSecondsAgo';
import { useMapData } from '../MapData/useMapData';
import { BatteryIconVerbose } from '../WorldOverlay/BatteryIcon/BatteryIcon';
import { HeartbeatIconVerbose } from '../WorldOverlay/HeartbeatIcon/HeartbeatIcon';

export const CommonItem = ({
  title,
  icon,
  children,
}: {
  title: string;
  icon: ReactNode;
  children: ReactNode;
}) => (
  <Grid item>
    <Stack direction="row" alignItems="center" spacing={2}>
      {icon}
      <Typography variant="h6">{title}</Typography>
      {children}
    </Stack>
  </Grid>
);

export const BatteryInfo = ({ batteryPercentage }: { batteryPercentage?: number }) => {
  return (
    <>
      <CommonItem
        icon={<BatteryIconVerbose percentage={batteryPercentage} />}
        title="Battery level:"
      >
        <Typography>
          {batteryPercentage !== undefined && batteryPercentage >= 0
            ? `${Math.round(batteryPercentage)}%`
            : `unknown`}
        </Typography>
      </CommonItem>
    </>
  );
};

export const UpdateInfo = ({ lastUpdate, oldSeconds, oldMessage }: { lastUpdate?: Date, oldSeconds?: number, oldMessage?: string }) => {
  const { getNow } = useMapData();
  const secondsSinceLastUpdate = useSecondsAgo({ from: lastUpdate, getNow });

  return (
    <>
      <CommonItem
        icon={<HeartbeatIconVerbose secondsSinceLast={secondsSinceLastUpdate} />}
        title="Last update:"
      >
        <Typography>
          {secondsSinceLastUpdate !== undefined
            ? `${secondsSinceLastUpdate} seconds ago`
            : `unknown`}
        </Typography>
      </CommonItem>
      {oldMessage && oldSeconds && secondsSinceLastUpdate && secondsSinceLastUpdate > oldSeconds && (
        <Grid item marginTop={-3} marginLeft={5}>
          <Typography>
            {oldMessage}
          </Typography>
        </Grid>)}
    </>
  );
};

export const UncertainPositionInfo = ({ confidence }: { confidence?: number }) => {
  if (confidence === undefined || confidence >= 0.5) return <></>;

  return (
    <>
      <CommonItem
        icon={<HelpOutlined />}
        title="Uncertain position"
      >
        <></>
      </CommonItem>
      <Grid item marginTop={-2} marginLeft={5}>
        <Typography>
          Positioned with reduced location accuracy due to weak signal strength.
        </Typography>
      </Grid>
    </>
  );
};
