import { differenceInSeconds } from 'date-fns';
import { boolean } from 'fp-ts';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SearchSidebar } from '../../../util/SearchSidebar/SearchSidebar';
import { stringifyIdRecord } from '../../../util/stringUtils';
import { portableAssetOnLevelFilter } from '../../util/assetUtils';
import { beaconLabelHack } from '../../util/beaconIdHack';
import { levelIdOfPoint } from '../../util/findNavmeshPath';
import { isActive } from '../../util/portableAssetUtils';
import { useInterpolatedMapData } from '../MapData/useInterpolatedData';
import { portbaleAssetsFromLiveData, useMapData } from '../MapData/useMapData';
import { useMapInteraction } from '../MapInteraction/useMapInteraction';
import { Secondary, useSidebarInteraction } from '../SidebarInteraction/useSidebarInteraction';

export const BeaconSidebar = () => {
  const map = useMapData();
  const { highlight, highlighted, level } = useMapInteraction();
  const { showSecondary } = useSidebarInteraction();
  const { assetsLevels } = useInterpolatedMapData();
  const onSelectedLevel = portableAssetOnLevelFilter(level, assetsLevels);

  const highlightedBeacon =
    highlighted && highlighted.category === 'beacon' ? highlighted.id : undefined;

  const assets = (map.data?.liveData ? portbaleAssetsFromLiveData(map.data.liveData) : [])
    .filter(onSelectedLevel);
  const now = map.getNow();

  return (
    <SearchSidebar
      title="Beacons"
      onClick={(id: string, label: string) => {
        highlight({ category: 'beacon', id, label });
      }}
      hide={() => showSecondary(Secondary.NONE)}
      options={ assets
          .filter((asset) => isActive(asset, now))
          .map((asset) => ({
            label: beaconLabelHack(asset.label),
            id: stringifyIdRecord(asset.id),
            selected: highlightedBeacon === stringifyIdRecord(asset.id),
          })) || []
      }
    />
  );
};
