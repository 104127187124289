import { registerType, createDetailedValidator } from 'typecheck.macro';

export type MicrofencePair = {
  clientId: string;
  projectId: string;
  id: string;
  upstreamId: { [identifier: string]: string | undefined };
  downstreamId: { [identifier: string]: string | undefined };
  name?: string | null;
  isTagboard: boolean;
  boundaryRssi: number;
  timeoutSeconds: number;
  type: string;
  zone?: string;
  createdAt?: string;
  updatedAt?: string | null;
};
export type MicrofencePairs = MicrofencePair[];

registerType('MicrofencePairs');

export const isMicrofencePairs = createDetailedValidator<MicrofencePairs>({
  expectedValueFormat: 'type-ir',
});
